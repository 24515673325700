import { useEffect } from 'react';

const useBeforeUnload = (isDirty: boolean) => {
  useEffect(() => {
    // eslint-disable-next-line consistent-return
    const handleBeforeUnload = (event: any) => {
      if (isDirty) {
        const message =
          'You have unsaved changes, are you sure you want to leave?';
        event.returnValue = message; // Chrome requires this to show a confirmation dialog
        return message; // Other browsers may use this message
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isDirty]);
};

export default useBeforeUnload;
