import {
    getDataByUrl,
    postDataByUrl,
    updateDataByUrl,
    deleteDataByUrl,
    putDataByUrl
} from 'requests';



export const getCustomers = (params: any) => getDataByUrl('/api/customers/', params);

export const getCustomerById = (id: any) =>
  getDataByUrl(`/api/customers/${id}/`);

export const getCustomerNotesDate = (id: any) =>
  getDataByUrl(`/api/customers/${id}/notes/`);

export const getCustomerFilesData = (id: any) =>
  getDataByUrl(`/api/customers/${id}/files/`);

export const CreateCustomer = (customerFelds: any) =>
  postDataByUrl(`/api/customers/`, customerFelds);

export const UpdateCustomer = (id: any, customerFelds: any) =>
  putDataByUrl(`/api/customers/${id}/`, customerFelds);

export const UpdateCustomerData = (id: any, data: any) =>
  updateDataByUrl(`/api/customers/${id}/`, data);

export const DeliteCustomer = (id: any) =>
  deleteDataByUrl(`/api/customers/${id}/`);
