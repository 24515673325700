import { useState } from 'react';
import { useSnackbar } from 'notistack';
import {
  Navigate,
  useNavigate,
  useLocation,
  Link as RouterLink
} from 'react-router-dom';

import { Typography, Box, TextField, Button } from '@material-ui/core';
import PasswordPageContainer from 'components/containers/PasswordPageContainer';
import { verifyPassword } from 'requests/account';
import { getTranslatedList } from 'constants/constants';
import { getButtons } from 'constants/text/buttons';
import useLocales from 'hooks/useLocales';
import { PATH_AUTH } from 'routes/paths';

export default function ConfirmNewPassword() {
  const [newPassword, setNewPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [error, setError] = useState(false);
  const token = new URLSearchParams(useLocation().search).get('token');

  const { t } = useLocales();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { DEFAULT_MESSAGE_TEXTS } = getTranslatedList();
  const { SAVE } = getButtons();

  const onError = (text: string) => {
    enqueueSnackbar(text, {
      variant: 'warning'
    });
    setError(true);
  };

  const onSuccess = (text: string) => {
    enqueueSnackbar(text, {
      variant: 'success'
    });
    setNewPassword('');
    setRepeatPassword('');
    setError(false);
    navigate(PATH_AUTH.login);
  };

  const handleSubmit = async () => {
    if (!newPassword) {
      onError(t('Please, fill in all fields before continue'));
    } else if (newPassword !== repeatPassword) {
      onError(t('New and repeat passwords should match'));
    } else {
      const response = await verifyPassword({
        password: newPassword,
        token
      });

      if (response) {
        onSuccess(DEFAULT_MESSAGE_TEXTS.updated);
      } else {
        onError(DEFAULT_MESSAGE_TEXTS.error);
      }
    }
  };

  if (!token) return <Navigate to={PATH_AUTH.login} />;
  return (
    <PasswordPageContainer title={t('Verify new password')}>
      <Box sx={{ maxWidth: 480, mx: 'auto' }}>
        <Typography variant="h3" gutterBottom>
          {t('Verify new password')}
        </Typography>
        <Typography sx={{ color: 'text.secondary', mb: 5 }}>
          {t('Please enter a new password to continue using our site')}.
        </Typography>

        <TextField
          sx={{ mb: 2 }}
          fullWidth
          type="password"
          label={t('New Password')}
          value={newPassword}
          error={error}
          onChange={(e) => {
            setError(false);
            setNewPassword(e.target.value);
          }}
        />
        <TextField
          sx={{ mb: 2 }}
          fullWidth
          type="password"
          label={t('Repeat Password')}
          value={repeatPassword}
          error={error}
          onChange={(e) => {
            setError(false);
            setRepeatPassword(e.target.value);
          }}
        />
        <Button
          fullWidth
          size="large"
          variant="contained"
          onClick={handleSubmit}
        >
          {SAVE}
        </Button>

        <Button
          fullWidth
          size="large"
          component={RouterLink}
          to={PATH_AUTH.login}
          sx={{ mt: 1 }}
        >
          {t('Back')}
        </Button>
      </Box>
    </PasswordPageContainer>
  );
}
