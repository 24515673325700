import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { Box, Card, Grid, Switch, Typography } from '@material-ui/core';
import { getTranslatedList } from 'constants/constants';
import { updateCompanyModuleData } from 'requests/account';
import { useTranslation } from 'react-i18next';
import {
  CustomWidthTooltip,
  ModuleItem,
  SwitchModuleBox,
  useModuleStyles
} from '../../../styled';
import { moduleDescriptions } from '../../../constants/text/texts';

const ModuleSettings = ({ refreshModules, companyModules }: any) => {
  const [modules, setModules] = useState(companyModules);
  const [activeBorder, setActiveBorder] = useState(true);
  const { DEFAULT_MESSAGE_TEXTS, COMPANY_MODULES } = getTranslatedList();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useModuleStyles();

  const handleActivateBorder = () => setActiveBorder(!activeBorder);

  const handleSetValue = async (field: string, value: boolean) => {
    const data = {
      ...modules,
      [field]: value
    };
    setModules(data);
    try {
      await updateCompanyModuleData(data.id, data);
      enqueueSnackbar(DEFAULT_MESSAGE_TEXTS.updated, { variant: 'success' });
    } catch {
      enqueueSnackbar(DEFAULT_MESSAGE_TEXTS.error, { variant: 'error' });
    }
    refreshModules();
  };

  return (
    <>
      <Grid style={{ width: '448px', marginTop: '20px', marginBottom: '50px' }}>
        <Typography
          sx={{
            fontFamily: 'Rubik',
            fontSize: '20px',
            fontWeight: '400'
          }}
        >
          Use these modules to adjust the information that you want to receive
          from your applicants
        </Typography>
      </Grid>

      <Card
        sx={{ p: 2, pl: '0' }}
        style={{ background: 'transparent', boxShadow: 'none' }}
      >
        <Box
          sx={{ display: 'flex', justifyContent: 'space-between', mb: '15px' }}
        >
          <Typography variant="h3">{t('Company module insights')}</Typography>
        </Box>
        <Grid display="flex" flexDirection="row" xs={12}>
          <Grid xs={6} style={{ marginRight: '30px' }}>
            {COMPANY_MODULES.map((item: any, index: number) =>
              index < 5 ? (
                <CustomWidthTooltip
                  key={item.id}
                  title={t(moduleDescriptions[item.value])}
                  className="module-tooltip"
                >
                  <ModuleItem key={item.value}>
                    <Typography
                      style={{ fontFamily: 'Rubik', fontWeight: 700 }}
                    >
                      {item.label}{' '}
                    </Typography>
                    <SwitchModuleBox>
                      <Grid
                        style={
                          !modules[item.value]
                            ? {
                                border: '1px solid #C9CAD9 ',
                                borderRadius: '20px'
                              }
                            : {
                                border: '1px solid #8AE98A',
                                borderRadius: '20px'
                              }
                        }
                      >
                        <Switch
                          size="small"
                          checked={modules[item.value]}
                          onChange={() => {
                            handleSetValue(item.value, !modules[item.value]);
                            handleActivateBorder();
                          }}
                          classes={{
                            track: classes.switch_track,
                            switchBase: classes.switch_base,
                            colorPrimary: classes.switch_primary
                          }}
                        />
                      </Grid>
                    </SwitchModuleBox>
                  </ModuleItem>
                </CustomWidthTooltip>
              ) : null
            )}
          </Grid>
          <Grid xs={6}>
            {COMPANY_MODULES.map((item: any, index: number) =>
              index > 4 ? (
                <CustomWidthTooltip
                  key={item.id}
                  title={t(moduleDescriptions[item.value])}
                  className="module-tooltip"
                >
                  <ModuleItem>
                    <Typography
                      style={{ fontFamily: 'Rubik', fontWeight: 700 }}
                    >
                      {item.label}{' '}
                    </Typography>

                    <SwitchModuleBox>
                      <Grid
                        style={
                          !modules[item.value]
                            ? {
                                border: '1px solid #C9CAD9 ',
                                borderRadius: '20px'
                              }
                            : {
                                border: '1px solid #8AE98A',
                                borderRadius: '20px'
                              }
                        }
                      >
                        <Switch
                          size="small"
                          checked={modules[item.value]}
                          onChange={() => {
                            handleSetValue(item.value, !modules[item.value]);
                            handleActivateBorder();
                          }}
                          classes={{
                            track: classes.switch_track,
                            switchBase: classes.switch_base,
                            colorPrimary: classes.switch_primary
                          }}
                        />
                      </Grid>
                    </SwitchModuleBox>
                  </ModuleItem>
                </CustomWidthTooltip>
              ) : null
            )}
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default ModuleSettings;
