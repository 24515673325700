import { Box, Typography } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import ReactHtmlParser from 'react-html-parser';
import Divider from '@mui/material/Divider';
import useLocales from 'hooks/useLocales';
import { DeleteButton, EditButton } from './Buttons';

export interface TemplateType {
  id: any;
  label: string;
  name?: string;
  content: string;
  click_count: number;
  created_at: string;
  owner: 0;
}

export interface TemplateCardProps {
  item: TemplateType;
  onDelete: any;
  onEdit: any;
}

const TitleTypography = styled(Typography)(() => ({
  fontFamily: 'Rubik',
  fontWeight: 600,
  color: '#131417',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  lineHeight: '25px'
}));

const CountTypography = styled(Typography)(() => ({
  fontFamily: 'Rubik',
  color: '#131417',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  lineHeight: '25px'
}));

function TemplateCard({ item, onEdit, onDelete }: TemplateCardProps) {
  const { t } = useLocales();
  const templateContent = item.content;
  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        borderRadius: '16px',
        backgroundColor: '#fafafd',
        padding: '15px',
        margin: '10px',
        height: '220px',
        overflow: 'hidden'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          height: '100%',
          overflow: 'hidden'
        }}
      >
        <Box height="100%" overflow="hidden">
          <TitleTypography mb="20px" variant="h4">
            {ReactHtmlParser(item.label)}
          </TitleTypography>
          <Typography variant="body2" overflow="hidden" maxHeight="85px">
            {ReactHtmlParser(templateContent)}
          </Typography>
          {templateContent.replace(/<[^>]*>?/gm, '').length > 316 && '...'}
        </Box>
      </Box>

      <Box height="20%" overflow="hidden">
        <Divider sx={{ color: '#C9CAD9' }} />
        <Box display="flex" alignItems="center">
          <Box display="flex" gap={1} width="100%">
            <CountTypography variant="body1" fontWeight={700}>
              {t('Number of clicks')} :
            </CountTypography>
            <CountTypography variant="body2" fontWeight={400}>
              {item.click_count}
            </CountTypography>
          </Box>
          <EditButton onClick={onEdit} />
          <DeleteButton onClick={onDelete} />
        </Box>
      </Box>
    </Box>
  );
}

export default TemplateCard;
