import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Icon } from '@iconify/react';
import { useSnackbar } from 'notistack';
import closeFill from '@iconify/icons-eva/close-fill';
import { MIconButton } from './@material-extend';
import { removeSnackbar } from '../redux/slices/notifications';
import { RootState } from '../redux/store';
import { useTranslation } from 'react-i18next';
import { getTranslatedList } from 'constants/constants';

let displayed: any[] = [];

const Notifier = () => {
  const dispatch = useDispatch();
  const { notifications }: any = useSelector(
    (store: RootState) => store.notifier
  );
  const { messages }: any = useSelector((store: RootState) => store.errors);
  const { t } = useTranslation();
  const { DEFAULT_MESSAGE_TEXTS }: any = getTranslatedList();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const storeDisplayed = (id: any) => {
    displayed = [...displayed, id];
  };

  const removeDisplayed = (id: any) => {
    displayed = [...displayed.filter((key) => id !== key)];
  };

  const authMessageError = t(`You don't have the permission to do that`);
  const serverMessageError = t('Something is wrong with the server, please try again later.');
  const authError = t('Your login credentials are incorrect.');
  const authErrorBadRequest = t('Something is wrong');
  const htmlRegex = /<[^>]*>/g;

  const checkStatus = (message: any, messageType: any) => {
    const responseStatus = messages?.at(-1)?.response?.status;
    let resultMessage = message;
    if (responseStatus == 403) {
      resultMessage = authMessageError;
    } else if (responseStatus >= 500) {
      resultMessage = serverMessageError;
    } else if (responseStatus == 401) {
      resultMessage = authError;
    } else if (htmlRegex.test(message)) {
      resultMessage = authErrorBadRequest;
    } else {
      resultMessage = message || DEFAULT_MESSAGE_TEXTS[messageType];
    }
    return resultMessage;
  };

  useEffect(() => {
    notifications.forEach(
      ({ key, message, messageType, options = {}, dismissed = false }: any) => {
        if (dismissed) {
          closeSnackbar(key);
          return;
        }
        if (displayed.includes(key)) return;

        const resultErrorMessage = checkStatus(message, messageType);

        enqueueSnackbar(resultErrorMessage, {
          key,
          ...options,
          onClose: (event, reason, myKey) => {
            if (options.onClose) {
              options.onClose(event, reason, myKey);
            }
          },
          onExited: (event, myKey) => {
            // remove this snackbar from redux store
            dispatch(removeSnackbar(myKey));
            removeDisplayed(myKey);
          },
          action: (key) => (
            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </MIconButton>
          )
        });

        // keep track of snackbars that we've displayed
        storeDisplayed(key);
      }
    );
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);

  return null;
};

export default Notifier;
