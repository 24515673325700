import { useTranslation } from 'react-i18next';

export function getPlaceholders() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { t } = useTranslation();
  return {
    NOW: t('Now'),
    EMPTY: t('Empty'),
    UNKNOWN: t('Unknown'),
    ANONYMOUS: t('Anonymous'),
    PUBLIC: t('Public'),
    TALENT_MATCH: t('Talent Match'),
    CULTURAL_FIT: t('Cultural Fit'),
    NOT_INDICATED: t('Not indicated'),
    NO_DATA: t('No data available'),
    NO_JOB_POST_TODAY: t('No open job posts for this period...'),
    NO_APPLICANTS_TODAY: t('No applicants for today')
  };
}
