import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Card, Modal, Typography } from '@material-ui/core';

import { getInitialize } from 'redux/slices/authJwt';
import { history, RootState } from 'redux/store';
import { PATH_DASHBOARD } from 'routes/paths';
import { CustomTextObject } from 'components/CompanyStyleSettings';
import ReactHtmlParser from 'react-html-parser';
import { useNavigate } from 'react-router-dom';
import useLocales from '../../hooks/useLocales';
import { Description2 } from '../../components/text/title';

export default function FinishNotificationModal({
  open,
  userId
}: {
  open: boolean;
  userId?: number | null;
}) {
  const { t } = useLocales();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { assessment_optional }: any = useSelector(
    (state: RootState) => state.dashboard.managementInsights
  );
  const { company }: any = useSelector(
    (state: RootState) => state.generalRecruiter
  );

  const startTestInfoText =
    company.custom_texts?.find(
      (CT: CustomTextObject) => CT.name === 'start_test_info'
    ) || '';

  const onSkipTesting = () => {
    dispatch(getInitialize());
    navigate(PATH_DASHBOARD.candidates.getCandidateProfileUrl('me'));
  };

  const onTestingStart = async () => {
    if (userId) {
      dispatch(getInitialize(userId));
    }
  };

  return (
    <Modal open={open}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '100%'
        }}
      >
        <Card sx={{ width: 700, textAlign: 'center', p: 8 }}>
          <Typography variant="h3" color="primary">
            {t('Congratulations!')}
          </Typography>
          <Description2
            style={{
              fontSize: '16px',
              marginTop: '24px',
              marginBottom: '24px'
            }}
          >
            {ReactHtmlParser(startTestInfoText.text)}
          </Description2>
          <Button
            sx={{ fontSize: 20, m: 2 }}
            size="large"
            variant="contained"
            onClick={onTestingStart}
          >
            {t('Start testing')}
          </Button>
          {assessment_optional && (
            <Typography
              component="div"
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end'
              }}
            >
              <Button
                sx={{ fontSize: 20, m: 2 }}
                size="large"
                onClick={onSkipTesting}
              >
                {t('Take the assessment later')}
              </Button>
            </Typography>
          )}
        </Card>
      </Box>
    </Modal>
  );
}
