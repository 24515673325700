import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import { Icon } from '@iconify/react';
import { useLocation, useNavigate } from 'react-router-dom';
import HeadingContainer from './containers/HeadingContainer';

function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`
  };
}

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: 'transparent',
    width: '100%'
  },
  tabs: {
    '& .MuiTabs-indicator': {
      display: 'none'
    },
    '& .MuiTab-root.Mui-selected': {
      background: 'linear-gradient(92.25deg, #9B5FDA -2.57%, #562C82 113.38%)',
      boxShadow: ' 0px 4px 4px rgba(131, 33, 255, 0.1)',
      borderRadius: ' 5px',
      color: 'white',
      fontWeight: 'bold'
    },
    '& .MuiTab-root': {
      borderRadius: ' 5px',
      background:
        'linear-gradient(94.35deg, rgba(255, 255, 255, 0.8) -5.21%, rgba(255, 255, 255, 0.3) 114.12%)',
      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.08)',
      width: '222px',
      height: '94px',
      marginRight: '15px',
      color: '#131417',
      fontWeight: 400
    },
    '&.MuiTabs-scrollableX': {
      background: 'red'
    }
  },
  dashboard: {
    '& span': {
      display: 'flex',
      alignItems: 'flex-start',
      rowGap: '10px',
      flexDirection: 'column !important'
    }
  }
}));

export default function FullWidthTabs({
  tabs = [],
  dashboard,
  old
}: {
  tabs: any[];
  dashboard?: boolean;
  old?: boolean;
}) {
  const navigate = useNavigate();
  const { hash } = useLocation();
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState(0);
  const [tab, setTab] = useState<any>([]);
  const getTabContent = (stepIndex: number) => tab[stepIndex]?.Component;

  useEffect(() => {
    if (hash) {
      for (let i = 0; i < tab.length; i++) {
        const element = tab[i];
        if (`#${element.path}` == hash || hash?.search(element.path) >= 0) {
          setActiveTab(i);
          break;
        }
      }
    } else {
      setActiveTab(0);
    }
  }, [hash, tab]);

  useEffect(() => {
    const newTab: any = [];
    if (tabs.length) {
      for (const key of tabs) {
        if (!key?.hide) {
          newTab.push(key);
        }
      }
      setTab(newTab);
    }
  }, []);

  const getIcon = (el: any, i: number): JSX.Element => {
    if (!dashboard) {
      return (
        <Icon
          icon={el.icon}
          style={{ width: '18px', height: '18px', display: 'flex' }}
        />
      );
    }
    if (!old) {
      return (
        <img
          src={activeTab !== i ? el.icon : el.activeIcon}
          style={{ width: '20px', height: '20px' }}
        />
      );
    }

    return (
      <Icon
        icon={el.icon}
        style={{ width: '20px', height: '20px', display: 'flex' }}
      />
    );
  };

  return (
    <>
      <Box style={{ maxWidth: '700px' }}>
        {tabs[activeTab]?.subTitle && (
          <HeadingContainer subtitle={tabs[activeTab].subTitle} />
        )}
      </Box>
      <div className={classes.root}>
        <AppBar
          position="static"
          color="inherit"
          style={{ backgroundColor: 'transparent', boxShadow: 'none' }}
        >
          <Tabs
            className={classes.tabs}
            value={activeTab}
            onChange={(e: any, value: number) => {
              navigate({ hash: tab[value]?.path });
              setActiveTab(value);
            }}
            variant="scrollable"
            aria-label="icon position tabs example"
          >
            {tab.map((el: any, i: number) =>
              el.hide ? null : (
                <Tab
                  icon={getIcon(el, i)}
                  key={i}
                  label={el.label}
                  {...a11yProps(i)}
                  sx={{
                    p: 2
                  }}
                  className={dashboard ? classes.dashboard : ''}
                />
              )
            )}
          </Tabs>
        </AppBar>
        <Box
          sx={{
            p: 2,
            pl: 0,
            pr: 0
          }}
        >
          {getTabContent(activeTab)}
        </Box>
      </div>
    </>
  );
}
