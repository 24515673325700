import { createSlice } from '@reduxjs/toolkit';
// @types
import { SettingsState } from '../../@types/settings';
import {dispatch} from "../store";

// ----------------------------------------------------------------------

const initialState: SettingsState = {
  themeMode: 'light',
  themeDirection: 'ltr',
  titleHeader:''
};

const slice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    switchMode(state, action) {
      state.themeMode = action.payload;
    },
    switchDirection(state, action) {
      state.themeDirection = action.payload;
    },
    newTitle(state, action) {
      state.titleHeader = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { switchMode, switchDirection } = slice.actions;
export const postTitle = (title:any) => {
  dispatch(slice.actions.newTitle(title));
};
