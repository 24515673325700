import { useState } from 'react';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import {
  categorySelect,
  DEFAULT_MESSAGE_TEXTS
} from '../../../../constants/constants';
import {
  deleteDataByUrl,
  getDataByUrl,
  updateDataByUrl
} from '../../../../requests';
import { useSnackbar } from 'notistack';
import { ApproveDialog, DeleteDialog } from '../../../../components/Dialog';

const IntegrationsTable = ({ komboData, setKomboData, getKomboData }: any) => {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [komboId, setKomboId] = useState<any>(null);
  const [open, setOpen] = useState(false);
  const [titleDialog, setTitleDialog] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  const deleteItem = async () => {
    await deleteDataByUrl(`/api/kombo/integrations/${komboId}/`);
    enqueueSnackbar(DEFAULT_MESSAGE_TEXTS.deleted, {
      variant: 'success'
    });
    setKomboData((prev: any) => prev.filter((el: any) => el.id !== komboId));
    setIsOpenModal(false);
  };

  const syncJobAts = async () => {
    setOpen(true);
    try {
      await getDataByUrl(`/api/kombo/jobs/sync/`);
      setTitleDialog('The operation is successful');
      getKomboData();
    } catch {
      setTitleDialog('The operation is not successful');
    }
  };

  const onActivateOrDeactivateIntegration = async (id: number) => {
    const integration = komboData.find((el: { id: number }) => el.id === id);
    try {
      await updateDataByUrl(`/api/kombo/integrations/${id}/`, {
        active: !integration.active
      });
      getKomboData();
    } catch (err: any) {
      enqueueSnackbar(err.message, {
        variant: 'error'
      });
    }
  };

  return komboData && komboData.length ? (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow sx={{ borderBottom: '0.5px solid #E1E1E1FF' }}>
            <TableCell sx={{ background: 'white' }}>Integration</TableCell>
            <TableCell sx={{ background: 'white' }}>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {komboData.map((row: any, index: number) => (
            <TableRow
              key={row.id}
              sx={{
                borderBottom:
                  index !== komboData?.length - 1 ? '0.5px solid #E1E1E1FF' : 0
              }}
            >
              <TableCell>
                {row.integration_id}
                {row.category && (
                  <Typography>
                    {categorySelect[row.category - 1].value}
                  </Typography>
                )}
              </TableCell>
              <TableCell>
                {row.active ? (
                  <Typography
                    sx={{
                      display: 'flex',
                      columnGap: '20px',
                      alignItems: 'center'
                    }}
                  >
                    <img src="/static/img/icons/ChekMark.svg" alt="chekmark" />
                    Active
                  </Typography>
                ) : (
                  <Typography>Inactive</Typography>
                )}
              </TableCell>
              <TableCell>
                {row.active && row.category === 1 && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={syncJobAts}
                  >
                    Sync Job
                  </Button>
                )}
              </TableCell>
              <TableCell>
                <Button
                  variant="contained"
                  sx={{
                    color: '#fff',
                    ':hover': {
                      background: row.active ? 'rgb(255,65,65)' : '#0d9d7b'
                    },
                    background: row.active ? 'rgb(255, 104, 104)' : '#18B590'
                  }}
                  onClick={() => onActivateOrDeactivateIntegration(row.id)}
                >
                  {row.active ? 'Deactivate' : 'Activate'}
                </Button>
              </TableCell>
              <TableCell>
                <Button
                  variant="text"
                  color="error"
                  onClick={() => {
                    setKomboId(row.id);
                    setIsOpenModal(true);
                  }}
                >
                  Delete
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <DeleteDialog
        open={isOpenModal}
        title="Are you sure you want to delete integration?"
        onClose={() => setIsOpenModal(false)}
        onDelete={deleteItem}
      />
      <ApproveDialog
        open={open}
        title={titleDialog}
        onApprove={() => setOpen(false)}
      />
    </TableContainer>
  ) : null;
};

export default IntegrationsTable;
