import { Box, CircularProgress, Typography } from '@material-ui/core';

const LoadingBox = ({
  fullHeight,
  text
}: {
  fullHeight?: boolean;
  text?: string;
}) => (
  <Box
    sx={{
      minHeight: !fullHeight ? '100%' : '100vh',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      m: 3
    }}
  >
    <CircularProgress sx={{ mr: 2 }} />
    <Typography variant="h4" color="primary">
      {text || 'Loading'}...
    </Typography>
  </Box>
);

export default LoadingBox;
