import { Typography } from '@material-ui/core';

export const getStatusString = (status: number | string) => {
  if (typeof status === 'string') {
    return status;
  }
  switch (status) {
    case 1:
      return 'online';
    case 0:
      return 'in progress';
    case 2:
      return 'offline';
    default:
      return '';
  }
};

const InProgress = ({
  status,
  style = {}
}: {
  status: number | string;
  style?: any;
}) => {
  const getStatusColor = (
    status: string
  ): 'green' | 'rgba(240, 180, 89, 0.2)' | 'red' | '' => {
    if (status) {
      switch (status?.toLowerCase()) {
        case 'online':
          return 'green';
        case 'in progress':
          return 'rgba(240, 180, 89, 0.2)';
        case 'offline':
          return 'red';
        default:
          return '';
      }
    }
    return '';
  };

  return (
    <Typography
      style={{
        whiteSpace: 'nowrap',
        padding: '10px 20px',
        background: getStatusColor(getStatusString(status)),
        borderRadius: '30px',
        fontWeight: 700,
        fontSize: '14px',
        color: '#F0B459',
        height: 'max-content',
        width: 'max-content',
        ...style
      }}
    >
      {getStatusString(status)}
    </Typography>
  );
};

export default InProgress;
