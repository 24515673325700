import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { forwardRef, ReactNode, useCallback, useEffect } from 'react';
import { Box, BoxProps, Modal, Typography } from '@material-ui/core';
import track from 'utils/analytics';
import useLocales from 'hooks/useLocales';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { activeMessenger, getUnRead } from '../../redux/slices/chat';
import useAuth from '../../hooks/useAuth';

interface PageProps extends BoxProps {
  children: ReactNode;
  title?: string;
}

let interval: any = null;

const Page = forwardRef<HTMLDivElement, PageProps>(
  ({ children, title = '', ...other }, ref) => {
    const auth = useAuth();
    const { t } = useLocales();
    const { pathname } = useLocation();
    const { countLoaded }: any = useSelector((state: RootState) => state.chat);

    const sendPageViewEvent = useCallback(() => {
      track.pageview({ page_path: pathname });
    }, [pathname]);

    useEffect(() => {
      sendPageViewEvent();
    }, [sendPageViewEvent]);
    useEffect(() => {
      activeMessenger(true);
    }, []);
    useEffect(() => {
      if (interval) {
        clearInterval(interval);
      }
      if (auth.isAuthenticated && pathname.includes('dashboard')) {
        interval = setInterval(() => {
          getUnRead();
        }, 30000);
      }
    }, [auth, pathname]);
    const { isAuthenticated } = auth;

    useEffect(() => {
      if (isAuthenticated && !countLoaded) {
        getUnRead();
      }
    }, [isAuthenticated, countLoaded]);

    return (
      <Box ref={ref} {...other} sx={{ paddingBottom: '0', marginTop: '20px' }}>
        {/* Deprecated 
        <Modal open={showChat}>
          <Box
            style={{
              position: 'absolute',
              bottom: '50px',
              right: 0,
              width: '50%',
              height: '50%',
              background: 'white',
              border: '2px solid #000',
              borderRadius: '15px'
            }}
          >
            <Typography
              style={{ position: 'absolute', right: 10, top: 5 }}
              onClick={() => activeChat(!showChat)}
            >
              X
            </Typography>
            <Chat showChat={showChat} />
          </Box>
        </Modal>
        */}
        <Helmet>
          <title>{`${t('Dashboard')} | ${title}`}</title>
        </Helmet>
        {children}
      </Box>
    );
  }
);

export default Page;
