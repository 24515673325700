import { Box } from '@material-ui/core';
import { IMG_PLACEHOLDER } from '../constants/constants';

export const ImageContainer = ({
  id = '',
  title = '',
  className = '',
  src = '',
  style = {},
  onClick = () => {}
}) => (
  <Box
    id={id}
    className={className}
    component="img"
    title={title || 'New Image'}
    alt={title || 'New Image'}
    src={src || IMG_PLACEHOLDER}
    style={{ backgroundColor: 'gray', ...style }}
    onClick={onClick}
  />
);
