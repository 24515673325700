import { createSlice } from '@reduxjs/toolkit';
import { prepareUpdatedData } from 'helpers/listValues';
import { AppDispatch, RootState } from 'redux/store';
import { enqueueSnackbar } from './notifications';
import {
  CreateCustomer,
  DeliteCustomer,
  getCustomerById,
  getCustomerFilesData,
  getCustomerNotesDate,
  getCustomers,
  UpdateCustomerData
} from '../../requests/customers';
import { postDataByUrl, getDataByUrl } from '../../requests';

const ITEMS_PER_PAGE = 12;
const initialState: any = {
  filters: {
    page: 1,
    limit: ITEMS_PER_PAGE,
    offset: 0,
    q: '',
    short: false
  },
  response: {
    results: null,
    count: 0,
    next: null,
    previous: null
  },
  subLoading: false,
  customer: {},
  notes: [],
  files: [],
  apiData: {
    share_url: '',
    id: ''
  }
};

const slice = createSlice({
  name: 'invitations',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setSubLoading(state, action) {
      state.subLoading = action.payload;
    },
    setFilter(state, action) {
      state.filters = {
        ...state.filters,
        ...action.payload
      };
    },
    getCustomersSuccess(state, action) {
      state.response = action.payload;
    },
    getCustomerData(state, action) {
      state.customer = action.payload;
    },
    getCustomerNotes(state, action) {
      state.notes = action.payload;
    },
    getCustomerFiles(state, action) {
      state.files = action.payload;
    },
    postCustomerApiData(state, action) {
      state.apiData = action.payload;
    }
  }
});

export default slice.reducer;

export const postCostumerApiData =
  (url: string) => async (dispatch: AppDispatch) => {
    postDataByUrl(url).then((res) => {
      dispatch(slice.actions.postCustomerApiData(res));
    });
  };

export const getCostumerApiData =
  (url: string) => async (dispatch: AppDispatch) => {
    getDataByUrl(url).then((res) => {
      dispatch(slice.actions.postCustomerApiData(res));
    });
  };

export const getCustomersData =
  (params: {
    limit?: number;
    offset?: number;
    page?: number;
    short?: boolean;
    q?: string;
  }) =>
  async (dispatch: AppDispatch) => {
    dispatch(slice.actions.setLoading(true));
    getCustomers(params).then((res) => {
      dispatch(slice.actions.getCustomersSuccess(res));
      dispatch(slice.actions.setLoading(false));
    });
  };

export const getCustomer = (id: string) => async (dispatch: AppDispatch) => {
  dispatch(slice.actions.setLoading(true));
  getCustomerById(id).then((res) => {
    dispatch(slice.actions.getCustomerData(res));
    dispatch(slice.actions.setLoading(false));
  });
};

export const getCustomerNotes =
  (id: string) => async (dispatch: AppDispatch) => {
    dispatch(slice.actions.setLoading(true));
    getCustomerNotesDate(id).then((res) => {
      dispatch(slice.actions.getCustomerNotes(res));
    });
  };

export const getCustomerFiles =
  (id: string) => async (dispatch: AppDispatch) => {
    dispatch(slice.actions.setLoading(true));
    getCustomerFilesData(id).then((res) => {
      dispatch(slice.actions.getCustomerFiles(res));
    });
  };

export const setCustomersFilter =
  (field: string, value: any) => (dispatch: AppDispatch) => {
    dispatch(
      slice.actions.setFilter({
        [field]: value
      })
    );
  };

export const addCustomer =
  (data: any) => async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(slice.actions.setSubLoading(true));
    CreateCustomer(data)
      .then((res) => {
        if (res) {
          const { customers } = getState();
          dispatch(
            slice.actions.getCustomersSuccess(
              prepareUpdatedData('create', customers.response, res)
            )
          );
        }
        return res;
      })
      .then((res) => {
        if (res) {
          dispatch(
            enqueueSnackbar({
              messageType: 'created',
              options: { variant: 'success' }
            })
          );
        }
        dispatch(slice.actions.setSubLoading(false));
      });
  };

export const deleteCustomer =
  (id: any) => async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(slice.actions.setSubLoading(true));
    DeliteCustomer(id)
      .then(() => {
        const { customers } = getState();
        dispatch(
          slice.actions.getCustomersSuccess(
            prepareUpdatedData('delete', customers.response, { id })
          )
        );
      })
      .then(() => {
        dispatch(
          enqueueSnackbar({
            messageType: 'deleted',
            options: { variant: 'success' }
          })
        );
        dispatch(slice.actions.setSubLoading(false));
      });
  };

export const patchCustomerData =
  (id: any, formData: any) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(slice.actions.setSubLoading(true));
    UpdateCustomerData(id, formData)
      .then((res) => {
        if (res) {
          const { customers } = getState();
          dispatch(
            slice.actions.getCustomersSuccess(
              prepareUpdatedData('update', customers.response, res)
            )
          );
        }
        return res;
      })
      .then((res) => {
        if (res) {
          dispatch(
            enqueueSnackbar({
              messageType: 'updated',
              options: { variant: 'success' }
            })
          );
        }
        dispatch(slice.actions.setSubLoading(false));
      });
  };

export const patchCustomerDataFromProfile =
  (id: any, formData: any) => async (dispatch: AppDispatch) => {
    dispatch(slice.actions.setSubLoading(true));
    UpdateCustomerData(id, formData)
      .then((res) => {
        if (res) {
          dispatch(slice.actions.getCustomerData(res));
        }
        return res;
      })
      .then((res) => {
        if (res) {
          dispatch(
            enqueueSnackbar({
              messageType: 'updated',
              options: { variant: 'success' }
            })
          );
        }
        dispatch(slice.actions.setSubLoading(false));
      });
  };
