import { ImgHTMLAttributes } from 'react';

// ----------------------------------------------------------------------

interface SvgIconStyleProps extends ImgHTMLAttributes<HTMLImageElement> {
  src: string;
  style?: any;
  width?: string;
  height?: string;
}

export default function SvgIconStyle({
  src,
  color = 'inherit',
  style,
  onClick = () => false,
  width,
  height,
  ...rest
}: SvgIconStyleProps) {
  return (
    <div
      role="presentation"
      onClick={onClick}
      style={{
        width: width || 'unset',
        height: height || 'auto'
      }}
    >
      <img src={src} style={style} {...rest} alt="image" />
    </div>
  );
}
