import { Box, Button, Typography } from '@material-ui/core';
import { ElementsConsumer } from '@stripe/react-stripe-js';
import { useSnackbar } from 'notistack';
import { CustomModal } from '../../CustomModal';
import useLocales from '../../../hooks/useLocales';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

const CheckoutForm = (props: any) => {
  const { stripe, sessionId, onClose, setSubscription } = props;
  const { company }: any = useSelector(
    (state: RootState) => state.generalRecruiter
  );

  const { t } = useLocales();
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = () => {
    if (stripe && sessionId) {
      stripe
        .redirectToCheckout({ sessionId })
        .then(() => enqueueSnackbar(t('Please, fill in the stripe form')));
    } else {
      onClose(true, stripe);
      setSubscription({
        plan: '',
        status: 'active',
        lastBillingDate: 0,
        nextBillingDate: 0
      });
    }
  };
  const subButtonText = company?.if_free_trial ? t('yes') : t('Get it now!');

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h3" sx={{ mb: 3 }}>
        {t('Are you sure you want to proceed ?')}
      </Typography>
      <div
        style={{ display: 'flex', justifyContent: 'center', columnGap: '20px' }}
      >
        <Button
          sx={{ mt: 3 }}
          variant="contained"
          onClick={handleSubmit}
          disabled={!stripe}
        >
          {t(subButtonText)}
        </Button>
        <Button
          sx={{ mt: 3 }}
          variant="contained"
          onClick={() => onClose(false)}
          disabled={!stripe}
        >
          {t('No')}
        </Button>
      </div>
    </Box>
  );
};

const PaymentModal = ({
  open,
  onClose,
  sessionId,
  setSubscription
}: {
  open: boolean;
  onClose: (value: boolean, stripe: any) => void;
  sessionId: string;
  setSubscription: any;
}) => (
  <CustomModal open={open} onClose={() => onClose(false, null)}>
    <ElementsConsumer>
      {({ elements, stripe }) => (
        <CheckoutForm
          elements={elements}
          stripe={stripe}
          sessionId={sessionId}
          onClose={onClose}
          setSubscription={setSubscription}
        />
      )}
    </ElementsConsumer>
  </CustomModal>
);

export default PaymentModal;
