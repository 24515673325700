/* eslint-disable react-hooks/rules-of-hooks */
import { hasFlag } from 'country-flag-icons';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import useLocales from '../hooks/useLocales';
import {
  getCheckedString,
  getNameByVal,
  getNameFormObj,
  getStringFromList,
  getTimePeriod
} from './listValues';

export const getCardValuesByType = (
  type: string,
  item?: any,
  options?: any,
  currency?: string
) => {
  const { t } = useLocales();
  const is_freelancer = item.hourly_rate !== null;

  switch (type) {
    case 'candidate-contact-info':
      return [
        {
          label: t('Email'),
          value: getCheckedString(item?.email),
          image: '/static/img/profile/email.svg'
        },
        {
          label: t('Phone'),
          value: getCheckedString(item?.phone),
          image: '/static/img/profile/phone.svg'
        }
      ];
    case 'customer-profile':
      return [
        {
          label: t('Contact Name'),
          value: getCheckedString(item?.contact_name),
          image: '/static/img/icons/person-fill.svg'
        },
        {
          label: t('Email'),
          value: getCheckedString(item?.email),
          image: '/static/img/profile/email.svg'
        },
        {
          label: t('Phone'),
          value: getCheckedString(item?.contact_number),
          image: '/static/img/profile/phone.svg'
        },
        {
          label: t('Website'),
          value: getCheckedString(item?.website),
          image: '/static/img/icons/globe.svg'
        }
      ];
    case 'candidate-pitch':
      return [
        {
          label: null,
          value: getCheckedString(item?.pitch),
          image: ''
        }
      ];
    case 'candidate-modal-info':
      return [
        {
          label: t('Career level'),
          value: getNameByVal(options.experiences, item.work_exp),
          image: '/static/img/candidates/career-icon.svg'
        },
        {
          label: t('Salary Expectation'),
          value: `${getNameByVal(options.salaries, item.salary)} ${currency}`,
          image: '/static/img/candidates/payroll-salary-icon.svg'
        },
        {
          label: t('Availability'),
          value: getNameByVal(options.availabilities, item.availability),
          image: '/static/img/candidates/business-rules-icon.svg'
        },
        ...(item.studies
          ? [
              {
                label: t('Studies'),
                value: getStringFromList(item.studies),
                image: '/static/img/candidates/internship-icon.svg'
              }
            ]
          : [
              {
                label: t('Study'),
                value: getStringFromList(item.study),
                image: '/static/img/candidates/internship-icon.svg'
              }
            ])
      ];
    case 'candidate-preferences-info':
      return [
        {
          label: t('Availability'),
          value: getNameByVal(options.availabilities, item?.availability)
        },
        {
          label: t('Work Hours'),
          value: getNameByVal(options.work_hours, item?.work_hours)
        },
        {
          label: t('Willing to relocate'),
          value: getNameByVal(options.approval, item?.relocation)
        },
        {
          label: t('Work Experience'),
          value: getNameByVal(options.experiences, item?.work_exp)
        },
        {
          label: t('Management Experience'),
          value: getNameByVal(options.experiences, item?.management_exp)
        },
        {
          label: t('Salary Expectation'),
          value: `${getNameByVal(options.salaries, item?.salary)} ${currency}`
        },
        {
          label: t('Locations'),
          value: getStringFromList(item?.locations)
        },
        {
          label: t('Industry'),
          value: getStringFromList(item?.industries)
        },
        {
          label: t('Contract'),
          value: getNameByVal(options.contracts, item?.contract)
        },
        {
          label: t('Preferred Language'),
          value: getNameByVal(options.default_lang, item?.default_lang)
        }
      ];
    case 'job-post':
      return [
        {
          label: t('Locations'),
          value: getStringFromList(item.locations)
        },
        {
          label: t('Industries'),
          value: getStringFromList(item.industries)
        },
        {
          label: t('Studies'),
          value: getStringFromList(item.studies)
        },
        {
          label: t('Degree'),
          value: getNameByVal(options.degreesStages, item.degree)
        },
        {
          label: t('Skills'),
          value: getStringFromList(item.skills)
        },
        {
          label: t('Musts'),
          value: getStringFromList(item.musts)
        },
        {
          label: t('Perks'),
          value: getStringFromList(item.perks)
        },
        {
          label: t('Contract'),
          value: getNameByVal(options.contracts, item.contract)
        },
        {
          label: t('Work Experience'),
          value: getNameByVal(options.experiences, item.work_exp)
        },
        {
          label: t('Available From'),
          value: getCheckedString(item.available)
        },
        {
          label: t('Drivers License'),
          value: item.drivers_license ? t('Yes') : t('No')
        },
        ...(is_freelancer
          ? [
              {
                label: t('Hourly Rate'),
                value: `${getNameByVal(
                  options.hourly_rates,
                  item.hourly_rate
                )} ${currency}`
              }
            ]
          : [
              {
                label: t('Salary Range'),
                value: `${getNameByVal(
                  options.salaries,
                  item.salary
                )} ${currency}`
              }
            ])
      ];
    case 'job-post-information':
      return [
        {
          label: t('Locations'),
          value: getStringFromList(item.locations),
          icon: '/static/img/icons/ic-locations.svg'
        },
        {
          label: t('Industries'),
          value: getStringFromList(item.industries),
          icon: '/static/img/icons/ic-industries.svg'
        },
        {
          label: t('Studies'),
          value: getStringFromList(item.studies),
          icon: '/static/img/icons/ic-study.svg'
        },
        {
          label: t('Degree'),
          value: getNameByVal(options.degreesStages, item.degree),
          icon: '/static/img/icons/ic-degree.svg'
        },
        {
          label: t('Skills'),
          value: getStringFromList(item.skills),
          icon: '/static/img/icons/ic-skills.svg'
        },
        {
          label: t('Contract'),
          value: getNameByVal(options.contracts, item.contract),
          icon: '/static/img/icons/ic-contact.svg'
        },
        {
          label: t('Work Experience'),
          value: getNameByVal(options.experiences, item.work_exp),
          icon: '/static/img/icons/ic-work-experience.svg'
        },
        {
          label: t('Available From'),
          value: getCheckedString(item.available),
          icon: '/static/img/icons/ic-available-from.svg'
        },
        {
          label: t('Salary Range'),
          value: `${getNameByVal(options.salaries, item.salary)} ${currency}`,
          icon: '/static/img/icons/ic-salary-range.svg'
        }
      ];
    case 'job-post-questions':
      return [
        {
          label: t('What are the must-haves for the candidates?'),
          value: getStringFromList(item.musts)
        },
        {
          label: t('What do we offer the candidates?'),
          value: getStringFromList(item.perks)
        },
        {
          label: t('Do candidates need a Drivers License?'),
          value: item.drivers_license ? t('Yes') : t('No')
        }
      ];
    case 'educations':
      return [
        {
          label: t('School'),
          value:
            getNameFormObj(item.school) + (item.graduate ? ' - Graduated' : '')
        },
        {
          label: t('Time period'),
          value: getTimePeriod(item.start, item.end)
        },
        {
          label: t('Study'),
          value: getNameFormObj(item.study)
        },
        {
          label: t('Degree'),
          value: getNameByVal(options.degreesStages, item.degree)
        }
      ];
    case 'notes':
      return [
        {
          label: t('Availability'),
          value: getNameByVal(options.availabilities, item.availability)
        }
      ];
    case 'it-skills':
      return [
        {
          label: t('Skill'),
          value: getNameFormObj(item.skill),
          image: '/static/img/profile/skils.svg'
        },
        {
          label: t('Description'),
          value: getCheckedString(item.description)
        },
        {
          label: t('Proficiency'),
          value: item.proficiency,
          proficiency: true
        }
      ];
    case 'activities':
      return [
        {
          label: t('Title'),
          value: getCheckedString(item.title)
        },
        {
          label: t('Description'),
          value: getCheckedString(item.description)
        },
        {
          label: t('Time period'),
          value: getTimePeriod(item.start, item.end)
        }
      ];
    case 'certifications':
      return [
        {
          label: t('Title'),
          value: getCheckedString(item.title)
        },
        {
          label: t('Institute'),
          value: getCheckedString(item.institute)
        },
        {
          label: t('Year'),
          value: `${item.year}`
        },
        {
          label: t('Received'),
          value: item.recieved ? t('Yes') : t('No')
        }
      ];
    case 'languages':
      return [
        {
          label: t('Language'),
          value: getCheckedString(item.language.name),
          component: hasFlag(item.language.flag.toUpperCase())
            ? getUnicodeFlagIcon(item.language.flag.toUpperCase())
            : getUnicodeFlagIcon('zz')
        },
        {
          label: t('Proficiency'),
          value: item.proficiency,
          proficiency: true
        }
      ];
    case 'social-media':
      return [
        {
          label: t('Network'),
          value: getNameByVal(options.socialMedias, item.network)
        },
        {
          label: t('Url'),
          value: getCheckedString(item.url)
        }
      ];
    case 'social-media-expertises':
      return [
        {
          label: t('Network'),
          value: getNameByVal(options.socialMedias, item.network)
        },
        {
          label: t('Proficiency'),
          value: item.proficiency,
          proficiency: true
        }
      ];
    case 'work-experiences':
      return [
        {
          label: t('Company'),
          value: getCheckedString(item?.company)
        },
        {
          label: t('Job Title'),
          value: getCheckedString(item?.title)
        },
        {
          label: t('Job Description'),
          value: getCheckedString(item?.description)
        },
        {
          label: t('Industry'),
          value: getCheckedString(item?.industry?.name)
        },
        {
          label: t('Contract'),
          value: getNameByVal(options.contracts, item?.contract)
        },
        {
          label: t('Time period'),
          value: getTimePeriod(item?.start, item?.end)
        },
        {
          label: t('Location'),
          value: getCheckedString(item.location?.display_name)
        },
        {
          label: t('Website'),
          value: getCheckedString(item?.website)
        }
      ];

    default:
      return [];
  }
};
