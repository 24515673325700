import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useLocales from 'hooks/useLocales';
import { fetchAllJobPosts } from 'redux/slices/jobSlice';
import Page from 'components/containers/Page';
import PageContainer from 'components/containers/PageContainer';
import DashboardNavbar from 'layouts/dashboard/navbar/DashboardNavbar';
import { Grid } from '@material-ui/core';
import JobPostAnalytics from './JobPostAnalytics';
import JobsAnalytics from './JobsAnalytics';
import JobApplicationStages from './JobApplicationStages';
import JobApplicationStagesPie from './JobApplicationStagesPie';
import JobPostDurations from './JobPostDurations';
import JobCostsAnalytics from './JobCostsAnalytics';
import JobApplicationDeclineReasons from './JobApplicationDeclineReasons';
import { Chart, GridStyle } from '../../styled';

const DataAnalytics = () => {
  const { t } = useLocales();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllJobPosts());
  }, []);

  return (
    <Page title={t('Data Analytics')}>
      <PageContainer>
        <DashboardNavbar title={t('Data Analytics')} />
        <Grid container direction="column" rowGap={12} maxWidth="1400px">
          <GridStyle item xs={12}>
            <Chart>
              <JobPostAnalytics />
            </Chart>
            <Chart>
              <JobsAnalytics />
            </Chart>
          </GridStyle>
          <GridStyle item xs={12}>
            <Chart>
              <JobPostDurations />
            </Chart>
            <Chart>
              <JobCostsAnalytics />
            </Chart>
          </GridStyle>
          <GridStyle item xs={12}>
            <Chart>
              <JobApplicationStagesPie />
            </Chart>
            <Chart>
              <JobApplicationStages />
            </Chart>
          </GridStyle>
          <GridStyle item xs={12}>
            <Chart>
              <JobApplicationDeclineReasons />
            </Chart>
          </GridStyle>
        </Grid>
      </PageContainer>
    </Page>
  );
};

export default DataAnalytics;
