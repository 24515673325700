import {
  deleteDataByUrl,
  getDataByUrl,
  ID,
  postDataByUrl,
  updateDataByUrl
} from 'requests';

export const getCandidateOverview = async (candidate_id: ID) =>
  getDataByUrl(`/api/candidate/overview/${candidate_id}/`);

export const getCandidateRemind = async (candidate_id: ID) =>
  getDataByUrl(`/api/candidate/${candidate_id}/remind/`);

export const getCandidateData = async (candidate_id: ID, route?: string) => {
  let url = `/api/candidate/${candidate_id}/`;
  if (route) url = `${url}${route}/`;
  return getDataByUrl(url);
};

export const getCandidateAssessment = async (id: ID) =>
  getDataByUrl(`/api/assessment/assessments/result/?user_id=${id}`);

export const postCandidateData = async (
  candidate_id: ID,
  formData: any,
  route: string
) => postDataByUrl(`/api/candidate/${candidate_id}/${route}/`, formData);

export const postCustomerData = async (
  customer_id: ID,
  formData: any,
  route: string
) => postDataByUrl(`/api/customers/${customer_id}/${route}/`, formData);

export const updateCandidateData = async (
  candidate_id: ID,
  id: ID,
  formData: any,
  route: string
) =>
  updateDataByUrl(`/api/candidate/${candidate_id}/${route}/${id}/`, formData);

export const submitCandidateData = async (
  candidate_id: ID,
  formData: any,
  route: string,
  id?: ID
) =>
  (id ? updateDataByUrl : postDataByUrl)(
    `/api/candidate/${candidate_id}/${route}${id ? `/${id}` : ''}/`,
    formData
  );

export const updateCustomerNotes = async (
  customer_id: ID,
  id: ID,
  formData: any,
  route: string
) => updateDataByUrl(`/api/customers/${customer_id}/${route}/${id}/`, formData);

export const updateCandidateMainInfo = async (
  candidate_id: ID,
  formData: any
) => updateDataByUrl(`/api/candidate/${candidate_id}/`, formData);

export const deleteCandidateData = async (
  candidate_id: string | number,
  id: string | number,
  route: string
) => deleteDataByUrl(`/api/candidate/${candidate_id}/${route}/${id}/`);

export const deleteCustomerData = async (
  customer_id: string | number,
  id: string | number,
  route: string
) => deleteDataByUrl(`/api/customers/${customer_id}/${route}/${id}/`);

export const updateApplicationData = async (id: ID, formData: any) =>
  updateDataByUrl(`/api/vacancy/applications/${id}/manage/`, formData);

export const cancelCandidateApplication = async (id: ID) =>
  updateDataByUrl(`/api/vacancy/applications/${id}/cancel/`, {});

export const getAssessmentTestLink = async (user: ID) =>
  postDataByUrl(`/api/assessment/assessments/`, { user });

export const getAllCandidate = async (
  user: ID,
  role: string,
  q: string,
  currentPage: number,
  limit: number
) =>
  getDataByUrl(
    `/api/account/?edit=true&limit=${limit}&offset=${
      limit * currentPage - limit
    }&role=${role}&q=${q}`,
    { user }
  );

export const createCandidate = async (data: any) =>
  postDataByUrl('/api/candidate/recruiter/create/', data);

export const sendInvitation = async (candidate_id: ID) =>
  postDataByUrl(`/api/account/${candidate_id}/invite/`);

export const editCandidate = async (data: any, candidate_id: ID) =>
  updateDataByUrl(`/api/candidate/recruiter/${candidate_id}/`, data);

export const deleteCandidate = async (candidate_id: ID) =>
  deleteDataByUrl(`/api/candidate/recruiter/${candidate_id}/`);