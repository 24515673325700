import { createSlice } from '@reduxjs/toolkit';
import { store } from '../store';

export interface IProduct {
  jobPostFor: { id: string; title: string };
  audience_group?: string;
  channel?: { id: number; name: string; type: string; url: string };
  cross_postings?: Array<string>;
  description?: string;
  duration: { range: string; period: number };
  homepage: string;
  industries?: Array<{ id: number; name: string }>;
  job_functions?: Array<{ id: number; name: string }>;
  locations?: Array<{ id: number; canonical_name: string }>;
  logo_rectangle_url: Array<{ size: string; url: string }>;
  logo_square_url: Array<{ size: string; url: string }>;
  logo_url: Array<{ url: string }>;
  product_id?: string;
  ratecard_price: Array<{ amount: number; currency: string }>;
  time_to_process?: { range: string; period: number };
  time_to_setup?: { range: string; period: number };
  title?: string;
  type?: string;
  vonq_price: Array<{ amount: number; currency: string }>;
}

interface IInitialState {
  products: Array<IProduct>;
  jobPostFor: { id: string; title: string } | null;
  autoFillValues: Array<{
    jobPostId: string;
    idJobCategory?: string;
    nameJobCategory?: string;
    idIndustries?: string;
    nameIndustries?: string;
  }>;
}

const initialState: IInitialState = {
  products: [],
  jobPostFor: null,
  autoFillValues: []
};

const slice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    addProduct(state, action) {
      state.products = [...state.products.concat(action.payload)];
    },
    delProduct(state, action) {
      state.products = [
        ...state.products.filter(
          (item) =>
            !(
              item.jobPostFor?.id === action.payload.job_post_id &&
              item.product_id === action.payload.product_id
            )
        )
      ];
    },
    delJobPostProducts(state, action) {
      state.products = [
        ...state.products.filter(
          (item) => item.jobPostFor?.id !== action.payload.job_post_id
        )
      ];
    },
    setJobPostFor(state, action) {
      state.jobPostFor = action.payload;
    },
    addAutoFillValues(state, action) {
      const isJobCategory = action.payload.fieldName === 'jobCategory';
      const id = isJobCategory ? 'idJobCategory' : 'idIndustries';
      const name = isJobCategory ? 'nameJobCategory' : 'nameIndustries';

      state.autoFillValues.some(
        (item) => item.jobPostId === action.payload.jobPostForId
      )
        ? (state.autoFillValues = [
            ...state.autoFillValues.map((item) =>
              item.jobPostId === action.payload.jobPostForId
                ? {
                    ...item,
                    [id]: action.payload.fieldValues?.id,
                    [name]: action.payload.fieldValues?.name
                  }
                : item
            )
          ])
        : (state.autoFillValues = [
            ...state.autoFillValues.concat({
              jobPostId: action.payload.jobPostForId,
              [id]: action.payload.fieldValues?.id,
              [name]: action.payload.fieldValues?.name
            })
          ]);
    },
    delAutoFillValues(state, action) {
      state.autoFillValues = [
        ...state.autoFillValues.filter(
          (item) => item.jobPostId !== action.payload
        )
      ];
    }
  }
});
export default slice.reducer;

export const addProduct = (product: IProduct) => {
  store.dispatch(slice.actions.addProduct(product));
};

export const delProduct = (
  product_id: string | null | undefined,
  job_post_id?: string | null
) => {
  store.dispatch(slice.actions.delProduct({ job_post_id, product_id }));
};

export const delJobPostProducts = (job_post_id?: string | null) => {
  store.dispatch(slice.actions.delJobPostProducts({ job_post_id }));
};

export const setCurrentJobPostId = (id: string, title: string) => {
  store.dispatch(slice.actions.setJobPostFor({ id, title }));
};

export const addAutoFillValues = (
  jobPostForId: string | undefined,
  fieldValues: { id: string | null; name: string | null },
  fieldName: string
) => {
  store.dispatch(
    slice.actions.addAutoFillValues({
      jobPostForId,
      fieldValues,
      fieldName
    })
  );
};

export const delAutoFillValues = (id: string | null | undefined) =>
  store.dispatch(slice.actions.delAutoFillValues(id));
