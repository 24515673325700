import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid, Slider, Typography } from '@material-ui/core';
import { getCompetenciesScoresAndLabels } from 'helpers/listValues';
import DashboardNavbar from 'layouts/dashboard/navbar/DashboardNavbar';
import { Description2 } from 'components/text/title';
import { LANGUAGES } from 'constants/constants';
import CandidateModal from '../../components/CandidateModal';
import LoadingScreen from '../../components/LoadingScreen';
import CardCandidateLow from '../../components/CardCandidateLow';
import Page from '../../components/containers/Page';
import { postCandidateToBlacklist } from '../../requests/profile';
import { RootState } from '../../redux/store';
import { PATH_DASHBOARD } from '../../routes/paths';
import PageContainer from '../../components/containers/PageContainer';
import {
  OverviewSearch,
  OverviewSelect
} from '../../components/OverviewFilter';
import ListContainer from '../../components/containers/ListContainer';
import PaginationContainer from '../../components/containers/PaginationContainer';
import {
  resetMatchCandidatesFilters,
  setMatchCandidatesFilter
} from '../../redux/slices/filters';
import { ResetButton } from '../../components/Buttons';
import useLocales from '../../hooks/useLocales';
import {
  getMatchProfiles,
  selectCandidateFromFindCandidates
} from '../../redux/slices/generalRecruiter';
import { CandidateGrid, FilterGroupRight } from '../../styled';
import {
  getProfileCandidate,
  getProfilesCandidates
} from '../../redux/slices/cultureProfileReducer';

const CandidatesOverview = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentCandidate, setCurrentCandidate] = useState({});
  const { isLoading, profiles, profile, cultureProfile } = useSelector(
    (state: RootState) => state.cultureProfileReducer
  );
  const matchProfiles: any = useSelector(
    (state: RootState) => state.generalRecruiter.matchProfiles.results
  );
  const { degreesStages, experiences } = useSelector(
    (state: RootState) => state.fixturesReducer
  );
  const { page, limit, offset, query, filterData, min_score } = useSelector(
    (state: RootState) => state.filtersReducer.matchCandidatesOverview
  );
  const { t } = useLocales();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      getInitialData(id);
    }
  }, [id, page, offset, filterData, min_score]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (id) {
        getInitialData(id);
      }
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [query]);

  useEffect(() => {
    dispatch(getMatchProfiles());
  }, []);

  const getIdsFromFilter = (data: any) => {
    const filters: any = { ...data };
    Object.keys(data).forEach((key: string) => {
      if (Array.isArray(data[key])) {
        filters[key] = data[key]?.map((el: any) => el?.id || el);
      } else if (data[key]?.id) {
        filters[key] = data[key].id;
      } else filters[key] = data[key];
    });
    return filters;
  };

  const getInitialData = async (id: string) => {
    if (id && id !== 'all') {
      const filters = getIdsFromFilter(filterData);
      dispatch(
        getProfilesCandidates(id, limit, offset, query, {
          ...filters,
          min_score
        })
      );
      dispatch(getProfileCandidate(id));
    }
  };

  const handleClose = () => {
    setModalIsOpen(false);
    setCurrentCandidate({});
  };

  const handleOpen = (candidate: any) => {
    setModalIsOpen(true);
    setCurrentCandidate(candidate);
  };

  const handleSelectMatchProfile = (id: any) => {
    navigate(
      PATH_DASHBOARD.candidates.getMatchProfileCandidatesUrl(id || 'all')
    );
  };

  const onMoveCandidateToBlacklist = async (param: any) => {
    if (param && profile.id && id) {
      await postCandidateToBlacklist(profile.id, {
        candidate_id: param
      });
      getInitialData(id);
    }
  };

  const handleSelectCandidate = async (
    candidateId: number,
    profileId: number
  ) => {
    await dispatch(selectCandidateFromFindCandidates(candidateId, profileId));
    if (id) {
      getInitialData(id);
    }
  };

  const filters = [
    {
      label: 'Language',
      fieldName: 'Known Language',
      type: 'select-language'
    },
    {
      label: 'Location',
      fieldName: 'location',
      type: 'location'
    },
    {
      label: 'Skills',
      fieldName: 'skills',
      type: 'select-skills',
      multi: true
    },
    {
      label: 'Experiences',
      fieldName: 'work_exps',
      options: experiences,
      multi: true
    },
    {
      label: 'Degrees',
      fieldName: 'degrees',
      options: degreesStages,
      multi: true
    },
    {
      label: 'Preferred Language',
      fieldName: 'default_lang',
      type: 'select',
      options: LANGUAGES
    }
  ];

  return (
    <Page title={t('Candidates Overview')}>
      <CandidateModal
        open={modalIsOpen}
        handleClose={handleClose}
        candidate={currentCandidate}
        profile={getCompetenciesScoresAndLabels(profile?.competencies)}
        cultureProfile={getCompetenciesScoresAndLabels(
          cultureProfile?.competencies
        )}
      />
      <PageContainer>
        <DashboardNavbar title={t('Profile match candidates')} />
        <CandidateGrid container xs={12}>
          <Grid style={{ width: '100%' }}>
            <Box sx={{ minHeight: '50vh' }}>
              {isLoading ? (
                <LoadingScreen />
              ) : (
                <ListContainer
                  limit={6}
                  match={true}
                  list={profiles?.results}
                  card={CardCandidateLow}
                  getCardProps={() => ({
                    onMoveCandidateToBlacklist,
                    handleOpen,
                    profile,
                    handleSelectCandidate
                  })}
                  gridProps={{
                    style: { width: '100%' }
                  }}
                />
              )}
            </Box>
          </Grid>
          <FilterGroupRight>
            <Typography
              sx={{
                fontWeight: '600',
                fontSize: '20px',
                color: '#131417',
                mb: '33px'
              }}
            >
              Filter by
            </Typography>
            <OverviewSearch
              setValue={useCallback(
                (value: any) =>
                  dispatch(setMatchCandidatesFilter('query', value)),
                [dispatch]
              )}
              value={query}
            />
            <Description2 variant="h5">{t('Min score')}</Description2>

            <Slider
              style={{
                margin: '10px 10px 30px 10px',
                width: 'auto'
              }}
              value={min_score}
              min={0}
              max={100}
              marks={[
                {
                  value: 0,
                  label: '0%'
                },
                {
                  value: 100,
                  label: '100%'
                }
              ]}
              valueLabelDisplay="auto"
              onChange={(value: any) =>
                dispatch(
                  setMatchCandidatesFilter('min_score', value.target.value)
                )
              }
            />
            <OverviewSelect
              title={t('Match Profile')}
              activeOption={+(id || '')}
              options={matchProfiles}
              setOption={(value: any) => handleSelectMatchProfile(value)}
            />
            {experiences.length && degreesStages.length
              ? filters.map(({ label, fieldName, options, multi, type }) => {
                  const activeOption = (filterData as any)[fieldName];
                  return (
                    <OverviewSelect
                      type={type || 'select'}
                      key={fieldName}
                      title={t(label)}
                      activeOption={activeOption}
                      options={options}
                      setOption={(value: any) =>
                        dispatch(
                          setMatchCandidatesFilter('filterData', {
                            ...filterData,
                            [fieldName]: value
                          })
                        )
                      }
                      multiple={multi}
                    />
                  );
                })
              : null}

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                pb: 2,
                cursor: 'pointer',
                margin: '0 auto'
              }}
              onClick={() => dispatch(resetMatchCandidatesFilters())}
            >
              <Typography
                style={{
                  fontFamily: 'Rubik',
                  fontWeight: 700,
                  fontSize: '18px',
                  color: '#562C82'
                }}
              >
                {t('Reset All')}
              </Typography>
              <ResetButton />
            </Box>
          </FilterGroupRight>
        </CandidateGrid>
        <PaginationContainer
          page={page}
          count={profiles?.count}
          perPage={limit}
          setPage={(value) => {
            dispatch(setMatchCandidatesFilter('page', value));
            dispatch(setMatchCandidatesFilter('offset', (value - 1) * limit));
          }}
        />
      </PageContainer>
    </Page>
  );
};
export default CandidatesOverview;
