import { Box, styled, Typography } from '@material-ui/core';

export const ProgressBar = ({
  compared = false,
  label,
  max,
  value,
  icon
}: {
  compared?: boolean;
  label?: string;
  max: number;
  value: number;
  icon?: any;
}) => {
  const MainBar = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.grey[300],
    borderRadius: '15px',
    height: '20px',
    width: '100%'
  }));

  const percent = (100 / max) * value;

  const Progress = styled(Box)(({ theme }) => ({
    backgroundColor: compared ? '#1C9DD7' : theme.palette.primary.main,
    borderRadius: '15px',
    height: '20px',
    width: `${percent}%`,
    color: 'white',
    fontSize: '11px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }));

  return (
    <Box sx={{ m: 2, textAlign: 'start' }}>
      {label ? <Typography gutterBottom>{label}</Typography> : null}
      <Box display="flex" flexDirection="row" alignItems="center">
        {icon}
        <Typography color="GrayText" sx={{ mr: 1, fontSize: 11 }}>
          1
        </Typography>
        <MainBar>
          <Progress>{value}</Progress>
        </MainBar>
        <Typography color="GrayText" sx={{ ml: 1, fontSize: 11 }}>
          {max}
        </Typography>
      </Box>
    </Box>
  );
};
