import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Modal } from '@material-ui/core';
import SvgIconStyle from 'components/SvgIconStyle';
import DashboardNavbar from 'layouts/dashboard/navbar/DashboardNavbar';
import { useDispatch, useSelector } from 'react-redux';
import useBeforeUnload from '@hooks/useBeforeUnload';
import LoadingScreen from '../../components/LoadingScreen';
import StepperComponent from '../../components/Stepper';
import Page from '../../components/containers/Page';
import PageContainer from '../../components/containers/PageContainer';
import HeadingContainer from '../../components/containers/HeadingContainer';
import { postProfile, updateProfile } from '../../requests/profile';
import { PATH_DASHBOARD } from '../../routes/paths';
import {
  getTranslatedList,
  MIN_COMPETENCY_SELECTED_LENGTH,
  MIN_COMPETENCY_SLIDER_RANGE
} from '../../constants/constants';
import useLocales from '../../hooks/useLocales';
import CultureProfileSecondStep from './CultureProfileSecondStep';
import CultureProfileFirstStep from './CultureProfileFirstStep';
import {
  ButtonBox,
  ModalBox,
  ModalBoxContent,
  ModalButton,
  ModalGrid,
  TypographyDescription,
  TypographyTitle
} from '../../styled';
import {
  getCompetencies,
  getCultureProfile
} from '../../redux/slices/cultureProfileReducer';
import { RootState } from '../../redux/store';

const CultureProfile = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [selectedLabels, setSelectedLabels] = useState<string[]>([]);
  const [descriptions, setDescriptions] = useState<string[]>([]);
  const [currentProfileTitle, setCurrentProfileTitle] = useState('');
  const [currentProfileAnonymous, setCurrentProfileAnonymous] = useState(false);
  const [minRanges, setMinRanges] = useState<number[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [showModalFinish, setShowModalFinish] = useState(true);
  const [prompt, setPrompt] = useState(true);
  const [path, setPath] = useState('');
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { DEFAULT_MESSAGE_TEXTS } = getTranslatedList();
  const { t } = useLocales();
  const navigate = useNavigate();
  const { id } = useParams();
  const { isLoading, cultureProfile, competency } = useSelector(
    (state: RootState) => state.cultureProfileReducer
  );
  const isNew = id === 'new';

  const isDirty = !!selectedLabels.length || !!currentProfileTitle;
  useBeforeUnload(isDirty);

  useEffect(() => {
    dispatch(getCompetencies());
    getInitialData();
  }, [id, isNew]);

  const getInitialData = () => {
    if (id && !isNew) {
      dispatch(getCultureProfile(id, true));
    }
  };

  const getInitialDataValues = () => {
    if (id && !isNew) {
      const desc: any = [];
      if (cultureProfile?.competencies) {
        cultureProfile?.competencies.map((item: any) => {
          desc.push(item.competency?.description);
        });
        setDescriptions(desc);
      }

      if (cultureProfile.name) setCurrentProfileTitle(cultureProfile.name);
      if (cultureProfile.anonymous)
        setCurrentProfileAnonymous(cultureProfile.anonymous);
      if (cultureProfile.competency_labels)
        setSelectedLabels(cultureProfile.competency_labels);
      if (cultureProfile.competency_min_scores)
        setMinRanges(cultureProfile.competency_min_scores);
    }
  };

  useEffect(() => {
    if (cultureProfile) {
      getInitialDataValues();
    }
  }, [cultureProfile, isNew]);

  const handleSetSelected = (label: string, id: any, text: string) => {
    const newSelected: string[] = [...selectedLabels];
    const newDescriptions: string[] = [...descriptions];
    const newMinRanges: number[] = [...minRanges];

    const index = selectedLabels.indexOf(label);
    if (index !== -1) {
      newSelected.splice(index, 1);
      newMinRanges.splice(index, 1);
      newDescriptions.splice(index, 1);
    } else if (newSelected.length < MIN_COMPETENCY_SELECTED_LENGTH) {
      newSelected.push(label);
      newDescriptions.push(text);
      newMinRanges.push(MIN_COMPETENCY_SLIDER_RANGE);
    }
    setSelectedLabels(newSelected);
    setDescriptions(newDescriptions);
    setMinRanges(newMinRanges);
  };

  const handleSetRanges = (i: number, values: number) => {
    const newMinRanges: number[] = [...minRanges];
    newMinRanges.splice(i, 1, values);
    setMinRanges(newMinRanges);
  };

  const handleSubmit = async () => {
    const data = {
      name: currentProfileTitle,
      anonymous: currentProfileAnonymous,
      competencies: competency
        .map((group: any) => group.competencies)
        .reduce((acc, val) => acc.concat(val), [])
        .filter((el: any) => selectedLabels.includes(el.name))
        .map((el: any) => ({
          competency: el.id,
          min_score: minRanges[selectedLabels.indexOf(el.name)]
        })),
      is_culture: true
    };
    if (isNew) {
      await postProfile(data, true);
      enqueueSnackbar(DEFAULT_MESSAGE_TEXTS.created, { variant: 'success' });
    } else {
      const { id } = cultureProfile;
      await updateProfile(id, data, true);
      enqueueSnackbar(DEFAULT_MESSAGE_TEXTS.updated, { variant: 'success' });
    }
    navigate(`${PATH_DASHBOARD.culture_profile.cultureProfiles}`);
  };

  const getActiveStepComponent = () => {
    switch (activeStep) {
      case 0:
        return (
          <CultureProfileFirstStep
            competencies={competency}
            selectedOptionsLabels={selectedLabels}
            handleSetSelected={handleSetSelected}
            title={currentProfileTitle}
            setCurrentProfileTitle={setCurrentProfileTitle}
            anonymous={currentProfileAnonymous}
            setAnonymous={setCurrentProfileAnonymous}
          />
        );
      case 1:
        return (
          <CultureProfileSecondStep
            selectedOptionsLabels={selectedLabels}
            minRanges={minRanges}
            setRanges={handleSetRanges}
            descriptions={descriptions}
          />
        );
      default:
        return 'No such step';
    }
  };

  const steps = [
    {
      label: t('Select the most important competencies'),
      nextEnable:
        selectedLabels.length >= MIN_COMPETENCY_SELECTED_LENGTH &&
        currentProfileTitle.length !== 0,
      subtitle: 'STEP 1',
      startSrc: '',
      activeSrc: '/static/img/icons/cursor-add.svg',
      endSrc: '/static/img/icons/cursor-add-active.svg'
    },
    {
      label: t('Select competencies score'),
      nextEnable: true,
      subtitle: 'STEP 2',
      startSrc: '/static/img/icons/Ellipse.svg',
      activeSrc: '/static/img/icons/Ellipse-active.svg',
      endSrc: ''
    }
  ];

  return isLoading ? (
    <LoadingScreen />
  ) : (
    <Page title={`${isNew ? t('Add') : t('Edit')} ${t('Culture Profile')}`}>
      <DashboardNavbar
        title={`${isNew ? t('Add') : t('Edit')} ${t('Culture Profile')}`}
      />

      <Modal
        open={showModal}
        onClose={() => {
          setShowModal(false);
        }}
      >
        <ModalGrid>
          <ModalBox>
            <SvgIconStyle
              onClick={() => setShowModal(false)}
              src="/static/img/icons/controls/close.svg"
              style={{
                top: '10px',
                width: '20px',
                height: '20px',
                position: 'absolute',
                right: '10px',
                cursor: 'pointer',
                filter:
                  'invert(45%) sepia(2%) saturate(20%) hue-rotate(189deg) brightness(71%) contrast(90%)'
              }}
            />
            <ModalBoxContent>
              <TypographyTitle>
                Are you sure you want to stop creating new culture profile and
                leave the page?
              </TypographyTitle>
              <TypographyDescription>
                The changes you made will not be saved and will be irretrievably
                lost
              </TypographyDescription>
              <ButtonBox>
                <ModalButton
                  variant="outlined"
                  onClick={() => setShowModal(false)}
                >
                  Back
                </ModalButton>
                <ModalButton
                  variant="contained"
                  onClick={() => {
                    setPrompt(false);
                    setTimeout(() => {
                      navigate(path);
                    }, 50);
                  }}
                >
                  OK
                </ModalButton>
              </ButtonBox>
            </ModalBoxContent>
          </ModalBox>
        </ModalGrid>
      </Modal>
      <PageContainer>
        <HeadingContainer
          subtitle={t(
            'Please select the most important competencies for your culture profile'
          )}
        />
        <StepperComponent
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          onFinish={() => {
            setShowModalFinish(false);
            handleSubmit();
          }}
          onBack={() => {}}
          steps={steps}
        >
          {getActiveStepComponent()}
        </StepperComponent>
      </PageContainer>
    </Page>
  );
};

export default CultureProfile;
