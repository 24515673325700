import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllVacancies, getCandidates } from 'redux/slices/dashboard';
import { RootState } from 'redux/store';

import { CandidateCard } from 'components/CardCandidate';
import { ResetButton } from 'components/Buttons';
import {
  FilterGroup,
  OverviewSearch,
  OverviewSelect
} from 'components/OverviewFilter';
import Page from 'components/containers/Page';
import ListContainer from 'components/containers/ListContainer';
import PaginationContainer from 'components/containers/PaginationContainer';
import PageContainer from 'components/containers/PageContainer';
import CandidateModal from 'components/CandidateModal';
import {
  resetApplicantsFilters,
  setApplicantsFilter
} from 'redux/slices/filters';
import { getTranslatedList } from 'constants/constants';
import { getCompetenciesScoresAndLabels } from 'helpers/listValues';
import useLocales from 'hooks/useLocales';
import { useDebounce } from '../../hooks/useDebounce';
import { Box, Grid, Modal, Slider, Typography } from '@material-ui/core';
import { styled } from '@mui/material';
import DashboardNavbar from '../../layouts/dashboard/navbar/DashboardNavbar';
import { ProfileChat } from 'components/Chat';
import { Close } from '@material-ui/icons';
import { getCandidateInfo } from 'redux/slices/candidate';
import { Description2 } from 'components/text/title';
import { useLocation, useNavigate } from 'react-router-dom';
import useURLFilters from '@hooks/useURLFilters';
import { PATH_PAGE } from 'routes/paths';

const TypographyFilter = styled(Typography)(() => ({
  fontWeight: 600,
  fontSize: '20px',
  lineHeight: '24px',
  color: '#131417',
  marginBottom: '33px'
}));

const ApplicantsGrid = styled(Grid)(() => ({
  display: 'flex',
  columnGap: '40px',
  '@media (max-width: 1440px)': {
    flexDirection: 'column-reverse'
  }
}));

const FilterGroupRight = styled(FilterGroup)(() => ({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '375px',
  padding: '30px',
  justifyContent: 'center',
  background:
    'linear-gradient(94.35deg, rgba(255, 255, 255, 0.8) -5.21%, rgba(255, 255, 255, 0.3) 114.12%)',
  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.08)',
  borderRadius: '20px',
  height: 'max-content',
  margin: '40px 0 0 auto',
  '@media (max-width: 1440px)': {
    margin: '0 0 40px 0',
    maxWidth: '100%'
  }
}));

const FilterGroupLeft = styled(FilterGroup)(() => ({
  '@media (max-width: 1200px)': {
    flexDirection: 'column-reverse',
    '.MuiAutocomplete-root': {
      width: '100%'
    }
  }
}));

const ApplicantsOverview = ({ style = {} }) => {
  const { t } = useLocales();
  const { PERIOD_TYPES, STAGE_TYPES, SCORE_TYPES } = getTranslatedList();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [chatModalIsOpen, setChatModalIsOpen] = useState(false);
  const [currentCandidate, setCurrentCandidate] = useState<any>({});
  const [isRefresh, setIsRefresh] = useState<boolean>(false);
  const [modalOpenedByHash, setModalOpenedByHash] = useState(false);

  const navigate = useNavigate();

  const handleClose = () => {
    setModalIsOpen(false);
    setCurrentCandidate({});
  };

  const handleOpenApplicantModal = (candidate: any) => {
    setModalIsOpen(true);
    setCurrentCandidate(candidate);
  };

  const handleOpenChat = (candidate: any) => {
    setChatModalIsOpen(true);
    setCurrentCandidate(candidate);
  };

  const handleCloseChat = () => {
    setChatModalIsOpen(false);
    setCurrentCandidate({});
  };

  const dailyApplicants = useSelector(
    (state: RootState) => state.dashboard.dailyApplicants
  );
  const vacancies = useSelector(
    (state: RootState) => state.dashboard.allVacancies
  );
  const user = useSelector((state: RootState) => state.authJwt.user);
  const company: any = useSelector(
    (state: RootState) => state.generalRecruiter.company
  );
  const filters = useSelector(
    (state: RootState) => state.filtersReducer.applicantsOverview
  );

  const {
    page,
    vacancyID,
    periodicity,
    limit,
    offset,
    query,
    stage,
    ordering,
    status,
    salaries,
    work_exps,
    degrees,
    locations,
    min_score,
    min_score_culture,
    skills,
    customer,
    q
  } = filters;

  const { results, count } = dailyApplicants || {};
  const { results: vacanciesResult } = vacancies ?? {};
  const dispatch = useDispatch();
  const debounced = useDebounce(q, 500);

  const resultsWithoutCanceled = useMemo(
    () => results?.filter((item) => item.status !== 4),
    [results]
  );

  const location = useLocation();
  const { hash } = location;

  const parsedApplicantId = Number(hash.substring(1).split('/')[1]);

  const parsedApplicant = useMemo(
    () => resultsWithoutCanceled?.find((item) => item.id === parsedApplicantId),
    [resultsWithoutCanceled, parsedApplicantId]
  );

  useEffect(() => {
    if (!modalOpenedByHash) {
      if (resultsWithoutCanceled && parsedApplicantId && !parsedApplicant) {
        navigate(PATH_PAGE.page404);
      }
      if (parsedApplicant) {
        handleOpenApplicantModal(parsedApplicant);
        setModalOpenedByHash(true);
      }
    }
  }, [modalOpenedByHash, resultsWithoutCanceled, parsedApplicant]);

  useEffect(() => {
    if (currentCandidate.id) {
      dispatch(getCandidateInfo(currentCandidate.candidate.id));
    }
  }, [dispatch, currentCandidate]);

  const fetchJobPostData = (query: any) => {
    dispatch(getAllVacancies(query, true));
  };

  useEffect(() => {
    fetchJobPostData(debounced);
  }, [debounced]);

  useEffect(() => {
    dispatch(setApplicantsFilter('offset', page * limit - limit));
  }, [dispatch, page, limit]);

  useEffect(() => {
    dispatch(
      getCandidates(
        customer?.id,
        vacancyID,
        periodicity,
        limit,
        offset,
        query,
        stage,
        ordering,
        status,
        salaries,
        work_exps,
        degrees,
        locations,
        min_score,
        min_score_culture,
        skills?.id
      )
    );
  }, [
    dispatch,
    vacancyID,
    periodicity,
    limit,
    offset,
    query,
    stage,
    ordering,
    status,
    salaries,
    work_exps,
    degrees,
    locations,
    min_score,
    min_score_culture,
    isRefresh,
    isRefresh,
    skills,
    customer
  ]);

  const filterNames = [
    'limit',
    'offset',
    'min_score',
    'min_score_culture',
    'vacancyID',
    'stage',
    'ordering',
    'query',
    'periodicity'
  ];

  const currentFilters = useSelector(
    (state: RootState) => state.filtersReducer.applicantsOverview
  );

  useURLFilters({
    fragmentIdentifier: '#applicants/',
    filterNames,
    setterFunction: setApplicantsFilter,
    currentFilters
  });

  const isRecruiterOrAdmin = user.role === 'recruiter' || user.role === 'admin';

  const title = isRecruiterOrAdmin
    ? t('Applicants Overview')
    : t('My Applications');
  const noDataText = vacancyID
    ? t('No data')
    : t('No data was found for selected filters');

  return (
    <Page title={title}>
      <CandidateModal
        open={modalIsOpen}
        handleClose={handleClose}
        candidate={currentCandidate}
        profile={getCompetenciesScoresAndLabels(
          currentCandidate?.vacancy?.competencies
        )}
        cultureProfile={getCompetenciesScoresAndLabels(
          currentCandidate?.vacancy?.culture_profile?.competencies
        )}
      />
      <Modal open={chatModalIsOpen} onClose={handleCloseChat}>
        <Grid
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'column',
            alignItems: 'center'
          }}
        >
          <Box
            style={{
              position: 'relative',
              width: '50%',
              height: 'max-content',
              background:
                'linear-gradient(94.35deg, rgba(255, 255, 255, 0.8) -5.21%, #FFFFFF -5.2%, #FFFFFF 114.12%)',
              boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.08)',
              borderRadius: '20px',
              margin: '0 auto',
              padding: '20px'
            }}
          >
            <Typography
              style={{
                position: 'absolute',
                right: 40,
                top: 20,
                cursor: 'pointer'
              }}
              onClick={handleCloseChat}
            >
              <Close style={{ color: '#C9CAD9' }} />
            </Typography>
            <ProfileChat
              showChat={chatModalIsOpen}
              candidate={currentCandidate.candidate}
              setShowChat={setChatModalIsOpen}
            />
          </Box>
        </Grid>
      </Modal>
      <PageContainer style={style}>
        <DashboardNavbar title={title} />

        <ApplicantsGrid>
          <Grid style={{ width: '100%' }}>
            <FilterGroupLeft
              style={{
                width: '100%',
                gap: '1.25rem',
                justifyContent: 'normal'
              }}
            >
              {isRecruiterOrAdmin && (
                <>
                  <OverviewSelect
                    title={t('Candidate stage')}
                    activeOption={stage}
                    options={STAGE_TYPES}
                    setOption={(value: any) =>
                      dispatch(setApplicantsFilter('stage', value))
                    }
                  />
                  <OverviewSelect
                    title={t('Order by')}
                    activeOption={ordering}
                    options={SCORE_TYPES}
                    setOption={(value: any) =>
                      dispatch(setApplicantsFilter('ordering', value))
                    }
                  />
                </>
              )}
              <OverviewSelect
                title={t('Date of jobpost')}
                activeOption={periodicity}
                options={PERIOD_TYPES}
                setOption={(value: any) =>
                  dispatch(setApplicantsFilter('periodicity', value))
                }
              />
            </FilterGroupLeft>
            <ListContainer
              cardCandidate={false}
              list={resultsWithoutCanceled}
              card={CandidateCard}
              noDataText={noDataText}
              getCardProps={() => ({
                setIsRefresh,
                handleOpenApplicantModal,
                handleOpenChat,
                isRefresh
              })}
              user={user}
            />
            <PaginationContainer
              page={page}
              count={count}
              perPage={limit}
              setPage={(value) => dispatch(setApplicantsFilter('page', value))}
            />
          </Grid>
          <FilterGroupRight style={{ marginTop: '27px' }}>
            <TypographyFilter>{t('Filter by')}</TypographyFilter>
            <OverviewSearch
              setValue={(value: any) => {
                dispatch(setApplicantsFilter('query', value));
              }}
              value={query}
            />
            <Description2 variant="h5">
              {t('Filter by talent match')}
            </Description2>
            <Slider
              style={{
                margin: '10px 10px 30px 10px',
                width: 'auto'
              }}
              value={min_score}
              max={100}
              marks={[
                {
                  value: 0,
                  label: '0%'
                },
                {
                  value: 100,
                  label: '100%'
                }
              ]}
              valueLabelDisplay="auto"
              onChange={(value: any) =>
                dispatch(setApplicantsFilter('min_score', value.target.value))
              }
            />

            <Description2 variant="h5">
              {t('Filter by culture fit')}
            </Description2>
            <Slider
              style={{
                margin: '10px 10px 30px 10px',
                width: 'auto'
              }}
              value={min_score_culture}
              max={100}
              marks={[
                {
                  value: 0,
                  label: '0%'
                },
                {
                  value: 100,
                  label: '100%'
                }
              ]}
              valueLabelDisplay="auto"
              onChange={(value: any) =>
                dispatch(
                  setApplicantsFilter('min_score_culture', value.target.value)
                )
              }
            />

            <OverviewSelect
              type="job_post"
              customer={true}
              width="100%"
              placeholder={t('Job Post')}
              activeOption={vacancyID}
              options={vacanciesResult}
              setOption={(value: any) =>
                dispatch(setApplicantsFilter('vacancyID', value))
              }
              setQuery={(value: string) =>
                dispatch(setApplicantsFilter('q', value))
              }
            />
            {company.is_agency && (
              <OverviewSelect
                width="100%"
                type="select-customers"
                placeholder={t('Customer')}
                activeOption={customer}
                setOption={(value: any) =>
                  dispatch(setApplicantsFilter('customer', value))
                }
                isPlaceholder={true}
              />
            )}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                pb: 2,
                cursor: 'pointer',
                margin: '0 auto'
              }}
              onClick={() => dispatch(resetApplicantsFilters())}
            >
              <Typography
                style={{
                  fontFamily: 'Rubik',
                  fontWeight: 700,
                  fontSize: '18px',
                  color: '#562C82'
                }}
              >
                {t('Reset All')}
              </Typography>
              <ResetButton />
            </Box>
          </FilterGroupRight>
        </ApplicantsGrid>
      </PageContainer>
    </Page>
  );
};

export default ApplicantsOverview;
