import { Grid, Box, Typography, CardContent } from '@material-ui/core';
import useLocales from '../../../hooks/useLocales';
import SvgIconStyle from '../../../components/SvgIconStyle';
import MButton from 'components/new-designer/button/MButton';
import { freePlanData } from 'constants/registerStep/constants';

export default function FreePlanTooltip({ setOpen, tooltip }: any): JSX.Element {
  const { t } = useLocales();
  const plans = tooltip ? freePlanData.slice(0, 5) : freePlanData;

  return (
    <Grid
      style={{
        background: '#FFFFFF',
        display: 'inline-flex',
        padding: !tooltip ? '43px 34px 43px 35px' : '50px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '42px',
        boxShadow: tooltip
          ? 'unset'
          : '0px 18.34289px 36.68578px -4.58572px rgba(145, 158, 171, 0.24), 0px 0px 2.29286px 0px rgba(145, 158, 171, 0.24)'
      }}
    >
      <Typography
        style={{
          fontSize: '38px',
          textAlign: 'start',
          color: '#000000',
          width: '100%',
          fontWeight: '600'
        }}
        variant="h4"
      >
        {t('Free plan')}
      </Typography>
      <Typography
        variant="h5"
        style={{
          fontSize: '12px',
          color: '#000000',
          opacity: 0.7,
          width: '100%',
          textAlign: 'start'
        }}
      >
        {t('Work towards an excellent selection process')}
      </Typography>
      <Typography
        style={{
          fontSize: '17px',
          color: '#562C82',
          fontWeight: 600,
          textAlign: 'start',
          width: '94%'
        }}
      >
        {t('€ 00.00 p.m')}
      </Typography>
      <CardContent style={{ padding: '0', marginTop: '20px' }}>
        <Box
          sx={{
            minHeight: 200,
            textAlign: 'start',
            display: 'flex',
            alignItems: 'start',
            rowGap: '20px',
            flexDirection: 'column',
            minWidth: '280px'
          }}
        >
          {plans?.map((point: string, i: number) => (
            <Typography
              key={i}
              fontSize="14px"
              style={{
                display: 'flex',
                alignItems: 'center',
                columnGap: '6px',
                color: '#000000'
              }}
            >
              <SvgIconStyle src="/static/img/icons/subscriptionIcon.svg" /> {point}
            </Typography>
          ))}
        </Box>
      </CardContent>
      {!tooltip && (
        <MButton
          title={t('Get it now!')}
          MyClass="blueButton"
          click={() => setOpen(false)}
          style={{
            fontSize: '17px',
            display: 'flex',
            width: '290px',
            height: '55px',
            minWidth: '73px',
            padding: '12px 24px 12px 25px',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '9px',
            background: '#562C82',
            boxShadow: '0px 9px 18px 0px rgba(86, 44, 130, 0.24)',
            marginTop: '20px',
            color: '#fff',
            '&:hover': {
              background: '#562C82',
              opacity: '0.7'
            }
          }}
        />
      )}
    </Grid>
  );
}
