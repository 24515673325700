import { Grid, Typography } from '@material-ui/core';
import { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';

interface Messages {
  text: string;
  time: string;
  messageId: number;
  context: string;
  readNotifications?: (id: number) => void;
}

const NotificationMessage: FC<Messages> = ({
  text,
  time,
  messageId,
  context = '',
  readNotifications
}) => {
  const pattern =
    /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g;

  const toChatPath = useMemo(
    () =>
      pattern.test(context) ? context.substring(context.indexOf('/')) : '',
    [context]
  );

  const redirectMessenger = () => {
    if (readNotifications) {
      readNotifications(messageId);
    }
  };

  return (
    <Grid
      style={{
        display: 'flex',
        flexDirection: 'row',
        padding: ' 10px 10px 10px 0',
        gap: '15px'
      }}
    >
      <Grid
        style={{
          padding: '5px  0 5px 0 ',
          display: 'flex',
          flexDirection: 'column',
          rowGap: '5px',
          width: '100%'
        }}
      >
        <Grid
          sx={{
            display: 'flex',
            width: '100%'
          }}
        >
          {context ? (
            <Link target="_blank" to={toChatPath} onClick={redirectMessenger}>
              <Typography style={{  color: 'black', fontFamily: 'Rubik', fontWeight: 700 }}>
                {text}
              </Typography>
            </Link>
          ) : (
            <Typography style={{ color: 'black', fontFamily: 'Rubik', fontWeight: 700  }}>
              {text}
            </Typography>
          )}
        </Grid>
        <Typography
          style={{
            color: '#1C9DD7',
            fontSize: '14px',
            fontFamily: 'Rubik',
            textAlign: 'right'
          }}
        >
          {time}
        </Typography>
      </Grid>
    </Grid>
  );
};
export default NotificationMessage;
