import { translateArrayOptionsLabels } from 'utils/helpers';
import useLocales from '../hooks/useLocales';

export const useFormFieldsByType = (
  type: string,
  itemValues: any,
  handleSetValue: (field: string, value: any, id?: number) => any,
  options?: any,
  errors?: any,
  helperText?: any,
  disabled?: any,
  labelForSalary?: string,
  is_agency?: boolean,
  withOutLang?: boolean,
  is_freelancer?: boolean
) => {
  const { t } = useLocales();
  switch (type) {
    case 'vonq-pre-order-campaigns':
      return [
        {
          type: 'text',
          value: itemValues.working_location_address,
          label: t('Working location address'),
          onChange: (e: any) =>
            handleSetValue('working_location_address', e.target.value),
          error: errors?.working_location_address
        },
        {
          type: 'text',
          value: itemValues.working_location_postcode,
          label: t('Working location postcode'),
          onChange: (e: any) =>
            handleSetValue('working_location_postcode', e.target.value),
          error: errors?.working_location_postcode
        },
        {
          type: 'text',
          value: itemValues.working_location_city,
          label: t('Working location city'),
          onChange: (e: any) =>
            handleSetValue('working_location_city', e.target.value),
          error: errors?.working_location_city
        },
        {
          type: 'text',
          value: itemValues.working_location_country,
          label: t('Working location country'),
          onChange: (e: any) =>
            handleSetValue('working_location_country', e.target.value),
          error: errors?.working_location_country
        },
        {
          type: 'count-select',
          value: itemValues.name,
          label: t('Weekly working hours from'),
          onChange: (e: any, el: any) =>
            handleSetValue('weekly_working_hours_from', el?.id),
          options: options?.weekly_working_hours_from,
          error: errors?.weekly_working_hours_from
        },
        {
          type: 'count-select',
          value: itemValues.name,
          label: t('Weekly working hours to'),
          onChange: (e: any, el: any) =>
            handleSetValue('weekly_working_hours_to', el?.id),
          options: options?.weekly_working_hours_to,
          error: errors?.weekly_working_hours_to
        },
        {
          type: 'count-select',
          value: itemValues.name,
          label: t('Years of experience'),
          onChange: (e: any, el: any) =>
            handleSetValue('years_of_experience', el?.id),
          options: options?.years_of_experience,
          error: errors?.years_of_experience
        },
        {
          type: 'vonq-education-level',
          value: itemValues.educationLevel,
          label: t('Education level'),
          onChange: (e: any, el: any) => handleSetValue('educationLevel', el),
          error: errors?.educationLevel
        },
        {
          type: 'vonq-seniority',
          value: itemValues.seniority,
          label: t('Seniority'),
          onChange: (e: any, el: any) => handleSetValue('seniority', el),
          error: errors?.seniority
        },
        {
          type: 'vonq-industries',
          value: itemValues.industry,
          label: t('Industries'),
          onChange: (e: any, el: any) => handleSetValue('industry', el),
          error: errors?.industry
        },
        {
          type: 'vonq-job-function',
          value: itemValues.jobCategory,
          label: t('Job category'),
          onChange: (e: any, el: any) => handleSetValue('jobCategory', el),
          error: errors?.jobCategory
        },
        {
          type: 'email',
          value: itemValues.jobpage_url,
          label: t('Job page URL'),
          onChange: (e: any) => handleSetValue('jobpage_url', e.target.value),
          error: errors?.jobpage_url,
          helperText: helperText.jobpage_url
        },
        {
          type: 'checkbox',
          value: itemValues.remote_work,
          label: t('Remote work'),
          onChange: (e: any) => handleSetValue('remote_work', e.target.checked),
          error: errors?.remote_work
        }
      ];
    case 'vonq-products':
      return [
        {
          type: 'vonq-job-title',
          value: itemValues.jobTitleId,
          label: t('Job title'),
          onChange: (e: any, el: any) => handleSetValue('jobTitleId', el),
          helperText: helperText.jobTitleId
        },
        {
          type: 'vonq-job-function',
          value: itemValues.jobFunctionId,
          label: t('Job function'),
          onChange: (e: any, el: any) => handleSetValue('jobFunctionId', el),
          helperText: helperText.jobFunctionId,
          disabled: disabled.jobFunctionId
        },
        {
          type: 'vonq-locations',
          value: itemValues.includeLocationId,
          label: t('Include location'),
          onChange: (e: any, el: any) =>
            handleSetValue('includeLocationId', el),
          helperText: helperText.includeLocationId
        },
        {
          type: 'vonq-industries',
          value: itemValues.industryId,
          label: t('Industries'),
          onChange: (e: any, el: any) => handleSetValue('industryId', el)
        }
      ];
    case 'vonq-products-full':
      return [
        {
          type: 'vonq-job-title',
          value: itemValues.jobTitleId,
          label: t('Job title'),
          onChange: (e: any, el: any) => handleSetValue('jobTitleId', el),
          helperText: helperText.jobTitleId
        },
        {
          type: 'vonq-job-function',
          value: itemValues.jobFunctionId,
          label: t('Job function'),
          onChange: (e: any, el: any) => handleSetValue('jobFunctionId', el),
          helperText: helperText.jobFunctionId,
          disabled: disabled.jobFunctionId
        },
        {
          type: 'vonq-locations',
          value: itemValues.includeLocationId,
          label: t('Include location'),
          onChange: (e: any, el: any) =>
            handleSetValue('includeLocationId', el),
          helperText: helperText.includeLocationId
        },
        {
          type: 'vonq-industries',
          value: itemValues.industryId,
          label: t('Industries'),
          onChange: (e: any, el: any) => handleSetValue('industryId', el)
        },
        {
          type: 'select',
          value: itemValues.durationFrom,
          label: t('Duration from'),
          onChange: (e: any, el: any) => handleSetValue('durationFrom', el?.id),
          options: options.durationFrom
        },
        {
          type: 'select',
          value: itemValues.durationTo,
          label: t('Duration to'),
          onChange: (e: any, el: any) => handleSetValue('durationTo', el?.id),
          options: options.durationTo
        }
      ];
    case 'vonq-products-search':
      return [
        {
          type: 'text',
          value: itemValues.name,
          label: t('Search for channels'),
          onChange: (e: any) => handleSetValue('name', e.target.value)
        }
      ];
    case 'cms-offer':
      return [
        {
          type: 'text',
          value: itemValues?.title,
          label: t('Title'),
          onChange: (e: any) => handleSetValue('title', e.target.value)
        },
        {
          type: 'text',
          value: itemValues?.description,
          label: t('Description Text'),
          onChange: (e: any) => handleSetValue('description', e.target.value),
          multiline: true,
          rows: 4
        }
      ];
    case 'cms-gallery':
      return [
        {
          type: 'text',
          value: itemValues?.title,
          label: t('Title'),
          onChange: (e: any) => handleSetValue('title', e.target.value)
        },
        {
          type: 'text',
          value: itemValues?.description,
          label: t('Description Text'),
          onChange: (e: any) => handleSetValue('description', e.target.value),
          multiline: true,
          rows: 4
        }
      ];
    case 'cms-settings-first':
      return [
        {
          type: 'text',
          value: itemValues.title,
          label: t('Home Page Title'),
          onChange: (e: any) => handleSetValue('title', e.target.value)
        },
        {
          type: 'text',
          value: itemValues.offer_section_title,
          label: t('Offer Section Title'),
          onChange: (e: any) =>
            handleSetValue('offer_section_title', e.target.value)
        },
        {
          type: 'text',
          value: itemValues.button,
          label: t('Button Text'),
          onChange: (e: any) => handleSetValue('button', e.target.value)
        },
        {
          type: 'text',
          value: itemValues.vacancy_section_title,
          label: t('Job Section Title'),
          onChange: (e: any) =>
            handleSetValue('vacancy_section_title', e.target.value)
        },
        {
          type: 'text',
          value: itemValues.gallery_section_title,
          label: t('Gallery Section Title'),
          onChange: (e: any) =>
            handleSetValue('gallery_section_title', e.target.value)
        }
      ];
    case 'welcome-form':
      return [
        {
          type: 'text',
          value: itemValues.welcome_section_title,
          label: t('Gallery Section Title'),
          onChange: (e: any) =>
            handleSetValue('welcome_section_title', e.target.value)
        },
        {
          type: 'text',
          value: itemValues.welcome_section_text,
          label: t('Button Text'),
          onChange: (e: any) =>
            handleSetValue('welcome_section_text', e.target.value)
        }
      ];
    case 'social-media-urls':
      return [
        {
          type: 'text',
          value: itemValues.url,
          label: t('URL'),
          onChange: (id: any, el: any) =>
            handleSetValue('url', el.target.value, id)
        }
      ];
    case 'cms-settings-second':
      return [
        {
          type: 'text',
          value: itemValues.information_section_title,
          label: t('Information Section Title'),
          onChange: (e: any) =>
            handleSetValue('information_section_title', e.target.value)
        },
        {
          type: 'text',
          value: itemValues.information,
          label: t('Information Text'),
          onChange: (e: any) => handleSetValue('information', e.target.value),
          multiline: true,
          rows: 11
        }
      ];
    case 'job-post-first-step':
      return [
        {
          type: 'text',
          value: itemValues.title,
          label: t('Job Title'),
          onChange: (e: any) => handleSetValue('title', e.target.value),
          error: errors?.title
        },
        ...(is_agency
          ? [
              {
                type: 'select-customers',
                value: itemValues.customer,
                label: t('Customer'),
                onChange: (e: any, item: any) =>
                  handleSetValue('customer', item),
                error: errors?.customer
              }
            ]
          : []),
        {
          type: 'culture_profile',
          value: itemValues.culture_profile,
          label: t('Culture Profile'),
          onChange: (e: any, el: any) => handleSetValue('culture_profile', el),
          options: options.profiles,
          error: errors?.culture_profile
        },
        {
          type: 'location',
          value: itemValues.locations,
          label: t('Locations'),
          onChange: (e: any, list: any) => handleSetValue('locations', list),
          multiple: true,
          error: errors?.locations
        },
        {
          type: 'select-industries',
          value: itemValues.industries,
          label: t('Industries'),
          onChange: (e: any, list: any) => handleSetValue('industries', list),
          multiple: true,
          error: errors?.industries
        },
        {
          type: 'select-degrees',
          value: itemValues.studies,
          label: t('Studies'),
          onChange: (e: any, el: any) => handleSetValue('studies', el),
          multiple: true,
          error: errors?.studies
        },
        {
          type: 'select',
          value: itemValues.degree,
          label: t('Degree'),
          onChange: (e: any, el: any) => handleSetValue('degree', el?.value),
          options: translateArrayOptionsLabels(options.degreesStages, t),
          error: errors?.degree
        },
        {
          type: 'select',
          value: itemValues.work_exp,
          label: t('Work Experience'),
          onChange: (e: any, el: any) => handleSetValue('work_exp', el?.value),
          options: translateArrayOptionsLabels(options.experiences, t),
          error: errors?.work_exp
        },
        {
          type: 'select',
          value: itemValues.contract,
          label: t('Contract'),
          onChange: (e: any, el: any) => handleSetValue('contract', el?.value),
          options: translateArrayOptionsLabels(options.contracts, t),
          error: errors?.contract
        },
        {
          type: 'date',
          value: itemValues.available || null,
          label: t('When do you want candidates to be available?'),
          onChange: (e: any) => handleSetValue('available', e.target.value),
          error: errors?.available
        },

        ...(is_freelancer
          ? [
              {
                type: 'select',
                value: itemValues.hourly_rate,
                label: t(`Hourly Rate (${labelForSalary})`),
                onChange: (e: any, el: any) =>
                  handleSetValue('hourly_rate', el?.value),
                options: options.hourly_rates,
                error: errors?.hourly_rate
              }
            ]
          : [
              {
                type: 'select',
                value: itemValues.salary,
                label: t(`Salary Range (${labelForSalary})`),
                onChange: (e: any, el: any) =>
                  handleSetValue('salary', el?.value),
                options: options.salaries,
                error: errors?.salary
              }
            ]),

        {
          type: 'checkbox',
          value: itemValues.drivers_license,
          label: t('Do candidates need a Drivers License?'),
          onChange: () =>
            handleSetValue('drivers_license', !itemValues.drivers_license)
        },
        {
          type: 'checkbox',
          value: itemValues.assessment_required,
          label: t('Assessment Required'),
          onChange: () =>
            handleSetValue(
              'assessment_required',
              !itemValues.assessment_required
            )
        }
      ];
    case 'job-post-second-step':
      return [
        {
          type: 'description',
          value: itemValues.description,
          label: t('Job Description (Add your job description)'),
          onChange: (e: any) => handleSetValue('description', e),
          multiline: true,
          rows: 2,
          error: errors?.description
        },
        {
          type: 'select-skills',
          value: itemValues.skills,
          label: t('Skills (Start typing and press enter to add each of your skills.)'),
          onChange: (e: any, list: any) => {
            handleSetValue('skills', list);
          },
          multiple: true,
          freeSolo: true
        },
        {
          type: 'select',
          value: itemValues.musts,
          label: t('Musts (Start typing and press enter to add each of your musts.)'),
          onChange: (e: any, el: any) => handleSetValue('musts', el),
          options: itemValues.musts,
          multiple: true,
          freeSolo: true,
          error: errors?.musts
        },
        {
          type: 'select',
          value: itemValues.perks,
          label: t('Perks (Start typing and press enter to add each of your perks.)'),
          onChange: (e: any, el: any) => handleSetValue('perks', el),
          options: itemValues.perks,
          multiple: true,
          freeSolo: true,
          error: errors?.perks
        },
        {
          type: 'select',
          value: itemValues.status,
          label: t('Status'),
          onChange: (e: any, el: any) => handleSetValue('status', el?.value),
          options: options.statuses,
          error: errors?.status
        },
        {
          name: 'anonymous',
          type: 'checkbox',
          value: itemValues.anonymous,
          label: t('Anonymous'),
          onChange: () => handleSetValue('anonymous', !itemValues.anonymous)
        }
      ];
    case 'candidate-pitch':
      return [
        {
          name: 'pitch',
          type: 'description',
          value: itemValues.pitch,
          label: t('Pitch'),
          onChange: (e: any) => handleSetValue('pitch', e),
          multiline: true,
          rows: 8,
          error: errors?.pitch
        }
      ];
    case 'candidate-preferences-info':
      return [
        {
          name: 'availability',
          type: 'select',
          value: itemValues.availability,
          label: t('Availability'),
          onChange: (e: any, el: any) =>
            handleSetValue('availability', el.value),
          options: options.availabilities,
          error: errors?.availability
        },
        {
          name: 'work_hours',
          type: 'select',
          value: itemValues.work_hours,
          label: t('Work hours'),
          onChange: (e: any, el: any) => handleSetValue('work_hours', el.value),
          options: options.work_hours,
          error: errors?.work_hours
        },
        {
          name: 'relocation',
          type: 'select',
          value: itemValues.relocation,
          label: t('Willing to relocate'),
          onChange: (e: any, el: any) => handleSetValue('relocation', el.value),
          options: options.approval,
          error: errors?.relocation
        },
        {
          name: 'work_exp',
          type: 'select',
          value: itemValues.work_exp,
          label: t('Work Experience'),
          onChange: (e: any, el: any) => handleSetValue('work_exp', el.value),
          options: translateArrayOptionsLabels(options.experiences, t),
          error: errors?.work_exp
        },
        {
          name: 'management_exp',
          type: 'select',
          value: itemValues.management_exp,
          label: t('Management Experience'),
          onChange: (e: any, el: any) =>
            handleSetValue('management_exp', el.value),
          options: translateArrayOptionsLabels(options.experiences, t),
          error: errors?.management_exp
        },
        {
          name: 'salary',
          type: 'select',
          value: itemValues.salary,
          label: t(`Salary Expectation (${labelForSalary})`),
          onChange: (e: any, el: any) => handleSetValue('salary', el.value),
          options: [
            ...options.salaries,
            { value: 6, label: 'I dont want to share' }
          ],
          error: errors?.salary
        },
        {
          type: 'location',
          value: itemValues.locations,
          label: t('Locations'),
          onChange: (e: any, list: any) => handleSetValue('locations', list),
          multiple: true,
          error: errors?.locations
        },
        {
          type: 'select-industries',
          value: itemValues.industries,
          label: t('Industries'),
          onChange: (e: any, list: any) => handleSetValue('industries', list),
          multiple: true,
          error: errors?.industries
        },
        {
          type: 'select',
          value: itemValues.contract,
          label: t('Contracts'),
          onChange: (e: any, list: any) =>
            handleSetValue(
              'contract',
              list.map((el: any) => el.value)
            ),
          options: translateArrayOptionsLabels(options.contracts, t),
          multiple: true,
          error: errors?.contract
        },
        ...(withOutLang
          ? []
          : [
              {
                name: 'default_lang',
                type: 'select',
                value: itemValues.default_lang,
                label: t('Preferred Language'),
                onChange: (e: any, list: any) =>
                  handleSetValue('default_lang', list?.value || 3),
                options: options.default_lang,
                error: errors?.default_lang
              }
            ])
      ];
    case 'address-info': // currently not using
      return [
        {
          name: 'country',
          type: 'text',
          value: itemValues.country,
          label: t('Country'),
          onChange: (e: any) => handleSetValue('country', e.target.value),
          error: errors?.country
        },
        {
          name: 'city',
          type: 'text',
          value: itemValues.city,
          label: t('City'),
          onChange: (e: any) => handleSetValue('city', e.target.value),
          error: errors?.city
        },
        {
          name: 'address',
          type: 'text',
          value: itemValues.address,
          label: t('Address'),
          onChange: (e: any) => handleSetValue('address', e.target.value),
          error: errors?.address
        },
        {
          name: 'state',
          type: 'text',
          value: itemValues.state,
          label: t('State'),
          onChange: (e: any) => handleSetValue('state', e.target.value),
          error: errors?.state
        },
        {
          name: 'zip',
          type: 'text',
          value: itemValues.zip,
          label: t('Zip Code'),
          onChange: (e: any) => handleSetValue('zip', e.target.value),
          error: errors?.zip
        }
      ];
    case 'notification-info':
      return [
        {
          name: 'contact_email',
          type: 'email',
          value: itemValues.contact_email,
          label: t('Contact Email Address'),
          onChange: (e: any) => handleSetValue('contact_email', e.target.value),
          error: errors?.contact_email
        }
      ];
    case 'company-info':
      return [
        {
          name: 'name',
          type: 'text',
          value: itemValues.name,
          label: t('Company Name'),
          onChange: (e: any) => handleSetValue('name', e.target.value),
          error: errors?.name
        },
        {
          name: 'website',
          type: 'url',
          value: itemValues.website,
          label: t('Website'),
          onChange: (e: any) => handleSetValue('website', e.target.value),
          error: errors?.website
        },
        {
          name: 'industry',
          type: 'select-industries',
          value: itemValues.industry,
          label: t('Industry'),
          onChange: (e: any, el: any) => handleSetValue('industry', el),
          error: errors?.industry
        },
        {
          name: 'contact',
          type: 'text',
          value: itemValues.contact,
          label: t('Contact'),
          onChange: (e: any) => handleSetValue('contact', e.target.value),
          error: errors?.contact
        },
        {
          name: 'privacy_statement',
          type: 'url',
          value: itemValues.privacy_statement,
          label: t('Privacy'),
          onChange: (e: any) =>
            handleSetValue('privacy_statement', e.target.value),
          error: errors?.privacy_statement
        },
        {
          name: 'general_terms',
          type: 'url',
          value: itemValues.general_terms,
          label: t('Terms'),
          onChange: (e: any) => handleSetValue('general_terms', e.target.value),
          error: errors?.general_terms
        },
        {
          name: 'currency',
          type: 'select',
          value: itemValues.currency,
          label: t('Currency'),
          onChange: (e: any, el: any) => handleSetValue('currency', el.value),
          options: options.currency,
          error: errors?.currency
        },
        {
          name: 'anonymous',
          type: 'checkbox',
          value: itemValues.anonymous,
          label: t('Anonymous policy'),
          onChange: () => handleSetValue('anonymous', !itemValues.anonymous)
        },
        {
          name: 'assessment_optional',
          type: 'checkbox',
          value: itemValues.assessment_optional,
          label: t('Assessment Optional'),
          onChange: () =>
            handleSetValue(
              'assessment_optional',
              !itemValues.assessment_optional
            )
        }
      ];
    case 'company-info-register':
      return [
        {
          name: 'name',
          type: 'text',
          value: itemValues.name,
          label: t('Company Name'),
          onChange: (e: any) => handleSetValue('name', e.target.value),
          error: errors?.name
        }, {
          name: 'website',
          type: 'url',
          value: itemValues.website,
          label: t('Website'),
          onChange: (e: any) => handleSetValue('website', e.target.value),
          error: errors?.website
        },
        {
          name: 'industry',
          type: 'select-industries',
          value: itemValues.industry,
          label: t('Industry'),
          onChange: (e: any, el: any) => handleSetValue('industry', el),
          error: errors?.industry
        },
      ]
    case 'account-info':
      return [
        {
          name: 'first_name',
          type: 'text',
          value: itemValues.first_name,
          label: t('First Name'),
          onChange: (e: any) => handleSetValue('first_name', e.target.value),
          error: errors?.first_name
        },
        {
          name: 'last_name',
          type: 'text',
          value: itemValues.last_name,
          label: t('Last Name'),
          onChange: (e: any) => handleSetValue('last_name', e.target.value),
          error: errors?.last_name
        },
        {
          name: 'email',
          type: 'text',
          value: itemValues.email,
          label: t('Email'),
          onChange: (e: any) => handleSetValue('email', e.target.value),
          error: errors?.email
        },
        {
          name: 'phone',
          type: 'text',
          value: itemValues.phone,
          label: t('Phone'),
          onChange: (e: any) => handleSetValue('phone', e.target.value),
          error: errors?.phone
        },
        {
          name: 'birthday',
          type: 'date',
          value: itemValues.birthday,
          label: t('Birthday'),
          onChange: (e: any) => handleSetValue('birthday', e.target.value),
          error: errors?.birthday
        },
        {
          name: 'gender',
          type: 'select',
          value: itemValues.gender,
          label: t('Gender'),
          onChange: (e: any, el: any) => handleSetValue('gender', el.value),
          options: options.genders,
          error: errors?.gender
        }
      ];
    case 'account-short-info':
      return [
        {
          name: 'phone',
          type: 'text',
          value: itemValues.phone,
          label: t('Phone'),
          onChange: (e: any) => handleSetValue('phone', e.target.value),
          error: errors?.phone
        },
        {
          name: 'default_lang',
          type: 'select',
          value: itemValues.default_lang,
          label: t('Preferred Language'),
          onChange: (e: any, list: any) =>
            handleSetValue('default_lang', list?.value || 3),
          options: options.default_lang,
          error: errors?.default_lang
        }
      ];
    case 'educations':
      return [
        {
          name: 'school',
          type: 'select-schools',
          value: itemValues.school,
          label: t('School'),
          onChange: (e: any, el: any) => handleSetValue('school', el),
          error: errors?.school
        },
        {
          name: 'study',
          type: 'select-degrees',
          value: itemValues.study,
          label: t('Study'),
          onChange: (e: any, el: any) => handleSetValue('study', el),
          error: errors?.study
        },
        {
          name: 'degree',
          type: 'select',
          value: itemValues.degree,
          label: t('Degree'),
          onChange: (e: any, el: any) => handleSetValue('degree', el.value),
          options: translateArrayOptionsLabels(options.degreesStages, t),
          error: errors?.degree
        },
        {
          name: 'start',
          type: 'date',
          value: itemValues.start,
          label: t('Start at'),
          onChange: (e: any) => handleSetValue('start', e.target.value),
          error: errors?.start
        },
        {
          name: 'end',
          type: 'date',
          value: itemValues.end,
          label: t('End at'),
          onChange: (e: any) => handleSetValue('end', e.target.value),
          error: errors?.end
        },
        {
          name: 'graduate',
          type: 'checkbox',
          value: itemValues.graduate,
          label: t('Graduate'),
          onChange: () => handleSetValue('graduate', !itemValues.graduate)
        }
      ];
    case 'upload_files':
      return [
        {
          name: 'title',
          type: 'text',
          value: itemValues.title,
          label: t('Title'),
          onChange: (e: any) => handleSetValue('title', e.target.value),
          error: errors?.title
        }
      ];
    case 'it-skills':
      return [
        {
          name: 'skill',
          type: 'select-skills',
          value: itemValues.skill,
          label: t('Skill'),
          onChange: (e: any, el: any) => handleSetValue('skill', el),
          error: errors?.skill
        },
        {
          name: 'description',
          type: 'text',
          value: itemValues.description,
          label: t('Description'),
          onChange: (e: any) => handleSetValue('description', e.target.value),
          multiline: true,
          rows: 2,
          error: errors?.description
        },
        {
          name: 'proficiency',
          type: 'proficiency',
          value: itemValues.proficiency,
          label: t('Proficiency'),
          onChange: (id: number) => handleSetValue('proficiency', id),
          error: errors?.proficiency
        }
      ];

    case 'activities':
      return [
        {
          name: 'title',
          type: 'text',
          value: itemValues.title,
          label: t('Title'),
          onChange: (e: any) => handleSetValue('title', e.target.value),
          error: errors?.title
        },
        {
          name: 'description',
          type: 'text',
          value: itemValues.description,
          label: t('Description'),
          onChange: (e: any) => handleSetValue('description', e.target.value),
          multiline: true,
          rows: 2,
          error: errors?.description
        },
        {
          name: 'start',
          type: 'date',
          value: itemValues.start,
          label: t('Start at'),
          onChange: (e: any) => handleSetValue('start', e.target.value),
          error: errors?.start
        },
        {
          name: 'end',
          type: 'date',
          value: itemValues.end,
          label: t('End at'),
          onChange: (e: any) => handleSetValue('end', e.target.value),
          error: errors?.end
        }
      ];

    case 'certifications':
      return [
        {
          name: 'title',
          type: 'text',
          value: itemValues.title,
          label: t('Title'),
          onChange: (e: any) => handleSetValue('title', e.target.value),
          error: errors?.title
        },
        {
          name: 'institute',
          type: 'text',
          value: itemValues.institute,
          label: t('Institute'),
          onChange: (e: any) => handleSetValue('institute', e.target.value),
          error: errors?.institute
        },
        {
          name: 'year',
          type: 'text',
          value: itemValues.year,
          label: t('Year'),
          onChange: (e: any) => handleSetValue('year', e.target.value),
          error: errors?.year
        },
        {
          name: 'recieved',
          type: 'checkbox',
          value: itemValues.recieved,
          label: t('Received'),
          onChange: () => handleSetValue('recieved', !itemValues.recieved)
        }
      ];
    case 'languages':
      return [
        {
          name: 'language',
          type: 'select-language',
          value: itemValues.language,
          label: t('Language'),
          onChange: (e: any, el: any) => handleSetValue('language', el),
          error: errors?.language
        },
        {
          name: 'proficiency',
          type: 'proficiency',
          value: itemValues.proficiency,
          label: t('Proficiency'),
          onChange: (id: number) => handleSetValue('proficiency', id),
          error: errors?.proficiency
        }
      ];
    case 'social-media':
      return [
        {
          name: 'network',
          type: 'select',
          value: itemValues.network,
          label: t('Network'),
          onChange: (e: any, el: any) => handleSetValue('network', el.value),
          options: options.socialMedias,
          error: errors?.network
        },
        {
          name: 'url',
          type: 'text',
          value: itemValues.url,
          label: t('Url'),
          onChange: (e: any) => handleSetValue('url', e.target.value),
          error: errors?.url
        }
      ];
    case 'social-media-expertises':
      return [
        {
          name: 'network',
          type: 'select',
          value: itemValues.network,
          label: t('Network'),
          onChange: (e: any, el: any) => handleSetValue('network', el.value),
          options: options.socialMedias,
          error: errors?.network
        },
        {
          name: 'proficiency',
          type: 'proficiency',
          value: itemValues.proficiency,
          label: t('Proficiency'),
          onChange: (id: number) => handleSetValue('proficiency', id),
          error: errors?.proficiency
        }
      ];
    case 'work-experiences':
      return [
        {
          name: 'title',
          type: 'text',
          value: itemValues.title,
          label: t('Job Title'),
          onChange: (e: any) => handleSetValue('title', e.target.value),
          error: errors?.title
        },
        {
          name: 'description',
          type: 'text',
          value: itemValues.description,
          label: t('Job Description'),
          onChange: (e: any) => handleSetValue('description', e.target.value),
          multiline: true,
          rows: 2,
          error: errors?.description
        },
        {
          name: 'company',
          type: 'text',
          value: itemValues.company,
          label: t('Company'),
          onChange: (e: any) => handleSetValue('company', e.target.value),
          error: errors?.company
        },
        {
          name: 'website',
          type: 'text',
          value: itemValues.website,
          label: t('Website'),
          onChange: (e: any) => handleSetValue('website', e.target.value),
          error: errors?.website
        },
        {
          name: 'start',
          type: 'date',
          value: itemValues.start,
          label: t('Start at'),
          onChange: (e: any) => handleSetValue('start', e.target.value),
          error: errors?.start
        },
        {
          name: 'end',
          type: 'date',
          value: itemValues.end,
          label: t('End at'),
          onChange: (e: any) => handleSetValue('end', e.target.value),
          error: errors?.end
        },
        {
          name: 'industry',
          type: 'select-industries',
          value: itemValues.industry,
          label: t('Industry'),
          onChange: (e: any, el: any) => handleSetValue('industry', el),
          error: errors?.industry
        },
        {
          name: 'location',
          type: 'location',
          value: itemValues.location,
          label: t('Location'),
          onChange: (e: any, el: any) => handleSetValue('location', el),
          error: errors?.location
        },
        {
          name: 'contract',
          type: 'select',
          value: itemValues.contract,
          label: t('Contract'),
          onChange: (e: any, el: any) => handleSetValue('contract', el.value),
          options: translateArrayOptionsLabels(options.contracts, t),
          error: errors?.contract
        }
      ];
    case 'customer-info':
      return [
        {
          name: 'name',
          type: 'text',
          value: itemValues.name,
          label: t('Name'),
          onChange: (e: any) => handleSetValue('name', e.target.value),
          error: errors?.name
        },
        {
          name: 'contact_name',
          type: 'text',
          value: itemValues.contact_name,
          label: t('Contact Name'),
          onChange: (e: any) => handleSetValue('contact_name', e.target.value),
          error: errors?.contact_name
        },
        {
          name: 'contact_number',
          type: 'text',
          value: itemValues.contact_number,
          label: t('Contact Number'),
          onChange: (e: any) =>
            handleSetValue('contact_number', e.target.value),
          error: errors?.contact_number
        },
        {
          name: 'email',
          type: 'email',
          value: itemValues.email,
          label: t('Email'),
          onChange: (e: any) => handleSetValue('email', e.target.value),
          error: errors?.email
        },
        {
          name: 'website',
          type: 'text',
          value: itemValues.website,
          label: t('Website'),
          onChange: (e: any) => handleSetValue('website', e.target.value),
          error: errors?.website
        }
      ];

    case 'add-candidate':
      return [
        {
          name: 'first_name',
          type: 'text',
          value: itemValues.first_name,
          label: t('First Name'),
          onChange: (e: any) => handleSetValue('first_name', e.target.value),
          error: errors?.first_name
        },
        {
          name: 'last_name',
          type: 'text',
          value: itemValues.last_name,
          label: t('Last Name'),
          onChange: (e: any) => handleSetValue('last_name', e.target.value),
          error: errors?.last_name
        },
        {
          name: 'email',
          type: 'email',
          value: itemValues.email,
          label: t('Email'),
          onChange: (e: any) => handleSetValue('email', e.target.value),
          error: errors?.email
        },
        {
          name: 'phone',
          type: 'text',
          value: itemValues.phone,
          label: t('Phone'),
          onChange: (e: any) => handleSetValue('phone', e.target.value),
          unRequired: true
        },
        {
          name: 'gender',
          type: 'select',
          value: itemValues.gender || null,
          label: t('Gender'),
          onChange: (e: any, el: any) => handleSetValue('gender', el.value),
          options: options.genders,
          unRequired: true
        },
        {
          name: 'linkedin_url',
          type: 'text',
          value: itemValues.linkedin_url || '',
          label: t('LinkedIn URL'),
          onChange: (e: any, el: any) =>
            handleSetValue('linkedin_url', e.target.value),
          unRequired: true
        },
        {
          name: 'job',
          type: 'select-job',
          value: itemValues.job || null,
          label: t('Select Job Post'),
          onChange: (e: any, el: any) => {
            handleSetValue('job', el);
          },
          unRequired: true
        },
        {
          name: 'birthday',
          type: 'date',
          value: itemValues.birthday || null,
          label: t('Birthday'),
          onChange: (e: any) => handleSetValue('birthday', e.target.value),
          unRequired: true
        }
      ];
    default:
      return [];
  }
};

export const prepareFormData = (values: any, images?: any) => {
  const formData = new FormData();

  for (const key in values) {
    if (key !== 'id') {
      formData.append(key, values[key]);
    }
  }
  if (images) {
    for (const key in images) {
      if (images[key]) {
        formData.append(key, images[key]);
      }
    }
  }

  return formData;
};
