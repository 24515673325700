import { createSlice } from '@reduxjs/toolkit';
import {
  initialPaginated,
  initialCompany,
  initialModules
} from 'constants/states';
import { getApplicants, getJobPosts } from 'requests/vacancy';
import { getCompanyData, getCompanyModules } from 'requests/account';
import { AppDispatch, history } from 'redux/store';
import { PATH_DASHBOARD } from 'routes/paths';

const initialState = {
  isJobPostLoading: false,
  dailyApplicants: initialPaginated,
  openJobPosts: initialPaginated,
  mostViewedJobPosts: initialPaginated,
  managementInsights: initialCompany,
  companyModuleInsights: initialModules,
  allVacancies: initialPaginated,
  errors: {},
  modalState: ''
};

const slice = createSlice({
  name: 'dashboardSlice',
  initialState,
  reducers: {
    modalDashboard(state, action) {
      state.modalState = action.payload;
    },
    jobPostLoading(state, action) {
      state.isJobPostLoading = action.payload;
    },
    // getting daily applicants
    dailyApplicantsSuccess(state, action) {
      state.dailyApplicants = action.payload.dailyApplicants;
    },

    // getting open job posts
    openJobPostsSuccess(state, action) {
      state.openJobPosts = action.payload.openJobPosts;
    },

    // getting management insights
    managementInsightsSuccess(state, action) {
      state.managementInsights = action.payload.managementInsights;
    },
    // getting company module
    companyModuleInsightsSuccess(state, action) {
      state.companyModuleInsights = action.payload.companyModuleInsights;
    },
    // getting most viewed job posts
    mostViewedJobPostsSuccess(state, action) {
      state.mostViewedJobPosts = action.payload.mostViewedJobPosts;
    },

    allVacanciesLoading(state, action) {
      state.isJobPostLoading = action.payload;
    },

    allVacanciesSuccess(state, action) {
      state.allVacancies = action.payload.allVacancies;
    },

    // dashboard errors
    dashboardErrors(state, action) {
      state.errors = action.payload.errors;
    }
  }
});
export const setJobPostLoading =
  (isLoading: boolean) => (dispatch: AppDispatch) => {
    dispatch(slice.actions.jobPostLoading(isLoading));
  };
export const setDashboardModal = (status: any) => (dispatch: AppDispatch) => {
  dispatch(slice.actions.modalDashboard(status));
};
export const getCandidates =
  (
    customer?: number,
    vacancyID?: string | null,
    period?: string | null,
    limit?: number | null,
    offset?: number | null,
    query?: string | null,
    stage?: number | null,
    score?: number | null,
    status?: string | null,
    salaries?: string | null,
    work_exps?: string | null,
    degrees?: string | null,
    locations?: string | null,
    min_score?: number | null,
    min_score_culture?: number | null,
    skills?: number | null
  ) =>
  async (dispatch: AppDispatch) => {
    getApplicants({
      vacancyID,
      title: query,
      periodicity: period,
      limit: limit || 5,
      offset: offset || 0,
      ordering: score || 'score',
      ...(stage || stage === 0 ? { stage } : {}),
      status,
      salaries: salaries || '',
      work_exps: work_exps || '',
      degrees: degrees || '',
      locations: locations || '',
      min_score: min_score || 0,
      min_score_culture: min_score_culture || 0,
      skills: skills || '',
      customerID: customer || ''
    }).then((res) =>
      dispatch(
        slice.actions.dailyApplicantsSuccess({
          dailyApplicants: res
        })
      )
    );
  };

export const getAllVacancies = (query: string, short = false) => async (dispatch: AppDispatch) => {
  dispatch(slice.actions.allVacanciesLoading(true));
  getJobPosts({q: query, short})
    .then((res) =>
      dispatch(
        slice.actions.allVacanciesSuccess({
          allVacancies: res,
          ...initialPaginated
        })
      )
    )
    .then(() => dispatch(slice.actions.allVacanciesLoading(false)));
};

export const resetOpenJobPosts = () => (dispatch: AppDispatch) => {
  dispatch(
    slice.actions.openJobPostsSuccess({
      openJobPosts: initialPaginated
    })
  );
};

export const getOpenJobPosts =
  (
    customer?: number | string,
    status?: string | null,
    periodicity?: string | null,
    limit?: number,
    offset?: number,
    owner?: string,
    query?: string,
    salaries?: string,
    degrees?: string,
    locations?: string,
    work_exps?: string,
    ordering?: string,
    score_candidate_id?: string | number | null,
    min_score?: number
  ) =>
  async (dispatch: AppDispatch) => {
    dispatch(setJobPostLoading(true));
    getJobPosts({
      status: status || 'published',
      periodicity: periodicity || '',
      limit: limit || '10',
      offset: offset || '0',
      owner: owner || 'all',
      customer: customer || '',
      q: query,
      salaries: salaries || '',
      degrees: degrees || '',
      locations: locations || '',
      work_exps: work_exps || '',
      ...(ordering ? { ordering } : {}),
      score_candidate_id,
      min_score: min_score || 0
    })
      .then((res) =>
        dispatch(
          slice.actions.openJobPostsSuccess({
            openJobPosts: res
          })
        )
      )
      .then(() => dispatch(setJobPostLoading(false)));
  };

export const getManagementInsights = () => async (dispatch: AppDispatch) => {
  getCompanyData().then((res) =>
    dispatch(
      slice.actions.managementInsightsSuccess({
        managementInsights: res[0]
      })
    )
  );
};

export const getCompanyModuleInsights = () => async (dispatch: AppDispatch) => {
  getCompanyModules().then((res) => {
    const regUrl = new RegExp(/^(\/candidates\/current\/)(([0-9])+\/)$/);
    const token = localStorage.getItem('newToken');
    const regToken = new RegExp(`(${token})+$`);
    if (res) {
      dispatch(
        slice.actions.companyModuleInsightsSuccess({
          companyModuleInsights: res[0]
        })
      );
    } else if (
      !res &&
      regUrl.test(history.location.pathname) &&
      regToken.test(history.location.search)
    ) {
      history.push(`${PATH_DASHBOARD.candidates.candidateProfileNotFound}`);
    }
  });
};

export const getMostViewedJobPosts =
  (period?: string, limit?: number, offset?: number, ordering?: string) =>
  async (dispatch: AppDispatch) => {
    dispatch(setJobPostLoading(true));
    getJobPosts({
      periodicity: period || '',
      limit: limit || '10',
      offset: offset || '0',
      ordering
    })
      .then((res) =>
        dispatch(
          slice.actions.mostViewedJobPostsSuccess({
            mostViewedJobPosts: res
          })
        )
      )
      .then(() => dispatch(setJobPostLoading(false)));
  };

export default slice.reducer;
