import { useState } from 'react';
import { useSnackbar } from 'notistack';
import {
  Card,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography
} from '@material-ui/core';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { Icon } from '@iconify/react';

import { getTranslatedList } from '../../../constants/constants';
import useLocales from '../../../hooks/useLocales';
import { patchAccountPassword } from '../../../requests/account';
import { getButtons } from '../../../constants/text/buttons';
import MButton from '../../../components/new-designer/button/MButton';

const ChangePassword = () => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [oldPasswordShow, setOldPasswordShow] = useState(false);
  const [newPasswordShow, setNewPasswordShow] = useState(false);
  const [repeatPasswordShow, setRepeatPasswordShow] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useLocales();
  const { DEFAULT_MESSAGE_TEXTS } = getTranslatedList();
  const { SAVE } = getButtons();

  const handleSubmit = async () => {
    if (oldPassword === '' || newPassword === '') {
      enqueueSnackbar(t('Please, fill both fields before submit'), {
        variant: 'warning'
      });
    } else if (oldPassword === newPassword) {
      enqueueSnackbar(t('New and old passwords should not match'), {
        variant: 'warning'
      });
    } else if (newPassword !== repeatPassword) {
      enqueueSnackbar(t('New and repeat passwords should match'), {
        variant: 'warning'
      });
    } else {
      try {
        await patchAccountPassword({
          old_password: oldPassword,
          new_password: newPassword
        });
        enqueueSnackbar(t('Changed successfully'), {
          variant: 'success'
        });
        setOldPassword('');
        setNewPassword('');
        setRepeatPassword('');
      } catch {
        enqueueSnackbar(DEFAULT_MESSAGE_TEXTS.error, {
          variant: 'error'
        });
      }
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={10} md={4}>
        <Card
          sx={{
            p: 5,
            mt: 4,
            display: 'flex',

            justifyContent: 'center',
            flexDirection: 'column',
            background:
              'linear-gradient(94.35deg, rgba(255, 255, 255, 0.8) -5.21%, rgba(255, 255, 255, 0.3) 114.12%)',
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.08)'
          }}
        >
          <Typography
            style={{
              fontFamily: 'Rubik',
              fontSize: '20px',
              color: ' #131417',
              fontWeight: 700
            }}
          >
            {t('Change Password')}
          </Typography>
          <Grid sx={{ width: '100%', marginTop: '24px' }}>
            <Typography
              style={{
                color: '#666666',
                fontFamily: 'Rubik',
                textAlign: 'start'
              }}
            >
              {t('Old Password')}
            </Typography>
            <OutlinedInput
              sx={{ mb: 2, borderRadius: '30px', background: 'white' }}
              fullWidth
              type={oldPasswordShow ? 'text' : 'password'}
              label={t('Old Password')}
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setOldPasswordShow(!oldPasswordShow)}
                    edge="end"
                  >
                    <Grid
                      style={{
                        height: '26px',
                        border: '1px #C9CAD9 solid',
                        marginRight: '15px'
                      }}
                    />
                    <Icon
                      icon={oldPasswordShow ? eyeFill : eyeOffFill}
                      style={{ color: '#C9CAD9' }}
                    />
                  </IconButton>
                </InputAdornment>
              }
            />
          </Grid>
          <Grid sx={{ width: '100%' }}>
            <Typography style={{ color: '#666666', fontFamily: 'Rubik' }}>
              {t('New Password')}
            </Typography>

            <OutlinedInput
              sx={{ mb: 2, borderRadius: '30px', background: 'white' }}
              fullWidth
              type={newPasswordShow ? 'text' : 'password'}
              label={t('New Password')}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setNewPasswordShow(!newPasswordShow)}
                    edge="end"
                  >
                    <Grid
                      style={{
                        height: '26px',
                        border: '1px #C9CAD9 solid',
                        marginRight: '15px'
                      }}
                    />
                    <Icon
                      icon={newPasswordShow ? eyeFill : eyeOffFill}
                      style={{ color: '#C9CAD9' }}
                    />
                  </IconButton>
                </InputAdornment>
              }
            />
          </Grid>
          <Grid sx={{ width: '100%' }}>
            <Typography style={{ color: '#666666', fontFamily: 'Rubik' }}>
              {t('Repeat password')}
            </Typography>

            <OutlinedInput
              sx={{ mb: 2, borderRadius: '30px', background: 'white' }}
              fullWidth
              type={repeatPasswordShow ? 'text' : 'password'}
              label={t('Repeat Password')}
              value={repeatPassword}
              onChange={(e) => setRepeatPassword(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setRepeatPasswordShow(!repeatPasswordShow)}
                    edge="end"
                  >
                    <Grid
                      style={{
                        height: '26px',
                        border: '1px #C9CAD9 solid',
                        marginRight: '15px'
                      }}
                    />
                    <Icon
                      icon={repeatPasswordShow ? eyeFill : eyeOffFill}
                      style={{ color: '#C9CAD9' }}
                    />
                  </IconButton>
                </InputAdornment>
              }
            />
          </Grid>
          <Grid
            sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}
          >
            <MButton title={SAVE} MyClass="violetButton" click={handleSubmit} />
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};

export default ChangePassword;
