import { useEffect, useMemo, useState } from 'react';
import useLocales from 'hooks/useLocales';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import Page from 'components/containers/Page';
import PageContainer from 'components/containers/PageContainer';
import { Box, Button, Grid, Stack, Typography } from '@material-ui/core';
import { getOneCampaignThunk } from 'redux/slices/campaignsReducer';
import { useNavigate, useParams, Link } from 'react-router-dom';
import LoadingBox from 'components/LoadingBox';
import { ApproveDialog } from 'components/Dialog';
import DashboardNavbar from 'layouts/dashboard/navbar/DashboardNavbar';
import InProgress, {
  getStatusString
} from 'components/new-designer/InProgress/InProgress';
import { fDateDHM } from 'utils/formatTime';
import { ArrowBackIosNew } from '@material-ui/icons';
import { getButtons } from 'constants/text/buttons';

import SvgIconStyle from 'components/SvgIconStyle';
import { getIcon } from '../../utils/getIcon';
import {
  ChipStyleNew,
  GridCard,
  GridScrollNew,
  ModuleItemNew,
  TypographyHD,
  TypographyInfo
} from '../../styled';
import { ICampaign } from './types';

const CampaignMoreInfo = () => {
  const [open, setOpen] = useState(false);
  const { t } = useLocales();
  const { id } = useParams();
  const { oneCampaign, isLoading } = useSelector(
    (state: RootState) => state.campaignsReducer
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { BACK } = getButtons();
  const { company }: any = useSelector(
    (state: RootState) => state.generalRecruiter
  );

  const handleDelete = async () => {
    if (id) {
      await dispatch(getOneCampaignThunk(id, true));
      setOpen(false);
    }
  };

  useEffect(() => {
    if (id) {
      dispatch(getOneCampaignThunk(id));
    }
  }, [id]);

  const getDataList = (campaign: ICampaign) => {
    if (campaign) {
      const { orderedProducts, postings, orderedProductsSpecs } = campaign;

      return orderedProducts?.map((item) => {
        const posting = postings?.find((i) => i.productId === item);
        const productSpec = orderedProductsSpecs?.find(
          (i) => i.productId === item
        );

        return {
          title: posting?.name,
          status: productSpec?.status,
          clicks: Number(posting?.clicks),
          duration: productSpec?.duration,
          actions: productSpec?.jobBoardLink,
          deliveredOn: productSpec?.deliveredOn
        };
      });
    }
    return null;
  };

  const rows = useMemo(
    () =>
      oneCampaign
        ? getDataList(oneCampaign)
            ?.sort((a, b) => b.clicks - a.clicks)
            .slice(0, 2)
        : [],
    [oneCampaign]
  );

  return (
    <Page title={t('Campaign Details')}>
      <PageContainer>
        <DashboardNavbar title={t('Campaign status')} />
        <Button
          onClick={() => {
            navigate(-1);
          }}
          variant="text"
          style={{
            color: '#562C82',
            marginBottom: '20px'
          }}
        >
          <ArrowBackIosNew
            style={{
              fontSize: '18px',
              margin: '0 0 5px 0'
            }}
          />
          {BACK}
        </Button>

        {isLoading ? (
          <LoadingBox />
        ) : (
          <Grid
            style={{
              display: 'flex',
              gap: '20px',
              flexDirection: 'column'
            }}
          >
            <GridCard
              container
              spacing={4}
              sx={{ textTransform: 'capitalize', p: 2 }}
            >
              <Grid
                style={{ display: 'flex', gap: '21px', alignItems: 'center' }}
              >
                <Box
                  style={{
                    background: '#FFFFFF',
                    border: '1px solid #C9CAD9',
                    borderRadius: '20px',
                    minWidth: '100px',
                    height: '100px',
                    display: 'flex',
                    alignItems: 'center',
                    padding: '10px'
                  }}
                >
                  <SvgIconStyle
                    src={company?.logo || ''}
                    style={{ width: '100px' }}
                  />
                </Box>
                <Box
                  style={{
                    maxWidth: '313px',
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '10px'
                  }}
                >
                  <Typography
                    style={{
                      fontWeight: 600,
                      fontSize: '20px',
                      color: '#131417'
                    }}
                  >
                    {`Campaign for "${oneCampaign?.postingDetails?.title}" job post`}
                  </Typography>
                  <Typography style={{ opacity: '0.6' }}>
                    {new Date(oneCampaign.createdOn).toLocaleDateString()}
                  </Typography>
                </Box>
              </Grid>
              <Grid
                rowGap="15px"
                display="flex"
                flexDirection="column"
                marginBottom="10px"
              >
                <Grid item sx={{ display: 'flex' }}>
                  <Box
                    sx={{
                      display: 'inline-flex',
                      minWidth: '22px',
                      height: '22px',
                      color: 'primary.main',
                      mr: 1
                    }}
                  >
                    {getIcon('v_user')}
                  </Box>
                  <Typography
                    style={{ color: 'rgba(19, 20, 23, 0.60)', display: 'flex' }}
                  >
                    <img
                      src="/static/img/icons/account.svg"
                      color="#FF6868"
                      style={{
                        display: 'inline',
                        width: '20px',
                        height: '20px',
                        marginRight: '15px'
                      }}
                    />
                    {oneCampaign.recruiterInfo?.name}
                  </Typography>
                </Grid>
                {/* <Grid item sx={{ display: 'flex' }}>
                  <Box
                    sx={{
                      display: 'inline-flex',
                      minWidth: '24px',
                      height: '24px',
                      color: 'primary.main',
                      mr: 1
                    }}
                  >
                    {getIcon('v_calendar')}
                  </Box>
                </Grid> */}

                <Grid item sx={{ display: 'flex' }}>
                  <Box
                    sx={{
                      display: 'inline-flex',
                      minWidth: '24px',
                      height: '24px',
                      color: 'primary.main',
                      mr: 1
                    }}
                  >
                    {getIcon('wallet')}
                  </Box>
                  <Typography
                    style={{ color: 'rgba(19, 20, 23, 0.60)', display: 'flex' }}
                  >
                    <img
                      src="/static/img/icons/walletColored.svg"
                      color="#FF6868"
                      style={{
                        display: 'inline',
                        width: '20px',
                        height: '20px',
                        marginRight: '15px'
                      }}
                    />
                    € {oneCampaign.totalPrice?.amount / 100}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} style={{ padding: 0, flexBasis: 'auto' }}>
                <Stack
                  direction="row"
                  spacing={1}
                  flexWrap="wrap"
                  rowGap="10px"
                  columnGap="5px"
                  maxWidth="365px"
                >
                  {Object.keys(oneCampaign.targetGroup || {}).map(
                    (key: string) =>
                      oneCampaign.targetGroup[key].map(
                        (el: { description: string }) => (
                          <ChipStyleNew
                            key={el.description}
                            label={el.description}
                          />
                        )
                      )
                  )}
                </Stack>
              </Grid>
              <ApproveDialog
                open={open}
                title={`${t('Are you sure you want cancel')} "${
                  oneCampaign?.postingDetails?.title || 'this campaign'
                }"?`}
                onClose={() => setOpen(false)}
                onApprove={handleDelete}
              />
              <Grid
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexDirection: 'column'
                }}
              >
                <InProgress status={oneCampaign.status} />
                <Button
                  sx={{ ml: 1, mt: 2 }}
                  style={{
                    border: 'none',
                    width: '100%',
                    fontSize: '14px',
                    justifyContent: 'flex-end',
                    alignItems: 'baseline'
                  }}
                  onClick={() => setOpen(true)}
                  variant="outlined"
                  color="error"
                >
                  {t('Cancel')}
                  <img
                    src="/static/img/icons/trash.svg"
                    color="#FF6868"
                    style={{
                      width: '12px',
                      height: '12px',
                      marginLeft: '7px'
                    }}
                  />
                </Button>
              </Grid>
            </GridCard>
            <Grid
              style={{
                width: '100%'
              }}
            >
              <Grid
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: '6px',
                  padding: '0 60px 0 30px',
                  minHeight: '65px',
                  borderRadius: '14px',
                  background:
                    'var(--Gradient-for-forms, linear-gradient(94deg, rgba(255, 255, 255, 0.80) -5.21%, rgba(255, 255, 255, 0.30) 114.12%))',
                  boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.08)'
                }}
              >
                <TypographyHD width="370px">Channel</TypographyHD>
                <TypographyHD>Expiration date</TypographyHD>
                <TypographyHD>Status</TypographyHD>
                <TypographyHD>Clicks</TypographyHD>
                <TypographyHD>Date of delivery</TypographyHD>
                <TypographyHD>Duration</TypographyHD>
                <TypographyHD>Actions</TypographyHD>
              </Grid>
              <GridScrollNew>
                {rows?.length &&
                  rows?.map((row, index) => (
                    <>
                      <ModuleItemNew
                        key={index}
                        style={{ padding: '26px 50px 26px 19px' }}
                      >
                        <TypographyHD width={400}>{row.title}</TypographyHD>
                        <TypographyHD width={90}>
                          {row.deliveredOn && row.duration
                            ? fDateDHM(
                                new Date(row.deliveredOn).getTime() +
                                  parseFloat(row.duration) * 24 * 60 * 60 * 1000
                              )
                            : '-'}
                        </TypographyHD>
                        <InProgress status={row.status || ''} />
                        <TypographyInfo>{row.clicks}</TypographyInfo>
                        <TypographyInfo>
                          {row?.deliveredOn ? fDateDHM(row.deliveredOn) : '-'}
                        </TypographyInfo>
                        <TypographyInfo>{row.duration}</TypographyInfo>
                        <Button
                          variant="text"
                          disabled={
                            getStatusString(row.status || '') === 'offline'
                          }
                          style={{
                            color: row.actions ? '#1C9DD7' : 'grey'
                          }}
                        >
                          <Box
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              columnGap: '5px'
                            }}
                          >
                            <Link
                              to={{ pathname: row.actions }}
                              target="_blank"
                              style={{
                                color: '#1C9DD7',
                                fontWeight: 600,
                                fontSize: '14px',
                                textDecoration: 'none'
                              }}
                            >
                              {t('Link')}
                            </Link>
                            <Box
                              style={{ marginBottom: '4px' }}
                              component="img"
                              src="/static/img/icons/lowest.svg"
                            />
                          </Box>
                        </Button>
                      </ModuleItemNew>
                      <hr
                        style={{
                          width: '95%',
                          margin: '0 auto',
                          color: '#fff',
                          borderTop: '1px solid #C9CAD9'
                        }}
                      />
                    </>
                  ))}
              </GridScrollNew>
            </Grid>
          </Grid>
        )}
      </PageContainer>
    </Page>
  );
};

export default CampaignMoreInfo;
