import {
  getDataByUrl,
  postDataByUrl,
  updateDataByUrl,
  deleteDataByUrl,
  ID
} from 'requests';

// COMPANY //

export const getCompanyData = () => getDataByUrl('/api/company/');

export const getCompanyModules = () => getDataByUrl('/api/company/module/');

export const updateCompanyData = (id: ID, data: any) =>
  updateDataByUrl(`/api/company/${id}/`, data);

export const updateCompanyModuleData = (id: ID, data: any) =>
  updateDataByUrl(`/api/company/module/${id}/`, data);

// ACCOUNT //

export const signIn = (data: any) =>
  postDataByUrl('/api/account/auth/login/', data);

export const logOutUser = () => getDataByUrl('/api/account/logout/');

export const signUp = (data: any, invitation?: string | null) =>
  postDataByUrl(
    '/api/account/auth/register/',
    data,
    invitation ? { invitation } : {}
  );

export const getAccountData = () => getDataByUrl('/api/account/me/');

export const updateAccountData = (id: ID, data: any) =>
  updateDataByUrl(`/api/account/${id}/`, data);

export const patchAccountPassword = (data: any) =>
  updateDataByUrl(`/api/account/auth/password/change/`, data);

export const resetPassword = (data: any) =>
  postDataByUrl(`/api/account/auth/password/reset/`, data);

export const verifyPassword = (data: any) =>
  postDataByUrl(`/api/account/auth/password/reset/confirm/`, data);

export const deleteAccount = (id: ID) => deleteDataByUrl(`/api/account/${id}/`);

export const setAccountLanguage = (language: string) =>
  postDataByUrl('/api/account/setlang/', { language });

export const searchRecruters = (name: string, limit: number) =>
  getDataByUrl(`/api/account/?role=recruiters&limit=${limit}&q=${name}`);

export const updateCustomTextData = (id: ID, data: any) =>
  updateDataByUrl(`/api/company/custom_texts/${id}/`, data);
