import { LANGS } from 'constants/constants';
import axios from '../utils/axios';

const getCurrentLanguage = () => {
  const langStorage = localStorage.getItem('i18nextLng');
  return LANGS.find((_lang) => _lang.value === langStorage)?.value || LANGS[1];
};

const getDataByUrl = async (url: string, params?: any) => {
  try {
    const response = await axios.get(url, params ? { params } : {});
    return response.data;
  } catch {
    return null;
  }
};

export const getLanguages = (params: any) =>
  getDataByUrl(`/api/fixtures/languages/?q=${params}`);
export const getLocations = (params: any) =>
  getDataByUrl('https://nora.recrout.com/api/cities/', params);
export const getSkills = (params: any) =>
  getDataByUrl(`/api/fixtures/skills/?q=${params}&lang=${getCurrentLanguage()}`);
export const getIndustries = (params: any) =>
  getDataByUrl(
    `/api/fixtures/industries/?q=${params}&lang=${getCurrentLanguage()}`
  );
export const getDegrees = (params: any) =>
  getDataByUrl(`/api/fixtures/degrees/?q=${params}&lang=${getCurrentLanguage()}`);
export const getRecruiterAndAdmin = (params: any) =>
  getDataByUrl(`/api/account/?role=recruiters&limit=10&q=${params ?? ''}`);

export const getSchools = (params: any) =>
  getDataByUrl(`/api/fixtures/schools/?q=${params}&lang=${getCurrentLanguage()}`);
export const getCandidates = (params: any) =>
  getDataByUrl(
    `/api/candidate/?limit=10&q=${params ?? ''}&lang=${getCurrentLanguage()}`
  );
export const getJobs = (params: any) =>
  getDataByUrl(`/api/vacancy/?short=true&limit=10&q=${params ?? ''}&lang=${getCurrentLanguage()}`);
export const getAllJobPosts = () => getDataByUrl('/api/vacancy/');
export const getAnalyticsJobsPeriod = (periodicity: string, jobId?: number) =>
  getDataByUrl(
    `/api/analytics/job_applications/?periodicity=${periodicity}&jobPost=${
      jobId || null
    }&lang=${getCurrentLanguage()}`
  );
export const getAnalyticsJobsData = (periodicity: string) =>
  getDataByUrl(`/api/analytics/job_applications_data/?periodicity=${periodicity}`);
export const getAnalyticsJobPostDurations = () =>
  getDataByUrl(`/api/analytics/job_post_durations/`);
export const getAnalyticsJobCosts = () =>
  getDataByUrl(`/api/analytics/job_post_cost/`);
export const getApplicationStagesPie = (jobId?: number) =>
  getDataByUrl(
    `/api/analytics/job_application_stages_pie/?jobPost=${jobId || null}`
  );
export const getApplicationDeclineReasons = (jobId?: number) =>
  getDataByUrl(
    `/api/analytics/job_application_decline_reasons/?jobPost=${jobId || null}`
  );
export const getJobApplicationStages = (periodicity: string, jobId?: number) =>
  getDataByUrl(
    `/api/analytics/job_application_stages/?periodicity=${periodicity}&jobPost=${jobId || null
    }`
  );
export const getApplicationStagesPieForExcel = () =>
  getDataByUrl(`/api/analytics/job_application_stages_data/`);
export const getApplicationDeclineReasonExcel = () =>
  getDataByUrl(`/api/analytics/job_application_decline_reasons/`);
export const getJobsAnalytics = (periodicity: string) =>
  getDataByUrl(`/api/analytics/job_posts/?periodicity=${periodicity}`);
export const getCultureProfiles = (query?: string) =>
  getDataByUrl(
    `/api/match/profiles/search/${query ? `?category=culture&q=${query}` : ''}&lang=${getCurrentLanguage()}`
  );
export const getCompetency = () =>
  getDataByUrl('/api/assessment/competencies_by_group/');
export const getRemoteJobs = (query?: string) =>
  getDataByUrl(`/api/kombo/jobs/?q=${query || ''}`);
export const getCompetencies = () => getDataByUrl('/api/assessment/competencies/');
export const getAssessmentResults = (userId: number | string) =>
  getDataByUrl(`/api/assessment/assessments/result/?user_id=${userId}`);

export const getCustomers = (search: string) =>
  getDataByUrl(`/api/customers/?q=${search}&short=true`);
