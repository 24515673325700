export const initialPaginated: initialPaginated = {
  results: null,
  count: 0,
  next: null,
  previous: null
};

type initialPaginated = {
  results: null | any[];
  count: number;
  next: string | null;
  previous: string | null;
};

export const unAuthUser = {
  id: null,
  candidate_id: '',
  name: '',
  last_login: null,
  is_superuser: false,
  first_name: '',
  last_name: '',
  email: '',
  is_staff: false,
  is_active: false,
  date_joined: '',
  role: '',
  gender: null,
  avatar: null,
  hero: null,
  birthday: null,
  phone: null,
  user_permissions: [],
  default_lang: null
};

export const initialCompany = {
  active: false,
  contact: null,
  contact_email: null,
  email_header: null,
  custom_texts: null,
  favicon: null,
  from_email: null,
  id: 1,
  industry: null,
  logo: '',
  name: '',
  plan: null,
  primary_color: '',
  secondary_color: '',
  privacy_statement: null,
  general_terms: null,
  status: null,
  stripe_id: null,
  subscription_id: null,
  website: '',
  assessment_optional: false
};

export const initialModules = {
  id: 0,
  pitch: true,
  preference: true,
  work_experience: true,
  education: true,
  language: true,
  extra: true,
  certification: true,
  it_skill: true,
  social_media: true,
  social_media_expertise: true,
  upload_files: true,
  soft_skills: true,
  hard_skills: true
};

export const initialCandidate = {
  mainInfo: {
    id: null,
    name: '',
    avatar: null,
    email: '',
    phone: null,
    bod: null,
    access: false,
    cv: null,
    pitch: null,
    anonymous: false,
    availability: 0,
    relocation: 0,
    work_exp: 0,
    management_exp: 0,
    salary: 0,
    locations: null,
    industries: null,
    contract: null,
    competencies: null,
    skills: null,
    study: null,
    languages: null,
    user: 0,
    hero: '',
    default_lang: null,
    messenger_room_id: 0,
    hard_skills: null,
    soft_skills: null
  },
  work_experience: [],
  educations: [],
  files: [],
  notes: [],
  languages: [],
  activities: [],
  certifications: [],
  it_skill: [],
  social_media: [],
  social_media_expertise: {} as SocialMediaExpertise
};

export const initialAssessmentRes = {
  assessment: {
    status: 0,
    test_logon_url: ''
  },
  result: null,
  report: {
    feedback: {
      en: '',
      nl: ''
    },
    mo_de: {
      en: '',
      nl: ''
    }
  },
  test_logon_url: ''
};

export interface SocialMediaExpertise {
  count: number;
  next: string;
  previous: null;
  results: SocialMediaExpertiseResult[];
}

export interface SocialMediaExpertiseResult {
  email: string;
  first_name: string;
  last_name: string;
  id: number;
  role: string;

  [key: string]: any;
}
