import { createSlice } from '@reduxjs/toolkit';
import { initialPaginated } from 'constants/states';
import { AppDispatch } from 'redux/store';
import { getTemplates, patchTemplate, postTemplate } from 'requests/templates';
import { enqueueSnackbar } from './notifications';

const initialState = {
  isTemplatesLoading: false,
  templates: initialPaginated
};

const slice = createSlice({
  name: 'templateSlice',
  initialState,
  reducers: {
    templatesLoading(state, action) {
      state.isTemplatesLoading = action.payload;
    },
    templateSuccess(state, action) {
      state.templates = action.payload.templates;
    },
    addTemplate(state, action) {
      state.templates.results?.push(action.payload.template);
    },
    updateTemplateInList(state, action) {
      if (state.templates.results) {
        const index = state.templates.results.findIndex(
          (t) => t.id === action.payload.template.id
        );
        if (index !== -1) {
          state.templates.results[index] = action.payload.template;
        }
      }
    }
  }
});

export const setTemplatesLoading =
  (isLoading: boolean) => (dispatch: AppDispatch) => {
    dispatch(slice.actions.templatesLoading(isLoading));
  };

export const getTemplatesList =
  (limit?: number, offset?: number, query?: string) =>
  async (dispatch: AppDispatch) => {
    dispatch(setTemplatesLoading(true));
    getTemplates({ limit: limit || '10', offset: offset || '0', q: query })
      .then((res) =>
        dispatch(slice.actions.templateSuccess({ templates: res }))
      )
      .then(() => dispatch(setTemplatesLoading(false)));
  };

export const createTemplate = (data: any) => async (dispatch: AppDispatch) => {
  postTemplate(data)
    .then((res) => {
      dispatch(slice.actions.addTemplate({ template: res }));
      dispatch(
        enqueueSnackbar({
          messageType: 'created',
          options: { variant: 'success' }
        })
      );
    })
    .catch(() =>
      dispatch(
        enqueueSnackbar({ messageType: 'error', options: { variant: 'error' } })
      )
    );
};

export const updateTemplate =
  (id: any, data?: any) => async (dispatch: AppDispatch) => {
    patchTemplate(id, data)
      .then((res) => {
        dispatch(slice.actions.updateTemplateInList({ template: res }));
        dispatch(
          enqueueSnackbar({
            messageType: 'updated',
            options: { variant: 'success' }
          })
        );
      })
      .catch(() =>
        dispatch(
          enqueueSnackbar({
            messageType: 'error',
            options: { variant: 'error' }
          })
        )
      );
  };

export default slice.reducer;


