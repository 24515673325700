import {
  Box,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Typography,
  styled
} from '@material-ui/core';
import { useNavigate } from 'react-router-dom';

import { useEffect } from 'react';
import { getButtons } from '../constants/text/buttons';
import SvgIconStyle from './SvgIconStyle';
import { ButtonPrimary } from './new-designer/button/ButtonPrimary';

const SubtitleTypography = styled(Typography)(() => ({
  fontFamily: 'Rubik',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '17px',
  color: '#C9CAD9'
}));

const BoxStyle = styled(Box)((theme: any) => ({
  ...(theme?.fromClarification
    ? {
        maxHeight: '600px',
        overflowY: 'scroll',
        overflowX: 'hidden',
        '::-webkit-scrollbar': {
          width: '4px',
          border: 0
        },

        '::-webkit-scrollbar-track': {
          backgroundColor: '#C9CAD9'
        },

        '::-webkit-scrollbar-thumb': {
          backgroundColor: '#1C9DD7',
          borderRadius: '4px',
          outline: '1px solid #1C9DD7'
        }
      }
    : {})
}));

const StepperComponent = (props: any) => {
  const {
    steps,
    onFinish,
    onFinishText,
    onBack,
    activeStep,
    setActiveStep,
    vertical = false,
    children,
    editJobPost,
    isFreeAccount,
    checkForCompetencies,
    competenciesReceived,
    generateJobDescription,
    onNextStep
  } = props;

  const navigate = useNavigate();

  const isFirst = activeStep === 0;
  const isSecond = activeStep === 1;
  const isLast = activeStep === steps.length - 1;

  const getNextEnable = (stepIndex: number) => !steps[stepIndex]?.nextEnable;

  const getSkippableEnable = (stepIndex: number) => steps[stepIndex]?.skippable;

  const next = () => {
    if (isLast) onFinish();
    else setActiveStep(activeStep + 1);
  };

  const handleNext = (skippable = false) => {
    if (steps[activeStep]?.nextValidation && !skippable) {
      const isValid = steps[activeStep].nextValidation();
      if (isValid) {
        if (isFirst && !editJobPost && !isFreeAccount) {
          generateJobDescription();
          next();
        } else if (
          isSecond &&
          !editJobPost &&
          !isFreeAccount &&
          !competenciesReceived
        ) {
          checkForCompetencies();
          next();
        } else {
          next();
        }
      }
    } else {
      next();
    }
  };

  const handleBack = () => {
    if (isFirst) {
      onBack();
      !editJobPost && navigate(-1);
    } else {
      setActiveStep(activeStep - 1);
    }
  };

  useEffect(() => {
    if (isSecond && onNextStep) {
      handleNext();
    }
  }, [onNextStep]);

  const ActiveContent = () => {
    const { BACK, FINISH, NEXT } = getButtons();
    return (
      <Box sx={{ mt: 2 }}>
        <Box
          sx={{
            mt: 2,
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            color: 'red'
          }}
        >
          <ButtonPrimary
            onClick={handleBack}
            style={{
              border: '1px solid#562C82',
              borderRadius: '30px',
              background: 'transparent',
              minWidth: '25%',
              color: '#9B5FDA'
            }}
            variant="outlined"
          >
            {BACK}
          </ButtonPrimary>
          <Box>
            <ButtonPrimary
              disabled={getNextEnable(activeStep)}
              onClick={() =>
                handleNext(isLast && getSkippableEnable(activeStep))
              }
              sx={{
                borderRadius: '30px',
                width: '283px',
                color: '#FFFFFF !important'
              }}
            >
              {isLast ? onFinishText || FINISH : NEXT}
            </ButtonPrimary>
          </Box>
        </Box>
      </Box>
    );
  };

  const getSrc = (index: number, step: any) => {
    let src;
    if (index === activeStep) {
      src = step.activeSrc;
    } else if (index < activeStep) {
      src = step.endSrc;
    } else {
      src = step.startSrc;
    }
    return src;
  };

  return (
    <BoxStyle fromClarification={props?.fromClarification}>
      <Stepper
        activeStep={activeStep}
        orientation={vertical ? 'vertical' : 'horizontal'}
        alternativeLabel={!vertical}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: props?.style?.height || '200px',
          py: '25px',
          background:
            'linear-gradient(94.35deg, rgba(255, 255, 255, 0.8) -5.21%, rgba(255, 255, 255, 0.3) 114.12%)',
          borderRadius: '20px',
          boxShadow: ' 0px 0px 10px rgba(0, 0, 0, 0.08)'
        }}
      >
        {steps.map((step: any, index: number) => (
          <Step
            key={step.label}
            style={{
              ...(props?.fromClarification
                ? {
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                  }
                : {})
            }}
          >
            <StepLabel
              StepIconComponent={() => {
                const src = getSrc(index, step);
                return src ? (
                  <SvgIconStyle
                    src={src}
                    style={{
                      marginTop: '-12px',
                      position: 'relative',
                      zIndex: 1,
                      background: '#F5F5F5',
                      borderRadius: '50%'
                    }}
                  />
                ) : null;
              }}
            >
              {step?.subtitle && (
                <SubtitleTypography>{step.subtitle}</SubtitleTypography>
              )}
              <Typography
                style={{
                  fontFamily: 'Rubik',
                  fontWeight: 700,
                  fontSize: '16px',
                  lineHeight: '19px',
                  color: '#131417'
                }}
              >
                {step.label}
              </Typography>
            </StepLabel>
            {vertical ? (
              <StepContent
                sx={{ border: props?.style?.border || '1px solid' }}
                style={{
                  ...(props?.fromClarification
                    ? {
                        width: '100%',
                        paddingLeft: '10px',
                        paddingRight: '20px'
                      }
                    : {})
                }}
              >
                {children}
                <ActiveContent />
              </StepContent>
            ) : null}
          </Step>
        ))}
      </Stepper>

      {!vertical ? (
        <Box sx={{ mt: 2 }}>
          {children}
          <ActiveContent />
        </Box>
      ) : null}
    </BoxStyle>
  );
};

export default StepperComponent;
