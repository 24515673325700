import { Box, Tooltip } from '@material-ui/core';
import { CSSProperties } from 'react';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';

const SignUpTooltip = ({
  title,
  style
}: {
  title: string;
  style?: CSSProperties;
}) => (
  <Box style={{ position: 'absolute', top: '40px', right: '24px', ...style }}>
    <Tooltip arrow placement="left" title={title}>
      <HelpOutlineOutlinedIcon
        sx={{ width: '24px', height: '24px', color: 'rgb(86, 44, 130)' }}
      />
    </Tooltip>
  </Box>
);

export default SignUpTooltip;
