import React, { useMemo, useState } from 'react';
import { Link, Link as RouterLink } from 'react-router-dom';
import { Avatar, Box, Button, Card, Grid, Typography } from '@material-ui/core';
import MenueSelect from './MenueSelect';
import { styled } from '@mui/material';
import {
  DEFAULT_MESSAGE_TEXTS,
  getTranslatedList,
  USER_ROLES
} from 'constants/constants';

import { PATH_DASHBOARD } from 'routes/paths';
import {
  cancelCandidateApplication,
  getCandidateRemind,
  updateApplicationData
} from 'requests/candidate';
import useLocales from 'hooks/useLocales';
import { getPlaceholders } from 'constants/text/placeholders';
import { getButtons } from 'constants/text/buttons';
import { ApplicantProps } from 'types/Applicant';
import { fDate } from 'utils/formatTime';
import { Block, TaskAlt } from '@material-ui/icons';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import ScoreField from './ScoreField';
import { GridStyleContainer } from './CardCandidateLow';
import { DeleteButton } from './Buttons';
import { OverviewSelect } from './OverviewFilter';
import { DeleteDialog } from './Dialog';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { ButtonPrimary } from './new-designer/button/ButtonPrimary';
import { useSnackbar } from 'notistack';

const CandidateLink = ({ id, name, avatar, style = {} }: any) => (
  <RouterLink
    to={PATH_DASHBOARD.candidates.getCandidateProfileUrl(id)}
    style={{ margin: '10px 0', display: 'flex', textDecoration: 'none' }}
  >
    <Avatar alt={name} src={avatar || ''} sx={{ mr: 1 }} style={style} />
    <Typography variant="h4">{name}</Typography>
  </RouterLink>
);

const ActionBox = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between'
}));

const ApplicantCard = styled(Card)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '35px 20px',
  '@media (max-width: 992px)': {
    flexDirection: 'column',
    rowGap: '20px',
    div: {
      maxWidth: 'max-content !important'
    },
    '.MuiAvatar-root': {
      maxWidth: '60px !important'
    }
  }
}));

const GridProfile = styled(Grid)(() => ({
  alignItems: 'center',
  width: '300px',
  '@media (max-width: 687px)': {
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center'
  }
}));

export const CandidateCard = ({
  item: {
    id,
    candidate: { id: candidateId, name, avatar, anonymous, user: userId },
    status,
    stage,
    vacancy
  },
  user,
  setIsRefresh,
  handleOpenApplicantModal,
  handleOpenChat,
  isRefresh,
  item
}: ApplicantProps) => {
  const [locStatus, setLocStatus] = useState(status);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openSelect = Boolean(anchorEl);
  const [inputValue, setInputValue] = useState('');
  const [onpentextInput, setOnpentextInput] = useState(false);
  const company: any = useSelector(
    (state: RootState) => state.generalRecruiter.company
  );
  const { t } = useLocales();
  const { ANONYMOUS } = getPlaceholders();
  const { STAGE_TYPES } = getTranslatedList();
  const { enqueueSnackbar } = useSnackbar();

  const {
    ACCEPT,
    DECLINE,
    DELETE,
    APPLICANTS: { CANCEL_APPLICATION }
  } = getButtons();

  const reasons = [
    t('Not qualified for the team'),
    t('Lack of knowledge'),
    t('Hired somewhere else'),
    t('Salary too high'),
    t('Lack of personality skills'),
    t('Lack of hard skills'),
    t('Spam')
  ];
  const [isCandidate, canCancel, candidateAvatar, candidateName] =
    useMemo(() => {
      const isCandidate = user?.role === USER_ROLES.CANDIDATE;
      const isOwner = userId === user?.id;
      const canCancel = user?.role === USER_ROLES.CANDIDATE || isOwner;
      const candidateAvatar = isOwner || !anonymous ? avatar : null;
      const candidateName = isOwner || !anonymous ? name : ANONYMOUS;

      return [isCandidate, canCancel, candidateAvatar, candidateName];
    }, [user?.role, userId, user?.id, anonymous]);

  const handleApplicationChange = async (
    status?: string | number,
    stage?: string | number,
    decline_reason?: string
  ) => {
    if (!isCandidate) {
      try {
        await updateApplicationData(id, {
          ...(stage && { stage }),
          ...(status && { status }),
          ...(decline_reason && { decline_reason })
        });
      } catch (_) {
        setIsRefresh(!isRefresh);
      }
    }
    setIsRefresh(!isRefresh);
  };

  if (locStatus === 3) return null;

  const handleDeleteMyApplication = () => {
    cancelCandidateApplication(id);
    setIsRefresh(!isRefresh);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOnpentextInput(false);
  };

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      event.stopPropagation();
      handleApplicationChange(3, '', inputValue.trim());
      setLocStatus(3);
      handleClose();
    }
  };

  const handleMenueItemClick = (value: any) => {
    handleApplicationChange(3, '', value.toString());
    setLocStatus(3);
    handleClose();
  };

  const handleRemind = async () => {
    const res = await getCandidateRemind(candidateId);
    try {
      if (res) {
        enqueueSnackbar('Reminded successfully', { variant: 'success' });
      }
    } catch {
      enqueueSnackbar(DEFAULT_MESSAGE_TEXTS.error, { variant: 'error' });
    }
  };

  return (
    <ApplicantCard sx={{ m: 1, width: '100%' }}>
      <GridProfile container style={{ flexWrap: 'nowrap' }}>
        <CandidateLink
          id={candidateId}
          avatar={candidateAvatar}
          style={{ height: '60px', width: '60px' }}
        />
        <Grid>
          <Typography
            variant="caption"
            style={{
              marginBottom: '20px',
              fontWeight: 400,
              fontSize: '12px'
            }}
          >
            {fDate(item.created_at)}
          </Typography>
          <Typography
            variant="h5"
            padding={0}
            style={{
              fontWeight: 700,
              fontSize: '20px'
            }}
          >
            {candidateName}{' '}
            {company.is_agency && item?.vacancy && item?.vacancy?.customer
              ? `[${item.vacancy?.customer?.name}]`
              : ''}
          </Typography>
          <Link
            to={PATH_DASHBOARD.jobPosts.getJobPostUrl(vacancy?.id)}
            style={{ textDecoration: 'none' }}
          >
            <Typography variant="h5" color="GrayText" padding={0}>
              {vacancy?.title || ''}
            </Typography>
          </Link>
        </Grid>
      </GridProfile>
      <Grid
        spacing={1}
        style={{
          width: '250px'
        }}
      >
        <Grid item>
          <GridStyleContainer>
            {item?.score || item?.culture_score ? (
              <>
                <ScoreField score={item?.score} type="talent" fontSize={20} />
                <ScoreField
                  score={item?.culture_score}
                  type="culture"
                  fontSize={20}
                />
              </>
            ) : (
              user?.role != 'candidate' && (
                <ButtonPrimary
                  style={{
                    width: '100%',
                    borderRadius: '30px',
                    cursor: 'pointer',
                    padding: '5px 15px'
                  }}
                  onClick={handleRemind}
                >
                  {t('Remind the candidate to take personality assessment')}
                </ButtonPrimary>
              )
            )}
          </GridStyleContainer>
        </Grid>
      </Grid>
      {user?.role !== USER_ROLES.CANDIDATE && (
        <Grid
          item
          style={{
            width: '160px'
          }}
        >
          <GridStyleContainer>
            {locStatus !== 2 && locStatus !== 3 ? (
              <ActionBox
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '10px'
                }}
              >
                <Button
                  variant="outlined"
                  color="success"
                  disabled={locStatus === 2}
                  onClick={() => {
                    handleOpenChat(item);
                    handleApplicationChange(2);
                    setLocStatus(2);
                  }}
                  style={{
                    borderRadius: '30px',
                    borderWidth: '2px'
                  }}
                >
                  <TaskAlt />
                  {ACCEPT}
                </Button>
                <Button
                  variant="outlined"
                  color="error"
                  disabled={locStatus === 3}
                  onClick={handleClick}
                  style={{
                    borderRadius: '30px',
                    borderWidth: '2px'
                  }}
                >
                  <Block />
                  {DECLINE}
                </Button>
                <MenueSelect
                  open={openSelect}
                  anchorEl={anchorEl}
                  options={reasons}
                  onClose={handleClose}
                  setInputValue={setInputValue}
                  inputValue={inputValue}
                  handleKeyPress={handleKeyPress}
                  handleMenueItemClick={handleMenueItemClick}
                  onpentextInput={onpentextInput}
                  setOnpentextInput={setOnpentextInput}
                />
              </ActionBox>
            ) : null}

            {(Number(status) > 0 || Number(locStatus) > 0) && (
              <Box>
                <OverviewSelect
                  width={180}
                  candidate={{ color: '#9B5FDA' }}
                  options={STAGE_TYPES}
                  activeOption={stage}
                  setOption={(value: string | number) =>
                    handleApplicationChange(undefined, value)
                  }
                  styleAutocomplete={{
                    background: 'rgba(155, 95, 218, 0.2)',
                    borderRadius: '30px'
                  }}
                />
                <DeleteButton
                  sx={{
                    width: '100%',
                    borderRadius: '30px',
                    borderWidth: '2px',
                    height: '38px'
                  }}
                  variant="outlined"
                  color="error"
                  disabled={locStatus === 3}
                  onClick={() => {
                    handleApplicationChange(3);
                    setLocStatus(3);
                  }}
                  buttontext={DELETE}
                />
              </Box>
            )}
          </GridStyleContainer>
        </Grid>
      )}
      {canCancel && (
        <Grid item xs={2}>
          <GridStyleContainer>
            <Box>
              <DeleteDialog
                open={open}
                onClose={() => setOpen(false)}
                title={t('Are you sure you want to delete your application?')}
                onDelete={handleDeleteMyApplication}
              />
              <Button
                style={{ width: 'max-content' }}
                variant="outlined"
                onClick={() => setOpen(true)}
              >
                {CANCEL_APPLICATION}
              </Button>
            </Box>
          </GridStyleContainer>
        </Grid>
      )}
      {handleOpenApplicantModal && (
        <KeyboardDoubleArrowRightIcon
          style={{
            fontSize: '30px',
            margin: '0 0 5px 5px',
            color: '#1C9DD7',
            cursor: 'pointer'
          }}
          onClick={() => handleOpenApplicantModal(item)}
        />
      )}
    </ApplicantCard>
  );
};
