export const formValidate = (
  propertyObj: any,
  errorHandler?: any,
  requiredFields?: string[]
) => {
  let isValid = true;
  const errors: any = {};
  const iterable = Object.keys(propertyObj);

  iterable.forEach((key: string) => {
    if (
      !requiredFields ||
      (requiredFields?.length && requiredFields.includes(key))
    ) {
      const value = propertyObj[key];
      const fieldIsEmpty = fieldValidate(value);
      if (fieldIsEmpty) {
        errors[key] = true;
      }
    }
  });
  errorHandler && errorHandler(errors);
  if (Object.keys(errors).length) {
    isValid = false;
  }

  return isValid;
};

export const makeNotRequired = (arrValues: any, fieldName: string) => {
  const iterable = Object.keys(arrValues);
  return iterable.filter((key: string) => key !== fieldName);
};

export const fieldValidate = (value: any) => {
  const arrayCase = Array.isArray(value) && value.length === 0;
  const stringCase = typeof value === 'string' && value.length === 0;
  const intCase = typeof value === 'number' && value === 0;
  const object = typeof value?.name === 'string' && value?.name=== "";
  return (
    arrayCase || stringCase || intCase || value === null || value === undefined || object
  );
};
