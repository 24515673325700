import { experimentalStyled as styled } from '@material-ui/core/styles';
import { Box, BoxProps, Typography } from '@material-ui/core';
import NoDataImage from './images/NoData';

const RootStyle = styled(Box)(() => ({
  height: '100%',
  width: '100%',
  display: 'flex',
  textAlign: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center'
}));

interface EmptyContentProps extends BoxProps {
  title: string;
  img?: string;
  description?: string;
  styleImg?: any;
}

export default function EmptyContent({
  title,
  description,
  img,
  styleImg,
  ...other
}: EmptyContentProps) {
  return (
    <RootStyle {...other}>
      <NoDataImage
        style={{ height: 240, mb: 3, ...styleImg }}
        alt="empty-content"
      />
      <Typography variant="h5" gutterBottom>
        {title}
      </Typography>

      {description && (
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {description}
        </Typography>
      )}
    </RootStyle>
  );
}
