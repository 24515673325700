import useLocales from 'hooks/useLocales';
import { CSSProperties } from 'react';
import { Box, Typography } from '@mui/material';
import { CustomModal } from './CustomModal';

const AIModal = ({
  open,
  onClose,
  customAvatar,
  customTitle,
  customMessage,
  titleStyle,
  textStyle
}: {
  open: boolean;
  onClose: () => void;
  customAvatar?: string;
  customTitle?: string;
  customMessage?: string;
  titleStyle?: CSSProperties;
  textStyle?: CSSProperties;
}) => {
  const { t } = useLocales();
  return (
    <CustomModal open={open} onClose={onClose}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '13px',
          p: '15px'
        }}
      >
        <img
          src={customAvatar || '/static/img/illustrations/rocketship.svg'}
          alt="ai-avatar"
          style={{ maxWidth: '200px', maxHeight: '200px' }}
        />
        <Typography variant="h3" fontWeight={600} style={titleStyle}>
          {customTitle || t('Please Wait!')}
        </Typography>
        <Typography
          variant="body2"
          fontWeight={400}
          color="#85878C"
          style={{ maxWidth: '276px', ...textStyle }}
        >
          {customMessage || t('NORA is working, please be patient.')}
        </Typography>
      </Box>
    </CustomModal>
  );
};

export default AIModal;
