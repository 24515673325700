import { useEffect, useState } from 'react';
import useLocales from 'hooks/useLocales';
import Page from 'components/containers/Page';
import PageContainer from 'components/containers/PageContainer';
import MButton from 'components/new-designer/button/MButton';
import { CustomModal } from 'components/CustomModal';
import { useDispatch, useSelector } from 'react-redux';
import {
  addCustomer,
  deleteCustomer,
  getCustomersData,
  patchCustomerData,
  setCustomersFilter
} from 'redux/slices/customrs';
import { RootState } from 'redux/store';
import {
  Box,
  Divider,
  Grid,
  InputAdornment,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField
} from '@material-ui/core';
import { styled } from '@mui/material';
import { DeleteButton, EditButton } from 'components/Buttons';
import PaginationContainer from 'components/containers/PaginationContainer';
import { Search } from '@material-ui/icons';
import DashboardNavbar from 'layouts/dashboard/navbar/DashboardNavbar';
import { NavLink } from 'react-router-dom';
import { DynamicFormWrapper } from '../../../components/DynamicForm';
import { PATH_DASHBOARD } from '../../../routes/paths';

const InputTextField = styled(TextField)`
  .MuiOutlinedInput-root {
    background: #ffffff;
    border: 1px solid #c9cad9;
    border-radius: 30px;
    margin-top: 8px;
  }
`;

const TypographyTable = styled(Table)(() => ({
  display: 'none',
  '@media (max-width: 1024px)': {
    display: 'block',
    width: 'max-content'
  }
}));

const CultureTable = styled(Table)(() => ({
  '@media (max-width: 1024px)': {
    tr: {
      display: 'flex',
      flexDirection: 'column',
      height: 'max-content',
      padding: '10px',
      td: {
        padding: '10px 0 !important',
        display: 'flex',
        flexWrap: 'wrap',
        wordBreak: 'break-word'
      }
    },
    thead: {
      tr: {
        display: 'none'
      }
    }
  }
}));

const customerDefaultValues = {
  name: '',
  contact_name: '',
  contact_number: '',
  email: '',
  website: ''
};

const CMS = () => {
  const { response, subLoading } = useSelector(
    (state: RootState) => state.customers
  );
  const { page, limit, offset, q, short } = useSelector(
    (state: RootState) => state.customers.filters
  );

  const dispatch = useDispatch();
  const [itemValues, setItemValues] = useState(customerDefaultValues);

  const [openModal, setOpenModal] = useState(false);
  const [editId, setEditId] = useState('');
  const { t } = useLocales();
  const handleSubmit = () => {
    if (!editId) {
      dispatch(addCustomer(itemValues));
    } else {
      dispatch(patchCustomerData(editId, itemValues));
    }
    setOpenModal(false);
  };

  useEffect(() => {
    dispatch(
      getCustomersData({ limit, offset: (page - 1) * limit, page, q, short })
    );
  }, [dispatch, limit, offset, page, q, short, subLoading]);

  const setNewValues = (field: string, value: any) => {
    setItemValues({
      ...itemValues,
      [field]: value
    });
  };

  useEffect(() => {
    if (response?.count - (page - 1) * limit <= 0) {
      dispatch(setCustomersFilter('page', page - 1));
    }
  }, [response]);

  const removeCustomer = (id: any) => {
    dispatch(deleteCustomer(id));
  };
  return (
    <Page title={t('CMS')}>
      <DashboardNavbar title={t('Customers')} />
      <PageContainer>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            mb: 2,
            alignItems: 'center',
            columnGap: '50px'
          }}
        >
          <MButton
            title={t('Add New')}
            MyClass="violetButton"
            click={() => {
              setEditId('');
              setItemValues(customerDefaultValues);
              setOpenModal(true);
            }}
            style={{ width: '140px' }}
          />
          <InputTextField
            fullWidth
            value={q}
            onChange={(event: any) =>
              dispatch(setCustomersFilter('q', event?.target.value))
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Search color="primary" />
                </InputAdornment>
              )
            }}
          />
        </Box>
        {openModal && (
          <CustomModal open={openModal} onClose={() => setOpenModal(false)}>
            <Grid item xs={12} pb={3}>
              <DynamicFormWrapper
                title="CMS Customer"
                type="customer-info"
                itemValues={itemValues}
                onSubmit={handleSubmit}
                setValues={setNewValues}
              />
            </Grid>
          </CustomModal>
        )}
        <CultureTable
          size="small"
          sx={{
            background:
              'linear-gradient(rgba(255, 255, 255, 0.8) -5.21%, rgba(255, 255, 255, 0.3) 114.12%)',
            borderRadius: '20px'
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  color: '#131417',
                  fontWeight: '600',
                  fontSize: '20px',
                  backgroundColor: 'transparent',
                  paddingTop: '40px',
                  boxShadow: 'none !important'
                }}
              >
                {t('Name')}
              </TableCell>
              <TableCell
                sx={{
                  color: '#131417',
                  fontWeight: '600',
                  fontSize: '20px',
                  backgroundColor: 'transparent',
                  paddingTop: '40px'
                }}
              >
                {t('Contact Name')}
              </TableCell>
              <TableCell
                sx={{
                  color: '#131417',
                  fontWeight: '600',
                  fontSize: '20px',
                  backgroundColor: 'transparent',
                  paddingTop: '40px'
                }}
              >
                {t('Contact Number')}
              </TableCell>
              <TableCell
                sx={{
                  color: '#131417',
                  fontWeight: '600',
                  fontSize: '20px',
                  backgroundColor: 'transparent',
                  paddingTop: '40px',
                  boxShadow: 'none !important'
                }}
              >
                {t('Email')}
              </TableCell>
              <TableCell
                sx={{
                  color: '#131417',
                  fontWeight: '600',
                  fontSize: '20px',
                  backgroundColor: 'transparent',
                  paddingTop: '40px',
                  boxShadow: 'none !important'
                }}
              >
                {t('Website')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ paddingLeft: '30px', paddingRight: '30px' }}>
            <Divider
              style={{
                display: 'table-row'
              }}
            />
            {response?.results &&
              response.results.map((row: any, index: number) => (
                <>
                  <TableRow
                    key={row.id}
                    sx={{
                      height: '54px'
                    }}
                  >
                    <TableCell
                      sx={{
                        fontSize: '16px',
                        fontWeight: '400',
                        color: '#131417',
                        lineHeight: '19px'
                      }}
                    >
                      <TypographyTable>{t('Name')}:&nbsp;</TypographyTable>
                      <NavLink
                        to={PATH_DASHBOARD.CUSTOMERS.getCustomerById(row.id)}
                      >
                        {t(row.name)}
                      </NavLink>
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: '16px',
                        fontWeight: '400',
                        color: '#131417',
                        lineHeight: '19px'
                      }}
                    >
                      <TypographyTable>{t('Role')}:&nbsp;</TypographyTable>
                      {t(row.contact_name)}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: '16px',
                        fontWeight: '400',
                        color: '#131417',
                        lineHeight: '19px'
                      }}
                    >
                      <TypographyTable>{t('FullName')}:&nbsp;</TypographyTable>
                      {t(row.contact_number)}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: '16px',
                        fontWeight: '400',
                        color: '#131417',
                        lineHeight: '19px'
                      }}
                    >
                      <TypographyTable>{t('Email')}:&nbsp;</TypographyTable>
                      {t(row.email)}
                    </TableCell>
                    <TableCell>
                      <Link
                        href={row.website}
                        target="_blank"
                        underline="always"
                        sx={{
                          background: row.website
                            ? 'linear-gradient(92.25deg, #9B5FDA -2.57%, #562C82 113.38%)'
                            : '#C9CAD9',
                          borderRadius: '30px',
                          py: '10px',
                          px: '20px',
                          fontWeight: '700',
                          color: '#fff',
                          textDecoration: 'none',
                          cursor: row.website ? 'pointer' : 'not-allowed'
                        }}
                      >
                        {t('website')}
                      </Link>
                    </TableCell>
                    <TableCell>
                      <EditButton
                        onClick={() => {
                          setEditId(row.id);
                          setItemValues(row);
                          setOpenModal(true);
                        }}
                      />
                      <DeleteButton onClick={() => removeCustomer(row.id)} />
                    </TableCell>
                  </TableRow>
                  {index != response.results.length - 1 && (
                    <Divider
                      style={{
                        display: 'table-row'
                      }}
                    />
                  )}
                </>
              ))}
          </TableBody>
        </CultureTable>
        <PaginationContainer
          page={page}
          count={response?.count}
          perPage={limit}
          setPage={(value) => {
            dispatch(setCustomersFilter('page', value));
          }}
        />
      </PageContainer>
    </Page>
  );
};

export default CMS;
