import { ReactNode } from 'react';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import Page from 'components/containers/Page';

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(12, 0),
  margin: 0
}));

export default function PasswordPageContainer({
  children,
  title
}: {
  children: ReactNode;
  title: string;
}) {
  return (
    <RootStyle title={title}>
      <Container>{children}</Container>
    </RootStyle>
  );
}
