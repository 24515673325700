import { useState } from 'react';
import { Button } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { getTranslatedList } from '../constants/constants';
import useLocales from '../hooks/useLocales';
import { ApproveDialog } from './Dialog';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import { fetchJobPost } from '../redux/slices/jobSlice';

export const ApplyJobButton = ({
  jobPostId,
  onApply,
  isApplied = false
}: {
  jobPostId: string | number;
  onApply: (res: any) => void;
  isApplied: boolean;
}) => {
  const [disabled, setDisabled] = useState(isApplied);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { jobPost } = useSelector((state: RootState) => state.job);

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useLocales();

  const {
    DEFAULT_MESSAGE_TEXTS: { error, applied }
  } = getTranslatedList();

  const handleApply = async () => {
    try {
      await dispatch(fetchJobPost({ vacancy: jobPostId }));
      onApply(jobPost);
      enqueueSnackbar(applied, { variant: 'success' });
      setDisabled(true);
      setOpen(false);
    } catch {
      enqueueSnackbar(error, { variant: 'error' });
    }
  };

  return (
    <>
      <ApproveDialog
        open={open}
        title={t('Are you sure you want to apply this vacancy?')}
        onClose={() => setOpen(false)}
        onApprove={handleApply}
      />
      <Button
        sx={{ textTransform: 'capitalize' }}
        disabled={disabled}
        onClick={(e) => {
          e.preventDefault();
          setOpen(true);
        }}
      >
        {disabled ? t('Applied') : t('Apply For Job')}
      </Button>
    </>
  );
};
