import { useTranslation } from 'react-i18next';

export function getButtons() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { t } = useTranslation();

  const applicants = {
    CANCEL_APPLICATION: t('Cancel this Application'),
    SELECT_CANDIDATE: t('Select Candidate'),
    SELECTED: t('Selected'),
    VIEW_CANDIDATE: t('View Candidate Profile'),
    SET_STAGE: t('Set Stage'),
    REMOVE_CANDIDATE: t('Remove candidate')
  };

  const candidateProfile = {
    ASSESSMENT_REPORT: t('Assessment Report'),
    MOTIVATOR_REPORT: t('Motivator/Demotivator Report'),
    MAKE_PUBLIC: t('Make profile public'),
    MAKE_ANONYMOUS: t('Make profile anonymous'),
    GET_URL: t('Get URL'),
    GENERATE_URL: t('Generate URL'),
    NEW_URL: t('New URL'),
    RENEW: t('Renew'),
    SHARE_URL: t('Share URL'),
    FIND_JOB: t('Find Job'),
    REMOVE_URL: t('Remove URL')
  };

  return {
    OK: t('Ok'),
    BACK: t('Back'),
    NEXT: t('Next'),
    FINISH: t('Finish'),
    SUBMIT: t('Submit'),
    SAVE: t('Save'),
    SKIP: t('Skip'),
    UPDATE: t('Update'),
    DELETE: t('Delete'),
    EDIT: t('Edit'),
    SUBSCRIBE: t('Subscribe'),
    SHOW_MORE: t('Show more'),
    MORE_INFO: t('More Info'),
    SEARCH: t('Search'),
    JOBS_SEARCH: t('Search job by name'),
    SELECT: t('Select'),
    ACCEPT: t('Accept'),
    ShOW_BEST_MATCHES: t('Show best matches'),
    FIND_BEST_MATCHES: t('Find best match'),
    DECLINE: t('Decline'),
    APPLY: t('Apply'),
    CANCEL: t('Cancel'),
    UPLOAD: t('Upload'),
    DOWNLOAD: t('Download'),
    ADDNOTE: t('Add'),
    UPDATENOTE: t('Update'),
    UNAVAILABLE: t('Unavailable'),
    ACTIVE: t('Active'),
    INACTIVE: t('Inactive'),
    TRIALING: t('Trialing'),
    ACTIVATE: t('Activate'),
    DEACTIVATE: t('Deactivate'),
    APPLICANTS: applicants,
    CANDIDATE: candidateProfile
  };
}
