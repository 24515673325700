import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Grid } from '@material-ui/core';
import ImageUploader from '../../../components/ImageUploader';
import { DynamicFormWrapper } from '../../../components/DynamicForm';
import { USER_ROLES } from '../../../constants/constants';
import { getInitialValuesByType } from '../../../helpers/formInitialValues';
import { prepareFormData } from '../../../helpers/formFields';
import useLocales from '../../../hooks/useLocales';
import { DeleteButton } from '../../../components/Buttons';
import { logout, patchAccountData } from '../../../redux/slices/authJwt';
import { setValues } from '../../../utils/helpers';

const GeneralProfileSettings = ({ accountData }: any) => {
  const [uploader, setUploader] = useState<any[]>([]);
  const [itemValues, setItemValues] = useState(
    getInitialValuesByType('account-info', accountData)
  );
  const [images, setImages] = useState({
    avatar: '',
    hero: ''
  });
  const { id, avatar, hero, role } = accountData;
  const { t } = useLocales();
  const dispatch = useDispatch();

  const setNewValues = (field: string, value: any) =>
    setValues(field, value, itemValues, setItemValues);

  const handleDeleteAccount = async () => {
    dispatch(logout());
  };

  const handleSubmit = async () => {
    const data = prepareFormData(itemValues, images);
    dispatch(patchAccountData(id, data));
  };

  const isRecruiterOrAdmin =
    accountData?.role === 'recruiter' || accountData?.role === 'admin';

  useEffect(() => {
    setUploader([
      {
        label: t('Avatar'),
        prevValue: avatar,
        newValue: images.avatar,
        setValue: (file: any) => setValues('avatar', file, images, setImages),
        rounded: true
      },
      ...(isRecruiterOrAdmin
        ? []
        : [
            {
              label: t('Hero'),
              prevValue: hero,
              newValue: images.hero,
              setValue: (file: any) =>
                setValues('hero', file, images, setImages),
              rounded: false
            }
          ])
    ]);
    return () => {
      setUploader([]);
    };
  }, [images, isRecruiterOrAdmin]);

  return (
    <Grid container spacing={3} style={{ marginTop: '40px' }}>
      <Grid
        item
        xs={12}
        md={4}
        style={{ display: 'flex', flexDirection: 'column', rowGap: '20px' }}
      >
        {uploader.map((el: any, i: number) => (
          <ImageUploader
            key={i}
            index={i}
            label={el.label}
            prevValue={el.prevValue}
            newValue={el.newValue}
            setValue={el.setValue}
            rounded={el.rounded}
          />
        ))}
      </Grid>
      <Grid item xs={12} md={8}>
        <DynamicFormWrapper
          title={t('Main Info')}
          type="account-info"
          itemValues={itemValues}
          setValues={setNewValues}
          onSubmit={handleSubmit}
        />
      </Grid>
      {role !== USER_ROLES.ADMIN && (
        <DeleteButton
          buttontext={t('Delete Account')}
          dialogtext={t('Do you really want to delete your account? This action cannot be applied backwards')}
          onClick={handleDeleteAccount}
          variant="contained"
          sx={{ p: 1, margin: '20px 0 0 24px' }}
        />
      )}
    </Grid>
  );
};

export default GeneralProfileSettings;
