import { useEffect, useState } from 'react';
import {
  experimentalStyled as styled,
  makeStyles
} from '@material-ui/core/styles';
import {
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  ButtonGroupProps,
  Checkbox,
  Grid,
  Input,
  InputAdornment,
  TextField,
  Typography
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { getButtons } from 'constants/text/buttons';
import { GroupSelect, LocationSelect } from './DynamicForm';
import { Search } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

export const FilterGroup = styled(Box)(() => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'row',
  flexWrap: 'wrap',
  margin: '0 20px 20px 0',
  justifyContent: 'space-between'
}));

export const Filter = styled(Box)(() => ({
  margin: '0 0 10px 0',
  '.MuiButtonGroup-root': {
    justifyContent: 'space-between',
    width: '100%',
    gap: "20px"
  }
}));

export const ButtonCheck = styled(Button)(({ hover, round }: any) => ({
  margin: '0 0 10px 0',
  padding: '10px 10px',
  transition: 'none',
  fontWeight: 400,
  fontSize: '14px',
  color: '#131417',
  '&:hover': {
    borderRadius: `${round ? '50%' : hover || '30px'} !important`
  }
}));

export const AutocompleteStyle = styled(Autocomplete)(({ candidate }: any) => ({
  ...(candidate
    ? {
      '.MuiOutlinedInput-root': {
        borderRadius: '30px',
        padding: '3px !important'
      },
      '.MuiInputLabel-root': {
        top: '-5px'
      },
      '.MuiOutlinedInput-input': {
        ...candidate
      }
    }
    : {})
}));

const TypographyTitle = styled(Typography)(() => ({
  fontWeight: 400,
  fontSize: '14px !important',
  color: '#666666',
  paddingBottom: 6,
  paddingLeft: 10
}));

interface OptionType {
  value: any;
  label?: any;
}

export interface OverviewFilterType {
  title?: string;
  options: OptionType[] | string[] | any;
  setOption: (value: any) => any;
  activeOption: any;
}

export const OverviewFilter = ({
  title,
  options,
  setOption,
  activeOption,
  disabled,
  style = {}
}: OverviewFilterType & ButtonGroupProps) => (
  <Filter style={style}>
    {title ? <TypographyTitle variant="h5">{title}</TypographyTitle> : null}
    <ButtonGroup disabled={disabled}>
      {options.map((option: OptionType | string) => {
        const value = typeof option === 'string' ? option : option.value;
        const label = typeof option === 'string' ? value : option?.label;
        return (
          <ButtonCheck
            sx={{
              textTransform: 'capitalize'
            }}
            style={
              activeOption === value
                ? {
                  borderRadius: 30,
                  background:
                    'linear-gradient(92.25deg, #9B5FDA -2.57%, #562C82 113.38%)',
                  fontWeight: 700,
                  fontSize: '14px',
                  color: '#FFFFFF',
                  padding: '10px 24px'
                }
                : {}
            }
            key={value}
            variant={activeOption === value ? 'contained' : 'text'}
            onClick={() => setOption(value)}
          >
            {label}
          </ButtonCheck>
        );
      })}
    </ButtonGroup>
  </Filter>
);

export const OverviewCheckBox = ({
  title,
  options,
  setOption,
  activeOption
}: OverviewFilterType) => {
  const { on, off } = options;
  return (
    <Filter style={{ marginTop: '30px' }}>
      <Grid display="flex" alignItems="center">
        <Checkbox
          checked={activeOption === on}
          onChange={() => setOption(activeOption === on ? off : on)}
          color="primary"
        />
        {title ? (
          <Typography
            variant="h5"
            padding={0}
            style={{
              fontWeight: 400,
              fontSize: '14px',
              color: '#131417'
            }}
          >
            {title}
          </Typography>
        ) : null}
      </Grid>
    </Filter>
  );
};

export const OverviewSearch = ({
  title,
  setValue,
  value,
  timeout = 700,
  placeholder = '',
  style = {},
  eventSearch
}: any) => {
  const { SEARCH } = getButtons();
  const [query, setQuery] = useState(value);

  const useStyles = makeStyles(() => ({
    root: {
      width: '100%',
      overflow: 'hidden',
      fontFamily: 'Rubik',
      marginBottom: '15px'
    },
    input: {
      color: '#000'
    }
  }));

  const classes = useStyles();

  useEffect(() => {
    let timeoutId: any = null;
    if (timeout) {
      timeoutId = setTimeout(() => {
        if (query != value) {
          setValue(query);
        }
      }, timeout);
    }
    return () => timeoutId && clearTimeout(timeoutId);
  }, [query]);

  useEffect(() => {
    setQuery(value);
  }, [value]);

  return (
    <Filter style={style}>
      {title ? <TypographyTitle variant="h5">{title}</TypographyTitle> : null}

      <Grid
        className="searchBar"
        item
        xs={8}
        sx={{ display: 'flex', alignItems: 'center', maxWidth: '100%' }}
      >
        <TextField
          autoFocus
          placeholder={placeholder != '' ? placeholder : SEARCH}
          onChange={(e) =>
            timeout ? setQuery(e.target.value) : setValue(e.target.value)
          }
          value={query}
          variant="standard"
          fullWidth
          InputProps={{
            classes,
            endAdornment: (
              <InputAdornment position="end" sx={{ mb: 1 }}>
                {!eventSearch ? (
                  <img src="/static/img/icons/zoom.svg" />
                ) : (
                  <img src="/static/img/icons/eventSearch.svg" />
                )}
              </InputAdornment>
            )
          }}
          sx={{
            mr: '0',
            fontWeight: 'fontWeightBold',
            p: !title ? 1 : 0,
            pr: '0'
          }}
        />
      </Grid>
    </Filter>
  );
};

export const OverviewJobsSearch = ({
  title,
  setValue,
  value,
  style = {}
}: any) => {
  const { JOBS_SEARCH } = getButtons();

  return (
    <Filter width="100%">
      {title ? <TypographyTitle variant="h5">{title}</TypographyTitle> : null}
      <Input
        style={style}
        autoFocus
        disableUnderline
        placeholder={JOBS_SEARCH}
        onChange={(e) => setValue(e.target.value)}
        value={value}
        startAdornment={
          <InputAdornment position="start">
            <Search color="primary" />
          </InputAdornment>
        }
        sx={{
          mr: 1,
          mb: '12px',
          p: '15px 22px',
          color: 'text.secondary',
          fontWeight: 'fontWeightBold',
          width: '100%',
          background: '#FFFFFF',
          border: '1px solid #E9EDF9',
          boxSizing: 'border-box',
          borderRadius: '10px'
        }}
      />
    </Filter>
  );
};

export const OverviewSelect = ({
  width,
  title,
  placeholder,
  options = [],
  setOption,
  activeOption,
  multiple = false,
  type = 'select',
  styleAutocomplete = {},
  candidate = false,
  customer = false,
  isPlaceholder = false,
  setQuery,
  filterSx,
  titleSx,
  disabled = false,
  todo = false
}: any) => {
  const { SELECT } = getButtons();
  const company: any = useSelector(
    (state: RootState) => state.generalRecruiter.company
  );

  const [isOption, setIsOption] = useState(options ?? []);
  const selectedOption = type === 'job_post' ? isOption : options;
  const optionsProp = selectedOption === null ? [] : selectedOption;

  useEffect(() => {
    if (type === 'job_post') {
      if (JSON.stringify(isOption) !== JSON.stringify(options)) {
        setIsOption(options);
      }
    }
  }, [options]);

  return (
    <Filter sx={filterSx}>
      {title ? (
        <TypographyTitle variant="h5" sx={titleSx}>
          {title}
        </TypographyTitle>
      ) : null}
      {(() => {
        switch (type) {
          case 'location':
            return (
              <LocationSelect
                sx={{
                  mb: 2,
                  width: width || 300,
                  background: '#ffffff',
                  borderRadius: '10px'
                }}
                multiple={multiple}
                label={(!title || placeholder) && (placeholder || SELECT)}
                onChange={(e: any, el: any) =>
                  setOption(multiple ? el.map((el: any) => el?.id) : el?.id)
                }
              />
            );

          case 'select-industries':
          case 'select-customers':
          case 'select-language':
          case 'select-skills':
          case 'select-schools':
          case 'select-degrees':
          case 'culture_profile':
            return (
              <GroupSelect
                type={type}
                sx={{
                  mb: 2,
                  width: width || 300,
                  background: '#ffffff',
                  borderRadius: '10px'
                }}
                multiple={multiple}
                label={
                  (!title || isPlaceholder) &&
                  !activeOption &&
                  (placeholder || SELECT)
                }
                onChange={(e: any, el: any) => setOption(el)}
                value={activeOption}
                isPlaceholder={isPlaceholder}
              />
            );

          case 'select':
          case 'job_post':
            return (
              <AutocompleteStyle
                candidate={candidate}
                disabled={disabled}
                popupIcon={<KeyboardArrowDownIcon style={{ color: todo ? "#8321FF" : "#562C82" }} />}
                clearIcon={todo ? null : undefined}
                sx={{
                  mb: 2,
                  width: width || 300,
                  background: '#ffffff',
                  borderRadius: '10px'
                }}
                onInputChange={(event: any, value: string) => {
                  if (type == 'job_post') {
                    setQuery && setQuery(value);
                    const filteredOptions = options?.filter((option: any) =>
                      option?.label
                        ?.toLowerCase()
                        .includes(value?.toLowerCase())
                    );

                    setIsOption(filteredOptions);
                  } else {
                    setQuery && setQuery(value);
                  }
                }}
                label
                style={styleAutocomplete}
                multiple={multiple}
                options={optionsProp}
                getOptionLabel={(option: any) =>
                  `${option?.label || option?.name || option?.title || SELECT}${company.is_agency && customer && option.customer
                    ? ` [${option.customer.name}]`
                    : ''
                  }`
                }
                value={
                  // eslint-disable-next-line no-nested-ternary
                  (activeOption || activeOption === 0) &&
                    (type === 'job_post' ? isOption?.length : options?.length)
                    ? multiple
                      ? (type === 'job_post' ? isOption : options)?.filter(
                        (el: any) => activeOption?.includes(el.id || el.value)
                      )
                      : (type === 'job_post' ? isOption : options)?.find(
                        (el: any) =>
                          el.id === activeOption || el.value === activeOption
                      )
                    : null
                }
                onChange={(e: any, el: any) =>
                  setOption(
                    !multiple
                      ? el?.id || el?.value
                      : el.map((el: any) => el?.id || el?.value)
                  )
                }
                renderInput={(params: any) => (
                  <TextField
                    InputLabelProps={{ disableAnimation: true }}
                    {...params}
                    placeholder={placeholder || title || SELECT}
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      style: { fontSize: todo ? '20px' : undefined, fontWeight: todo ? '600' : undefined, padding: todo ? '9px 21px' : undefined }
                      // endAdornment: (
                      //   <InputAdornment position="end">
                      //     <KeyboardArrowDown />
                      //   </InputAdornment>
                      // )
                    }}
                  />
                )}
              />
            );
        }
        return null;
      })()}
    </Filter>
  );
};
