import { Link as RouterLink } from 'react-router-dom';
import { motion } from 'framer-motion';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { Avatar, Box, Button, Container, Typography } from '@material-ui/core';
import { MotionContainer, varBounceIn } from '../components/animate';
import Page from '../components/containers/Page';
import useLocales from '../hooks/useLocales';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10)
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  left: 0,
  lineHeight: 0,
  width: '100%',
  position: 'absolute',
  padding: theme.spacing(3, 3, 0),
  [theme.breakpoints.up('sm')]: { padding: theme.spacing(5, 5, 0) }
}));

export default function DenyPrivacy() {
  const cms: any = useSelector((state: RootState) => state.cms.base);

  const { t } = useLocales();
  return (
    <RootStyle title="404 Page Not Found">
      <HeaderStyle>
        <RouterLink to="/">
          <Avatar
            src={cms.logo}
            sx={{ mr: 1, width: '100px', height: '100px' }}
            alt="logo"
          />
        </RouterLink>
      </HeaderStyle>

      <Container>
        <MotionContainer initial="initial" open>
          <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
            <motion.div variants={varBounceIn}>
              <Typography
                variant="h3"
                gutterBottom
                style={{ fontFamily: 'Rubik' }}
              >
                {t('We are sorry that you did not accepted our privacy terms')}
              </Typography>
            </motion.div>
            <Typography sx={{ color: 'text.secondary', fontFamily: 'Rubik' }}>
              {t('Your account is now deleted in order to comply with the law, feel free to register again if you changed your idea!')}
              .
            </Typography>

            <Button
              style={{
                marginTop: '30px',
                background:
                  'linear-gradient(92.25deg, #9B5FDA -2.57%, #562C82 113.38%)',
                borderRadius: '30px',
                fontFamily: 'Rubik'
              }}
              to="/"
              size="large"
              variant="contained"
              component={RouterLink}
            >
              {t('Go to Home')}
            </Button>
          </Box>
        </MotionContainer>
      </Container>
    </RootStyle>
  );
}
