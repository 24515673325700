import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState, dispatch } from 'redux/store';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Box from '@mui/material/Box';
import useLocales from 'hooks/useLocales';
import { CustomModal } from 'components/CustomModal';
import MButton from 'components/new-designer/button/MButton';
import CompanyProfileSettings from 'views/dashboard/companySettings/companySettings';
import NotificationsSettings from 'views/dashboard/companySettings/notificationsSettings';
import {
  currentStepDetails,
  steps
} from '../../constants/registerStep/constants';
import { ICustomStepIcon, StepsType } from './types';
import { StepHeader } from './stepHeader';
import SiteColours from './SiteColours';
import { Grid } from '@material-ui/core';
import { updateCompanyInfo } from 'redux/slices/generalRecruiter';
import { getCmsBaseInfo, updateCmsBaseInfo } from 'redux/slices/cms';
import { prepareFormData } from 'helpers/formFields';
import { useSnackbar } from 'notistack';
import NewDesignStylingForm from 'views/dashboard/companySettings/NewDesignCarerSite';
import FreePlanTooltip from 'views/dashboard/companySettings/freePlanTooltip';

const CustomStepIcon = ({ active, completed }: ICustomStepIcon) => {
  if (completed) {
    return (
      <svg
        width="34"
        height="34"
        viewBox="0 0 34 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="17"
          cy="17"
          r="16.7167"
          fill="#8AE98A"
          stroke="#8AE98A"
          strokeWidth="0.566667"
        />
        <path
          d="M26 11.9998L14 23.9998L8.5 18.4998L9.91 17.0898L14 21.1698L24.59 10.5898L26 11.9998Z"
          fill="white"
        />
      </svg>
    );
  }

  if (active) {
    return (
      <svg
        width="34"
        height="37"
        viewBox="0 0 34 37"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M33.7167 17.4741C33.7167 26.9758 26.2251 34.6649 17 34.6649C7.77494 34.6649 0.283333 26.9758 0.283333 17.4741C0.283333 7.97252 7.77494 0.283333 17 0.283333C26.2251 0.283333 33.7167 7.97252 33.7167 17.4741Z"
          stroke="#562C82"
          strokeWidth="0.566667"
        />
        <g filter="url(#filter0_d_23_332)">
          <ellipse
            cx="16.9999"
            cy="17.4744"
            rx="14.1667"
            ry="14.5618"
            fill="#562C82"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_23_332"
            x="0.566585"
            y="2.9126"
            width="32.8666"
            height="33.6569"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="2.26667" />
            <feGaussianBlur stdDeviation="1.13333" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.337255 0 0 0 0 0.172549 0 0 0 0 0.509804 0 0 0 0.2 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_23_332"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_23_332"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    );
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    />
  );
};

export default function Subscription() {
  const { company }: any = useSelector(
    (state: RootState) => state.generalRecruiter
  );
  const { base }: any = useSelector((state: RootState) => state.cms);
  const { t } = useLocales();
  const { enqueueSnackbar } = useSnackbar();
  const [activeStep, setActiveStep] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(true);
  const [deActiveElement, setDeActiveElement] = useState<string>(
    `&:nth-child(${activeStep + 2})`
  );
  const [colors, setColors] = useState({
    primary_color: company?.primary_color || '#8321FF',
    secondary_color: company?.secondary_color || '#ED094D'
  });
  const [isStatusActive, setIsStatusActive] = useState<boolean>(
    company?.active
  );
  const [isSiteHidden, setIsSiteHidden] = useState<boolean>(!!base.active);
  let colorTime: NodeJS.Timeout;

  const paddingBtn = (): string => {
    switch (activeStep) {
      case 0:
        return '11px 150px';
      case steps.length - 1:
        return '11px 40px';
      default:
        return '11px 110px';
    }
  };

  const customModalPadding = (): string => {
    switch (activeStep) {
      case 0:
        return '80px';
      case 4:
        return '25px';
      case 5:
        return '30px';
      default:
        return '0';
    }
  };

  useEffect(() => {
    setDeActiveElement(`&:nth-child(${activeStep + 2})`);
  }, [activeStep]);

  const isColor = (strColor: string): boolean => {
    const { style } = new Option();
    style.color = strColor;
    return style.color !== '';
  };

  const handleSubmit = (value: any): void => {
    const data = prepareFormData(value);
    dispatch(updateCompanyInfo(company.id, data));
    dispatch(getCmsBaseInfo());
  };

  const handleSetValues = (field: string, value: any): void => {
    const newValue = value?.css?.backgroundColor || value;
    const data = {
      ...colors,
      [field]: newValue
    };

    setColors(data);
    if (colorTime) {
      clearTimeout(colorTime);
    }
    colorTime = setTimeout(() => {
      if (isColor(newValue)) {
        handleSubmit(data);
        enqueueSnackbar(t('Color updated successfully'), { variant: 'success' });
      } else enqueueSnackbar(t('Incorrect color type'), { variant: 'error' });
    }, 500);
  };

  const getStep = (): JSX.Element => {
    switch (activeStep) {
      case 1:
        return (
          <CompanyProfileSettings
            setRegisterStep={(e: number) => setActiveStep(e)}
            registerStep={activeStep}
            type="company-info-register"
          />
        );
      case 2:
        return (
          <Grid
            sx={{
              display: 'flex',
              flexDirection: 'column-reverse',
              justifyContent: 'center',
              padding: '0 90px',
              columnGap: '20px'
            }}
          >
            <SiteColours setColors={setColors} colors={colors} />
            <NewDesignStylingForm  initialValues={company} styles={true} />
          </Grid>
        );
      case 3:
        return (
          <NotificationsSettings
          isShowSubscribe={false} 
            styles={{
              justifyContent: 'center',
              columnGap: '30px',
              margin: '0 140px',
              width: '-webkit-fill-available'
            }}
          />
        );
      default:
        return <></>;
    }
  };

  const next = (): void => {
    setActiveStep(activeStep + 1);
    switch (activeStep) {
      case 2:
        {
          if (
            JSON.stringify({
              primary_color: company?.primary_color || '#8321FF',
              secondary_color: company?.secondary_color || '#ED094D'
            }) !== JSON.stringify(colors)
          ) {
            handleSetValues('primary_color', colors.primary_color);
            handleSetValues('secondary_color', colors.secondary_color);
          }

          const data = prepareFormData({
            active: isStatusActive
          });
          dispatch(updateCompanyInfo(company.id, data));

          const cmsBaseInfo = { active: isSiteHidden };
          dispatch(updateCmsBaseInfo(1, cmsBaseInfo));
        }
        break;
      default:
        break;
    }
  };

  return (
    <CustomModal
      open={open}
      style={{
        padding: `40px 10px ${customModalPadding()} 0`,
        position: 'relative',
        overflow: 'visible',
        width: activeStep === 1 ? '1250px' : 'fit-content'
      }}
    >
      {activeStep === 5 && (
        <Grid
          style={{
            background: 'white',
            width: 'fit-content',
            borderRadius: '50%',
            top: '-38px',
            position: 'absolute',
            left: '330px'
          }}
        >
          <img
            src="/static/img/icons/subscriptionCongrats.svg"
            alt="subscriptionCongrats"
          />
        </Grid>
      )}
      <Box
        sx={{
          width: '100%',
          minWidth: '762px',
          '& text': {
            display: 'none'
          },
          '& .deActiveStep': {
            '&>span:first-child': {
              background: '#fff',
              width: '34px',
              height: '35px',
              border: '1px solid #C9CAD9',
              borderRadius: '30px',
              '&>svg': {
                display: 'none'
              }
            }
          },
          '& .activeStep': {
            '&>span:first-child': {
              width: '34px',
              height: '35px',
              borderRadius: '30px',
              '&>svg': {
                width: '100%',
                height: '100%'
              }
            }
          },
          '& .MuiStepConnector-horizontal': {
            top: '15px',
            width: '54%',
            left: '-47px',
            height: '1.9px'
          },
          '& .MuiStepIcon-completed': {
            color: 'green'
          },
          '& .MuiStepConnector-root.Mui-completed, & .MuiStepConnector-root.Mui-active':
            {
              background: '#8AE98A'
            },
          '& .MuiStep-alternativeLabel': {
            [deActiveElement]: {
              '& div': {
                background:
                  'linear-gradient(to right, #562C82 50%, #C9CAD9 50%)',
                borderRadius: '10px'
              }
            }
          },
          '& .MuiStepLabel-label': {
            marginTop: '9px !important'
          },
          '& .MuiStepper-root.MuiStepper-horizontal.MuiStepper-alternativeLabel':
            {
              maxWidth: '890px',
              margin: '0 auto'
            }
        }}
      >
        {activeStep < steps.length && (
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((e: StepsType, index: number) => (
              <Step key={e.id}>
                <StepLabel
                  StepIconComponent={CustomStepIcon}
                  className={
                    e.id > activeStep + 1 ? 'deActiveStep' : 'activeStep'
                  }
                  sx={
                    index === 0
                      ? {
                          '.MuiStepConnector-root': {
                            background:
                              'linear-gradient(to right, #562C82 50%, #C9CAD9 50%)',
                            borderRadius: '10px'
                          }
                        }
                      : {}
                  }
                >
                  <p
                    style={{
                      color: '#C9CAD9'
                    }}
                  >
                    {e.preTitle}
                  </p>
                  <span
                    style={{
                      color: '#131417',
                      fontWeight: '700'
                    }}
                  >
                    {e.title}
                  </span>
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        )}
      </Box>
      <StepHeader
        title={currentStepDetails[activeStep].title}
        subTitle={currentStepDetails[activeStep].subTitle}
        activeStep={activeStep}
      />
      {activeStep < steps.length ? (
        getStep()
      ) : (
        <FreePlanTooltip
          setOpen={() => {
            const data = { onboarding_completed: true, active: true };
            dispatch(updateCompanyInfo(company.id, data));
            setOpen(false);
          }}
        />
      )}
      {activeStep !== 1 && activeStep < steps.length && (
        <div
          style={{
            display: 'flex',
            flexDirection: !activeStep ? 'column' : 'row-reverse',
            alignItems: 'center',
            rowGap: '22px',
            justifyContent: 'space-between',
            padding: [2, 3, 4].includes(activeStep)
              ? '40px 90px 30px'
              : '0 40px'
          }}
        >
          <MButton
            title={
              activeStep + 1 < steps.length
                ? t('Next')
                : t('Activate my Account')
            }
            MyClass="violetButton"
            click={() => next()}
            style={{
              padding: paddingBtn(),
              maxWidth: activeStep === 0 ? '' : '232px',
              width: 'auto',
              height: 'auto'
            }}
          />
          {activeStep > 0 && (
            <MButton
              title={t('Back')}
              MyClass="violetButton"
              click={() => {
                if (activeStep > 0) {
                  setActiveStep(activeStep - 1);
                }
              }}
              style={{
                padding: activeStep === 0 ? '11px 150px' : '11px 110px',
                maxWidth: activeStep === 0 ? '' : '232px',
                width: 'auto',
                height: 'auto',
                border: '1.5px solid var(--Purple, #562C82)',
                background: 'unset',
                color:
                  'linear-gradient(92.25deg, #9B5FDA -2.57%, #562C82 113.38%)'
              }}
            />
          )}
        </div>
      )}
    </CustomModal>
  );
}
