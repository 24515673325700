import './static/locales/i18n';
import './utils/highlight';
import 'simplebar/src/simplebar.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import 'react-image-lightbox/style.css';
import 'react-quill/dist/quill.snow.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';
import 'lazysizes/plugins/object-fit/ls.object-fit';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';

// if ('serviceWorker' in navigator) {
//   window.addEventListener('load', () => {
//     navigator.serviceWorker
//       .register('/serviceWorker.js')
//       .then(
//         (reg) => console.log('ServiceWorker scope: ', reg.scope),
//         (err) => console.log('ServiceWorker registration failed: ', err)
//       )
//       .catch((err) => console.log(err));
//   });
// } else {
//   console.log('service worker is not supported');
// }

ReactDOM.render(<App />, document.getElementById('root'));

reportWebVitals();
