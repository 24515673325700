const LocaleNames: any = {
    EUR: 'nl-NL',
    USD: 'en-US',
    GBP: 'en-GB',
    INR: 'en-IN',
}

export const getCurrencyFromLocale = (locale: string, value: any) => {
    const data = value;
    const currency = data.toLocaleString(LocaleNames[locale], { style: 'currency', currency: locale })
    return currency
}