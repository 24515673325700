import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  getAnalyticsJobCosts,
  getAnalyticsJobPostDurations,
  getAnalyticsJobsPeriod,
  getApplicationDeclineReasons,
  getApplicationStagesPie,
  getJobApplicationStages,
  getJobsAnalytics
} from '../../requests/fixtures';

const initialState = {
  applicationStagesPie: { data: [], labels: [] },
  applicationDeclineReasons: { data: [], labels: [] },
  applicationStages: { datasets: [{ data: [] }], labels: [] },
  costsAnalytics: { labels: [], data: [], currency: '' },
  jobPostAnalytics: { labels: [], data: [] },
  analyticsJobPostDurations: { labels: [], data: [] },
  jobsAnalytics: { labels: [], data: [] }
};

export const fetchApplicationStagesPie = createAsyncThunk(
  'job/fetchApplicationStagesPie',
  async (id?: number) => getApplicationStagesPie(id)
);
export const fetchApplicationDeclineReason = createAsyncThunk(
  'job/fetchApplicationDeclineReasons',
  async (id?: number) => getApplicationDeclineReasons(id)
);

export const fetchJobsAnalytics = createAsyncThunk(
  'job/fetchJobsAnalytics',
  async (value: string) => getJobsAnalytics(value)
);

export const fetchCostsAnalytics = createAsyncThunk(
  'job/fetchCostsAnalytics',
  async () => getAnalyticsJobCosts()
);

export const fetchAnalyticsJobPostDurations = createAsyncThunk(
  'job/fetchAnalyticsJobPostDurations',
  async () => getAnalyticsJobPostDurations()
);

export const fetchJobPostAnalytics = createAsyncThunk(
  'job/fetchJobPostAnalytics',
  async (params: { value: string; id?: number }) => {
    const { value, id } = params;
    return getAnalyticsJobsPeriod(value, id);
  }
);

export const fetchApplicationStages = createAsyncThunk(
  'job/fetchApplicationStages',
  async (params: { value: string; id?: number }) => {
    const { value, id } = params;
    return getJobApplicationStages(value, id);
  }
);

const dataAnalytics = createSlice({
  name: 'dataAnalytics',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchJobsAnalytics.fulfilled, (state, action) => {
        state.jobsAnalytics = action.payload;
      })
      .addCase(fetchJobPostAnalytics.fulfilled, (state, action) => {
        state.jobPostAnalytics = action.payload;
      })
      .addCase(fetchAnalyticsJobPostDurations.fulfilled, (state, action) => {
        state.analyticsJobPostDurations = action.payload;
      })
      .addCase(fetchCostsAnalytics.fulfilled, (state, action) => {
        state.costsAnalytics = action.payload;
      })
      .addCase(fetchApplicationStagesPie.fulfilled, (state, action) => {
        state.applicationStagesPie = action.payload;
      })
      .addCase(fetchApplicationDeclineReason.fulfilled, (state, action) => {
        state.applicationDeclineReasons = action.payload;
      })
      .addCase(fetchApplicationStages.fulfilled, (state, action) => {
        state.applicationStages = action.payload;
      });
  }
});

export default dataAnalytics.reducer;
