import {
  getDataByUrl,
  postDataByUrl,
  updateDataByUrl,
  deleteDataByUrl,
  ID
} from 'requests';

export const getJobPosts = (params: any) => getDataByUrl('/api/vacancy/', params);

export const getJobPost = (id: ID) => getDataByUrl(`/api/vacancy/${id}/`);

export const postNewJobPost =  (data: any) =>  postDataByUrl(`/api/vacancy/`, data, {}, true);

export const updateJobPost = (id: ID, data: any) =>
  updateDataByUrl(`/api/vacancy/${id}/`, data);

export const deleteJobPost = (id: ID) => deleteDataByUrl(`/api/vacancy/${id}/`);

export const getApplicants = (params: any) =>
  getDataByUrl(`/api/vacancy/applications/`, params);

export const applyJobPost = (data: any) =>
  postDataByUrl(`/api/vacancy/applications/`, data);

export const uploadJobFile = (data: any) =>
  postDataByUrl('/api/openai/upload_document/', data);
