import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, CardContent, Typography } from '@material-ui/core';

import { SignUpTooltip } from 'components/authentication/register/components';
import { getInitialValuesByType } from '../../../helpers/formInitialValues';
import { patchCandidateMainInfoData } from '../../../redux/slices/candidate';
import { CloseButton } from '../../Buttons';
import { DynamicFormWrapper } from '../../DynamicForm';
import { CardWrapper } from '../../../styled';
import useLocales from '../../../hooks/useLocales';

export const CandidatePitchForm = ({
  initialData,
  candidateId,
  onNext,
  onClose,
  candidate
}: any) => {
  const type = 'candidate-pitch';
  const { t } = useLocales();

  const [itemValues, setItemValues] = useState(
    getInitialValuesByType(type, initialData)
  );

  const setNewValues = (field: string, value: any) => {
    setItemValues({
      ...itemValues,
      [field]: value
    });
  };

  const dispatch = useDispatch();

  const handleSubmit = () => {
    dispatch(patchCandidateMainInfoData(candidateId, itemValues));
    if (onNext) onNext();
  };

  return (
    <CardWrapper>
      <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">{t('Pitch')}</Typography>
          {onClose && <CloseButton onClick={onClose} />}
        </Box>
        <SignUpTooltip title={t('pitch_tooltip')} />
        <DynamicFormWrapper
          type="candidate-pitch"
          itemValues={itemValues}
          setValues={setNewValues}
          onSubmit={handleSubmit}
          candidate={candidate}
        />
      </CardContent>
    </CardWrapper>
  );
};
