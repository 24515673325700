import { useEffect, useRef, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  FormControl,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Modal,
  TextField,
  Typography,
  Paper,
  Tooltip,
  Zoom,
  makeStyles
} from '@material-ui/core';

import ReactHtmlParser from 'react-html-parser';

import { Autocomplete, styled } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import NotInterested from '@material-ui/icons/NotInterested';
import {
  AttachFile as AttachFileIcon,
  DescriptionOutlined as DescriptionIcon,
  Clear as ClearIcon,
  Send as SendIcon,
  SaveOutlined as SaveOutlinedIcon,
  AutoAwesomeMosaicOutlined as AutoAwesomeMosaicOutlinedIcon,
  PeopleAltOutlined as PeopleAltOutlinedIcon
} from '@mui/icons-material';

import { getAllReadNotifications, getAsReadNotifications } from 'requests/Chat';
import { Title1 } from 'components/text/title';

import { fDateTime } from 'utils/formatTime';
import { getCandidateInfo } from 'redux/slices/candidate';
import { useLocation, useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';
import { useDebounce } from 'hooks/useDebounce';
import IconButton from '@material-ui/core/IconButton';
import { OverviewSearch } from 'components/OverviewFilter';
import { RootState } from '../../redux/store';
import {
  deleteRoomChange,
  forMessangerRoomMessage,
  getAllMessengerRooms,
  getUnRead,
  sendMessageFromMessanger,
  getMessengerTemplate,
  saveTemplate,
  addTemplateCount,
  getColleaguesList,
  getPrivateMessagesList,
  sendPrivateMessage,
  deletePrivateConversation,
  getCandidatesList
} from '../../redux/slices/chat';
import Page from '../../components/containers/Page';
import useLocales from '../../hooks/useLocales';
import DashboardNavbar from '../../layouts/dashboard/navbar/DashboardNavbar';
import {
  Candidate,
  ResultColleaguesList,
  ResultMessagesRoom,
  ResultPrivateMessage
} from 'types/Chat';

interface Template {
  id: number;
  label: string;
  name: string;
  content: string;
  content_plain: string;
  click_count: number;
  created_at: string;
  owner: number;
}

const ModalGrid = styled(Grid)(() => ({
  width: '100%',
  height: '100%',
  alignItems: 'center',
  display: 'flex',
  zIndex: 10,
  position: 'relative'
}));

const ModalBox = styled(Box)(() => ({
  width: '590px',
  height: '300px',
  background: '#FFFFFF',
  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.08)',
  borderRadius: '20px',
  margin: 'auto',
  alignItems: 'center',
  display: 'flex',
  position: 'relative'
}));

const ModalBoxContent = styled(Box)(() => ({
  maxWidth: '430px',
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  color: '#131417',
  fontFamily: 'Rubik',
  rowGap: '30px'
}));

const ButtonBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  columnGap: '10px'
}));

const ModalButton = styled(Button)(() => ({
  borderRadius: '30px',
  padding: '10px 0',
  width: '130px'
}));

const styledProfileMainTitle = {
  fontFamily: 'Rubik',
  fontWeight: 600,
  fontSize: '20px',
  ml: '8px',
  mb: '15px'
};

export const ReactQuillBody = styled(ReactQuill)(() => ({
  '.ql-snow': {
    background: '#FFFFFF',
    border: 0,
    padding: 0,
    width: '100%',
    borderRadius: '30px'
  },
  '.ql-snow:first-of-type': {
    border: 0,
    paddingRight: 0
  },
  '.ql-snow:last-of-type': {
    border: 0,
    width: '100%'
  },
  '.ql-editor': {
    maxHeight: '60px',
    fontSize: '16px',
    minHeight: '170px'
  },
  '.css-1e87pdh-MuiGrid-root': {
    width: '100%'
  }
}));

export const AutoCompleteStyle = {
  padding: 0,
  '& .MuiOutlinedInput-root': {
    backgroundColor: 'white',
    borderRadius: 0,
    boxShadow:
      '0 0 2px 0 rgba(145, 158, 171, 0.24), 0 20px 40px -4px rgba(145, 158, 171, 0.24)'
  },

  '& .MuiAutocomplete-endAdornment': {
    display: 'none'
  },
  '& .MuiTextField-root': {
    borderColor: 'transparent'
  }
};

export const CustomPaper = (props: any) => (
  <Paper
    style={{
      background: 'white',
      fontSize: '14px',
      boxSizing: 'border-box',
      border: '1px solid #C9CAD9',
      borderBottom: 0,
      borderRadius: 0,
      boxShadow: 'none'
    }}
    {...props}
  />
);

const IconStyle = { width: 22 };

const IconsWithHover = {
  ...IconStyle,
  color: '#C9CAD9',
  ':hover': { color: '#562C82' }
};

const useStyles = makeStyles({
  scrollBar: {
    '&::-webkit-scrollbar': {
      borderRadius: '2px',
      background: '#ffffff',
      width: '7px'
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#562C82',
      borderRadius: '10px'
    }
  }
});

export function getInitials(name: string) {
  const words = name.toUpperCase().split(' ');
  let initials = words[0][0];
  if (words.length > 1) {
    initials += words[words.length - 1][0];
  }
  return initials;
}

const Messenger = ({ messengerVariant }: { messengerVariant: string }) => {
  const {
    allMessangerRooms,
    notifications,
    forMessangerMessagesRoom,
    templates,
    colleagues,
    privateMessagesList,
    candidates
  } = useSelector((state: RootState) => state.chat);
  const classes = useStyles();
  const { user }: any = useSelector((state: RootState) => state.authJwt);
  const { messengerState }: any = useSelector((state: RootState) => state.chat);
  const [activeDialog, setActiveDialog] = useState<any>([]);
  const [allRoom, setAllRooms] = useState<any>();
  const [colleaguesList, setColleaguesList] = useState<any>();
  const [rawInput, setRawInput] = useState<string>('');
  const [valueMessage, setValueMessage] = useState<string>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [changeRoom, setChangeRoom] = useState<any>();
  const [arrayMessage, setArrayMessage] = useState<ResultMessagesRoom[]>([]);
  const [privateArrayMessage, setPrivateArrayMessage] = useState<
    ResultPrivateMessage[]
  >([]);
  const [roomState, setRoomState] = useState<any>();
  const messagesEndRef = useRef<null | HTMLDivElement>(null);
  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);
  const [showTemplates, setShowTemplates] = useState(false);
  const [showCandidatesList, setShowCandidatesList] = useState(false);
  const [active, setActive] = useState<any>(null);
  const [activeColleague, setActiveColleague] = useState<any>(null);
  const [messageArray, setMessageArray] = useState<any>([]);
  const [privateMessageArray, setPrivateMessageArray] = useState<any>([]);
  const [templateSearchInput, setTemplateSearchInput] = useState('');
  const [candidateSearchInput, setCandidateSearchInput] = useState('');
  const [colleagueSearchInput, setColleagueSearchInput] = useState('');
  const { candidate } = useSelector((state: RootState) => state.candidate);
  const location = useLocation();
  const { pathname, hash } = location;
  const isCandidatesChat = messengerVariant === 'candidates';
  const conversationsList = isCandidatesChat ? allRoom : colleaguesList;
  const messagesToShow = isCandidatesChat ? arrayMessage : privateArrayMessage;

  const fileInputRef = useRef<HTMLInputElement>(null);
  const toggleTemplatesRef = useRef<HTMLButtonElement>(null);
  const formControlRef = useRef<HTMLDivElement | null>(null);
  const toggleCandidatesRef = useRef<HTMLButtonElement>(null);
  const shareCandidateRef = useRef<HTMLDivElement | null>(null);

  const currentDomain = window.location.host;

  const { t } = useLocales();

  const debouncedValue = useDebounce(rawInput, 500);
  const debouncedCandidateSearchInput = useDebounce(candidateSearchInput, 500);
  const debouncedTemplateSearchInput = useDebounce(templateSearchInput, 500);
  const debouncedColleagueSearchInput = useDebounce(colleagueSearchInput, 500);

  const navigate = useNavigate();

  const getQueryParam = (param: string) => {
    const [, queryString] = hash.split('?');

    if (queryString) {
      const params = new URLSearchParams(queryString);
      return params.get(param);
    }
    return null;
  };

  useEffect(() => {
    const userIdParam = getQueryParam('user');
    if (userIdParam) {
      const userId = parseInt(userIdParam, 10);
      if (
        isCandidatesChat &&
        userId !== active &&
        hash.split('#')[1].split('?')[0] !== 'colleagues'
      ) {
        setActive(userId);
        messages(userId);
        readMessages(userId);
      } else if (!isCandidatesChat && userId !== activeColleague) {
        setActiveColleague(userId);
        privateMessages(user.id, userId);
        readPrivateMessages(userId);
      }
    }
  }, [location, isCandidatesChat]);

  const updateUrlWithActiveUser = (userId: any) => {
    const [hash, query] = location.hash.split('?');
    const searchParams = new URLSearchParams(query);
    searchParams.set('user', userId.toString());
    const newHash = `${hash}?${searchParams.toString()}`;
    const newRelativePathQuery = `${location.pathname}${newHash}`;
    navigate(newRelativePathQuery);
  };

  const handleTemplateSelect = (event: any, newValue: Template | null) => {
    if (newValue) {
      addTemplateCount(newValue.id);
      setRawInput((prevRawInput) => prevRawInput + newValue.content);
    }
  };

  useEffect(() => {
    if (debouncedTemplateSearchInput) {
      getMessengerTemplate(debouncedTemplateSearchInput);
    } else if (debouncedColleagueSearchInput) {
      getColleaguesList(debouncedColleagueSearchInput);
    } else {
      getCandidatesList(debouncedCandidateSearchInput);
    }
  }, [
    debouncedTemplateSearchInput,
    debouncedCandidateSearchInput,
    debouncedColleagueSearchInput
  ]);

  const handleSearch = (event: any, newInputValue: string) => {
    if (event.target.id === 'template-select') {
      setTemplateSearchInput(newInputValue);
    } else {
      setCandidateSearchInput(newInputValue);
    }
  };

  const handleCandidateSelect = (event: any, newValue: Candidate | null) => {
    if (newValue) {
      const href = `https://${currentDomain}/dashboard/candidates/current/${newValue.id}/`;
      const link = `<a href=${href} target="_blank">${href}</a>`;
      setRawInput(
        (prevRawInput) => `${prevRawInput} ${newValue.name}: ${link}`
      );
    }
  };

  const handleClickOutside = (event: any) => {
    const { target } = event;
    if (
      formControlRef.current &&
      !formControlRef.current.contains(target) &&
      !target.closest('.MuiAutocomplete-popper') &&
      toggleTemplatesRef.current &&
      !toggleTemplatesRef.current.contains(target)
    ) {
      setShowTemplates(false);
      setTemplateSearchInput('');
    } else if (
      shareCandidateRef.current &&
      !shareCandidateRef.current.contains(target) &&
      !target.closest('.MuiAutocomplete-popper') &&
      toggleCandidatesRef.current &&
      !toggleCandidatesRef.current.contains(target)
    ) {
      setShowCandidatesList(false);
      setCandidateSearchInput('');
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setValueMessage(debouncedValue);
  }, [debouncedValue]);

  useEffect(() => {
    getMessengerTemplate('');
    getCandidatesList('');
    getColleaguesList('');
  }, []);

  useEffect(() => {
    if (debouncedColleagueSearchInput === '') getColleaguesList('');
  }, [debouncedColleagueSearchInput]);

  useEffect(() => {
    if (debouncedTemplateSearchInput === '') getMessengerTemplate('');
  }, [debouncedTemplateSearchInput]);

  const saveToTemplates = (valueMessage: any) => {
    if (
      !templates?.some(
        (t) => t.content.toLowerCase() === valueMessage.toLowerCase()
      )
    ) {
      saveTemplate({
        content: valueMessage
      });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [arrayMessage, privateArrayMessage]);

  useEffect(() => {
    setArrayMessage(forMessangerMessagesRoom.results);
  }, [forMessangerMessagesRoom]);

  useEffect(() => {
    setPrivateArrayMessage(privateMessagesList.results);
  }, [privateMessagesList]);

  useEffect(() => {
    if (candidate?.mainInfo?.messenger_room_id) {
      setChangeRoom(candidate.mainInfo.messenger_room_id);
    }
  }, [candidate]);

  const SendMessage = async () => {
    if (user) {
      if (
        valueMessage &&
        valueMessage.replace(/<[^>]*>?/gm, '').trim() !== '' &&
        valueMessage.replace(/<[^>]*>?/gm, '') !== ('<br>' || '<br/>')
      ) {
        const formData = new FormData();

        if (selectedFile) {
          if (isCandidatesChat) {
            formData.append('room', changeRoom.toString());
            formData.append('content', valueMessage || '');
            formData.append('upload', selectedFile);
          } else {
            formData.append('content', valueMessage || '');
            formData.append('receiver', activeColleague);
            formData.append('upload', selectedFile);
          }
        }
        const dataWithoutFile = isCandidatesChat
          ? { room: changeRoom, content: valueMessage }
          : { receiver: activeColleague, content: valueMessage };

        const data = selectedFile ? formData : dataWithoutFile;

        if (isCandidatesChat) {
          sendMessageFromMessanger(data, changeRoom, user.role);
        } else {
          sendPrivateMessage(data, user.id, activeColleague);
        }

        setRawInput('');
        setValueMessage('');
        setSelectedFile(undefined);
      }

      scrollToBottom();
    }
  };

  // useEffect(() => {
  //   if (isCandidatesChat && changeRoom) {
  //     forMessangerRoomMessage(changeRoom);
  //   }
  // }, [changeRoom, isCandidatesChat]);

  useEffect(() => {
    if (allMessangerRooms?.results) setAllRooms(allMessangerRooms?.results);
    if (colleagues?.results)
      setColleaguesList(
        colleagues?.results.filter(
          (colleague: ResultColleaguesList) => colleague.id !== user.id
        )
      );
    if (allMessangerRooms?.results?.length && !getQueryParam('user')) {
      const conversationId = allMessangerRooms?.results[0]?.id;
      setActive(conversationId);
      messages(conversationId);
      readMessages(conversationId);
    }
    if (colleagues?.results?.length && !getQueryParam('user')) {
      const conversationId = colleagues?.results[0]?.id;
      setActiveColleague(conversationId);
      privateMessages(user.id, conversationId);
      readPrivateMessages(conversationId);
    }
  }, [allMessangerRooms, colleagues]);

  const readMessages = (id: any) => {
    setActiveDialog(activeDialog.filter((el: any) => el !== id));
    messageArray.forEach((el: any) =>
      +el.splitValue === id ? getAsReadNotifications(el.idValue) : null
    );
  };

  const readPrivateMessages = (id: any) => {
    setActiveDialog(activeDialog.filter((el: any) => el !== id));
    privateMessageArray.forEach((el: any) =>
      +el.splitValue === id ? getAsReadNotifications(el.idValue) : null
    );
  };

  const messages = (room: number) => {
    if (isCandidatesChat) {
      setRoomState(room);
      setChangeRoom(room);
      forMessangerRoomMessage(room);
    }
  };

  const privateMessages = (userId: number, colleagueId: number) => {
    getPrivateMessagesList(userId, colleagueId);
  };

  const deleteChat = (itemId: number) => {
    if (isCandidatesChat) {
      setAllRooms((prev: any[]) => prev.filter((el) => el.id !== itemId));
      deleteRoomChange(itemId);
    } else {
      deletePrivateConversation(user.id, itemId);
    }
    setShowModal(false);
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    let interval: any = '';
    if (pathname.includes('messenger')) {
      if (messengerState && roomState && isCandidatesChat) {
        interval = setInterval(() => {
          forMessangerRoomMessage(roomState);
        }, 10000);
      }
    }
    return () => {
      clearInterval(interval);
    };
  }, [messengerState, pathname, roomState, isCandidatesChat]);

  useEffect(() => {
    if (isCandidatesChat) {
      getAllMessengerRooms();
    } else getColleaguesList('');
  }, [isCandidatesChat]);

  useEffect(() => {
    scrollToBottom();
  }, [arrayMessage]);

  useEffect(() => {
    let array = [];
    let code = [];
    if (notifications !== undefined && notifications?.length) {
      array = notifications?.map(
        (el: any) => el.description.split('chatroom=')[1]
      );
      code = notifications.map((el: any) => ({
        splitValue: el.description.split('chatroom=')[1],
        idValue: el.id
      }));
    }
    setActiveDialog(array);
    setMessageArray(code);
    setPrivateMessageArray(code);
  }, [notifications]);

  const getCount = (id: any) => {
    const count = activeDialog?.filter((item: any) => item == id);
    return count ? count.length : '0';
  };

  const dispatch = useDispatch();
  useEffect(() => {
    if (user?.role !== 'candidate') getUnRead();
    if (user?.candidate_id) {
      dispatch(getCandidateInfo(user.candidate_id));
    }
    if (user.role === 'candidate') {
      getAllReadNotifications();
    }
  }, [user]);

  const validateFile = (file: File) => {
    const maxSize = 5 * 1024 * 1024;
    if (file.size > maxSize) {
      alert(t('File is too large. Max size is 1MB.'));
      return false;
    }
    const validTypes = [
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    ];
    if (!validTypes.includes(file.type)) {
      alert(t('Invalid file type. Only PDF, DOC, and DOCX are allowed.'));
      return false;
    }
    return true;
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      if (validateFile(file)) {
        setSelectedFile(file);
      }
    }
    event.target.value = '';
  };

  const extractFileName = (url: string) => {
    const messengerIndex = url.lastIndexOf('messenger/');
    if (messengerIndex === -1) return '';

    const [fileNameWithExtension] = url
      .substring(messengerIndex + 'messenger/'.length)
      .split(/[?#]/);

    return fileNameWithExtension;
  };

  /* This was added solely for the purpose of controlling link colors,
    but can be used to further style certain elements if needed. */

  const renderMessageContent = (message: any) => {
    const isSentByCurrentUser = user.id === message.sender.id;
    const linkColor = isSentByCurrentUser
      ? 'rgba(255,255,255)'
      : 'rgba(19,20,23)';
    const styledMessageContent = message.content.replace(
      /<a /g,
      `<a style="color: ${linkColor}" `
    );
    return ReactHtmlParser(styledMessageContent);
  };

  return (
    <Page title={t('Messenger')}>
      <DashboardNavbar title={t('Messenger')} />
      <Grid
        container
        sx={{
          display: 'flex',
          gap: '20px',
          flexWrap: 'nowrap',
          marginTop: '20px'
        }}
      >
        <Grid item xs={4}>
          <Typography sx={styledProfileMainTitle}>
            {isCandidatesChat ? t('Active Conversation') : t('My co-workers')}
          </Typography>
          {!isCandidatesChat && (
            <OverviewSearch
              setValue={(value: any) => setColleagueSearchInput(value)}
              value={colleagueSearchInput}
            />
          )}
          <List
            className={classes.scrollBar}
            sx={{
              maxHeight: isCandidatesChat ? '720px' : '650px',
              pr: '20px',
              overflowY: conversationsList?.length > 10 ? 'scroll' : 'auto'
            }}
          >
            {conversationsList?.map((item: any) => (
              <Grid
                sx={{
                  display: 'flex',
                  mb: '15px'
                }}
                key={item.id}
              >
                <ListItem
                  button
                  key="RemySharp"
                  onClick={() => {
                    if (isCandidatesChat) {
                      messages(item.id);
                      setActive(item.id);
                      readMessages(item.id);
                      updateUrlWithActiveUser(item.id);
                    } else {
                      setActiveColleague(item.id);
                      privateMessages(user.id, item.id);
                      readPrivateMessages(item.id);
                      updateUrlWithActiveUser(item.id);
                    }
                  }}
                  style={
                    (isCandidatesChat ? active : activeColleague) === item.id
                      ? {
                          backgroundColor: '#ffffff',
                          color: '#000000',
                          borderRadius: '20px',
                          padding: '15px'
                        }
                      : {
                          backgroundColor: 'transparent'
                        }
                  }
                >
                  <ListItemIcon>
                    <Avatar
                      alt="Remy Sharp"
                      src={
                        isCandidatesChat ? item.candidate.avatar : item.avatar
                      }
                    >
                      {item.candidate
                        ? getInitials(item.candidate.name)
                        : getInitials(`${item.first_name} ${item.last_name}`)}
                    </Avatar>
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={
                      isCandidatesChat
                        ? item.candidate.name
                        : `${item.first_name} ${item.last_name}`
                    }
                    sx={{
                      color: 'blue',
                      fontFamily: 'Rubik',
                      fontSize: 16,
                      fontWeight: 600
                    }}
                  />

                  {activeDialog?.includes(`${item.id}`) ? (
                    <Typography
                      sx={{
                        width: '26px',
                        height: '26px',
                        background: '#1C9DD7',
                        borderRadius: '50%',
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'center'
                      }}
                    >
                      {getCount(item.id)}
                    </Typography>
                  ) : (
                    <p style={{ visibility: 'hidden' }}>0</p>
                  )}
                </ListItem>
              </Grid>
            ))}
          </List>
        </Grid>

        <Grid
          item
          xs={8}
          sx={{
            mr: '64px',
            boxShadow: '0px 0px 10px rgb(0 0 0 / 20%)',
            mb: '70px',
            height: 'fit-content',
            background:
              'linear-gradient(rgba(255, 255, 255, 0.8) -5.21%, rgba(255, 255, 255, 0.3) 114.12%)',
            borderRadius: '30px'
          }}
        >
          {(isCandidatesChat ? allRoom : colleaguesList)?.map((item: any) =>
            (isCandidatesChat ? active : activeColleague) === item.id ? (
              <div key={item.id}>
                <Modal
                  open={showModal}
                  onClose={() => {
                    setShowModal(false);
                  }}
                >
                  <ModalGrid>
                    <ModalBox>
                      <Button
                        onClick={() => setShowModal(false)}
                        style={{
                          cursor: 'pointer',
                          top: '40px',
                          width: '20px',
                          height: '0px',
                          position: 'absolute',
                          right: '10px',
                          backgroundColor: 'transparent'
                        }}
                      >
                        <img src="/static/img/icons/remove.svg" />
                      </Button>
                      <ModalBoxContent>
                        <Title1>
                          {t(' Are you sure you want to delete conversation?')}
                        </Title1>
                        <ButtonBox>
                          <ModalButton
                            variant="outlined"
                            onClick={() => setShowModal(false)}
                          >
                            {t('Back')}
                          </ModalButton>
                          <ModalButton
                            variant="contained"
                            onClick={() => deleteChat(item.id)}
                          >
                            {t('Yes')}
                          </ModalButton>
                        </ButtonBox>
                      </ModalBoxContent>
                    </ModalBox>
                  </ModalGrid>
                </Modal>
                <Grid
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    marginTop: '30px',
                    marginBottom: '15px',
                    marginRight: '30px',
                    cursor: 'pointer'
                  }}
                >
                  <NotInterested
                    sx={{ fontSize: '1.3 rem', color: 'red', mr: '5.5px' }}
                  />
                  <Typography
                    key={item.id}
                    style={{
                      color: 'red',
                      fontSize: '16px',
                      cursor: 'pointer'
                    }}
                    onClick={() => setShowModal(true)}
                  >
                    {t('Delete Conversation')}
                  </Typography>
                </Grid>
              </div>
            ) : (
              <Typography key={item.id} sx={{ display: 'none' }}>
                .
              </Typography>
            )
          )}
          <List id="custom_chat" className="custom_disabled_scroll">
            <>
              {messagesToShow
                ?.slice(0)
                .reverse()
                .map((message) => (
                  <ListItem key={message.id}>
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        style={
                          user.id === message.sender.id
                            ? {
                                display: 'flex',
                                justifyContent: 'flex-end'
                              }
                            : {
                                display: 'flex',
                                justifyContent: 'flex-start'
                              }
                        }
                      >
                        <List
                          style={
                            user.id === message.sender.id
                              ? {
                                  display: 'flex',
                                  gap: '25px',
                                  flexDirection: 'row-reverse',
                                  marginRight: '14px'
                                }
                              : {
                                  display: 'flex',
                                  gap: '25px',
                                  flexDirection: 'row',
                                  marginLeft: '14px'
                                }
                          }
                        >
                          <ListItemIcon
                            sx={{
                              margin: 0,
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              rowGap: '10px'
                            }}
                          >
                            <Typography>{message.sender.name}</Typography>
                            <Avatar
                              src={message.sender.avatar}
                              sx={{ height: '50px', width: '50px' }}
                            >
                              {getInitials(message.sender.name)}
                            </Avatar>
                          </ListItemIcon>

                          <ListItemText
                            primary={renderMessageContent(message)}
                            primaryTypographyProps={{
                              sx: {
                                mb: '12px',
                                textAlign: 'start',
                                wordBreak: 'break-word'
                              }
                            }}
                            secondaryTypographyProps={
                              user.id === message.sender.id
                                ? {
                                    sx: {
                                      textAlign: 'end',
                                      color: 'rgba(255, 255, 255, 0.6)'
                                    }
                                  }
                                : {
                                    sx: {
                                      textAlign: 'start',
                                      color: '#C9CAD9'
                                    }
                                  }
                            }
                            secondary={
                              <Box
                                display="flex"
                                flexDirection="column"
                                alignItems="start"
                              >
                                {message.upload && (
                                  <Box
                                    display="flex"
                                    flexDirection="column"
                                    alignItems="start"
                                    textAlign="left"
                                    mb="12px"
                                  >
                                    <Box
                                      display="flex"
                                      alignItems="center"
                                      height={24}
                                      my="12px"
                                    >
                                      <DescriptionIcon
                                        sx={{
                                          color:
                                            message.sender.id === user.id
                                              ? 'white'
                                              : '#131417'
                                        }}
                                      />
                                      <Button>
                                        <a
                                          href={message.upload}
                                          download
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          style={{
                                            textDecoration: 'none',
                                            color: 'inherit'
                                          }}
                                        >
                                          <Typography
                                            color={
                                              message.sender.id === user.id
                                                ? 'white'
                                                : '#131417'
                                            }
                                          >
                                            {t('Download')}
                                          </Typography>
                                        </a>
                                      </Button>
                                    </Box>
                                    <Typography
                                      variant="body2"
                                      sx={{
                                        wordBreak: 'break-word',
                                        width: 'auto'
                                      }}
                                    >
                                      {extractFileName(message.upload)}
                                    </Typography>
                                  </Box>
                                )}
                                {fDateTime(message.created_at)}
                              </Box>
                            }
                            style={
                              user.id === message.sender.id
                                ? {
                                    flex: 'unset',
                                    padding: '20px',
                                    color: '#fff',
                                    backgroundColor: '#562C82',
                                    borderRadius: '10px',
                                    maxWidth: '439px',
                                    width: 'max-content',
                                    justifyContent: 'flex-end'
                                  }
                                : {
                                    flex: 'unset',
                                    padding: '20px',
                                    textAlign: 'start',
                                    color: '#131417',
                                    backgroundColor: '#EBEBF1',
                                    borderRadius: '10px',
                                    maxWidth: '439px',
                                    width: 'max-content'
                                  }
                            }
                          />
                        </List>
                      </Grid>
                    </Grid>
                  </ListItem>
                ))}
            </>

            <div ref={messagesEndRef} />
          </List>

          <Grid
            sx={{
              backgroundColor: 'white',
              borderRadius: '30px',
              padding: 0,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-end',
              width: '96%',
              marginLeft: '2%',
              marginBottom: '20px',
              margin: 2
            }}
          >
            <Grid position="relative">
              <ReactQuillBody
                placeholder={t('Type a message...')}
                value={rawInput}
                onChange={setRawInput}
                modules={{
                  toolbar: [
                    [{ font: [] }],
                    [{ size: ['small', false, 'large', 'huge'] }],
                    ['bold', 'italic', 'underline'],
                    [{ list: 'ordered' }, { list: 'bullet' }],
                    [{ color: [] }, { background: [] }],
                    ['clean']
                  ]
                }}
              />

              <input
                ref={fileInputRef}
                type="file"
                accept=".pdf, .doc, .docx"
                onChange={handleFileUpload}
                style={{ display: 'none' }}
              />
            </Grid>
            <Grid
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                alignItems: 'center',
                marginRight: '10px',
                paddingBottom: {
                  xs: '35px',
                  sm: '20px',
                  md: '35px',
                  lg: '25px'
                }
              }}
            >
              <Box
                sx={{
                  position: 'relative',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '5px',
                  paddingBottom: { xs: '10px', md: 0 }
                }}
              >
                {showTemplates && (
                  <FormControl
                    ref={formControlRef}
                    sx={{
                      position: 'absolute',
                      top: '-58px',
                      right: 10,
                      minWidth: '362px',
                      maxWidth: '362px'
                    }}
                  >
                    <Autocomplete
                      options={templates}
                      getOptionLabel={(option) => option.label}
                      open
                      id="template-select"
                      onInputChange={handleSearch}
                      onChange={handleTemplateSelect}
                      PaperComponent={CustomPaper}
                      sx={AutoCompleteStyle}
                      renderInput={(params) => (
                        <TextField {...params} placeholder="Search Templates" />
                      )}
                      renderOption={(props, option) => (
                        <li {...props}>{option.label}</li>
                      )}
                    />
                  </FormControl>
                )}

                {showCandidatesList && (
                  <FormControl
                    ref={shareCandidateRef}
                    sx={{
                      position: 'absolute',
                      top: '-58px',
                      right: 40,
                      minWidth: '362px',
                      maxWidth: '362px'
                    }}
                  >
                    <Autocomplete
                      options={candidates}
                      getOptionLabel={(option) => option.name}
                      open
                      id="candidate-select"
                      onInputChange={handleSearch}
                      onChange={handleCandidateSelect}
                      PaperComponent={CustomPaper}
                      sx={AutoCompleteStyle}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Search Candidates"
                        />
                      )}
                      renderOption={(props, option) => (
                        <li {...props}>
                          <Avatar
                            sx={{ width: '36px', height: '36px', mr: '10px' }}
                            alt="Candidate avatar"
                            src={option.avatar}
                          >
                            {getInitials(option.name)}
                          </Avatar>
                          {option.name}
                        </li>
                      )}
                    />
                  </FormControl>
                )}

                {!isCandidatesChat && (
                  <Tooltip
                    arrow
                    placement="top"
                    TransitionComponent={Zoom}
                    title={t('Share Candidates URL')}
                  >
                    <IconButton
                      ref={toggleCandidatesRef}
                      onClick={() => {
                        setShowCandidatesList(!showCandidatesList);
                      }}
                      sx={{ padding: 0 }}
                    >
                      <PeopleAltOutlinedIcon sx={IconsWithHover} />
                    </IconButton>
                  </Tooltip>
                )}
                <Box
                  sx={{
                    ':hover': {
                      '& #file-icon': {
                        display: 'none'
                      },
                      '& #clear': {
                        display: 'flex',
                        justifyContent: 'start'
                      },
                      '& #clear-icon': {
                        display: 'flex'
                      }
                    }
                  }}
                >
                  <Box display="flex" alignItems="center" position="relative">
                    {!selectedFile ? (
                      <Tooltip
                        arrow
                        placement="top"
                        TransitionComponent={Zoom}
                        title={t('Attach File')}
                      >
                        <IconButton
                          onClick={() => {
                            if (fileInputRef.current) {
                              fileInputRef.current.click();
                            }
                          }}
                          sx={{ padding: 0 }}
                        >
                          <AttachFileIcon sx={IconsWithHover} />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip
                        arrow
                        placement="top"
                        TransitionComponent={Zoom}
                        title={selectedFile.name}
                      >
                        <Box width={22} height={22} mb="2px">
                          <DescriptionIcon
                            id="file-icon"
                            sx={{ ...IconStyle, color: '#C9CAD9' }}
                          />

                          <Button
                            id="clear"
                            onClick={() => setSelectedFile(undefined)}
                            sx={{
                              position: 'absolute',
                              display: 'none',
                              padding: 0,
                              ':hover': { backgroundColor: 'transparent' }
                            }}
                          >
                            <ClearIcon
                              id="clear-icon"
                              sx={{
                                ...IconStyle,
                                color: 'red',
                                display: 'none'
                              }}
                            />
                          </Button>
                        </Box>
                      </Tooltip>
                    )}
                  </Box>
                </Box>
                <Tooltip
                  arrow
                  placement="top"
                  TransitionComponent={Zoom}
                  title={t('Insert Template')}
                >
                  <IconButton
                    ref={toggleTemplatesRef}
                    onClick={() => {
                      setShowTemplates(!showTemplates);
                    }}
                    sx={{ padding: 0 }}
                  >
                    <AutoAwesomeMosaicOutlinedIcon sx={IconsWithHover} />
                  </IconButton>
                </Tooltip>
                <Tooltip
                  arrow
                  placement="top"
                  TransitionComponent={Zoom}
                  title={t('Save Template')}
                >
                  <IconButton
                    onClick={() => saveToTemplates(valueMessage)}
                    sx={{ padding: 0, marginRight: { xs: 0, md: '10px' } }}
                  >
                    <SaveOutlinedIcon sx={IconsWithHover} />
                  </IconButton>
                </Tooltip>
              </Box>
              <IconButton
                onClick={() => SendMessage()}
                style={{
                  width: '35px',
                  height: '35px',
                  borderRadius: 60,
                  background:
                    'linear-gradient(92.25deg, #9B5FDA -2.57%, #562C82 113.38%)'
                }}
              >
                <SendIcon
                  sx={{ color: 'white', width: '18px', paddingLeft: '2px' }}
                />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Page>
  );
};
export default Messenger;
