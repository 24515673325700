import {
  deleteDataByUrl,
  getDataByUrl,
  postDataByUrl,
  updateDataByUrl
} from './index';

export const getTemplates = (params: any) =>
  getDataByUrl(`/api/templates/`, params);
export const postTemplate = async (data: any) =>
  postDataByUrl(`/api/templates/`, data);
export const postTemplateCount = async (id: number) =>
  postDataByUrl(`/api/templates/${id}/add_count/`);
export const deleteTemplate = async (id: any) =>
  deleteDataByUrl(`/api/templates/${id}/`);
export const patchTemplate = (id: any, data: any) =>
  updateDataByUrl(`/api/templates/${id}/`, data);
