import { styled } from '@mui/material';
import { Typography } from '@material-ui/core';

interface IHeadContainer {
  title?: string;
}

const styleHeader = {
  fontFamily: 'Rubik',
  fontSize: '40px',
  color: '#131417'
};

const ToolbarStyleTitle = styled(Typography)(() => ({
  '@media (max-width: 1200px)': {
    display: 'flex',
    justifyContent: 'center',
    fontSize: '30px !important',
    textAlign: 'center'
  }
}));

const HeadContainer = ({ title }: IHeadContainer) => (
  <>
    {title ? (
      <ToolbarStyleTitle variant="h1" style={styleHeader}>
        {title}
      </ToolbarStyleTitle>
    ) : null}
  </>
);
export default HeadContainer;
