import { useState } from 'react';
import { CandidatePitchForm } from 'components/forms/account/CandidatePitchForm';
import { getCardValuesByType } from 'helpers/cardValues';
import CandidateCardContainer from 'components/containers/CandidateCardContainer';
import CandidateModalContainer from 'components/containers/CandidateModalContainer';
import useLocales from 'hooks/useLocales';
import { CandidateType } from 'types/Candidate';
import { Description3, TitlePrimary1 } from 'components/text/title';
import { getPlaceholders } from 'constants/text/placeholders';
import { Link } from '@mui/material';
import Markdown from 'components/Markdown';

interface CandidateInfoProps {
  candidateId: string | number;
  mainInfo: CandidateType;
  editable: boolean;
  onInit?: () => void;
  candidate?: boolean;
  withoutBox?: boolean
}

const isLink = (val: string) =>
  val.includes('http://') || val.includes('https://');

const CardField = ({ label, value }: any) => {
  const { NOT_INDICATED } = getPlaceholders();

  const getValue = (value: any) => {
    if (value?.length) {
      if (isLink(value)) {
        <Link href={value} target="blank">
          {value}
        </Link>
      }
      else {
        return value
      }
    }
    else
      return NOT_INDICATED
    return ''
  }
  return (
    <Description3
      style={{
        margin: '5px 0',
        fontFamily: 'Rubik',
        color: '#131417',
        display: 'flex',
        fontSize: '18px'
      }}
      color="GrayText"
    >
      <TitlePrimary1>{label ? `${label}:` : ''}</TitlePrimary1>
      &nbsp;
      <Markdown source={getValue(value)} />
    </Description3>
  );
};


export const CandidatePitchCard = ({
  candidateId,
  mainInfo,
  editable,
  onInit,
  candidate = false,
  withoutBox
}: CandidateInfoProps) => {
  const type = 'candidate-pitch';
  const [editModalOpen, setEditModalOpen] = useState(false);
  const handleClose = () => setEditModalOpen(false);
  const cardFields = getCardValuesByType(type, mainInfo);
  const { t } = useLocales();
  return (
    <CandidateCardContainer
      title={t('Pitch')}
      onEdit={editable ? () => setEditModalOpen(true) : null}
      withoutBox={withoutBox}
    >
      <CandidateModalContainer open={editModalOpen} onClose={handleClose}>
        <CandidatePitchForm
          initialData={mainInfo}
          candidateId={candidateId}
          onClose={handleClose}
          onNext={() => {
            onInit && onInit();
            handleClose();
          }}
          candidate={candidate}
        />
      </CandidateModalContainer>
      {cardFields.map(({ label, value }: any, i: number) => (
        <CardField key={i} label={label} value={value} />
      ))}
    </CandidateCardContainer>
  );
};
