import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { Line } from 'react-chartjs-2';
import { fDateForChart } from 'utils/formatTime';
import { getTexts } from 'constants/text/texts';
import { Title1 } from 'components/text/title';
import { Legend } from 'components/Legend';
import * as ExcelJS from 'exceljs';
import moment from 'moment';
import XLSXButton from 'components/XLSXButton';
import { OverviewFilter } from '../../components/OverviewFilter';
import { getTranslatedList } from '../../constants/constants';
import { HrDivider } from '../../styled';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { fetchJobsAnalytics } from '../../redux/slices/dataAnalytics';
import { getMonth } from '../../helpers/generateYearValues';
import { getJobsAnalytics } from '../../requests/fixtures';
import { createExcel, drawTheCeil } from '../../utils/helpers';
import useLocales from 'hooks/useLocales';

const JobsAnalytics = () => {
  const { PERIOD_TYPES_ANALYTICS } = getTranslatedList();
  const [period, setPeriod] = useState(PERIOD_TYPES_ANALYTICS[1].value);
  const { PERIODICITY } = getTexts();
  const [legendActive, setLegendActive] = useState(true);
  const dispatch = useDispatch();
  const { jobsAnalytics } = useSelector(
    (state: RootState) => state.dataAnalytics
  );
  const { t } = useLocales();

  const getData = (value: string) => {
    dispatch(fetchJobsAnalytics(value));
    setPeriod(value);
  };

  useEffect(() => {
    if (period) {
      getData(period);
    }
  }, []);

  const getColumnNames = (num: number) => {
    const columnNames = [];
    for (let j = 0; j < num / 26; j++) {
      for (let i = 1; i <= 26; i++) {
        columnNames.push(
          `${j ? String.fromCharCode(64 + j) : ''}${String.fromCharCode(
            64 + i
          )}`
        );
      }
    }
    return columnNames;
  };
  const data: any = [];
  const quarterly = ['I', 'II', 'III', 'IV'];

  const onClick = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet 1');
    const month = getMonth();

    const alphabet = getColumnNames(180);
    const res = await getJobsAnalytics(period);
    const weekValues: number[] = [];
    if (period === 'weekly') {
      data[2] = ['', ...month.sorted.map((el: any) => el.reverce)];

      const weekLen = Math.ceil(month.sorted.length / 4);
      for (let i = 0; i < 4; i++) {
        const cell = worksheet.getCell(
          `${alphabet[i * weekLen + 1]}1:${alphabet[i * weekLen + weekLen]}1`
        );
        cell.value = `${quarterly[i]}-QUARTERLY`;
        cell.alignment = { horizontal: 'center' };
        worksheet.mergeCells(
          `${alphabet[i * weekLen + 1]}1:${alphabet[i * weekLen + weekLen]}1`
        );
      }

      let countForColumn = 1;
      for (let i = 0; i < month.months.length; i++) {
        const element = month.months[i];
        countForColumn += month.weeksWithName[element].length;
        const cell = worksheet.getCell(
          `${alphabet[countForColumn - month.weeksWithName[element].length]}2:${
            alphabet[countForColumn - 1]
          }2`
        );
        cell.value = element;
        cell.alignment = { horizontal: 'center' };
        worksheet.mergeCells(
          `${alphabet[countForColumn - month.weeksWithName[element].length]}2:${
            alphabet[countForColumn - 1]
          }2`
        );
      }

      for (let i = 0; i < res.labels.length; i++) {
        const a = moment(new Date(res.labels[i] * 1000));
        weekValues.push(a.week());
      }

      const firstRowValues: any = ['Number of job posts in a specific period'];

      for (let i = 0; i < weekValues.length; i++) {
        for (let j = 0; j < month.sorted.length; j++) {
          let val;
          if (month.sorted[j].right == weekValues[i]) {
            val = res.data[i];
          } else {
            val = '';
          }
          if (firstRowValues[j + 1] == undefined) {
            firstRowValues.push(val);
          } else if (!firstRowValues[j + 1] && firstRowValues[j + 1] !== 0) {
            firstRowValues[j + 1] = val;
          }
        }
      }
      data.push(firstRowValues);
    } else if (period === 'monthly') {
      const romanLetters = res.labels.map((element: number) =>
        new Date(element * 1000).toLocaleString('default', { month: 'short' })
      );
      drawTheCeil(romanLetters, worksheet, 1);
      drawTheCeil(res.data, worksheet, 2);

      const cell = worksheet.getCell(2, 1);
      cell.value = 'Number of job posts in a specific period';
    } else {
      const romanLetters = ['I', 'II', 'III'];
      drawTheCeil(romanLetters, worksheet, 1);
      drawTheCeil(res.data, worksheet, 2);

      const cell = worksheet.getCell(2, 1);
      cell.value = 'Number of job posts in a specific period';
    }

    worksheet.addRows(data);
    data.length = 0;
    const firstCell = worksheet.getCell(
      `A1:${period === 'weekly' ? 'A3' : 'A1'}`
    );
    firstCell.value = 'Job Posts Name';
    firstCell.alignment = { vertical: 'middle' };
    worksheet.getColumn('A').width = 45;
    worksheet.mergeCells(`A1:${period === 'weekly' ? 'A3' : 'A1'}`);
    await createExcel(workbook);
  };

  return (
    <Grid
      style={{
        position: 'relative'
      }}
    >
      <XLSXButton
        onClick={onClick}
        available={jobsAnalytics?.data?.some((el: number) => el !== 0)}
      />
      <Grid item xs={12}>
        <Title1 marginBottom="30px">
          {t("Number of jobposts in a specific period")}
        </Title1>
        <HrDivider />
      </Grid>
      <Grid xs={6} pb={3} marginTop="30px">
        <OverviewFilter
          title={PERIODICITY}
          activeOption={period}
          options={PERIOD_TYPES_ANALYTICS}
          setOption={(value) => getData(value)}
          style={{ display: 'flex', flexDirection: 'column', rowGap: '10px' }}
        />
      </Grid>
      <Grid>
        <Line
          options={{
            responsive: true,
            scales: {
              x: {
                grid: {
                  display: false
                }
              },
              y: {
                grid: {
                  display: false
                }
              }
            },
            plugins: {
              datalabels: {
                display: false
              },
              legend: {
                display: false
              },
              tooltip: {
                callbacks: {
                  title: (data: any) => {
                    const { formattedValue } = data[0];
                    return formattedValue;
                  },
                  label: () => '',
                  footer: (data: any) => {
                    const date = jobsAnalytics?.labels[data[0].dataIndex];
                    return (
                      date &&
                      fDateForChart(jobsAnalytics?.labels[data[0].dataIndex])
                    );
                  }
                },
                titleMarginBottom: 0,
                footerColor: 'rgba(123, 72, 178, 0.52)',
                titleFont: {
                  size: 20
                },
                footerFont: {
                  size: 10
                },
                padding: {
                  left: 20,
                  top: 10,
                  bottom: 10,
                  right: 20
                },
                titleColor: '#7B48B2',
                backgroundColor: '#fff',
                borderWidth: 1,
                borderColor: 'rgba(128,128,128,0.2)',
                cornerRadius: 10,
                caretSize: 0
              }
            }
          }}
          height={200}
          data={{
            labels:
              jobsAnalytics?.labels?.map((el: any) =>
                fDateForChart(el * 1000)
              ) || [],
            datasets: [
              {
                data: jobsAnalytics?.data || [],
                label: 'Legend 1',
                fill: true,
                borderColor: legendActive
                  ? 'rgba(155,95,218,0.6)'
                  : '#ffffff00',
                borderWidth: 1,
                backgroundColor: (context: any) => {
                  const { chart } = context;
                  const { ctx } = chart;
                  const gradient = ctx.createLinearGradient(0, 100, 0, 300);
                  gradient.addColorStop(0, '#9B5FDA');
                  gradient.addColorStop(1, '#9B5FDA30');
                  return legendActive ? gradient : '#ffffff00';
                },
                pointBackgroundColor: (context: any) => {
                  const { chart } = context;
                  const { ctx } = chart;
                  const gradient = ctx.createLinearGradient(500, 500, 200, 200);
                  gradient.addColorStop(0, '#9B5FDA');
                  gradient.addColorStop(0.52, '#562C82');
                  return legendActive ? gradient : 'ffffff00';
                },
                pointBorderColor: legendActive ? '#ffffff' : '#ffffff00',
                pointBorderWidth: legendActive ? 2 : 0,
                pointRadius: legendActive ? 5 : 0
              }
            ]
          }}
        />
        <Legend
          setLegendActive={setLegendActive}
          legendActive={legendActive}
          title="Legend 1"
        />
      </Grid>
    </Grid>
  );
};

export default JobsAnalytics;
