import { useState } from 'react';
import { Button, Typography } from '@material-ui/core';

import { DeleteDialog } from './Dialog';
import { getIcon } from '../utils/getIcon';
import useLocales from '../hooks/useLocales';
import { Icon } from '@iconify/react';
import editIcon from '@iconify/icons-ant-design/edit';
import garbageIcon from '@iconify/icons-ant-design/delete';
import { Add } from '@material-ui/icons';

export const EditButton = ({ long = false, ...props }: any) => {
  const { t } = useLocales();
  return (
    <Button
      sx={{
        minWidth: '40px',
        height: '40px',
        padding: '6px',
        color: 'green'
      }}
      {...props}
    >
      {long && <Typography sx={{ mr: 1 }}>{t('Edit')}</Typography>}
      <Icon
        icon={editIcon}
        color="#1C9DD7"
        style={{ width: '25px', height: '25px' }}
      />
    </Button>
  );
};

export const DeleteButton = (props: any) => {
  const [open, setOpen] = useState(false);
  const handleDelete = () => {
    props.onClick();
    setOpen(false);
  };

  return (
    <>
      <DeleteDialog
        open={open}
        title={props.dialogtext}
        onClose={() => setOpen(false)}
        onDelete={handleDelete}
      />
      <Button
        sx={{
          minWidth: '25px',
          width: '40px',
          height: '40px',
          padding: '6px'
        }}
        {...props}
        onClick={(e) => {
          e.preventDefault();
          setOpen(true);
        }}
        color="error"
      >
        <>
          <Icon
            icon={garbageIcon}
            color="#FF6868"
            style={{ minWidth: '25px', minHeight: '25px' }}
          />
          {props.buttontext ?? null}
        </>
      </Button>
    </>
  );
};

export const CloseButton = (props: any) => (
  <Button
    sx={{
      minWidth: '25px',
      width: '40px',
      height: '40px',
      padding: '6px'
    }}
    {...props}
  >
    {getIcon('controls/close')}
  </Button>
);

export const InfoButton = (props: any) => (
  <Button
    sx={{
      minWidth: '25px',
      width: '28px',
      height: '28px',
      padding: '6px'
    }}
    {...props}
  >
    {getIcon('information', '28px')}
  </Button>
);

export const ResetButton = (props: any) => (
  <Button
    sx={{
      minWidth: '25px',
      width: '40px',
      height: '40px',
      padding: '6px'
    }}
    {...props}
  >
    {getIcon('reset')}
  </Button>
);

export const UploadButton = (props: any) => (
  <Button
    sx={{
      minWidth: '25px',
      width: '40px',
      height: '40px',
      padding: '6px'
    }}
    {...props}
  >
    <Add />
  </Button>
);
