import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { categorySelect } from '../../../../constants/constants';

const IntegrationSelect = ({ label, setCategory, category }: any) => {

  const handleChange = (event: any) => {
    setCategory(event.target.value);
  };

  return (
     <FormControl fullWidth>
       <InputLabel id="category">{label}</InputLabel>
       <Select
          labelId="category"
          value={category}
          label="category"
          onChange={handleChange}
       >
         {categorySelect.map((item: any, index: number) =>
            <MenuItem value={item.value} key={index}>{item.name}</MenuItem>
         )}
       </Select>
     </FormControl>
  );
};

export default IntegrationSelect;