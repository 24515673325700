import {
  Alert,
  InputLabel,
  List,
  Typography,
  Grid,
  Card,
  FormControl,
  Input,
  FormControlLabel,
  Checkbox,
  TextField
} from '@material-ui/core';
import { Box, Button } from '@mui/material';
import { getButtons } from 'constants/text/buttons';
import { prepareFormData } from 'helpers/formFields';
import useLocales from 'hooks/useLocales';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateCompanyInfo,
  updateCustomTexts
} from 'redux/slices/generalRecruiter';
import {
  GridPickerStyle,
  ModuleCmsItem,
  ReactQuillBody,
  SwitchBox,
  styleActive,
  styleCard,
  useCmsStyles
} from 'styled';
import SvgIconStyle from './SvgIconStyle';
import { Clear } from '@material-ui/icons';
import { ButtonPrimary } from './new-designer/button/ButtonPrimary';
import { Description1 } from './text/title';
import ImageUploader from './ImageUploader';
import { RootState } from 'redux/store';
import { getCmsBaseInfo, updateCmsBaseInfo } from 'redux/slices/cms';
import { useSnackbar } from 'notistack';
import { Color, ColorPicker, createColor } from 'material-ui-color';
import { enqueueSnackbar } from 'redux/slices/notifications';

export interface CustomTextObject {
  format: string;
  id: number;
  name: string;
  text: string;
}

const ColorPickers = ({ initialValues }: any) => {
  const [values, setValues] = useState({
    primary_color: initialValues.primary_color || '#000000',
    secondary_color: initialValues.secondary_color || '#808080'
  });
  let colorTime: NodeJS.Timeout;
  const { t } = useLocales();
  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();
  const isColor = (strColor: string) => {
    const s = new Option().style;
    s.color = strColor;
    return s.color !== '';
  };

  const handleSetValues = (field: string, value: any) => {
    const newValue = value?.css?.backgroundColor || value;
    const data = {
      ...values,
      [field]: newValue
    };
    setValues(data);
    if (colorTime) {
      clearTimeout(colorTime);
    }
    colorTime = setTimeout(() => {
      if (isColor(newValue)) {
        handleSubmit(data);
        enqueueSnackbar('Color updated successfully', { variant: 'success' });
      } else enqueueSnackbar('Incorrect color type', { variant: 'error' });
    }, 500);
  };

  const handleSubmit = (value: any) => {
    const data = prepareFormData(value);
    dispatch(updateCompanyInfo(initialValues.id, data));
    dispatch(getCmsBaseInfo());
  };

  return (
    <Card sx={{ p: 3 }} style={styleCard}>
      <Grid container>
        <Grid
          style={{ rowGap: '20px', display: 'flex', flexDirection: 'column' }}
        >
          <Typography
            sx={{ m: 1 }}
            style={{
              fontFamily: 'Rubik',
              fontSize: '20px',
              fontWeight: 700
            }}
          >
            {t('Site Colours')}
          </Typography>
          <GridPickerStyle>
            <ColorPicker
              disableAlpha={true}
              value={createColor(values.primary_color)}
              onChange={(color: Color) =>
                handleSetValues('primary_color', color)
              }
            />
          </GridPickerStyle>
          <GridPickerStyle>
            <ColorPicker
              disableAlpha={true}
              value={createColor(values.secondary_color)}
              onChange={(color: Color) =>
                handleSetValues('secondary_color', color)
              }
            />
          </GridPickerStyle>
        </Grid>
      </Grid>
    </Card>
  );
};

function ActivateCareers() {
  const { base }: any = useSelector((state: RootState) => state.cms);
  const data = { active: !base.active };
  const dispatch = useDispatch();

  return (
    <ButtonPrimary
      onClick={() => {
        dispatch(updateCmsBaseInfo(1, data));
      }}
      style={{
        width: '100%',
        marginTop: '8px',
        background:
          'linear-gradient(92.25deg, #5DA27F80 -2.57%, #5DA27F 113.38%)',
        backgroundColor: 'rgb(6 102 53) 113.38%)'
      }}
    >
      {base.active ? 'Hide Careers Site' : 'Show Career Site'}
    </ButtonPrimary>
  );
}

const StylingForm = ({ initialValues }: any) => {
  const [uploader, setUploader] = useState<any[]>([]);
  const [images, setImages] = useState({
    logo: initialValues.logo || '',
    favicon: initialValues.favicon || '',
    candidate_banner: initialValues.candidate_banner || ''
  });

  const { t } = useLocales();
  const dispatch = useDispatch();
  const { company }: any = useSelector(
    (state: RootState) => state.generalRecruiter
  );

  const setNewImage = (field: string, file: any) => {
    setImages((prevImages) => ({
      ...prevImages,
      [field]: file
    }));
    dispatch(
      enqueueSnackbar({
        messageType: 'updated',
        options: {
          variant: 'success'
        }
      })
    );
  };

  useEffect(() => {
    setUploader([
      {
        label: t('Site Logo'),
        prevValue: initialValues.logo,
        newValue: images.logo,
        setValue: (file: any) => setNewImage('logo', file)
      },
      {
        label: t('Site Icon'),
        prevValue: initialValues.favicon,
        newValue: images.favicon,
        setValue: (file: any) => setNewImage('favicon', file)
      }
    ]);

    return () => {
      setUploader([]);
    };
  }, [images]);

  const onLogoSelected = async (selectedFile: any) => {
    const data = prepareFormData({}, { logo: selectedFile });
    dispatch(updateCompanyInfo(company.id, data));
  };

  const onFaviconSelected = async (selectedFile: any) => {
    const data = prepareFormData({}, { favicon: selectedFile });
    dispatch(updateCompanyInfo(company.id, data));
  };

  const onBannerImageSelected = async (selectedFile: any) => {
    const data = prepareFormData({}, { candidate_banner: selectedFile });
    dispatch(updateCompanyInfo(company.id, data));
  };

  return (
    <Grid container style={{ padding: 0 }}>
      <Grid item xs={12} style={{ backgroundColor: 'transparent' }}>
        <Card style={{ backgroundColor: 'transparent', boxShadow: '0px 0px' }}>
          <Typography
            style={{
              fontFamily: 'Rubik',
              fontSize: '36px',
              marginBottom: '30px',
              fontWeight: 700
            }}
          >
            {t('Styling Settings For Your Career Site')}
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <ImageUploader
                onSubmit={onLogoSelected}
                label="Site Logo"
                prevValue={
                  typeof images.logo === 'string'
                    ? images.logo
                    : initialValues.logo ?? ''
                }
                setValue={(file: any) => setNewImage('logo', file)}
                imageStyle={{ height: '536px' }}
                hasTooltip
                tooltipTitle={t('site_logo_tooltip')}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <ImageUploader
                onSubmit={onFaviconSelected}
                label="Site Icon"
                prevValue={
                  typeof images.favicon === 'string'
                    ? images.favicon
                    : initialValues.favicon ?? ''
                }
                setValue={(file: any) => setNewImage('favicon', file)}
                imageStyle={{ height: '536px' }}
                hasTooltip
                tooltipTitle={t('site_icon_tooltip')}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <ImageUploader
                onSubmit={onBannerImageSelected}
                label="Profile banner"
                prevValue={
                  typeof images.candidate_banner === 'string'
                    ? images.candidate_banner
                    : initialValues.candidate_banner ?? ''
                }
                setValue={(file: any) => setNewImage('candidate_banner', file)}
                imageStyle={{ height: '536px' }}
                hasTooltip
                tooltipTitle={t('profile_banner_tooltip')}
              />
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};

const ActivationForm = ({ company }: any) => {
  const { t } = useLocales();
  const dispatch = useDispatch();
  const { ACTIVATE, DEACTIVATE, ACTIVE, INACTIVE } = getButtons();
  const isActive = company?.active;

  const stylingReady =
    company.logo &&
    company.favicon &&
    company.primary_color &&
    company.secondary_color;

  const notificationsReady = company.contact_email && company.from_email;

  const companyReady = company.name && company.industry;

  const fields = [
    {
      label: t('Company Settings'),
      value: companyReady
    },
    {
      label: t('Logo, Icon, Colors'),
      value: stylingReady
    },
    {
      label: t('Notifications Settings'),
      value: notificationsReady
    }
  ];

  const handleSubmit = () => {
    const data = prepareFormData({ active: !company?.active });
    dispatch(updateCompanyInfo(company.id, data));
  };

  return (
    <Card style={styleCard}>
      <Grid>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row'
          }}
        >
          <Typography
            sx={{ mb: 1 }}
            style={{
              fontFamily: 'Rubik',
              fontSize: '20px',
              fontWeight: 700
            }}
            gutterBottom
          >
            {t('Site Status')}
          </Typography>
          {isActive ? (
            <Typography style={styleActive}>{ACTIVE}</Typography>
          ) : (
            <Alert severity="warning">{INACTIVE}</Alert>
          )}
        </Box>
      </Grid>
      <Grid>
        <Box>
          <List>
            {fields.map(({ value, label }: any) => (
              <ModuleCmsItem key={label} style={{ borderBottom: 0 }}>
                <SwitchBox>
                  {value ? (
                    <SvgIconStyle src="/static/img/icons/ChekMark.svg" />
                  ) : (
                    <Clear />
                  )}
                </SwitchBox>
                <Typography style={{ fontFamily: 'Rubik', marginLeft: '15px' }}>
                  {label}
                </Typography>
              </ModuleCmsItem>
            ))}
          </List>
        </Box>
        <Grid style={{ width: '100%' }}>
          <ButtonPrimary style={{ width: '100%' }} onClick={handleSubmit}>
            {isActive ? DEACTIVATE : ACTIVATE}
          </ButtonPrimary>
          <ActivateCareers />
        </Grid>
      </Grid>
    </Card>
  );
};

export default function CompanyStyleSettings() {
  const { base, gallery, offers }: any = useSelector(
    (state: RootState) => state.cms
  );
  const { company }: any = useSelector(
    (state: RootState) => state.generalRecruiter
  );
  const classes = useCmsStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { enable_linkedin_signup, linkedin_key } = company;
  const [linkedinValues, setLinkedinValues] = useState({
    enable_linkedin_signup,
    linkedin_key,
    linkedin_secret: ''
  });

  const [customTexts, setCustomTexts] = useState<CustomTextObject[]>(
    company.custom_texts || []
  );

  const updateCustomTextState = (id: number, key: string, value: string) => {
    setCustomTexts((customTexts) =>
      customTexts.map((text) =>
        text.id === id ? { ...text, [key]: value } : text
      )
    );
  };

  const updateCustomText = async (CT: CustomTextObject) => {
    if (customTexts) {
      if (company?.id) {
        try {
          await dispatch(await updateCustomTexts(CT.id, CT));
          enqueueSnackbar('Updated successfully', { variant: 'success' });
        } catch (e) {
          console.error(e);
        }
      }
    }
  };

  const getCustomFieldName = (name: string) => {
    let fieldName = '';
    switch (name) {
      case 'start_test_info':
        fieldName = 'Congratulation text';
        break;
      case 'candidate_signup_message':
        fieldName = 'Sign-up message';
        break;
      default:
        fieldName = name
          .split('_')
          .map(
            (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
          )
          .join(' ');
    }
    return fieldName;
  };

  const { t } = useLocales();
  const dispatch = useDispatch();
  const setChangeValues = (key: string, value: any) => {
    setLinkedinValues({
      ...linkedinValues,
      [key]: value
    });
  };

  return (
    <Grid container style={{ marginTop: '40px' }}>
      <Grid
        xs={5}
        style={{
          rowGap: '20px',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <ActivationForm
          company={company}
          cms={base}
          hasGallery={gallery?.results.length > 0}
          hasOffers={offers?.results.length > 0}
        />
        <ColorPickers
          initialValues={{
            id: company.id,
            primary_color: company.primary_color,
            secondary_color: company.secondary_color,
            logo: company.logo,
            favicon: company.favicon
          }}
        />
        <Card style={styleCard}>
          <Grid container gap={2} direction="column">
            <Typography
              sx={{ mb: 1 }}
              style={{
                fontFamily: 'Rubik',
                fontSize: '20px',
                fontWeight: 700
              }}
              gutterBottom
            >
              {t('LinkedIn Signup Integration')}
            </Typography>
            <Description1>
              {t('You will have to create an app on your LinkedIn account and have it configured properly, please ask our account manager for detail.')}
            </Description1>
            <FormControlLabel
              control={
                <Checkbox
                  size="medium"
                  checked={linkedinValues.enable_linkedin_signup}
                />
              }
              onChange={(el: any) => {
                setChangeValues('enable_linkedin_signup', el.target.checked);
              }}
              label={t('enable linkedin signup')}
            />
            <FormControl>
              <InputLabel htmlFor="Linkedin Key">
                {t('Linkedin Key')}
              </InputLabel>
              <Input
                id="Linkedin Key"
                value={linkedinValues.linkedin_key}
                onChange={(el: any) => {
                  setChangeValues('linkedin_key', el.target.value);
                }}
              />
            </FormControl>
            <FormControl>
              <InputLabel htmlFor="Linkedin Secret">
                {t('Linkedin Secret')}
              </InputLabel>
              <Input
                id="Linkedin Secret"
                onChange={(el: any) => {
                  setChangeValues('linkedin_secret', el.target.value);
                }}
              />
            </FormControl>
            <ButtonPrimary
              onClick={async () => {
                const formData = new FormData();
                for (const key in linkedinValues) {
                  if (
                    Object.prototype.hasOwnProperty.call(linkedinValues, key)
                  ) {
                    formData.append(key, (linkedinValues as any)[key]);
                  }
                }
                try {
                  await dispatch(await updateCompanyInfo(company.id, formData));
                  dispatch(
                    enqueueSnackbar('Company data has been updated', {
                      variant: 'success'
                    })
                  );
                } catch (err) {
                  console.log(err, 'err');
                }
              }}
            >
              {t('Save')}
            </ButtonPrimary>
          </Grid>
        </Card>
      </Grid>
      <Grid xs={7}>
        <StylingForm
          initialValues={{
            id: company?.id,
            primary_color: company.primary_color,
            secondary_color: company.secondary_color,
            logo: company.logo,
            favicon: company.favicon,
            candidate_banner: company.candidate_banner
          }}
        />
        {customTexts.map((CT) => (
          <Grid key={CT.id} xs={12} mt={3}>
            <InputLabel style={{ marginBottom: '5px' }}>
              {getCustomFieldName(CT.name)}
            </InputLabel>
            {CT.format === 'html' ? (
              <ReactQuillBody
                value={CT.text}
                onChange={(newValue) =>
                  updateCustomTextState(CT.id, 'text', newValue)
                }
                modules={{
                  toolbar: [
                    [{ font: [] }],
                    [{ size: ['small', false, 'large', 'huge'] }],
                    ['bold', 'italic', 'underline'],
                    [{ list: 'ordered' }, { list: 'bullet' }],
                    [{ align: [] }],
                    [{ color: [] }, { background: [] }],
                    ['clean']
                  ]
                }}
              />
            ) : (
              <TextField
                fullWidth
                InputProps={{ classes, disableUnderline: true }}
                type="text"
                value={CT.text}
                onChange={(e) =>
                  updateCustomTextState(CT.id, 'text', e.target.value)
                }
                multiline
              />
            )}
            <Button
              variant="contained"
              onClick={() => updateCustomText(CT)}
              style={{
                marginTop: '10px',
                padding: '10px 0',
                borderRadius: '40px'
              }}
              fullWidth
            >
              {t('Save')}
            </Button>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}
