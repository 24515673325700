import { Box, Grid } from '@material-ui/core';
import { Description2 } from './text/title';

export const Legend = ({
  setLegendActive,
  legendActive,
  title,
  index,
  color = '#9B5FDA'
}: any) => {
  let active: boolean;
  if (legendActive === true || legendActive === false) {
    active = legendActive;
  } else {
    active = !legendActive?.[`${index}`];
  }

  return (
    <Grid
      style={{
        display: 'flex',
        justifyContent: 'center',
        columnGap: '10px',
        marginTop: '20px',
        cursor: 'pointer'
      }}
      onClick={() => setLegendActive(!legendActive)}
    >
      <Box
        style={{
          width: '17px',
          height: '17px',
          background: color,
          borderRadius: '2px'
        }}
      />
      <Description2
        style={{ textDecoration: active ? 'none' : 'line-through' }}
      >
        {title}
      </Description2>
    </Grid>
  );
};
