import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Accordion,
  AccordionDetails,
  Box,
  Button,
  CardActions,
  CardContent,
  LinearProgress,
  Typography
} from '@material-ui/core';

import { useTranslation } from 'react-i18next';
import { SignUpTooltip } from 'components/authentication/register/components';
import { CloseButton, DeleteButton, EditButton } from '../../Buttons';
import { DynamicForm } from '../../DynamicForm';
import {
  deleteCandidateData,
  submitCandidateData
} from '../../../requests/candidate';
import { getInitialValuesByType } from '../../../helpers/formInitialValues';
import { formValidate } from '../../../helpers/formValidation';
import { showOnRequestMessage } from '../../../helpers/showMessageOnRequest';
import { getItemTitleByType } from '../../../helpers/formTitle';
import { handleSetValues } from 'utils/helpersForms';
import { FormField } from './type';
import { enqueueSnackbar } from '../../../redux/slices/notifications';
import {
  AccordionSummaryStyles,
  AccordionTitle,
  CardWrapper
} from '../../../styled';
import { useFormFieldsByType } from '../../../helpers/formFields';

export const AccordionForm = ({
  itemId,
  title,
  fields,
  isExpanded,
  setExpanded,
  onDelete,
  onSubmit,
  isLoading,
  type = ''
}: any) => (
  <Accordion expanded={!itemId || isExpanded}>
    <AccordionSummaryStyles
      itemId={!itemId}
      expandIcon={!itemId ? null : <EditButton onClick={setExpanded} />}
    >
      <AccordionTitle>
        <Typography variant="h6" onClick={setExpanded}>
          {title}
        </Typography>
        {itemId ? <DeleteButton onClick={onDelete} /> : null}
      </AccordionTitle>
    </AccordionSummaryStyles>
    <AccordionDetails style={{ padding: 0 }}>
      <DynamicForm
        type={type}
        inputValues={fields}
        onSubmit={onSubmit}
        isLoading={isLoading}
        fullWidth
      />
    </AccordionDetails>
  </Accordion>
);

export const FormDataWrapper = ({
  index,
  candidateId,
  itemId,
  item,
  type,
  isExpanded,
  setExpanded,
  onCloseAll,
  onDelete,
  onUpdate,
  options,
  accordionTitle,
  isLoading,
  setLoading,
  onChanging,
  updateData
}: any) => {
  const [itemValues, setItemValues] = useState(item);
  const [errors, setErrors] = useState<any>({});
  const dispatch = useDispatch();

  const handleSetValue = (field: string, value: any) =>
    handleSetValues(
      setItemValues,
      itemValues,
      requiredFields,
      setErrors,
      errors,
      field,
      value
    );

  const formFields: FormField[] = useFormFieldsByType(
    type,
    itemValues,
    handleSetValue,
    options,
    errors
  );

  const requiredFields = useMemo(
    () =>
      formFields
        .filter((field: any) => !field.unRequired)
        .map((field: any) => field.name),
    [formFields]
  );

  const errorHandler = (errors: any) => {
    setErrors(errors);
    if (Object.keys(errors).length) {
      dispatch(
        enqueueSnackbar({
          messageType: 'required',
          options: { variant: 'warning' }
        })
      );
    }
  };

  const handleSubmit = async () => {
    if (formValidate(itemValues, errorHandler, requiredFields)) {
      setLoading(true);
      const requestType = itemId ? 'updated' : 'created';
      try {
        const response = await submitCandidateData(
          candidateId,
          {
            ...itemValues,
            school: itemValues.school?.id,
            study: itemValues.study?.id,
            skill: itemValues.skill?.id,
            language: itemValues.language?.id,
            industry: itemValues.industry?.id
          },
          type,
          itemId
        );
        if (response) {
          onCloseAll();
          onChanging && onChanging(true);
          onUpdate(index, response);
        }
        setLoading(false);
        showOnRequestMessage(dispatch, response, requestType);
        updateData(type, candidateId);
      } catch (err) {
        enqueueSnackbar({
          message: err,
          options: { variant: 'error' }
        });
      }
    }
  };

  const handleDelete = async () => {
    setLoading(true);
    try {
      const response = await deleteCandidateData(candidateId, itemId, type);
      showOnRequestMessage(dispatch, response, 'deleted');
      onChanging && onChanging(true);
      onCloseAll();
      onDelete(index);
      setLoading(false);
    } catch (err) {
      enqueueSnackbar({
        message: err,
        options: { variant: 'error' }
      });
    }
  };

  return (
    <AccordionForm
      type={type}
      itemId={itemId}
      title={accordionTitle || ''}
      fields={formFields}
      isExpanded={isExpanded}
      setExpanded={setExpanded}
      onDelete={handleDelete}
      onSubmit={handleSubmit}
      isLoading={isLoading}
    />
  );
};

export const CandidateFormsContainer = ({
  candidateId,
  groupTitle,
  initialDataList,
  type,
  options,
  onClose,
  onChanging,
  updateData
}: any) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [dataList, setDataList] = useState<any[]>(initialDataList || []);

  const onExpandedChange = (index: number) => {
    setExpanded(index === expanded ? 0 : index);
  };

  const handleDeleteFromList = (index: number) => {
    if (index !== undefined) {
      setDataList([...dataList.filter((el: any, i: number) => i !== index)]);
    }
  };

  const handleUpdateInList = (index: number, data: any) => {
    if (index !== undefined && data) {
      const newData = [...dataList];
      newData[index] = {
        ...dataList[index],
        ...data
      };
      setDataList(newData);
    }
  };

  const handleAddNew = () => {
    const initialValues = getInitialValuesByType(type);
    setDataList([...dataList, initialValues]);
  };

  const getTooltipName = (type: string) => {
    if (type === 'activities') {
      type = 'activity';
    } else if (type.endsWith('s')) {
      type = type.slice(0, -1);
    }
    return type.replace(/[\s-]/g, '_');
  };

  return (
    <CardWrapper>
      <SignUpTooltip title={t(`${getTooltipName(type)}_tooltip`)} />
      <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">{groupTitle}</Typography>
          {onClose && <CloseButton onClick={onClose} />}
        </Box>
        {isLoading && <LinearProgress />}
        <Box>
          {dataList.length ? (
            dataList.map((item: any, i: number) => (
              <FormDataWrapper
                updateData={updateData}
                key={i}
                index={i}
                candidateId={candidateId}
                type={type}
                itemId={item?.id || null}
                item={getInitialValuesByType(type, item)}
                isExpanded={i + 1 === expanded}
                setExpanded={() => onExpandedChange(i + 1)}
                onCloseAll={() => setExpanded(0)}
                onDelete={handleDeleteFromList}
                onUpdate={handleUpdateInList}
                options={options}
                accordionTitle={getItemTitleByType(type, item)}
                isLoading={isLoading}
                setLoading={setLoading}
                onChanging={onChanging}
              />
            ))
          ) : (
            <Typography variant="h6" color="GrayText">
              No {groupTitle} here...
            </Typography>
          )}
        </Box>
      </CardContent>
      <CardActions>
        <Button
          variant={expanded !== 0 ? 'outlined' : 'contained'}
          fullWidth
          onClick={handleAddNew}
        >
          {t('Add new')}
        </Button>
      </CardActions>
    </CardWrapper>
  );
};