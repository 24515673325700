import { useEffect, useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { Grid } from '@material-ui/core';
import FreePlanTooltip from 'views/dashboard/companySettings/freePlanTooltip';

export const StepHeader = ({ title, subTitle, activeStep }: any) => {
  const [innerHeight, setInnerHeight] = useState<number>(window.innerHeight);

  const handleResize = (): void => {
    setInnerHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <div
        style={{
          marginTop: '32px',
          color: 'var(--Black, #131417)',
          textAlign: 'center',
          fontSize: '40px',
          fontWeight: '600'
        }}
      >
        {title}
      </div>
      <div
        style={{
          fontSize: '16px',
          fontWeight: '400',
          marginBottom: '22px',
          display: 'flex',
          justifyContent: 'center',
          columnGap: '6px'
        }}
      >
        <p>{subTitle}</p>
        <Tooltip
          title={
            <FreePlanTooltip tooltip />
          }
          placement="top-start"
          componentsProps={{
            tooltip: {
              sx: {
                background: '#fff',
                color: '#000',
                borderRadius: '13.417px 13.417px 0px 13.417px',
                boxShadow:
                  '0px 5.85953px 11.71907px -1.46488px rgba(145, 158, 171, 0.24), 0px 0px 0.73244px 0px rgba(145, 158, 171, 0.24)',
                marginLeft: '-115px',
                position: 'absolute',
                bottom: activeStep > 2 && innerHeight < 1220 ? '-400px' : '-10px',
                left: '130px',
                height: '400px',
                padding: '0',
                borderTopLeftRadius: "80px 80px"
              }
            }
          }}
        >
          <Grid
            style={{
              color: '#fff',
              borderRadius: '50%',
              background: '#6A3B9C',
              width: '18px',
              height: '18px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              opacity: '0.4',
              paddingTop: '4px'
            }}
          >
            i
          </Grid>
        </Tooltip>
      </div>
    </>
  );
}