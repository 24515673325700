import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import { PATH_DASHBOARD, PATH_HOME } from '../routes/paths';
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

type GuestProtectProps = {
  children: ReactNode;
};

export default function GuestProtect({ children }: GuestProtectProps) {
  const { user, isLoading, isAuthenticated } = useAuth();

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (isAuthenticated) {
    switch (user?.role) {
      case 'recruiter':
        return <Navigate to={PATH_DASHBOARD.showcase} />;
      case 'admin':
        return <Navigate to={PATH_DASHBOARD.showcase} />;
      case 'candidate':
        return (
          <Navigate
            to={PATH_DASHBOARD.candidates.getCandidateProfileUrl('me')}
          />
        );
      case 'employee':
        return (
          <Navigate
            to={PATH_DASHBOARD.candidates.getCandidateProfileUrl('me')}
          />
        );
      default:
        return <Navigate to={PATH_HOME.home} />;
    }

    // return <Navigate to={PATH_HOME.home} />;
  }

  return <>{children}</>;
}
