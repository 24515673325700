import React, { useEffect, useState } from 'react';
import useLocales from 'hooks/useLocales';
import { Grid, TextField, Typography } from '@material-ui/core';
import { Line } from 'react-chartjs-2';
import { getAnalyticsJobsData } from 'requests/fixtures';
import {
  CategoryScale,
  Chart as ChartJS,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip
} from 'chart.js';
import * as ExcelJS from 'exceljs';
import { fDateForChart } from 'utils/formatTime';
import { getTexts } from 'constants/text/texts';
import { Title1 } from 'components/text/title';
import { Legend } from 'components/Legend';
import { initialCurrentJobPost } from './JobPost';
import {
  AutocompleteStyle,
  OverviewFilter
} from '../../components/OverviewFilter';
import { getTranslatedList } from '../../constants/constants';
import {
  appendLabels,
  generateLabels
} from '../../utils/changeLabelsForDataAnalytics';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { fetchJobPostAnalytics } from '../../redux/slices/dataAnalytics';
import XLSXButton from 'components/XLSXButton';
import { HrDivider } from '../../styled';
import { createExcel } from '../../utils/helpers';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip
);

const JobPostAnalytics = () => {
  const [legendActive, setLegendActive] = useState(true);
  const { allJobPosts } = useSelector((state: RootState) => state.job);
  const { PERIOD_TYPES_ANALYTICS } = getTranslatedList();
  const [allData, setAllData] = useState({
    existingJobPost: initialCurrentJobPost,
    period: PERIOD_TYPES_ANALYTICS[1].value
  });
  const { jobPostAnalytics } = useSelector(
    (state: RootState) => state.dataAnalytics
  );
  const dispatch = useDispatch();
  const { t } = useLocales();
  const { PERIODICITY } = getTexts();

  const getData = (data: any, value: string) => {
    dispatch(fetchJobPostAnalytics({ value, id: data?.id }));
    setAllData({ existingJobPost: data, period: value });
  };

  const setDataFromExistingPost = (data: any, value: string) => {
    getData(data?.id ? data : allData.existingJobPost, value || allData.period);
  };

  const firstFields = {
    type: 'select',
    value: allData.existingJobPost,
    label: t('You can select one of the existing job posts'),
    onChange: (e: any, el: any) => setDataFromExistingPost(el, ''),
    options: allJobPosts || [],
    helperText: t('You can select one of existing job-posts to auto fill form')
  };

  useEffect(() => {
    setDataFromExistingPost(null, allData.period);
  }, []);

  const newData: number[] = [...(jobPostAnalytics?.data || [])];

  if (!newData?.length) {
    for (let i = 0; i < 7; i++) {
      newData?.push(0);
    }
  } else if (newData?.length < 7) {
    for (let i = newData.length; i < 7; i++) {
      newData?.push(0);
    }
  }

  const onClick = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet 1');
    const res = await getAnalyticsJobsData(allData.period);
    const jobs = Object.values(res.data ?? {});
    const jobsKey = Object.keys(res.data ?? {});
    const resData = res.dt ?? [];
    let romanLetters;

    if (allData.period === 'weekly') {
      romanLetters = resData.map((_: number, index: number) =>
        String(index + 1)
      );
    } else if (allData.period === 'monthly') {
      romanLetters = resData.map((element: number) =>
        new Date(element * 1000).toLocaleString('default', { month: 'short' })
      );
    } else {
      const quarterly = ['I', 'II', 'III', 'IV'];
      romanLetters = resData.map(
        (_: number, index: number) => quarterly[index]
      );
    }

    romanLetters.forEach((letter: any, index: number) => {
      const cell = worksheet.getCell(1, index + 2);
      cell.value = letter;
      cell.alignment = { horizontal: 'center' };
    });

    jobs.forEach((element, index) => {
      const rowValues = [jobsKey[index], ...(element as any)];
      worksheet.addRow(rowValues);
    });

    const firstCell = worksheet.getCell('A1');
    firstCell.value = `Job Posts Name / ${allData.period}`;
    firstCell.alignment = { vertical: 'middle' };
    worksheet.getColumn('A').width = 45;
    worksheet.mergeCells('A1:A1');
    await createExcel(workbook);
  };

  return (
    <Grid
      container
      flexWrap="wrap"
      justifyContent="center"
      style={{
        position: 'relative'
      }}
    >
      <Grid item xs={12}>
        <Title1 marginBottom="30px">
          {t('Number of total job applications in a specific period')}
        </Title1>
        <HrDivider />
      </Grid>
      <XLSXButton
        onClick={onClick}
        available={jobPostAnalytics?.data?.some((el: number) => el !== 0)}
      />
      <Grid item xs={6} pb={3} pr={1} mt="20px">
        <Typography
          style={{
            fontFamily: 'Rubik',
            color: '#666666',
            marginBottom: '6px'
          }}
        >
          {firstFields.label}
        </Typography>
        <AutocompleteStyle
          style={{
            background: 'white',
            borderRadius: '10px',
            fontFamily: 'Rubik'
          }}
          fullWidth
          options={firstFields.options || []}
          getOptionLabel={(option: any) =>
            option?.label || option?.name || option?.title
          }
          value={firstFields.options?.find(
            (el: any) => firstFields.value.id === el.id
          )}
          onChange={firstFields.onChange}
          renderInput={(params: any) => (
            <TextField {...params} variant="outlined" />
          )}
        />
      </Grid>
      <Grid item xs={6} mt={2}>
        <OverviewFilter
          title={PERIODICITY}
          activeOption={allData.period}
          options={PERIOD_TYPES_ANALYTICS}
          setOption={(value) => setDataFromExistingPost(null, value)}
        />
      </Grid>
      <Grid item xs={12}>
        <Line
          options={{
            responsive: true,
            scales: {
              x: {
                grid: {
                  display: false
                }
              },
              y: {
                grid: {
                  display: false
                }
              }
            },
            plugins: {
              datalabels: {
                display: false
              },
              legend: {
                display: false
              },
              tooltip: {
                callbacks: {
                  title: (data: any) => {
                    const { formattedValue } = data[0];
                    return formattedValue;
                  },
                  label: () => '',
                  footer: (data: any) => {
                    const date = jobPostAnalytics?.labels[data[0].dataIndex];
                    return (
                      date &&
                      fDateForChart(jobPostAnalytics?.labels[data[0].dataIndex])
                    );
                  }
                },
                titleMarginBottom: 0,
                footerColor: 'rgba(123, 72, 178, 0.52)',
                titleFont: {
                  size: 20
                },
                footerFont: {
                  size: 10
                },
                padding: {
                  left: 20,
                  top: 10,
                  bottom: 10,
                  right: 20
                },
                titleColor: '#7B48B2',
                backgroundColor: '#fff',
                borderWidth: 1,
                borderColor: 'rgba(128,128,128,0.2)',
                cornerRadius: 10,
                caretSize: 0
              }
            }
          }}
          height={200}
          data={{
            labels: (
              (!jobPostAnalytics?.labels?.length &&
                generateLabels(allData?.period)[0]) ||
              (jobPostAnalytics?.labels?.length < 7 &&
                appendLabels(jobPostAnalytics?.labels, allData?.period)) ||
              jobPostAnalytics?.labels
            ).map((el: any) => fDateForChart(el)),
            datasets: [
              {
                data: newData,
                label: 'Legend 1',
                fill: true,
                borderColor: legendActive
                  ? 'rgba(155,95,218,0.6)'
                  : '#ffffff00',
                borderWidth: 1,
                backgroundColor: (context: any) => {
                  const { chart } = context;
                  const { ctx } = chart;
                  const gradient = ctx.createLinearGradient(0, 100, 0, 300);
                  gradient.addColorStop(0, '#9B5FDA');
                  gradient.addColorStop(1, '#9B5FDA30');
                  return legendActive ? gradient : '#ffffff00';
                },
                pointBackgroundColor: (context: any) => {
                  const { chart } = context;
                  const { ctx } = chart;
                  const gradient = ctx.createLinearGradient(500, 500, 200, 200);
                  gradient.addColorStop(0, '#9B5FDA');
                  gradient.addColorStop(0.52, '#562C82');
                  return legendActive ? gradient : '#ffffff00';
                },
                pointBorderColor: legendActive ? '#ffffff' : '#ffffff00',
                pointBorderWidth: legendActive ? 2 : 0,
                pointRadius: legendActive ? 5 : 0
              }
            ]
          }}
        />
      </Grid>
      <Legend
        setLegendActive={setLegendActive}
        legendActive={legendActive}
        title="Legend 1"
      />
    </Grid>
  );
};

export default JobPostAnalytics;
