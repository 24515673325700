import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';

const TypographyStyle = styled(Typography)(() => ({
  fontFamily: 'Rubik',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '20px',
  color: '#131417'
}));

const TypographyTitle2 = styled(Typography)(() => ({
  fontFamily: 'Rubik',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '35px',
  color: '#131417'
}));

const TypographyTitle3 = styled(Typography)(() => ({
  fontFamily: 'Rubik',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '24px',
  color: '#131417'
}));

const TypographyTitle4 = styled(Typography)(() => ({
  fontFamily: 'Rubik',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '14px',
  color: '#131417'
}));

const TypographyTitle5 = styled(Typography)(() => ({
  fontFamily: 'Rubik',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '30px',
  color: '#404040'
}));

const TypographyTitle6 = styled(Typography)(() => ({
  fontFamily: 'Rubik',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '24px',
  color: '#6C3C9D'
}));

const TypographyTitle7 = styled(Typography)(() => ({
  fontFamily: 'Rubik',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '60px',
  color: '#D5BEE9'
}));

const TypographyTitle8 = styled(Typography)(() => ({
  fontFamily: 'Rubik',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '40px',
  color: '#424142'
}));

const TypographyPrimary = styled(Typography)(() => ({
  fontFamily: 'Rubik',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '16px',
  color: '#562C82'
}));

const TypographyPrimary1 = styled(Typography)(() => ({
  fontFamily: 'Rubik',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '18px',
  color: '#562C82'
}));

const TypographyDescription = styled(Typography)(() => ({
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '19px'
}));

const TypographyDescription2 = styled(Typography)(() => ({
  fontFamily: 'Rubik',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '14px',
  color: '#666666'
}));

const TypographyDescription3 = styled(Typography)(() => ({
  fontFamily: 'Rubik',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '14px',
  color: '#131417'
}));

const TypographyDescription4 = styled(Typography)(() => ({
  fontFamily: 'Rubik',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '12px',
  color: '#666666'
}));

const TypographyDescription5 = styled(Typography)(() => ({
  fontFamily: 'Rubik',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '18px',
  color: '#636363'
}));

const TypographyPrimaryGreen = styled(Typography)(() => ({
  fontFamily: 'Rubik',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '80px',
  color: '#562C82',
  '@media (max-width: 768px)': {
    fontSize: '36px'
  }
}));

export const Title1 = ({ children, ...rest }: any) => (
  <TypographyStyle {...rest}>{children}</TypographyStyle>
);

export const Title2 = ({ children, ...rest }: any) => (
  <TypographyTitle2 {...rest}>{children}</TypographyTitle2>
);

export const Title3 = ({ children, ...rest }: any) => (
  <TypographyTitle3 {...rest}>{children}</TypographyTitle3>
);

export const Title4 = ({ children, ...rest }: any) => (
  <TypographyTitle4 {...rest}>{children}</TypographyTitle4>
);

export const Title5 = ({ children, ...rest }: any) => (
  <TypographyTitle5 {...rest}>{children}</TypographyTitle5>
);

export const Title6 = ({ children, ...rest }: any) => (
  <TypographyTitle6 {...rest}>{children}</TypographyTitle6>
);

export const Title7 = ({ children, ...rest }: any) => (
  <TypographyTitle7 {...rest}>{children}</TypographyTitle7>
);

export const Title8 = ({ children, ...rest }: any) => (
  <TypographyTitle8 {...rest}>{children}</TypographyTitle8>
);

export const TitlePrimaryGreen = ({ children, ...rest }: any) => (
  <TypographyPrimaryGreen {...rest}>{children}</TypographyPrimaryGreen>
);

export const TitlePrimary = ({ children, ...rest }: any) => (
  <TypographyPrimary {...rest}>{children}</TypographyPrimary>
);

export const TitlePrimary1 = ({ children, ...rest }: any) => (
  <TypographyPrimary1 {...rest}>{children}</TypographyPrimary1>
);

export const Description1 = ({ children, ...rest }: any) => (
  <TypographyDescription {...rest}>{children}</TypographyDescription>
);

export const Description2 = ({ children, ...rest }: any) => (
  <TypographyDescription2 {...rest}>{children}</TypographyDescription2>
);

export const Description3 = ({ children, ...rest }: any) => (
  <TypographyDescription3 {...rest}>{children}</TypographyDescription3>
);

export const Description4 = ({ children, ...rest }: any) => (
  <TypographyDescription4 {...rest}>{children}</TypographyDescription4>
);

export const Description5 = ({ children, ...rest }: any) => (
  <TypographyDescription5 {...rest}>{children}</TypographyDescription5>
);
