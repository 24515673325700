import axios from 'axios';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  timeout: 5000
});

axiosInstance.interceptors.request.use((request) => {
  const accessToken = localStorage.getItem('accessToken');
  const newToken = localStorage.getItem('newToken');
  const lng = localStorage.getItem('i18nextLng');

  if (lng) {
    request.headers['Accept-Language'] = lng;
  }

  if (accessToken) {
    localStorage.removeItem('newToken');
    request.headers.Authorization = `Bearer ${accessToken}`;
  } else if (newToken) {
    localStorage.removeItem('accessToken');
    request.headers.Authorization = `Token ${newToken}`;
  }

  return request;
});

export default axiosInstance;
