import { useDispatch } from 'react-redux';
import { Box, Card, Grid } from '@material-ui/core';
import { useEffect, useState } from 'react';
import ImageUploader from 'components/ImageUploader';
import useLocales from 'hooks/useLocales';
import { prepareFormData } from 'helpers/formFields';
import { updateCompanyInfo } from 'redux/slices/generalRecruiter';

const NewDesignStylingForm = ({ initialValues }: any) => {
  const [uploader, setUploader] = useState<any[]>([]);
  const [images, setImages] = useState({
    logo: '',
    favicon: ''
  });
  const { t } = useLocales();
  const dispatch = useDispatch();

  const setNewImage = (field: string, file: any) => {
    setImages({
      ...images,
      [field]: file
    });
  };

  const handleSubmit = () => {
    const values = {
      primary_color: initialValues.primary_color || '#000000',
      secondary_color: initialValues.secondary_color || '#808080'
    };
    const data = prepareFormData(values, images);
    dispatch(updateCompanyInfo(initialValues?.id || 1, data));
  };

  useEffect(() => {
    setUploader([
      {
        label: t('Site Logo'),
        prevValue: initialValues.logo,
        newValue: images.logo,
        setValue: (file: any) => setNewImage('logo', file)
      },
      {
        label: t('Site Icon'),
        prevValue: initialValues.favicon,
        newValue: images.favicon,
        setValue: (file: any) => setNewImage('favicon', file)
      }
    ]);
    handleSubmit();

    return () => {
      setUploader([]);
    };
  }, [images]);

  return (
    <Grid container style={{ padding: 0 }}>
      <Grid item xs={12} style={{ backgroundColor: 'transparent' }}>
        <Card
          style={{
            backgroundColor: 'transparent',
            boxShadow: '0px 0px',
            marginBottom: '35px'
          }}
        >
          <Grid
            container
            style={{
              display: 'flex',
              columnGap: '30px',
              justifyContent: 'center'
            }}
          >
            {uploader.map((el: any, i: number) => (
              <Box key={i} style={{ maxWidth: '320px', width: '100%' }}>
                <ImageUploader
                  index={i}
                  label={el.label}
                  prevValue={el.prevValue}
                  newValue={el.newValue}
                  setValue={el.setValue}
                  imageStyle={{
                    height: '385px',
                    backgroundColor:
                      'linear-gradient(94deg, rgba(84, 46, 131, 0.07)',
                    boxShadow: '0px 4px 14px 0px rgba(0, 0, 0, 0.08)'
                  }}
                  styles
                />
              </Box>
            ))}
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};

export default NewDesignStylingForm;
