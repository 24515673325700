import { getPlaceholders } from 'constants/text/placeholders';

export const getStringFromList = (list: any[] | null) => {
  const { NOT_INDICATED } = getPlaceholders();
  return list && list.length
    ? list
        .map((el: any) =>
          typeof el === 'string' ? el : el.name || el.display_name
        )
        .join(', ')
    : NOT_INDICATED;
};

export const splitToColumns = (list: any[]) => {
  const columns: any = [[], []];
  list.forEach((el: any, i) => {
    const col = i % 2;
    columns[col].push(el);
  });
  return columns;
};

export const getNameByVal = (
  list: any[],
  value: number | number[] | null,
  zeroValue = false
) => {
  // const { NOT_INDICATED } = getPlaceholders();
  const NOT_INDICATED = 'Not indicated';
  if (value || (zeroValue && value === 0)) {
    if (Array.isArray(value)) {
      return (
        list
          .filter((el) => {
            const indicator = el.value || el.id;
            return value.includes(indicator);
          })
          .map((el) => el.label || el.name)
          .join(', ') || NOT_INDICATED
      );
    }

    const currentOption = list.find((el: any) => {
      const indicator = el.value || el.id;
      return indicator === value;
    });
    return currentOption.label || currentOption.name || currentOption.network;
  }
  return NOT_INDICATED;
};

export const getMultiNameByVal = (list: any[], values: number[]) => {
  const { NOT_INDICATED } = getPlaceholders();
  return values.length
    ? list
        .filter((el: any) => values.includes(el.value))
        .map((el: any) => el.label)
        .join(', ')
    : NOT_INDICATED;
};

export const getNameFormObj = (obj: any) => {
  const { NOT_INDICATED } = getPlaceholders();
  return obj && obj.name ? obj.name : NOT_INDICATED;
};

export const getCheckedString = (str: any, maxSize: number | null = null) => {
  const { NOT_INDICATED } = getPlaceholders();
  if (str && maxSize && str.length > maxSize) {
    return `${str.substring(0, maxSize)}...`;
  }
  return str || NOT_INDICATED;
};

export const getTimePeriod = (start: string, end: string) => {
  const { NOW, NOT_INDICATED } = getPlaceholders();
  return start ? `${start} — ${end || NOW}` : NOT_INDICATED;
};

export const getComparedData = (candidateComp: any[], profileComp: any[]) => {
  const profileCompIds = profileComp?.map((el: any) => el?.competency?.id);
  const arr: any = [];
  profileCompIds?.map((item) => {
    const element = candidateComp?.find((el) => el?.competency_id === item);
    arr.push(element);
  });
  return arr;
};

export const getComparedLabel = (candidateComp: any[]) =>
  candidateComp?.map((el: any) => el.competency.name);

export const getCompetenciesScoresAndLabels = (competencies: any[]) => ({
  competencies,
  competency_labels: competencies?.length
    ? competencies?.map((el: any) => ({
        name: el?.competency?.name,
        id: el?.competency?.id
      }))
    : [],
  competency_min_scores: competencies?.length
    ? competencies?.map((el: any) => ({
        min_score: el?.min_score,
        id: el?.competency?.id
      }))
    : []
});

export const prepareUpdatedData = (
  method: string,
  prevState: any,
  data: any
) => {
  switch (method) {
    case 'create':
      return {
        ...prevState,
        results: [data, ...prevState.results]
      };
    case 'update':
      return {
        ...prevState,
        results: [
          ...prevState.results?.map((el: any) => (el.id === data.id ? data : el))
        ]
      };
    case 'delete':
      return {
        ...prevState,
        results: [...prevState.results?.filter((el: any) => el.id !== data.id)]
      };
    default:
      return prevState;
  }
};
