import { createSlice } from '@reduxjs/toolkit';
import { initialPaginated } from 'constants/states';
import { AppDispatch, RootState } from 'redux/store';
import {
  createCmsGalleryData,
  createCmsOffersData,
  deleteCmsGalleryData,
  deleteCmsMediaData,
  deleteCmsOffersData,
  getCmsBaseData,
  getCmsGalleryData,
  getCmsMediaData,
  getCmsOffersData,
  saveCmsMediaData,
  updateCmsBaseData,
  updateCmsGalleryData,
  updateCmsMediaData,
  updateCmsOffersData
} from 'requests/cms';
import { prepareUpdatedData } from 'helpers/listValues';
import { getJobPosts } from 'requests/vacancy';
import { enqueueSnackbar } from './notifications';
import { setCmsLoading } from './loading';
import { getCompanyInfo } from './generalRecruiter';

const initialState = {
  isLoading: true,
  isJobsLoading: false,
  base: {
    id: 1,
    active: true,
    hero_img: '',
    title: '',
    button: '',
    gallery_section_title: '',
    offer_section_title: '',
    vacancy_section_title: '',
    information_section_title: '',
    information: '',
    registerScreenText: '',
    primary_color: '#562C82'
  },
  gallery: {
    results: []
  },
  offers: {
    results: []
  },
  media: {
    results: []
  },
  updateMediaSuccess: null,
  jobPosts: initialPaginated,
  jobPostsPaginated: initialPaginated
};

const slice = createSlice({
  name: 'cmsSlice',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
    setJobsLoading(state, action) {
      state.isJobsLoading = action.payload;
    },
    baseSuccess(state, action) {
      state.base = action.payload.base;
    },
    mediaSuccess(state, action) {
      state.media = action.payload.media;
    },
    updateMediaSuccess(state, action) {
      state.media = action.payload.updatedMedia;
    },
    gallerySuccess(state, action) {
      state.gallery = action.payload.gallery;
    },
    jobPostsSuccess(state, action) {
      state.jobPostsPaginated =
        action.payload.jobPostsPaginated || state.jobPostsPaginated;
      state.jobPosts = action.payload.jobPosts || state.jobPosts;
    },
    offersSuccess(state, action) {
      state.offers = action.payload.offers;
    }
  }
});

export const getCmsData = () => async (
  dispatch: AppDispatch,
  getState: () => RootState
) => {
  const { cms } = getState();
  try {
    if (!cms.gallery.results.length) await dispatch(getCmsGallery());
    if (!cms.offers.results.length) await dispatch(getCmsOffers());
    if (!cms.jobPosts.results) await dispatch(getCmsJobPosts());
  } catch {
    dispatch(
      enqueueSnackbar({
        messageType: 'error',
        options: { variant: 'error' }
      })
    );
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

export const getCmsStylingData = () => async (dispatch: AppDispatch) => {
  dispatch(setCmsLoading(true));
  try {
    await dispatch(getCmsBaseInfo());
    await dispatch(getCompanyInfo());
  } catch {
    dispatch(
      enqueueSnackbar({
        messageType: 'error',
        options: { variant: 'error' }
      })
    );
  } finally {
    dispatch(setCmsLoading(false));
  }
};

export const getCmsBaseInfo = () => async (dispatch: AppDispatch) =>
  getCmsBaseData()
    .then((res) => dispatch(slice.actions.baseSuccess({ base: res })))
    .catch(() =>
      dispatch(
        enqueueSnackbar({
          messageType: 'error',
          options: { variant: 'error' }
        })
      )
    );

export const getCmsMediaInfo = () => (dispatch: AppDispatch) =>
  getCmsMediaData()
    .then((res) => dispatch(slice.actions.mediaSuccess({ media: res })))
    .catch(() =>
      dispatch(
        enqueueSnackbar({
          messageType: 'error',
          options: { variant: 'error' }
        })
      )
    );

export const getCmsGallery = () => async (dispatch: AppDispatch) =>
  getCmsGalleryData()
    .then((res) => dispatch(slice.actions.gallerySuccess({ gallery: res })))
    .catch(() =>
      dispatch(
        enqueueSnackbar({
          messageType: 'error',
          options: { variant: 'error' }
        })
      )
    );

export const getCmsOffers = () => async (dispatch: AppDispatch) =>
  getCmsOffersData()
    .then((res) => dispatch(slice.actions.offersSuccess({ offers: res })))
    .catch(() =>
      dispatch(
        enqueueSnackbar({
          messageType: 'error',
          options: { variant: 'error' }
        })
      )
    );

export const getCmsJobPosts = (
  status?: string,
  limit?: number,
  offset?: number,
  locations?: string,
  degrees?: string,
  salaries?: string,
  work_exps?: string,
  q?: string
) => async (dispatch: AppDispatch) => {
  dispatch(slice.actions.setJobsLoading(true));
  getJobPosts({
    ...(status ? { status } : {}),
    limit: limit || 5,
    offset: offset || 0,
    locations: locations || '',
    degrees: degrees || '',
    salaries: salaries || '',
    work_exps: work_exps || '',
    q: q || '',
    ordering: 'created_at'
  })
    .then((res) => {
      const data = limit
        ? {
            jobPostsPaginated: res
          }
        : {
            jobPosts: res
          };
      dispatch(slice.actions.jobPostsSuccess(data));
    })
    .catch(() =>
      dispatch(
        enqueueSnackbar({
          messageType: 'error',
          options: { variant: 'error' }
        })
      )
    )
    .finally(() => dispatch(slice.actions.setJobsLoading(false)));
};

export const updateCmsBaseInfo = (id: number, data: any) => async (
  dispatch: AppDispatch
) =>
  updateCmsBaseData(id, data)
    .then((res) => dispatch(slice.actions.baseSuccess({ base: res })))
    .catch(() =>
      dispatch(
        enqueueSnackbar({
          messageType: 'error',
          options: { variant: 'error' }
        })
      )
    );

export const updateCmsMediaInfo = (id: number, data: any) => async (
  dispatch: AppDispatch
) =>
  updateCmsMediaData(id, data)
    .then((res) =>
      dispatch(slice.actions.updateMediaSuccess({ updatedMedia: res }))
    )
    .catch(() =>
      dispatch(
        enqueueSnackbar({
          messageType: 'error',
          options: { variant: 'error' }
        })
      )
    );

export const saveCmsMediaInfo = (data: any) => async (dispatch: AppDispatch) =>
  saveCmsMediaData(data)
    .then((res) =>
      dispatch(slice.actions.updateMediaSuccess({ updatedMedia: res }))
    )
    .catch(() =>
      dispatch(
        enqueueSnackbar({
          messageType: 'error',
          options: { variant: 'error' }
        })
      )
    );

export const deleteCmsMediaInfo = (id: any) => async (dispatch: AppDispatch) =>
  deleteCmsMediaData(id)
    .then((res) =>
      dispatch(slice.actions.updateMediaSuccess({ updatedMedia: res }))
    )
    .catch(() =>
      dispatch(
        enqueueSnackbar({
          messageType: 'error',
          options: { variant: 'error' }
        })
      )
    );

export const postCmsGallery = (data: any) => async (
  dispatch: AppDispatch,
  getState: () => RootState
) =>
  createCmsGalleryData(data)
    .then((res) => {
      const { cms } = getState();
      dispatch(
        slice.actions.gallerySuccess({
          gallery: prepareUpdatedData('create', cms.gallery, res)
        })
      );
      dispatch(
        enqueueSnackbar({
          messageType: 'created',
          options: {
            variant: 'success'
          }
        })
      );
    })
    .catch(() =>
      dispatch(
        enqueueSnackbar({
          messageType: 'error',
          options: { variant: 'error' }
        })
      )
    );

export const updateCmsGallery = (id: number, data: any) => async (
  dispatch: AppDispatch,
  getState: () => RootState
) =>
  updateCmsGalleryData(id, data)
    .then((res) => {
      const { cms } = getState();
      dispatch(
        slice.actions.gallerySuccess({
          gallery: prepareUpdatedData('update', cms.gallery, res)
        })
      );
      dispatch(
        enqueueSnackbar({
          messageType: 'updated',
          options: {
            variant: 'success'
          }
        })
      );
    })
    .catch(() =>
      dispatch(
        enqueueSnackbar({
          messageType: 'error',
          options: { variant: 'error' }
        })
      )
    );

export const deleteCmsGallery = (id: number) => async (
  dispatch: AppDispatch,
  getState: () => RootState
) =>
  deleteCmsGalleryData(id)
    .then(() => {
      const { cms } = getState();
      dispatch(
        slice.actions.gallerySuccess({
          gallery: prepareUpdatedData('delete', cms.gallery, { id })
        })
      );
      dispatch(
        enqueueSnackbar({
          messageType: 'deleted',
          options: {
            variant: 'success'
          }
        })
      );
    })
    .catch(() =>
      dispatch(
        enqueueSnackbar({
          messageType: 'error',
          options: { variant: 'error' }
        })
      )
    );

export const postCmsOffer = (data: any) => async (
  dispatch: AppDispatch,
  getState: () => RootState
) =>
  createCmsOffersData(data)
    .then((res) => {
      const { cms } = getState();
      dispatch(
        slice.actions.offersSuccess({
          offers: prepareUpdatedData('create', cms.offers, res)
        })
      );
      dispatch(
        enqueueSnackbar({
          messageType: 'created',
          options: {
            variant: 'success'
          }
        })
      );
    })
    .catch(() =>
      dispatch(
        enqueueSnackbar({
          messageType: 'error',
          options: { variant: 'error' }
        })
      )
    );

export const updateCmsOffer = (id: number, data: any) => async (
  dispatch: AppDispatch,
  getState: () => RootState
) =>
  updateCmsOffersData(id, data)
    .then((res) => {
      const { cms } = getState();
      dispatch(
        slice.actions.offersSuccess({
          offers: prepareUpdatedData('update', cms.offers, res)
        })
      );
      dispatch(
        enqueueSnackbar({
          messageType: 'updated',
          options: {
            variant: 'success'
          }
        })
      );
    })
    .catch(() =>
      dispatch(
        enqueueSnackbar({
          messageType: 'error',
          options: { variant: 'error' }
        })
      )
    );

export const deleteCmsOffer = (id: number) => async (
  dispatch: AppDispatch,
  getState: () => RootState
) =>
  deleteCmsOffersData(id)
    .then(() => {
      const { cms } = getState();
      dispatch(
        slice.actions.offersSuccess({
          offers: prepareUpdatedData('delete', cms.offers, { id })
        })
      );
      dispatch(
        enqueueSnackbar({
          messageType: 'deleted',
          options: {
            variant: 'success'
          }
        })
      );
    })
    .catch(() =>
      dispatch(
        enqueueSnackbar({
          messageType: 'error',
          options: { variant: 'error' }
        })
      )
    );

export default slice.reducer;
