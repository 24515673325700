import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useLocation } from 'react-router-dom';
import StepperComponent from '../../Stepper';
import { CandidateFormsContainer } from '../../forms/account/CandidateForm';
import { RootState } from '../../../redux/store';

import { AccountInfoForm } from '../../forms/account/AccountInfoForm';
import { CandidatePitchForm } from '../../forms/account/CandidatePitchForm';
import { CandidatePreferencesForm } from '../../forms/account/CandidatePreferencesForm';
import useLocales from '../../../hooks/useLocales';
import {
  getCandidateInfo,
  getCandidateActivities,
  getCandidateCertifications,
  getCandidateEducations,
  getCandidateITSkills,
  getCandidateLanguages,
  getCandidateSocialMedia,
  getCandidateSocialMediaExpertises,
  getCandidateWorkExperience
} from '../../../redux/slices/candidate';
import useAuth from '../../../hooks/useAuth';
import { getAccount } from '../../../redux/slices/authJwt';

// ----------------------------------------------------------------------

export default function ClarificationForm({
  userId,
  candidateId,
  onFinish
}: {
  userId?: number;
  candidateId: number;
  onFinish: () => any;
}) {
  const [activeStep, setActiveStep] = useState(0);
  const { t } = useLocales();
  const dispatch = useDispatch();

  const { user } = useAuth();
  const options = useSelector((state: RootState) => state.fixturesReducer);
  const modules = useSelector(
    (state: RootState) => state.dashboard.companyModuleInsights
  );
  const { candidate } = useSelector((state: RootState) => state.candidate);
  const role = new URLSearchParams(useLocation().search).get('role');

  const steps =
    role === 'employee'
      ? [
          {
            step: 0,
            enable: true,
            label: t('Personal Info'),
            nextEnable: true
          }
        ]
      : [
          {
            step: 0,
            enable: true,
            label: t('Personal Info'),
            nextEnable: true
          },
          {
            step: 1,
            enable: modules.pitch,
            label: t('Your Pitch'),
            nextEnable: true
          },
          {
            step: 2,
            enable: modules.preference,
            label: t('Your Preferences'),
            nextEnable: true
          },
          {
            step: 3,
            enable: modules.education,
            label: t('Educations'),
            nextEnable: true
          },
          {
            step: 4,
            enable: modules.certification,
            label: t('Certifications'),
            nextEnable: true
          },
          {
            step: 5,
            enable: modules.language,
            label: t('Languages'),
            nextEnable: true
          },
          {
            step: 6,
            enable: modules.it_skill,
            label: t('IT Skills'),
            nextEnable: true
          },
          {
            step: 7,
            enable: modules.work_experience,
            label: t('Work Experiences'),
            nextEnable: true
          },
          {
            step: 8,
            enable: modules.social_media_expertise,
            label: t('SM Expertise'),
            nextEnable: true
          },
          {
            step: 9,
            enable: modules.extra,
            label: t('Activities'),
            nextEnable: true
          },
          {
            step: 10,
            enable: modules.social_media,
            label: t('Social Media'),
            nextEnable: true
          }
        ];

  const onNextAccountInform =
    role === 'employee'
      ? () => {
          isNext();
          dispatch(getAccount());
        }
      : () => {
          isNext();
          dispatch(getAccount());
        };
  const currentSteps = steps.filter((el) => el.enable);
  const curStep =
    currentSteps[steps.findIndex((el: any) => el.step === activeStep)]?.step;
  const currentStep = curStep == 0 || curStep ? curStep : 11;

  const isLast = activeStep === currentSteps.length - 1;

  const isNext = () => {
    if (isLast) onFinish();
    else setActiveStep(activeStep + 1);
  };

  const getActiveStepComponent = () => {
    switch (currentStep) {
      case 0:
        return (
          <AccountInfoForm
            initialData={user}
            id={userId}
            candidateId={candidateId}
            options={options}
            onNext={onNextAccountInform}
          />
        );
      case 1:
        return (
          <CandidatePitchForm
            initialData={candidate.mainInfo}
            candidateId={candidateId}
            options={options}
            onNext={() => {
              isNext();
              dispatch(getCandidateInfo(candidateId));
            }}
          />
        );
      case 2:
        return (
          <CandidatePreferencesForm
            initialData={candidate?.mainInfo}
            candidateId={candidateId}
            options={options}
            onNext={() => {
              isNext();
              dispatch(getCandidateInfo(candidateId));
            }}
            withOutLang={true}
          />
        );
      case 3:
        return (
          <CandidateFormsContainer
            initialDataList={candidate?.educations || {}}
            candidateId={candidateId}
            groupTitle={t('Educations')}
            type="educations"
            options={options}
            onChanging={() => dispatch(getCandidateEducations(candidateId))}
          />
        );
      case 4:
        return (
          <CandidateFormsContainer
            initialDataList={candidate?.certifications || {}}
            candidateId={candidateId}
            groupTitle={t('Certifications')}
            type="certifications"
            options={options}
            onChanging={() => dispatch(getCandidateCertifications(candidateId))}
          />
        );
      case 5:
        return (
          <CandidateFormsContainer
            initialDataList={candidate?.languages || {}}
            candidateId={candidateId}
            groupTitle={t('Languages')}
            type="languages"
            options={options}
            onChanging={() => dispatch(getCandidateLanguages(candidateId))}
          />
        );
      case 6:
        return (
          <CandidateFormsContainer
            initialDataList={candidate?.it_skill || {}}
            candidateId={candidateId}
            groupTitle={t('IT Skills')}
            type="it-skills"
            options={options}
            onChanging={() => dispatch(getCandidateITSkills(candidateId))}
          />
        );
      case 7:
        return (
          <CandidateFormsContainer
            initialDataList={candidate?.work_experience || {}}
            candidateId={candidateId}
            groupTitle={t('Work Experiences')}
            type="work-experiences"
            options={options}
            onChanging={() => dispatch(getCandidateWorkExperience(candidateId))}
          />
        );
      case 8:
        return (
          <CandidateFormsContainer
            initialDataList={candidate?.social_media_expertise?.results || []}
            candidateId={candidateId}
            groupTitle={t('Social Media Expertises')}
            type="social-media-expertises"
            options={options}
            onChanging={() =>
              dispatch(getCandidateSocialMediaExpertises(candidateId))
            }
          />
        );
      case 9:
        return (
          <CandidateFormsContainer
            initialDataList={candidate?.activities || {}}
            candidateId={candidateId}
            groupTitle={t('Activities')}
            type="activities"
            options={options}
            onChanging={() => dispatch(getCandidateActivities(candidateId))}
          />
        );
      case 10:
        return (
          <CandidateFormsContainer
            initialDataList={candidate?.social_media || {}}
            candidateId={candidateId}
            groupTitle={t('Social Media')}
            type="social-media"
            options={options}
            onChanging={() => dispatch(getCandidateSocialMedia(candidateId))}
          />
        );
      default:
        return 'No such step';
    }
  };

  return (
    <StepperComponent
      activeStep={activeStep}
      setActiveStep={setActiveStep}
      onFinish={onFinish}
      onBack={() => {}}
      steps={currentSteps}
      vertical
      style={{
        height: 'auto',
        border: '0'
      }}
      fromClarification={true}
    >
      {getActiveStepComponent()}
    </StepperComponent>
  );
}
