import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { IProduct } from 'redux/slices/productsReducer';
import { RootState } from 'redux/store';
import { useFormFieldsByType } from 'helpers/formFields';
import { DEFAULT_MESSAGE_TEXTS } from 'constants/constants';
import { orderCampaigns } from 'utils/vonq';
import { formValidate, makeNotRequired } from 'helpers/formValidation';
import { Box, Card, Typography } from '@material-ui/core';
import useLocales from 'hooks/useLocales';
import { ApproveDialog } from './Dialog';
import LoadingScreen from './LoadingScreen';
import { DynamicForm } from './DynamicForm';
import {
  formatProducts,
  makeDaysArrForSelect,
  mapAutoFillArray
} from '../utils/helpersForms';
import { FieldsData } from './types';
import { useSnackbar } from 'notistack';

const VonqPreOrderForm = ({
  products,
  ifSubmitSuccess
}: {
  products: Array<IProduct>;
  ifSubmitSuccess: (id: string | undefined) => void;
}) => {
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [errors, setErrors] = useState(null);
  const user = useSelector((state: RootState) => state.authJwt.user.id);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useLocales();
  const autoFillArray = useSelector(
    (state: RootState) => state.productsReducer.autoFillValues
  );

  const [vacancy, jobCategoryForTargetGroup, industriesForTargetGroup] =
    useMemo(() => {
      const vacancy = products[0].jobPostFor?.id;
      const jobCategoryForTargetGroup = mapAutoFillArray(
        autoFillArray,
        vacancy,
        'idJobCategory',
        'nameJobCategory'
      );
      const industriesForTargetGroup = mapAutoFillArray(
        autoFillArray,
        vacancy,
        'idIndustries',
        'nameIndustries'
      );
      return [vacancy, jobCategoryForTargetGroup, industriesForTargetGroup];
    }, [autoFillArray, products[0].jobPostFor]);

  const [fieldsData, setFieldsData] = useState<FieldsData>({
    working_location_address: '',
    working_location_postcode: '',
    working_location_city: '',
    working_location_country: '',
    years_of_experience: 0,
    weekly_working_hours_from: 0,
    weekly_working_hours_to: 0,
    educationLevel: { id: '', name: '' },
    seniority: { id: '', name: '' },
    industry: industriesForTargetGroup?.id
      ? industriesForTargetGroup
      : { id: '', name: '' },
    jobCategory: jobCategoryForTargetGroup?.id
      ? jobCategoryForTargetGroup
      : { id: '', name: '' },
    remote_work: false,
    jobpage_url: ''
  });

  const handleSetFieldsData = (field: string, value: any) => {
    setFieldsData({
      ...fieldsData,
      [field]: value
    });
  };

  const options = {
    years_of_experience: [...makeDaysArrForSelect(40, 'year')],
    weekly_working_hours_from: [...makeDaysArrForSelect(168, 'hour')],
    weekly_working_hours_to: [...makeDaysArrForSelect(168, 'hour')]
  };

  const helperText = {
    jobpage_url: t('Here you can enter your job page. This field is optional. If you leave it blank, the vacancies page will use the vacancies recrout page.')
  };

  const fields = [
    ...useFormFieldsByType(
      'vonq-pre-order-campaigns',
      fieldsData,
      handleSetFieldsData,
      options,
      errors,
      helperText
    )
  ];

  const errorHandler = (errors: any) => {
    setErrors(errors);
    if (Object.keys(errors).length) {
      enqueueSnackbar(DEFAULT_MESSAGE_TEXTS.required, {
        variant: 'warning'
      });
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const res = await orderCampaigns({
        user,
        vacancy: Number(vacancy),
        working_location_address: fieldsData.working_location_address,
        working_location_postcode: fieldsData.working_location_postcode,
        working_location_city: fieldsData.working_location_city,
        working_location_country: fieldsData.working_location_country,
        years_of_experience: fieldsData.years_of_experience,
        weekly_working_hours_from: fieldsData.weekly_working_hours_from,
        weekly_working_hours_to: fieldsData.weekly_working_hours_to,
        ordered_products: [...formatProducts(products)],
        remote_work: fieldsData.remote_work,
        jobpage_url: fieldsData.jobpage_url,
        target_group: {
          educationLevel: [
            {
              vonqId: fieldsData.educationLevel.id,
              description: fieldsData.educationLevel.name
            }
          ],
          seniority: [
            {
              vonqId: fieldsData.seniority.id,
              description: fieldsData.seniority.name
            }
          ],
          industry: [
            {
              vonqId: fieldsData.industry.id,
              description: fieldsData.industry.name
            }
          ],
          jobCategory: [
            {
              vonqId: fieldsData.jobCategory.id,
              description: fieldsData.jobCategory.name
            }
          ]
        }
      });
      if (res?.stripe_session_url) {
        window.open(res.stripe_session_url);
        ifSubmitSuccess(vacancy);
        setLoading(false);
      }
    } catch (err: any) {
      enqueueSnackbar(err.message, { variant: 'error' });
      setLoading(false);
    }
  };

  const validateBeforeSubmit = () => {
    if (
      formValidate(
        fieldsData,
        errorHandler,
        makeNotRequired(fieldsData, 'jobpage_url')
      )
    ) {
      setOpenDialog(true);
    }
  };

  return (
    <Card sx={{ p: 5, maxWidth: '960px' }}>
      {loading ? (
        <Box sx={{ px: 24, py: 12 }}>
          <LoadingScreen />
        </Box>
      ) : (
        <Box>
          <ApproveDialog
            open={openDialog}
            title={t('Are you sure you want to post this job?')}
            onClose={() => setOpenDialog(false)}
            onApprove={handleSubmit}
          />
          <Typography variant="h3" sx={{ mb: 3, textAlign: 'center' }}>
            {t('Fill the fields please')}
          </Typography>
          <DynamicForm
            inputValues={fields}
            buttonText={t('Order')}
            onSubmit={() => validateBeforeSubmit()}
            cols
            type="vonq-pre-order-campaigns"
          />
        </Box>
      )}
    </Card>
  );
};

export default VonqPreOrderForm;
