import { useState } from 'react';
import { Box, Button, Link, Modal, Typography } from '@mui/material';
import IntegrationSelect from './IntegrationSelect';
import { postDataByUrl } from '../../../../requests';
import { showKomboConnect } from '@kombo-api/connect';

const KomboModal = ({ open, setOpen }: any) => {
  const [category, setCategory] = useState('');

  const onConnectКombo = async () => {
    if (category) {
      const data = await postDataByUrl('/api/kombo/integrations/', { category });
      const activationToken = await showKomboConnect(data.link);
      await postDataByUrl('/api/kombo/integrations/activate/', { category, token: activationToken });
      setOpen(false);
    }
  };

  return (
     <Modal open={open} onClose={() => setOpen(false)}>
       <Box sx={{
         position: 'absolute',
         top: '50%',
         left: '50%',
         transform: 'translate(-50%, -50%)',
         width: 400,
         bgcolor: 'background.paper',
         boxShadow: 24,
         borderRadius: 2
       }}>
         <Box p={4}>
           <Typography textAlign="center" fontSize={24}> Set up a new integration </Typography>
           <Typography sx={{ mb: 3, textAlign: 'center', fontSize: '13px', color: '#acacac' }}>
             Manually set up an integration on behalf of a customer or for development purposes
           </Typography>
           <IntegrationSelect label="Category" setCategory={setCategory} category={category} />
           <Typography sx={{ m: '20px 0', fontSize: '13px', color: '#acacac' }}>
             Select the category of tools you would like to create the integration for.
           </Typography>
           <Button fullWidth variant="contained" sx={{ background: '#18B590', mt: 3, ':hover': { background: '#0d9d7b' } }} onClick={onConnectКombo}>
             Continue
           </Button>
         </Box>
         <Typography sx={{ background: '#f4f4f4', p: 2, borderRadius: '0 0 15px 15px' }}>
           Want to allow your customer to set up integrations themselves from within your app? Take a look at
           <Link href="https://docs.kombo.dev/connect" target="_blank"> Connect!</Link>
         </Typography>
       </Box>
     </Modal>
  );
};

export default KomboModal;