import { experimentalStyled as styled } from '@material-ui/core/styles';
import { AppBar, Box, Toolbar } from '@material-ui/core';
import ProductsBasket from 'components/ProductsBasket';
import useAuth from 'hooks/useAuth';
import AccountPopover from './AccountPopover';
import LanguagePopover from './LanguagePopover';
import Notification from '../../../components/notification/Notification';
import { Title4 } from 'components/text/title';
import HeadContainer from '../../../components/HeadContainer';

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(() => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  background: 'transparent',
  width: 'auto',
  left: '155px'
}));
const styleIconHeader = {
  background: 'white',
  width: '47px',
  height: '47px',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
};

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  maxWidth: '1525px',
  width: '100%',
  justifyContent: 'space-between',
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 12, 0, 5)
  },
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(0, 12, 0, 5)
  },
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(0, 10, 0, 10)
  },
  [theme.breakpoints.up('xs')]: {
    padding: theme.spacing(0, 6, 0, 6)
  },
  '@media (max-width: 1280px)': {
    padding: '0 24px'
  }
}));

type DashboardNavbarProps = {
  title?: string;
};

export default function DashboardNavbar({ title }: DashboardNavbarProps) {
  const { user, isAuthenticated } = useAuth();

  return (
    <RootStyle>
      <ToolbarStyle>
        <HeadContainer title={title} />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            '& > *:not(:first-of-type)': {
              ml: { xs: 1.5, sm: 2, lg: 3 }
            }
          }}
        >
          <>
            {isAuthenticated && (
              <Box sx={styleIconHeader}>
                <Notification />
              </Box>
            )}
            <Box sx={styleIconHeader}>
              <LanguagePopover />
            </Box>
            {isAuthenticated && user.role !== 'candidate' && (
              <Box sx={styleIconHeader}>
                <ProductsBasket />
              </Box>
            )}

            <AccountPopover />
            <Title4 style={{ marginLeft: '10px' }}>{user.name}</Title4>
          </>
        </Box>
      </ToolbarStyle>
    </RootStyle>
  );
}
