export const appendLabels = (labels: any, interval: any) => {
  const newLabels = labels.map((label: any) => new Date(label));
  for (let index = labels.length; index < 7; index++) {
    const lastLabel = newLabels[newLabels.length - 1];
    const newLabel = new Date(lastLabel.getTime());
    switch (interval) {
      case 'weekly':
        newLabel.setDate(newLabel.getDate() + 7);
        break;
      case 'monthly':
        newLabel.setMonth(newLabel.getMonth() + 1);
        break;
      case 'quarterly':
        newLabel.setMonth(newLabel.getMonth() + 3);
        break;
    }
    newLabels.push(newLabel);
  }
  return newLabels;
};

export const generateLabels = (interval: any) => {
  const newLabels = [];
  const newData = [];
  for (let i = 0; i < 7; i++) {
    if (!newLabels.length) {
      newLabels.push(new Date());
    } else {
      const firstLabel: any = newLabels[0];
      const newLabel = new Date(firstLabel.getTime());
      switch (interval) {
        case 'weekly':
          newLabel.setDate(newLabel.getDate() - 7);
          break;
        case 'monthly':
          newLabel.setMonth(newLabel.getMonth() - 1);
          break;
        case 'quarterly':
          newLabel.setMonth(newLabel.getMonth() - 3);
          break;
      }
      newLabels.unshift(newLabel);
    }
    newData.push(0);
  }
  return [newLabels];
};
