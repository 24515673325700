import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CandidateCardContainer from 'components/containers/CandidateCardContainer';
import CardField from 'components/CardField';
import useLocales from 'hooks/useLocales';
import { RootState } from 'redux/store';
import { CandidateType } from 'types/Candidate';
import { getCardValuesByType } from 'helpers/cardValues';
import { Box } from '@mui/material';
import { useMemo, useState } from 'react';
import { Grid } from '@material-ui/core';
import { EditButton } from '../../../components/Buttons';
import { patchCustomerDataFromProfile } from '../../../redux/slices/customrs';
import { CustomModal } from '../../../components/CustomModal';
import { DynamicFormWrapper } from '../../../components/DynamicForm';
import Calendar from './Calendar';

interface CustomerType {
  id?: number | null;
  contact_phone: string;
  email: string;
  name: string;
  website: string;
  contact_name: string;
}

interface CandidateInfoProps {
  mainInfo: CandidateType | CustomerType;
  recruiterOrAdmin?: boolean;
  language?: string | null;
  title: string;
  isForCustomer: boolean | undefined;
  customerId: string;
  customer: CustomerType;
  calendar?: boolean;
}

export const CandidateContactCard = ({
  mainInfo,
  recruiterOrAdmin,
  language,
  title,
  isForCustomer,
  customerId,
  customer,
  calendar
}: CandidateInfoProps) => {
  const { id }: any = useParams();
  const navigate = useNavigate();
  const [editId, setEditId] = useState<string>('');
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [itemValues, setItemValues] = useState({
    contact_name: '',
    name: '',
    contact_phone: '',
    email: '',
    website: ''
  });
  const dispatch = useDispatch();
  const options = useSelector((state: RootState) => state.fixturesReducer);
  const { t } = useLocales();
  const type = isForCustomer ? 'customer-profile' : 'candidate-contact-info';
  const cardFields = getCardValuesByType(type, mainInfo, options);
  const user = useSelector((state: RootState) => state.authJwt.user);

  const costumerProfile = useMemo(
    () =>
      customer
        ? Object.entries(customer).reduce((acc, [key, value]) => {
            if (key !== 'id') {
              acc[key] = value;
            }
            return acc;
          }, {} as any)
        : {},
    [customer]
  );

  const setNewValues = (field: string, value: any) => {
    setItemValues({
      ...itemValues,
      [field]: value
    });
  };

  const handleSubmit = () => {
    if (editId) {
      dispatch(patchCustomerDataFromProfile(editId, itemValues));
      setOpenModal(false);
    }
  };

  return (
    <CandidateCardContainer title={title}>
      <Box position="relative">
        {isForCustomer && (
          <>
            {user?.id && (
              <EditButton
                sx={{ position: 'absolute', top: '-40px', right: 0 }}
                onClick={() => {
                  setEditId(customerId);
                  setItemValues(costumerProfile);
                  setOpenModal(true);
                }}
              />
            )}
            {openModal && (
              <CustomModal open={openModal} onClose={() => setOpenModal(false)}>
                <Grid item xs={12} pb={3}>
                  <DynamicFormWrapper
                    title="CMS Customer"
                    type="customer-info"
                    itemValues={itemValues}
                    onSubmit={handleSubmit}
                    setValues={setNewValues}
                  />
                </Grid>
              </CustomModal>
            )}
          </>
        )}
        {cardFields.map(({ label, value, image }: any, i: number) => (
          <CardField
            key={i}
            label={label}
            value={value}
            iconSrc={image}
            recruiterOrAdmin={recruiterOrAdmin && i == 0}
          />
        ))}
        {language && (
          <CardField
            label={t('Preferred Language')}
            value={language}
            iconSrc="/static/img/icons/globe.svg"
          />
        )}
        {calendar && <Calendar jobDetails candidateProfile />}
      </Box>
    </CandidateCardContainer>
  );
};
