import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

import { RootState } from 'redux/store';
import { getMostViewedJobPosts } from 'redux/slices/dashboard';
import { PATH_DASHBOARD } from 'routes/paths';
import { getTranslatedList } from 'constants/constants';
import { getTexts } from 'constants/text/texts';
import { deleteJobPost } from 'requests/vacancy';
import useLocales from 'hooks/useLocales';
import useAuth from 'hooks/useAuth';

import { FilterGroup, OverviewFilter } from 'components/OverviewFilter';
import { OpenJobPostCard } from 'components/CardJobPost';
import Page from 'components/containers/Page';
import ListContainer from 'components/containers/ListContainer';
import PageContainer from 'components/containers/PageContainer';
import PaginationContainer from 'components/containers/PaginationContainer';
import { setMostViewedPostsFilter } from 'redux/slices/filters';
import useURLFilters from '@hooks/useURLFilters';
import DashboardNavbar from '../../layouts/dashboard/navbar/DashboardNavbar';

const JobPostsMostViewed = () => {
  const { t } = useLocales();
  const { user } = useAuth();
  const { PERIOD_TYPES, DEFAULT_MESSAGE_TEXTS } = getTranslatedList();
  const { PERIODICITY } = getTexts();
  const {
    mostViewedJobPosts: { results, count },
    isJobPostLoading
  } = useSelector((state: RootState) => state.dashboard);
  const { page, periodicity, limit, offset, ordering } = useSelector(
    (state: RootState) => state.filtersReducer.mostViewedJobPosts
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    dispatch(getMostViewedJobPosts(periodicity, limit, offset, ordering));
  }, [dispatch, periodicity, limit, offset, ordering]);

  useEffect(() => {
    dispatch(setMostViewedPostsFilter('offset', page * limit - limit));
  }, [dispatch, page, limit]);

  const isCandidate = user.role === 'candidate';

  const onEdit = (id: string | number) => {
    navigate(PATH_DASHBOARD.jobPosts.getJobPostUrl(id));
  };

  const onDelete = async (id: string | number) => {
    try {
      await deleteJobPost(id);
      enqueueSnackbar(DEFAULT_MESSAGE_TEXTS.deleted, { variant: 'success' });
    } catch {
      enqueueSnackbar(DEFAULT_MESSAGE_TEXTS.error, { variant: 'error' });
    }
    dispatch(getMostViewedJobPosts(periodicity));
  };

  const filterNames = ['limit', 'offset', 'periodicity'];

  const currentFilters = useSelector(
    (state: RootState) => state.filtersReducer.mostViewedJobPosts
  );

  useURLFilters({
    fragmentIdentifier: '#most-viewed/',
    filterNames,
    setterFunction: setMostViewedPostsFilter,
    currentFilters
  });

  return (
    <Page title={t(' Most Viewed Job Posts')}>
      <PageContainer
        style={{
          padding: 0
        }}
      >
        <DashboardNavbar title={t(' Most Viewed Job Posts')} />
        <FilterGroup>
          <OverviewFilter
            title={PERIODICITY}
            activeOption={periodicity}
            options={PERIOD_TYPES}
            setOption={(value) =>
              dispatch(setMostViewedPostsFilter('periodicity', value))
            }
          />
        </FilterGroup>

        <ListContainer
          loading={isJobPostLoading}
          list={results}
          card={OpenJobPostCard}
          getCardProps={(item: any) => ({
            editable: !isCandidate,
            onDelete: () => onDelete(item.id),
            onEdit: () => onEdit(item.id)
          })}
          gridProps={{ sm: 6, lg: 4 }}
        />
        <PaginationContainer
          page={page}
          count={count}
          perPage={limit}
          setPage={(value) => dispatch(setMostViewedPostsFilter('page', value))}
        />
      </PageContainer>
    </Page>
  );
};

export default JobPostsMostViewed;
