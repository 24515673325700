import { StepsType } from '../../components/subscription/types';

export const steps: StepsType[] = [
  {
    id: 1,
    title: 'Welcome',
    preTitle: 'STEP 1'
  },
  {
    id: 2,
    title: 'Сompany details',
    preTitle: 'STEP 2'
  },
  {
    id: 3,
    title: 'Site settings',
    preTitle: 'STEP 3'
  },
  {
    id: 4,
    title: 'Notifications',
    preTitle: 'STEP 4'
  },
];

export const currentStepDetails = [
  {
    id: 0,
    title: 'Welcome!',
    subTitle: "Let's set up your account. Click next to start"
  },
  {
    id: 1,
    title: 'Сompany details',
    subTitle: 'Fill in your company details'
  },
  {
    id: 2,
    title: 'Site Colours',
    subTitle: 'Fill in your company details'
  },
  {
    id: 3,
    title: 'Notifications',
    subTitle: 'Customize your notifications'
  },
  {
    id: 4,
    title: 'Style settings',
    subTitle: 'Customize your style settings'
  },
  {
    id: 5,
    title: 'Congratulations !',
    subTitle: 'Your account is set'
  }
];

export const freePlanData: string[] = [
  'Userfriendly ATS with all basic functionalities',
  'Up to 3 assessments per year',
  '1 job opening every month',
  'Access to 2500+ PAID jobboards',
  'Including your own careerpage',
  'Email support',
  '1 user'
];
