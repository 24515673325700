import { Box } from '@material-ui/core';
import { Download } from '@material-ui/icons';
import { Description4 } from './text/title';

const XLSXButton = ({
  onClick,
  style,
  text = 'XLSX',
  textStyle = {},
  available = true
}: any) => (
  <Box
    onClick={available ? onClick : undefined}
    style={{
      position: 'absolute',
      right: '20px',
      bottom: '0',
      cursor: available && 'pointer',
      display: 'flex',
      alignItems: 'center',
      ...style
    }}
  >
    <Download
      sx={{ color: available ? 'rgb(155, 95, 218)' : 'rgb(208,208,208)' }}
    />
    <Description4 style={textStyle}> {text} </Description4>
  </Box>
);

export default XLSXButton;
