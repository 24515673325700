import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ContentEditable, { ContentEditableEvent } from 'react-contenteditable';
import { getCandidateNotes } from 'redux/slices/candidate';
import { getCustomerNotes } from 'redux/slices/customrs';
import { RootState } from 'redux/store';
import { getSearchedRecruters } from 'redux/slices/authJwt';
import {
  deleteCandidateData,
  deleteCustomerData,
  postCandidateData,
  postCustomerData,
  updateCandidateData,
  updateCustomerNotes
} from 'requests/candidate';
import { Box, Button, Avatar, makeStyles } from '@material-ui/core';
import CandidateCardContainer from 'components/containers/CandidateCardContainer';
import { DeleteButton, EditButton } from 'components/Buttons';
import { Description4 } from 'components/text/title';
import { fDateChat } from 'utils/formatTime';
import { getButtons } from 'constants/text/buttons';
import { showOnRequestMessage } from 'helpers/showMessageOnRequest';
import { AccountType } from 'types/Account';

const useStyles = makeStyles({
  scrollBar: {
    '&::-webkit-scrollbar': {
      borderRadius: '2px',
      background: '#ffffff',
      width: '7px',
      height: '39px'
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#562C82',
      borderRadius: '10px'
    }
  }
});

export const CandidateNotes = ({
  title,
  candidateId,
  type,
  dataList,
  mainInfo,
  isAnonymous,
  iconSrc,
  isForCostumerProfile,
  customerId,
  addNoteTitle
}: any): JSX.Element => {
  const [addNote, setAddNote] = useState<string | any>('');
  const [editId, setEditId] = useState<string>();
  const [vacancyApp, setVacancyApp] = useState<any>();
  const { user, users } = useSelector((state: RootState) => state.authJwt);
  const { ADDNOTE, UPDATENOTE } = getButtons();
  const [mentionDropdown, setMentionDropdown] = useState<boolean>(false);
  const [mentionPost, setMentionPost] = useState<Array<string>>([]);
  const [mentionId, setMentionId] = useState<Array<string>>([]);
  const [searchedUsers, setSearchedUsers] = useState<Array<AccountType>>([]);
  const [selectedSuggestion, setSelectedSuggestion] = useState<number>(0);
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const limit = 5;
  const regex = /<span[^>]*>(.*?)<\/span>/g;
  const inputRef: any = useRef(null);
  const classes = useStyles();

  const getLastWord = (): {
    lastWord?: string;
    anchorOffset?: number;
  } => {
    const selection = window.getSelection();

    if (selection && selection.rangeCount > 0) {
      const endContainer = selection.focusNode;
      const endOffset = selection.focusOffset;

      const fullText = endContainer?.textContent;

      let lastWordStart = endOffset;

      if (fullText) {
        while (
          lastWordStart > 0 &&
          !/\s/.test(fullText.charAt(lastWordStart - 1))
        ) {
          lastWordStart--;
        }

        return {
          lastWord: fullText.slice(lastWordStart, endOffset),
          anchorOffset: selection?.anchorOffset
        };
      }
    }
    return {};
  };

  const handleAddMention = (name: string, id: string): void => {
    if (name) {
      let nameSlice;
      if (addNote) {
        const str = addNote.trim();
        const words = str.split(' ');
        const { lastWord, anchorOffset } = getLastWord();
        const newMention = `&nbsp;<span contentEditable="false" class="highlighted">@${name}</span>&nbsp;`;

        if (words.length > 1 && anchorOffset !== 1) {
          nameSlice = addNote.replace(
            new RegExp(`(&nbsp;| )${lastWord}`),
            `&nbsp;${newMention}&nbsp;`
          );
        } else {
          nameSlice = addNote?.replace(
            lastWord,
            `<span contentEditable="false" class="highlighted">@${name}</span>&nbsp;`
          );
        }
      }

      setMentionId((e: Array<string>) => [...e, id]);
      setMentionPost((e: Array<string>) => [...new Set([...e, `@${name}`])]);
      setAddNote(nameSlice);
      setMentionDropdown(false);
      setSearchedUsers([]);
    }
  };

  useEffect(() => {
    if (isForCostumerProfile) dispatch(getCustomerNotes(customerId));
    else dispatch(getCandidateNotes(candidateId));
    setVacancyApp(mainInfo?.vacancy_applications);
  }, [isForCostumerProfile]);

  const getLastLetter = (): string => {
    const selection = window.getSelection();

    if (selection && selection?.rangeCount > 0) {
      const endContainer = selection.focusNode;
      const endOffset = selection.focusOffset;

      if (endContainer) {
        return endContainer?.textContent?.charAt(endOffset - 1) || '';
      }
    }
    return '';
  };

  const handleChange = async (e: ContentEditableEvent): Promise<void> => {
    const newText = e?.target?.value;
    let lastTypedLetter;

    if (newText !== addNote) {
      lastTypedLetter = getLastLetter();
    }

    setAddNote(e?.target?.value);
    let pattern;
    const lastWord = getLastWord()?.lastWord?.trim();

    if (lastWord?.startsWith('@')) {
      dispatch(getSearchedRecruters(lastWord?.substring(1), limit));
    } else {
      if (lastWord) pattern = new RegExp(`@[^@\\s]* ${lastWord}`);

      const match = pattern?.exec(newText);

      if (match && match[0]) {
        dispatch(getSearchedRecruters(match[0]?.substring(1), limit));
      }
    }

    if (lastTypedLetter?.trim() == '@') {
      setMentionDropdown(true);
    }

    if (lastTypedLetter?.trim() === '') {
      setMentionDropdown(false);
    }

    if (!newText) {
      setMentionDropdown(false);
    }
  };

  const handleNote = async (): Promise<void> => {
    let replaceMention;
    let result: string = addNote || '';
    for (const key of mentionPost) {
      if (result.includes(key)) {
        result = result.replace(/<[^>]*>/g, '');

        const letterToReplace = new RegExp(key, 'gi');

        result = result?.replace(letterToReplace, `{mention=${key}}`);

        replaceMention = result;
      }
    }

    const dataNote = isAnonymous
      ? {
          text: replaceMention || addNote,
          commenter: 'Anonymous',
          mentions: mentionId || []
        }
      : {
          text: replaceMention || addNote,
          mentions: mentionId || []
        };

    if (editId) {
      if (isForCostumerProfile) {
        await updateCustomerNotes(customerId, editId, dataNote, 'notes');
      } else {
        await updateCandidateData(candidateId, editId, dataNote, 'notes');
      }
    } else if (isForCostumerProfile) {
      await postCustomerData(customerId, dataNote, type);
    } else {
      await postCandidateData(candidateId, dataNote, type);
    }

    setAddNote('');
    setEditId('');

    if (isForCostumerProfile) {
      dispatch(getCustomerNotes(customerId));
    } else {
      dispatch(getCandidateNotes(candidateId));
    }
  };

  const deleteItemHTMLContent = (text: string): string => {
    const replacedText = text.replace(
      /\{mention=(.*?)\}/g,
      '<span contentEditable="false" class="highlighted">$1</span>'
    );

    const finalHTML = `<div>${replacedText}</div>`;

    return finalHTML;
  };

  const handleDelete = async (deleteOne: string): Promise<void> => {
    try {
      const deleteFunction = isForCostumerProfile
        ? deleteCustomerData
        : deleteCandidateData;
      const response = await deleteFunction(customerId, deleteOne, 'notes');
      showOnRequestMessage(dispatch, response, 'deleted');
      if (response) {
        if (isForCostumerProfile) {
          dispatch(getCustomerNotes(customerId));
        } else {
          dispatch(getCandidateNotes(candidateId));
        }
      }
    } catch (err: any) {
      showOnRequestMessage(dispatch);
    }
  };

  const handleEdit = async (
    editOne: string,
    editText: string
  ): Promise<void> => {
    setAddNote(deleteItemHTMLContent(editText));
    setEditId(editOne);
    dispatch(getCandidateNotes(candidateId));
  };

  useEffect(() => {
    setSearchedUsers(users?.results);
  }, [users]);

  const handleKeyPress = (event: any): void => {
    if (mentionDropdown) {
      if (event.key === 'ArrowDown') {
        event.preventDefault();

        if (inputRef.current) {
          inputRef.current.focus();
        }
        setSelectedSuggestion((prev: number): number =>
          prev < searchedUsers.length - 1 ? prev + 1 : prev
        );
      } else if (event.key === 'ArrowUp') {
        event.preventDefault();
        setSelectedSuggestion((prev: number) => (prev > 0 ? prev - 1 : prev));
      } else if (event.key === 'Enter') {
        event.preventDefault();

        if (selectedSuggestion >= 0) {
          handleAddMention(
            searchedUsers[selectedSuggestion]?.name ||
              searchedUsers[selectedSuggestion]?.first_name
              ? `${`${searchedUsers[selectedSuggestion]?.first_name}`}${
                  searchedUsers[selectedSuggestion]?.last_name
                }`
              : searchedUsers[selectedSuggestion]?.email,
            searchedUsers[selectedSuggestion]?.id
          );
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [mentionDropdown, selectedSuggestion, searchedUsers]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '5px' }}>
      <CandidateCardContainer title={title}>
        <div style={{ margin: '1rem 0' }}>
          {vacancyApp !== undefined && vacancyApp?.length === 0 ? (
            <p style={{ color: 'red' }}>Not Applied yet</p>
          ) : (
            vacancyApp?.map((item: any) => (
              <p key={item.vacancy_id}>
                Applied for {`"${item.vacancy_title}"`} on{' '}
                {item.applied_on.split('T')[0]}
                {item?.decline_reason && (
                  <span>
                    . {t('Declined due to')} "{t(item?.decline_reason)}"
                  </span>
                )}
              </p>
            ))
          )}
        </div>
      </CandidateCardContainer>
      <CandidateCardContainer iconSrc={iconSrc} title={addNoteTitle}>
        <div style={{ textAlign: 'right', marginTop: '15px' }}>
          <ContentEditable
            style={{
              width: '100%',
              maxHeight: 400,
              overflowY: 'auto',
              outline: 'none',
              border: '1px solid lightgray',
              padding: '10px',
              borderRadius: '30px',
              textAlign: 'start',
              wordBreak: 'brake-all'
            }}
            html={addNote}
            onChange={handleChange}
          />
          {mentionDropdown && (
            <Box
              style={{
                maxHeight: '400px',
                overflowY: 'auto',
                alignItems: 'start',
                display: 'flex',
                flexDirection: 'column',
                rowGap: '10px',
                width: 'fit-content',
                color: '#562C82',
                border: '1px solid rgba(0,0,0,0.1)',
                borderRadius: '5px',
                padding: '3px 5px',
                position: 'absolute',
                zIndex: 10,
                background: '#ffffff',
                minWidth: 250
              }}
              sx={{
                '::-webkit-scrollbar': {
                  width: 10
                },
                '::-webkit-scrollbar-thumb': {
                  background: '#562C82',
                  borderRadius: 10
                }
              }}
            >
              {searchedUsers?.length > 0 &&
                searchedUsers?.map((user: any, index: number) => (
                  <Box
                    ref={inputRef}
                    onClick={() =>
                      handleAddMention(
                        user?.name || user?.first_name
                          ? `${`${user?.first_name} `}${user?.last_name}`
                          : user?.email,
                        user?.id
                      )
                    }
                    style={{
                      cursor: 'pointer',
                      borderBottom: '1px solid rgba(0,0,0,0.1)',
                      padding: '5px',
                      minWidth: '100%',
                      display: 'flex',
                      columnGap: '10px',
                      alignItems: 'center',
                      backgroundColor:
                        selectedSuggestion === index
                          ? 'lightgray'
                          : 'transparent',
                      transition: 'background-color 0.3s'
                    }}
                    key={user.id}
                  >
                    <Avatar
                      alt={user?.first_name + user?.last_name || user?.email[0]}
                      src={
                        user?.avatar
                          ? user?.avatar
                          : user?.first_name[0] ||
                            user?.last_name[0] ||
                            user?.email[0]
                      }
                      style={{
                        width: '50px',
                        height: '50px',
                        borderRadius: '50%'
                      }}
                    />
                    {user?.name || user?.first_name
                      ? `${user?.first_name} ${user?.last_name}`
                      : user?.email}
                  </Box>
                ))}
            </Box>
          )}
          <Button
            variant="contained"
            style={{
              borderRadius: '30px',
              background:
                'linear-gradient(92.25deg, #9B5FDA -2.57%, #562C82 113.38%)',
              width: '100%',
              marginTop: '20px',
              padding: '10px'
            }}
            disabled={false}
            onClick={handleNote}
          >
            {editId ? `${UPDATENOTE} Note` : `${ADDNOTE} Note`}
          </Button>
        </div>
        <div
          className={classes.scrollBar}
          style={{
            margin: '1rem 0',
            maxHeight: '400px',
            overflow: 'auto'
          }}
        >
          {Array.isArray(dataList) &&
            dataList.length != 0 &&
            dataList.map((_item: any) => (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: '10px',
                  alignItems: 'center'
                }}
                key={_item.id}
              >
                <div
                  style={{
                    border: '1px solid lightgray',
                    padding: '10px 20px 10px 20px',
                    borderRadius: '30px',
                    margin: '10px 0',
                    width: '100%'
                  }}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: deleteItemHTMLContent(_item.text)
                    }}
                  />
                  <Description4 style={{ textAlign: 'end' }}>
                    {_item.commenter} - {fDateChat(_item.created_at)}
                  </Description4>
                </div>
                {user?.id == _item.owner && user.id && (
                  <>
                    <EditButton
                      onClick={() => handleEdit(_item.id, _item.text)}
                      disabled={isAnonymous}
                    />
                    <DeleteButton
                      onClick={() => handleDelete(_item.id)}
                      disabled={isAnonymous}
                    />
                  </>
                )}
              </div>
            ))}
        </div>
      </CandidateCardContainer>
    </Box>
  );
};
