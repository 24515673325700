import { Box, Grid, Link, Typography } from '@material-ui/core';

export default function EmptyImageContent({ href, xs, text }: any) {
  return (
    <Box
      style={{
        position: 'relative',
        zIndex: 1,
        left: '115px'
      }}
    >
      <img width="900px" src="/static/img/dashboard/emptyImage.png" alt="empty" />
      <Grid
        xs={xs}
        style={{ width: '163px' }}
        sx={{
          minHeight: '171px'
        }}
      >
        <Link
          href={href}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            left: '582px',
            bottom: '605px'
          }}
        >
          <Grid
            style={{
              backgroundImage: `url("/static/img/icons/plus.svg") `,
              width: '29.19px',
              height: '29.19px',
              marginBottom: '16px',
              zIndex: 2
            }}
          />
          <Typography
            sx={{
              color: '#562C82',
              fontSize: '16px',
              fontWeight: 700,
              fontFamily: 'Rubik',
              textAlign: 'center',
              zIndex: 2,
              maxWidth: '200px'
            }}
          >
            {text}
          </Typography>
        </Link>
      </Grid>
    </Box>
  );
}
