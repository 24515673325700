import { IProduct } from '../redux/slices/productsReducer';
import { fieldValidate } from '../helpers/formValidation';

export const makeDaysArrForSelect = (count: number, counterName: string) => {
  const arr = [];
  for (let i = 0; i < count; i++) {
    const obj = { id: i, name: `${i} ${counterName}${i > 1 ? 's' : ''}` };
    arr.push(obj);
  }
  return arr;
};

export const mapAutoFillArray = (
  autoFillArray: any[],
  vacancy: string,
  idKey: string,
  nameKey: string
) =>
  autoFillArray
    .filter((item: any) => item.jobPostId === vacancy)
    .map((item: any) => ({
      id: item[idKey],
      name: item[nameKey]
    }))[0];

export const formatProducts = (arr: Array<IProduct>) =>
  arr.map((item) => ({
    title: item.title,
    product_id: item.product_id,
    price: Number(item.vonq_price[0].amount)
  }));

export const handleSetValues = (
  setItemValues: (arg: any) => void,
  itemValues: any,
  requiredFields: any,
  setErrors: (errors: any) => void,
  errors: any,
  field: string,
  value: any
) => {
  setItemValues({
    ...itemValues,
    [field]: value
  });
  if (requiredFields.includes(field)) {
    const fieldIsEmpty = fieldValidate(value);
    if (fieldIsEmpty) {
      setErrors({
        ...errors,
        [field]: true
      });
    }
    if (!fieldIsEmpty && errors[field]) {
      const errorUpdate: any = {
        ...errors
      };
      delete errorUpdate[field];
      setErrors(errorUpdate);
    }
  }
};
