import { Button } from '@material-ui/core';
import useAuth from 'hooks/useAuth';
import { useDispatch, useSelector } from 'react-redux';
import { getAssessmentTest } from 'redux/slices/dialogs';
import useLocales from '../hooks/useLocales';
import { RootState } from '../redux/store';

export const TestAssesmentButton = () => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state: RootState) => state.dialogs);
  const { user } = useAuth();
  const { t } = useLocales();

  const onTestingStart = () => {
    if (user?.id) {
      dispatch(getAssessmentTest(user?.id));
    }
  };

  return (
    <>
      <Button
        sx={{ textTransform: 'capitalize' }}
        disabled={loading}
        onClick={(e) => {
          e.preventDefault();
          onTestingStart();
        }}
      >
        {t('Assessment Test Required')}
      </Button>
    </>
  );
};
