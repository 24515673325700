import { Pagination, Box } from '@material-ui/core';
import styled from '@emotion/styled';

const MainContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: '20px'
}));

const StylePagination = styled(Pagination)(({ theme }: any) => ({
  li: {
    button: {
      background: '#f5f7ff',
      border: '1px solid #c9cad9',
      borderRadius: '5px',
      width: '35px',
      height: '35px',
      fontFamily: 'Rubik',
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '19px',
      color: '#666666'
    }
  },
  '.Mui-selected': {
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: '#ffffff'
  }
}));

interface PaginationContainerProps {
  page: number;
  count: number;
  perPage: number;
  setPage: (value: number) => any;
}

const PaginationContainer = ({
  page,
  count,
  perPage,
  setPage
}: PaginationContainerProps) =>
  count && count > perPage ? (
    <MainContainer>
      <StylePagination
        className="pagination"
        count={Math.ceil(count / perPage)}
        page={page}
        variant="outlined"
        shape="rounded"
        showFirstButton
        showLastButton
        onChange={(el: any, value: number) => {
          setPage(value);
        }}
      />
    </MainContainer>
  ) : null;

export default PaginationContainer;
