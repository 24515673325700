import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  getInvoices,
  getPlans,
  getSubscription,
  getSubscriptionPortal,
  subscribeCheckout,
  subscribeOnPlan
} from '../../requests/subscriptions';
import { loadStripe, Stripe } from '@stripe/stripe-js';

const initialState: {
  stripePromise: Stripe | null;
  loading: boolean;
  plans: null | any[];
  subscription: any;
  invoices: any;
  submittedSessionId: string;
  stripePubKey: string;
} = {
  loading: false,
  plans: null,
  subscription: {
    plan: '',
    status: '',
    lastBillingDate: 0,
    nextBillingDate: 0,
    subscription_limit: {
      current_assessments: 0,
      assessment_limit: 0
    }
  },
  invoices: null,
  stripePromise: null,
  submittedSessionId: '',
  stripePubKey: ''
};

export const fetchPlans = createAsyncThunk('job/fetchPlans', async () =>
  getPlans()
);

export const fetchSubscription = createAsyncThunk(
  'job/fetchSubscription',
  async () => getSubscription()
);

export const fetchInvoices = createAsyncThunk('job/fetchInvoices', async () =>
  getInvoices()
);

export const fetchSubscribeOnPlan = createAsyncThunk(
  'job/fetchSubscribeOnPlan',
  async (id: string) => subscribeOnPlan(id)
);

export const fetchSubscribeCheckout = createAsyncThunk(
  'job/fetchSubscribeCheckout',
  async (id: string) => subscribeCheckout(id)
);

export const manageSubscription = createAsyncThunk(
  'job/manageSubscription',
  async () => getSubscriptionPortal()
);

const billingSlice = createSlice({
  name: 'billing',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPlans.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchPlans.fulfilled, (state, action) => {
        state.plans = action.payload.plans.data;
        const { stripe_pub_key } = action.payload;
        state.stripePubKey = stripe_pub_key;
        const res: any = loadStripe(stripe_pub_key || '');
        state.stripePromise = res;
      })
      .addCase(fetchSubscription.fulfilled, (state, action) => {
        state.subscription = action.payload;
      })
      .addCase(fetchInvoices.fulfilled, (state, action) => {
        state.invoices = action.payload.invoices;
        state.loading = false;
      })
      .addCase(fetchSubscribeOnPlan.fulfilled, (state, action) => {
        state.submittedSessionId = action.payload.sessionId;
      })
      .addCase(fetchSubscribeCheckout.fulfilled, (state, action) => {
        state.submittedSessionId = action.payload.sessionId;
      })
      .addCase(manageSubscription.fulfilled, (state, action) => {
        window.open(action.payload.url);
      });
  }
});

export default billingSlice.reducer;
