import { styled } from '@mui/material';
import { Box, Grid, Typography, Avatar } from '@material-ui/core';
import SvgIconStyle from 'components/SvgIconStyle';
import { Description4 } from 'components/text/title';

const Heading = styled(Box)(() => ({
  width: '100%',
  position: 'relative',
  borderRadius: 15,
  marginBottom: '20px'
}));

const ProfileHero = styled('div')(() => ({
  width: '100%',
  background: 'linear-gradient(92.25deg, #9B5FDA -2.57%, #562C82 113.38%)',
  padding: '24px 0 24px 33px',
  display: 'flex',
  columnGap: '120px',
  alignItems: 'center'
}));

const ProfileHeaderPDF = ({
  candidate,
  candidateAvatar,
  candidateName
}: any) => (
  <Heading>
    <ProfileHero>
      <Grid>
        <Avatar className="candidate-avatar-pdf" src={candidateAvatar} />
      </Grid>
      <Grid
        style={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '30px'
        }}
      >
        <Grid
          style={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '10px'
          }}
        >
          <Typography
            style={{
              fontWeight: 700,
              fontSize: '56px',
              marginTop: '-20px',
              textTransform: 'uppercase',
              color: '#FFFFFF'
            }}
          >
            {candidateName}
          </Typography>
          {candidate?.mainInfo?.vacancy_applications && (
            <Typography
              style={{
                fontWeight: 300,
                fontSize: '32px',
                textTransform: 'uppercase',
                color: '#FFFFFF'
              }}
            >
              {candidate?.mainInfo?.vacancy_applications[0]?.vacancy_title}
            </Typography>
          )}
        </Grid>
      </Grid>
      <Grid
        style={{
          margin: '-24px 0 auto auto',
          background: '#ffffff',
          borderBottomLeftRadius: '15px',
          padding: '0 10px 10px 10px'
        }}
      >
        <SvgIconStyle
          src="/static/img/illustrations/logo-new.jpg"
          style={{ width: '80px', margin: '0 auto' }}
        />
        <Description4
          style={{
            textAlign: 'center',
            margin: 0
          }}
        >
          powered by Recrout
        </Description4>
      </Grid>
    </ProfileHero>
  </Heading>
);
export default ProfileHeaderPDF;
