import { TFunction } from "react-i18next";

export const sumAmount = (fieldName: string, arr?: any) =>
  arr
    ?.map((item: any) => Number(item[fieldName]))
    .reduce((a: number, b: number) => a + b);

export const setValues = (
  field: string,
  value: any,
  values: any,
  setItemValues: (data: any) => void
) => {
  setItemValues({
    ...values,
    [field]: value
  });
};

export const getNumber = (i: number) => (i < 10 ? `0${i}` : `${i}`);

export const getWeeksDiff = (startDate: any, endDate: any) => {
  const msInWeek = 1000 * 60 * 60 * 24 * 7;

  return Math.round(Math.abs(endDate - startDate) / msInWeek);
};

export const getInitialJobPostData = (data: any, isNew: boolean) => ({
  curJobPost: {
    id: data?.id,
    user: data?.user,
    studies: data?.studies,
    title: data?.title,
    contract: data?.contract,
    description: data?.description,
    degree: data?.degree,
    work_exp: data?.work_exp,
    hourly_rate: data?.hourly_rate,
    salary: data?.salary,
    locations: data?.locations,
    industries: data?.industries,
    skills: data?.skills,
    musts: data?.musts,
    perks: data?.perks,
    status: data?.status,
    available: data?.available,
    drivers_license: data?.drivers_license,
    anonymous: data?.anonymous,
    customer: data?.customer,
    assessment_required: data?.assessment_required,
    culture_profile: data?.culture_profile,
    ...(isNew || data?.remote_job ? { remote_job: data?.remote_job } : {})
  },
  curCompetencies: {
    competency_labels: data?.competency_labels || [],
    competency_min_scores: data?.competency_min_scores || [],
    competency_ids: data?.competencies?.length
      ? data?.competencies.map((el: any) => el.competency.id)
      : [],
    descriptions: data?.competencies?.length
      ? data?.competencies.map((el: any) => el.competency.description)
      : []
  }
});

export const createExcel = async (workbook: any) => {
  const dataBuffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([dataBuffer], { type: 'application/octet-stream' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'merged-cells.xlsx';
  a.click();
};

export const drawTheCeil = (arr: any[], worksheet: any, row: number) => {
  for (let i = 0; i < arr.length; i++) {
    const cell = worksheet.getCell(row, i + 2);
    cell.value = arr[i];
    cell.alignment = { horizontal: 'center' };
  }
};

export const translateArrayOptionsLabels = (
  array: { value: string | number; label: string }[],
  formatter: TFunction<"translation", undefined>
) => {
  const res = array.map((el: { value: string | number; label: string }) => ({
    ...el,
    label: formatter(el.label)
  }));
  
  return res
};
