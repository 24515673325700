import moment from 'moment';

const MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

export const getMonth = () => {
  const weeksObject: any = {};
  const year = new Date().getFullYear();
  const start = moment().year(year - 1);
  const end = moment().year(year);
  const months: string[] = [];
  let index = 0;
  let week = -1;
  for (let i = end; i.isAfter(start); i.add(-1, 'day')) {
    if (i.week() != week) {
      week = i.week();
      index++;
    }
    const month = MONTHS[i.month()];
    const monthFind = months.find((el: any) => `${month}/${i.year()}` == el);
    if (!monthFind) {
      months.push(`${month}/${i.year()}`);
    }
    if (!weeksObject[`${MONTHS[i.month()]}/${i.year()}`]) {
      weeksObject[`${MONTHS[i.month()]}/${i.year()}`] = [
        { reverce: index, right: i.week(), month: i.month() }
      ];
    } else {
      const val = index;
      const element = weeksObject[`${MONTHS[i.month()]}/${i.year()}`].find(
        (el: any) => val == el.reverce
      );
      if (!element) {
        weeksObject[`${MONTHS[i.month()]}/${i.year()}`].push({
          reverce: index,
          right: i.week(),
          month: i.month()
        });
      }
    }
  }

  let arr: any = [];
  for (let i = 0; i < Object.values(weeksObject).length; i++) {
    const element: any = Object.values(weeksObject)[i];
    arr = [...element, ...arr];
  }

  return {
    sorted: arr.sort((a: any, b: any) => a.reverce - b.reverce),
    weeksWithName: weeksObject,
    months
  };
};
