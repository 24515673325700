import { createSlice } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from 'redux/store';
import { enqueueSnackbar } from './notifications';
import {
  CreateEvent,
  DeleteEvent,
  GetEvents,
  UpdateEventData
} from '../../requests/events';
import { store } from '../store';

const handleYearMonthFilter = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');

  const formattedDate = `${year}-${month}`;
  return formattedDate;
};

const initialState: any = {
  filters: {
    page: 1,
    offset: 0,
    short: false,
    query: '',
    year_month: handleYearMonthFilter(),
    periodicity: 'monthly',
    owner: 'me',
    limit: 10000
  },
  response: {
    results: [],
    count: 0,
    next: null,
    previous: null
  },
  subLoading: false,
  customer: {},
  notes: [],
  files: [],
  apiData: {
    share_url: '',
    id: ''
  }
};

export const getEventsData =
  (
    q?: string,
    year_month?: string,
    periodicity?: string,
    owner?: string,
    limit?: string
  ) =>
  async (dispatch: AppDispatch) => {
    dispatch(slice.actions.setLoading(true));
    GetEvents(q, year_month, periodicity, owner, limit).then((res) => {
      dispatch(slice.actions.getEventsSuccess(res));
      dispatch(slice.actions.setLoading(false));
    });
  };

export const setApplicantsFilter = (field: string, value: any) => () => {
  const updatedPage =
    field === 'page' || field === 'offset' || field === 'min_score'
      ? {}
      : { page: 1 };
  store.dispatch(
    slice.actions.setApplicantsOverviewFilter({
      [field]: value,
      ...updatedPage
    })
  );
};

export const addEvent = (data: any) => async (dispatch: AppDispatch) => {
  dispatch(slice.actions.setSubLoading(true));
  CreateEvent(data)
    .then((res) => {
      if (res) {
        dispatch(slice.actions.updateEvents(res));
      }
      return res;
    })
    .then((res) => {
      if (res) {
        dispatch(
          enqueueSnackbar({
            messageType: 'created',
            options: { variant: 'success' }
          })
        );
      }
      dispatch(slice.actions.setSubLoading(false));
    });
};

export const deleteEvent = (id: any) => async (dispatch: AppDispatch) => {
  dispatch(slice.actions.setSubLoading(true));
  DeleteEvent(id)
    .then(() => {
      dispatch(slice.actions.deleteEventsCalendar(id));
    })
    .then(() => {
      dispatch(
        enqueueSnackbar({
          messageType: 'deleted',
          options: { variant: 'success' }
        })
      );
      dispatch(slice.actions.setSubLoading(false));
    });
};

export const editEvent =
  (id: string, formData: any) => async (dispatch: AppDispatch) => {
    dispatch(slice.actions.setSubLoading(true));
    UpdateEventData(id, formData)
      .then((res) => {
        if (res) {
          dispatch(slice.actions.editEvents(res));
        }
        return res;
      })
      .then((res) => {
        if (res) {
          dispatch(
            enqueueSnackbar({
              messageType: 'updated',
              options: { variant: 'success' }
            })
          );
        }
        dispatch(slice.actions.setSubLoading(false));
      });
  };

const slice = createSlice({
  name: 'invitations',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setSubLoading(state, action) {
      state.subLoading = action.payload;
    },
    getEventsSuccess(state, action) {
      state.response = action.payload;
    },
    updateEvents(state, action) {
      state.response = [...state.response, action.payload];
    },
    editEvents(state, action) {
      state.response = [
        ...state.response?.map((el: any) =>
          el.id === action.payload.id ? action.payload : el
        )
      ];
    },
    deleteEventsCalendar(state, action) {
      state.response = [
        ...state.response?.filter((el: any) => el.id !== action.payload)
      ];
    },
    setApplicantsOverviewFilter(state, action) {
      state.filters = {
        ...state.filters,
        ...action.payload
      };
    }
  }
});

export default slice.reducer;
