import { useTranslation } from 'react-i18next';
import {
  MenuItem,
  TextField,
  Button,
  Popover
} from '@material-ui/core';

export type PropsType = {
  open: boolean;
  options: string[];
  onClose: () => void;
  anchorEl: HTMLElement | null;
  setInputValue: (value: string) => void;
  inputValue: string;
  handleKeyPress: (value: any) => void;
  handleMenueItemClick: (value: number) => void;
  onpentextInput: boolean;
  setOnpentextInput: (value: boolean) => void;
};

function MenueSelect({
  open,
  options,
  onClose,
  anchorEl,
  setInputValue,
  inputValue,
  handleKeyPress,
  handleMenueItemClick,
  setOnpentextInput,
  onpentextInput
}: PropsType) {
  const { t } = useTranslation();
  return (
    <Popover open={open} onClose={onClose} anchorEl={anchorEl}>
      {options?.map((el: any, index: number) => (
        <MenuItem onClick={() => handleMenueItemClick(index + 1)} key={index}>
          {el}
        </MenuItem>
      ))}
      <MenuItem>
        {!onpentextInput && (
          <Button onClick={() => setOnpentextInput(true)}>
            {t("Edit disqualify reason")}
          </Button>
        )}
        {onpentextInput && (
          <TextField
            onKeyDown={handleKeyPress}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
          />
        )}
      </MenuItem>
    </Popover>
  );
}

export default MenueSelect;
