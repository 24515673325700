import { Icon } from '@iconify/react';
import { useSnackbar } from 'notistack';
import { useRef, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { alpha } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Divider,
  MenuItem,
  Typography,
  useTheme
} from '@material-ui/core';
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import MyAvatar from '../../../components/MyAvatar';
import MenuPopover from '../../../components/MenuPopover';
import useLocales from '../../../hooks/useLocales';
import { MENU_OPTIONS } from '../../../constants/constants';
import { MIconButtonStyle } from '../../../styled';

export default function AccountPopover() {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const anchorRef = useRef(null);
  const { user, logout } = useAuth();
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useLocales();
  const theme = useTheme();

  const handleLogout = async () => {
    try {
      await logout();
      if (isMountedRef.current) {
        setOpen(false);
      }
    } catch (error) {
      enqueueSnackbar(t('Unable to logout'), { variant: 'error' });
    }
  };

  return user?.id ? (
    <>
      <MIconButtonStyle
        ref={anchorRef}
        onClick={() => {
          setOpen(true);
        }}
        sx={{
          padding: 0,
          width: 47,
          height: 47,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: alpha(theme.palette.grey[900], 0.72)
            }
          })
        }}
      >
        <MyAvatar sx={{ width: '100%', height: '100%' }} />
      </MIconButtonStyle>
      <MenuPopover
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {user.name}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user.email}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        {MENU_OPTIONS.map((option) => (
          <MenuItem
            key={option.label}
            to={option.linkTo}
            component={RouterLink}
            onClick={() => {
              setOpen(false);
            }}
            sx={{ typography: 'body2', py: 1, px: 2.5 }}
          >
            <Box
              component={Icon}
              icon={option.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24
              }}
            />

            {t(option.label)}
          </MenuItem>
        ))}

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button
            fullWidth
            color="inherit"
            variant="outlined"
            onClick={handleLogout}
          >
            {t('Logout')}
          </Button>
        </Box>
      </MenuPopover>
    </>
  ) : null;
}
