import { Card, Box } from '@material-ui/core';
import { InfoButton } from '@components/Buttons';
import { useState } from 'react';
import { ScoresTooltip } from '@components/ScoresTooltip';
import SliderComponent from '../../components/Slider';
import CultureProfileCharts from '../../components/CultureProfileCharts';
import {
  MAX_COMPETENCY_SLIDER_RANGE,
  MIN_COMPETENCY_SLIDER_RANGE
} from '../../constants/constants';

const CultureProfileSecondStep = ({
  selectedOptionsLabels,
  minRanges,
  setRanges,
  descriptions
}: any) => {
  const [openTooltip, setOpenTooltip] = useState(false);
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%'
      }}
    >
      <Card sx={{ p: 2, m: 1, width: '48%' }}>
        <Box sx={{ position: 'absolute', right: '16px', top: '16px' }}>
          <InfoButton onClick={() => setOpenTooltip(true)} />
        </Box>
        {selectedOptionsLabels.map((el: string, i: number) => (
          <SliderComponent
            key={i}
            title={el}
            minVal={MIN_COMPETENCY_SLIDER_RANGE}
            maxVal={MAX_COMPETENCY_SLIDER_RANGE}
            values={minRanges[i]}
            handleSetValues={(values: number[]) => setRanges(i, values)}
          />
        ))}
      </Card>
      <Card sx={{ p: 2, m: 1, width: '48%' }}>
        <CultureProfileCharts
          labels={selectedOptionsLabels}
          points={minRanges}
          withInfo={false}
          descriptions={descriptions}
          profile={true}
        />
      </Card>
      <ScoresTooltip open={openTooltip} onClose={() => setOpenTooltip(false)} />
    </Box>
  );
};

export default CultureProfileSecondStep;
