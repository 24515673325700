import { Grid, Typography } from '@material-ui/core';
import { FC, useEffect, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import useLocales from 'hooks/useLocales';
import styled from '@emotion/styled';
import MButton from '../new-designer/button/MButton';
import {
  getAllNotificationsMessages,
  getNotification,
  getUnRead
} from '../../redux/slices/chat';
import {
  getAllReadNotifications,
  getAsReadNotifications
} from '../../requests/Chat';
import NotificationMessage from './NotificationMessage';
import { fDateTime } from '../../utils/formatTime';

interface NotificationInterface {
  close: () => void;
  notifications: any;
  count: number;
}

const GridStyle = styled(Grid)(() => ({
  '&::-webkit-scrollbar': {
    display: 'none'
  }
}));

const ModalNotification: FC<NotificationInterface> = ({
  close,
  notifications,
  count
}) => {
  const [showAll, setShowAll] = useState(false);
  const { allNotifications } = useSelector((state: RootState) => state.chat);
  const { candidate } = useSelector((state: RootState) => state.candidate);
  const { user }: any = useSelector((state: RootState) => state.authJwt);

  const { t } = useLocales();

  const readAllNotifications = () => {
    getAllReadNotifications();
    getNotification();
    getUnRead();
  };
  const readNotifications = async (id: number) => {
    await getAsReadNotifications(id);
    getNotification();
    getUnRead();
  };

  const getAllNotifications = () => {
    setShowAll(!showAll);
    getAllNotificationsMessages();
  };

  useEffect(() => {
    getNotification();
  }, []);
  const massages: any = {};
  for (let i = 0; i < notifications?.length; i++) {
    const element = notifications[i];
    if (!massages[`${element?.id}`]) {
      massages[`${element?.id}`] = [element];
    }
  }

  const notificationsData = showAll
    ? allNotifications.results
    : notifications.results;

  return (
    <Grid
      style={{
        position: 'absolute',
        borderRadius: '10px',
        padding: '30px',
        zIndex: 1,
        display: 'flex',
        flexDirection: 'column',
        right: '18%',
        top: '85%',
        backgroundColor: '#FFFFFF',
        width: '440px',
        height: '440px'
      }}
    >
      <Grid
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}
      >
        <Grid
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'end',
            justifyContent: 'space-between',
            width: '100%'
          }}
        >
          <Typography
            style={{
              color: 'black',
              fontFamily: 'Rubik',
              fontWeight: 700,
              lineHeight: '24px',
              fontSize: '20px'
            }}
          >
            {`You have ${count || 'no'} unread message`}
          </Typography>
          <Typography
            onClick={close}
            style={{ display: 'flex', alignItems: 'end' }}
          >
            <CloseIcon
              color="primary"
              style={{ color: '#C9CAD9', cursor: 'pointer' }}
            />
          </Typography>
        </Grid>
      </Grid>
      <Grid
        style={{
          height: '1px',
          width: '100%',
          backgroundColor: '#C9CAD9',
          marginTop: '20px'
        }}
      />

      <GridStyle
        sx={{
          backgroundColor: 'grey',
          height: '300px',
          overflow: 'auto'
        }}
      >
        {notificationsData?.map((item: any, index: number) =>
          candidate.mainInfo.user != item[0] || user.role != 'candidate' ? (
            <Grid key={index} style={{ marginBottom: '10px' }}>
              <NotificationMessage
                text={item.verb}
                time={fDateTime(item.timestamp)}
                messageId={item?.id}
                context={item.description}
                readNotifications={readNotifications}
              />
            </Grid>
          ) : (
            <></>
          )
        )}
      </GridStyle>
      <Grid
        style={{
          height: '1px',
          width: '100%',
          backgroundColor: '#C9CAD9',
          marginTop: '20px'
        }}
      />
      <Grid
        sx={{
          marginTop: '10px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center'
        }}
      >
        <MButton
          click={readAllNotifications}
          title={t('Read Everything')}
          MyClass="violetButton"
          style={{
            background: 'white',
            color: '#562C82',
            border: '2px solid #562C82',
            marginRight: '13px'
          }}
        />
        <MButton
          title={`Show ${showAll ? 'Unread' : 'All'}`}
          click={getAllNotifications}
          MyClass="violetButton"
        />
      </Grid>
    </Grid>
  );
};
export default ModalNotification;
