import { createSlice } from '@reduxjs/toolkit';
import { AppDispatch } from 'redux/store';
import { getAssessmentTestLink } from '../../requests/candidate';
import { LIMIT_ASSESSMENT_ERROR_MESSAGE } from '../../constants/constants';

const initialState: {
  loading: boolean;
  assesmentErrorMessage: { open: boolean; message: string };
} = {
  loading: false,
  assesmentErrorMessage: {
    open: false,
    message: ''
  }
};

const slice = createSlice({
  name: 'dialogs',
  initialState,
  reducers: {
    openAssesmentErrorMessage(state, action) {
      state.assesmentErrorMessage.message = action.payload;
      state.assesmentErrorMessage.open = true;
    },
    closeAssesmentErrorMessage(state) {
      state.assesmentErrorMessage.message = '';
      state.assesmentErrorMessage.open = false;
    },
    getAssessmentTest(state, action) {
      window.open(action.payload.test_logon_url);
    },
    setLoading(state, action) {
      state.loading = action.payload;
    }
  }
});

export const getAssessmentTest =
  (id: string | number) => async (dispatch: AppDispatch) => {
    dispatch(slice.actions.setLoading(true));
    getAssessmentTestLink(id)
      .then((res) => dispatch(slice.actions.getAssessmentTest(res)))
      .catch(() =>
        dispatch(
          slice.actions.openAssesmentErrorMessage(
            LIMIT_ASSESSMENT_ERROR_MESSAGE
          )
        )
      )
      .finally(() => dispatch(slice.actions.setLoading(false)));
  };

export const openAssesmentLimitDialog =
  (errMessage: string) => async (dispatch: AppDispatch) => {
    dispatch(slice.actions.openAssesmentErrorMessage(errMessage));
  };

export const closeAssesmentLimitDialog = () => (dispatch: AppDispatch) => {
  dispatch(slice.actions.closeAssesmentErrorMessage());
};

export default slice.reducer;
