import { Box, Card, Typography, Tooltip, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { HelpOutlineOutlined as HelpOutlineOutlinedIcon } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { useDropzone } from 'react-dropzone';
import DeleteIcon from '@mui/icons-material/Delete';
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import MButton from './new-designer/button/MButton';
import { IMG_PLACEHOLDER } from '../constants/constants';
import useLocales from '../hooks/useLocales';
import { CustomModal } from './CustomModal';
import { infoBoxStyle } from './FileUploader';

const ImageWrapper = styled('div')(() => ({
  padding: '20px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: 'column',
  height: '100%',
  backgroundColor: 'transparent'
}));

const modalContentStyle = {
  width: '100%',
  height: '100%',
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center'
};

const TextWrapperStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: 'column',
  color: '#C9CAD9'
};

const ImageUploader = ({
  index,
  label,
  prevValue,
  newValue,
  setValue,
  rounded,
  imageStyle,
  imageStyleBox,
  labelStyle,
  fileSize = 1000000,
  imgTypesText = 'Allowed *.jpeg, *.jpg, *.png, *.gif',
  fileTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/bmp'],
  styles,
  setSelectedFiles = false,
  onSubmit = undefined,
  hasTooltip = false,
  tooltipTitle = ''
}: any) => {
  const { enqueueSnackbar } = useSnackbar();
  const name = `image-${index}`;
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState<string>(prevValue || IMG_PLACEHOLDER);
  const [file, setFile] = useState<File | null>(null);

  const { t } = useLocales();

  const transformAcceptArray = (fileTypes: string[]) => {
    const mimeToExtensionMap: Record<string, string[]> = {
      'image/gif': ['.gif'],
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/png': ['.png'],
      'image/bmp': ['.bmp'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        ['.doc', '.docx'],
      'application/pdf': ['.pdf']
      // Add other MIME types and their extensions as needed
    };

    return fileTypes.reduce<Record<string, string[]>>((acc, mimeType) => {
      if (mimeToExtensionMap[mimeType]) {
        acc[mimeType] = mimeToExtensionMap[mimeType];
      }
      return acc;
    }, {});
  };

  const onDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      const selectedFile = acceptedFiles[0];
      setFile(selectedFile);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result as string);
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: transformAcceptArray(fileTypes),
    maxSize: fileSize,
    multiple: false,
    onDropRejected: () => {
      enqueueSnackbar(`Please add a file less than ${fileSize / 1000000}MB`, {
        variant: 'warning'
      });
    }
  });

  useEffect(() => {
    if (prevValue) {
      setImage(prevValue);
    }
  }, [prevValue]);

  useEffect(() => {
    if (newValue) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result as string);
      };
      if (newValue instanceof Blob) {
        reader.readAsDataURL(newValue);
      } else {
        setImage(newValue);
      }
    }
  }, [newValue]);

  const handleCancel = () => {
    setFile(null);
    setOpen(false);
  };

  const handleSubmit = () => {
    if (file) {
      setValue(file);
      onSubmit && onSubmit(file);
      setOpen(false);
      setFile(null);
    }
  };

  const handleDelete = () => {
    setFile(null);
    if (prevValue) {
      setImage(prevValue);
    } else setImage(IMG_PLACEHOLDER);
  };

  const isAFile = file instanceof File;

  const isAFilePreview = () =>
    /^data:(application\/pdf|application\/msword|application\/vnd.openxmlformats-officedocument.wordprocessingml.document)/.test(
      image
    );

  return (
    <>
      <Card
        style={{
          background:
            'linear-gradient(94.35deg, rgba(255, 255, 255, 0.8) -5.21%, rgba(255, 255, 255, 0.3) 114.12%)',
          boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.08)',
          borderRadius: '20px',
          height: '100%',
          pointerEvents: 'unset !important',
          ...imageStyle
        }}
      >
        {hasTooltip && (
          <Tooltip arrow placement="left" title={tooltipTitle}>
            <HelpOutlineOutlinedIcon
              sx={{
                width: '24px',
                height: '24px',
                color: 'rgb(86, 44, 130)',
                position: 'absolute',
                top: '16px',
                right: '16px'
              }}
            />
          </Tooltip>
        )}
        <ImageWrapper
          style={{
            background: styles
              ? 'linear-gradient(94deg, rgba(84, 46, 131, 0.07) -5.21%, rgba(255, 255, 255, 0.00) 114.12%)'
              : ''
          }}
        >
          <Typography
            style={{
              fontFamily: 'Rubik',
              fontWeight: 600,
              fontSize: '20px',
              marginBottom: '8px',
              ...labelStyle
            }}
          >
            {label}
          </Typography>
          {isAFilePreview() ? (
            <FileCopyIcon
              color="primary"
              sx={{
                width: '80px',
                height: '80px'
              }}
            />
          ) : (
            <Box
              id={name}
              className={`image-uploader ${
                rounded ? 'rounded' : 'roundedHero'
              }`}
              component="img"
              src={image}
              style={{
                ...imageStyleBox,
                cursor: 'pointer',
                width: '100%'
              }}
              onClick={() => setOpen(true)}
            />
          )}
          <Box sx={TextWrapperStyle}>
            <Typography
              variant="caption"
              style={{
                fontFamily: 'Rubik',
                fontSize: styles ? '14px' : '12px',
                color: styles ? '#9FA0AD' : '#666666'
              }}
            >
              {t('Click on image to update')}
            </Typography>
            <Typography
              variant="caption"
              style={{
                fontFamily: 'Rubik',
                fontSize: styles ? '14px' : '12px',
                color: styles ? '#9FA0AD' : '#666666'
              }}
            >
              {t(imgTypesText)}
            </Typography>
            <Typography
              variant="caption"
              style={{
                fontFamily: 'Rubik',
                fontSize: styles ? '14px' : '12px',
                color: styles ? '#9FA0AD' : '#666666'
              }}
            >
              {t('We recommend JPEG for non-transparent images')}
            </Typography>
            <Typography
              variant="caption"
              style={{
                fontFamily: 'Rubik',
                fontSize: styles ? '14px' : '12px',
                color: styles ? '#9FA0AD' : '#666666'
              }}
            >
              {t(`Max size of ${fileSize / 1000000}MB`)}
            </Typography>
          </Box>
        </ImageWrapper>
      </Card>
      <CustomModal open={open} onClose={handleCancel}>
        <Box sx={modalContentStyle}>
          <Box {...getRootProps()} sx={infoBoxStyle}>
            <img src="/static/img/illustrations/cloud.svg" alt="upload-avatar" />
            <input {...getInputProps()} />
            <Typography
              variant="h6"
              style={{ fontFamily: 'Rubik', fontWeight: 600, color: '#333333' }}
            >
              {t('Drag and drop a file here, or click to select one')}
            </Typography>
            <Box>
              <Typography
                variant="body2"
                style={{
                  fontFamily: 'Rubik',
                  fontWeight: 400,
                  color: '#666666'
                }}
              >
                {imgTypesText || t('Allowed *.jpeg, *.jpg, *.png, *.gif')}
              </Typography>
              <Typography
                variant="body2"
                style={{
                  fontFamily: 'Rubik',
                  fontWeight: 400,
                  color: '#666666'
                }}
              >
                {t(`Max size of ${fileSize / 1000000}MB`)}
              </Typography>
            </Box>
          </Box>
          {file && (
            <Box sx={{ marginTop: '20px', textAlign: 'center' }}>
              <Typography variant="body1" fontWeight={500} mb="16px">
                {file.name}
              </Typography>
              <Box sx={{ position: 'relative', display: 'inline-block' }}>
                {isAFile ? (
                  <FileCopyIcon
                    color="primary"
                    sx={{
                      width: '80px',
                      height: '80px'
                    }}
                  />
                ) : (
                  <img
                    src={image}
                    alt="Preview"
                    style={{ maxWidth: '100%', maxHeight: '300px' }}
                  />
                )}
                <IconButton
                  sx={{
                    position: 'absolute',
                    top: '-10px',
                    right: '-10px',
                    backgroundColor: 'red',
                    ':hover': { backgroundColor: 'white', color: 'red' },
                    color: 'white'
                  }}
                  onClick={handleDelete}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            </Box>
          )}
          <Box sx={{ display: 'flex', gap: '10px', marginTop: '20px' }}>
            <MButton
              title={t('Cancel')}
              MyClass="violetOutlined"
              click={handleCancel}
            />
            <MButton
              title={t('Submit')}
              MyClass="violetButton"
              click={handleSubmit}
            />
          </Box>
        </Box>
      </CustomModal>
    </>
  );
};

export default ImageUploader;
