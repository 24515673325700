import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Form, FormikProvider, useFormik } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';

import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  TextField
} from '@material-ui/core';
import { PATH_AUTH } from 'routes/paths';

import useAuth from 'hooks/useAuth';
import useIsMountedRef from 'hooks/useIsMountedRef';
import useLocales from 'hooks/useLocales';

import { emailError, passwordError } from 'utils/helpError';
import styled from '@emotion/styled';
import { ButtonPrimary } from 'components/new-designer/button/ButtonPrimary';
import { Description2 } from 'components/text/title';

type InitialValues = {
  email: string;
  password: string;
  remember: boolean;
  afterSubmit?: string;
};

const TextFieldStyle = styled(TextField)(() => ({
  '.MuiOutlinedInput-root': {
    background: '#FFFFFF',
    border: '1px solid #C9CAD9',
    borderRadius: '30px'
  }
}));

export default function LoginForm({ color, linkedin_signup_url }: any) {
  const { login } = useAuth();
  const { t } = useLocales();
  const isMountedRef = useIsMountedRef();
  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .required(t('Email address is required'))
      .email(t('Email must be a valid email address')),
    password: Yup.string().required(t('Password is required'))
  });

  const formik = useFormik<InitialValues>({
    initialValues: {
      email: '',
      password: '',
      remember: true
    },
    validationSchema: LoginSchema,
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
      try {
        login({
          email: values.email,
          password: values.password
        });

        if (isMountedRef.current) {
          setSubmitting(false);
        }
      } catch (error: any) {
        console.error(error);
        resetForm();
        if (isMountedRef.current) {
          setSubmitting(false);
          setErrors({ afterSubmit: error.code || error.message });
        }
      }
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } =
    formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        {linkedin_signup_url && (
          <>
            <Grid item width="100%">
              <Link href="/api/social-auth/login/linkedin-oauth2/">
                <ButtonPrimary
                  onClick={() => {}}
                  fullWidth
                  style={{
                    height: '50px',
                    borderRadius: '30px',
                    background: `linear-gradient(92.25deg, ${color}90 -2.57%, ${color} 113.38%)`
                  }}
                >
                  {t('Sign In With Linkedin')}
                </ButtonPrimary>
              </Link>
            </Grid>
            <Grid item xs={12} textAlign="center">
              <Description2 style={{ fontWeight: '900', fontSize: '24px' }}>
                {t('OR')}
              </Description2>
            </Grid>
          </>
        )}
        <Description2>{t('Email address')}</Description2>
        <TextFieldStyle
          fullWidth
          autoComplete="email"
          {...getFieldProps('email')}
          error={
            Boolean(touched.email && errors.email) ||
            emailError(errors.afterSubmit || '').error
          }
          helperText={
            (touched.email && errors.email) ||
            emailError(errors.afterSubmit || '').helperText
          }
          sx={{
            mb: 3,
            height: '84px'
          }}
        />
        <Description2>{t('Password')}</Description2>
        <TextFieldStyle
          fullWidth
          autoComplete="current-password"
          type={showPassword ? 'text' : 'password'}
          placeholder={t('Password')}
          {...getFieldProps('password')}
          InputProps={{
            endAdornment: (
              <InputAdornment>
                <IconButton onClick={handleShowPassword} edge="end">
                  <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                </IconButton>
              </InputAdornment>
            )
          }}
          error={
            Boolean(touched.password && errors.password) ||
            passwordError(errors.afterSubmit || '').error
          }
          helperText={
            (touched.password && errors.password) ||
            passwordError(errors.afterSubmit || '').helperText
          }
          sx={{
            height: '84px'
          }}
        />
        <Box
          sx={{
            my: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                {...getFieldProps('remember')}
                checked={values.remember}
              />
            }
            label={t('Remember me')}
          />

          <Link
            component={RouterLink}
            variant="subtitle2"
            to={PATH_AUTH.resetPassword}
          >
            {t('Forgot password?')}
          </Link>
        </Box>

        <ButtonPrimary
          type="submit"
          pending={isSubmitting}
          style={{
            minWidth: '130px',
            background: `linear-gradient(92.25deg, ${color}90 -2.57%, ${color} 113.38%)`
          }}
        >
          {t('Login')}
        </ButtonPrimary>
      </Form>
    </FormikProvider>
  );
}
