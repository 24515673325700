import { Box, Slider, Tooltip } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';

import {
  IT_SKILLS_PROFICIENCIES_CHOICES,
  PROFICIENCIES_CHOICES
} from '../constants/constants';

const ProficiencyCirce = styled('div')(({ theme }) => ({
  borderRadius: '50%',
  background: theme.palette.grey[400],
  width: 20,
  height: 20,
  marginRight: '1vh'
}));

const ActiveProficiencyCircle = styled('div')(({ theme }) => ({
  borderRadius: '50%',
  background: theme.palette.primary.main,
  width: 20,
  height: 20,
  marginRight: '1vh'
}));

const Proficiencies = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row'
}));

const ProgressSlider = styled(Slider)(() => ({
  padding: '10px 0',
  '.MuiSlider-track': {
    background: 'linear-gradient(92.25deg, #9B5FDA -2.57%, #562C82 113.38%)',
    height: '8px',
    borderRadius: '7px'
  },
  '.MuiSlider-rail': {
    background: 'rgba(86, 44, 130, 0.17)',
    borderRadius: '7px',
    height: '8px'
  },
  '.MuiSlider-thumbColorPrimary': {
    background: 'linear-gradient(92.25deg, #9B5FDA -2.57%, #562C82 113.38%)',
    height: '20px !important',
    width: '20px !important',
    marginTop: '-7px !important',
    marginLeft: '-14px !important'
  }
}));

interface LanguageProficiencyProps {
  proficiency: number;
  withoutBox?: boolean;
}

export const ProficiencyDisplay = ({
  proficiency,
  withoutBox
}: LanguageProficiencyProps) => (
  <Box sx={{ width: '150px', ...(withoutBox ? { marginTop: '10px' } : {}) }}>
    <Proficiencies>
      <Tooltip
        title={IT_SKILLS_PROFICIENCIES_CHOICES[proficiency - 1].label}
        key={IT_SKILLS_PROFICIENCIES_CHOICES[proficiency - 1].value}
      >
        <ProgressSlider
          disabled
          defaultValue={proficiency}
          max={IT_SKILLS_PROFICIENCIES_CHOICES.length}
          aria-label="Disabled slider"
        />
      </Tooltip>
    </Proficiencies>
  </Box>
);

export const ITSKillsProficiencySelect = ({ proficiency, onChange }: any) => (
  <Proficiencies>
    {IT_SKILLS_PROFICIENCIES_CHOICES.map(({ value, label }: any) => (
      <Tooltip title={label} key={value}>
        {proficiency >= value ? (
          <ActiveProficiencyCircle
            style={{ cursor: 'pointer' }}
            onClick={() => onChange(value)}
          />
        ) : (
          <ProficiencyCirce
            style={{ cursor: 'pointer' }}
            onClick={() => onChange(value)}
          />
        )}
      </Tooltip>
    ))}
  </Proficiencies>
);

export const ProficiencySelect = ({ proficiency, onChange }: any) => (
  <Proficiencies>
    {PROFICIENCIES_CHOICES.map(({ value, label }: any) => (
      <Tooltip title={label} key={value}>
        {proficiency >= value ? (
          <ActiveProficiencyCircle
            style={{ cursor: 'pointer' }}
            onClick={() => onChange(value)}
          />
        ) : (
          <ProficiencyCirce
            style={{ cursor: 'pointer' }}
            onClick={() => onChange(value)}
          />
        )}
      </Tooltip>
    ))}
  </Proficiencies>
);
