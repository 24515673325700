import React, { useEffect, useState } from 'react';
import useLocales from 'hooks/useLocales';
import { Grid, TextField, Typography } from '@material-ui/core';
import { ArcElement, Chart as ChartJS, Tooltip } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { Description4, Title1, TitlePrimary } from 'components/text/title';
import { Legend } from 'components/Legend';
import { initialCurrentJobPost } from './JobPost';
import { AutocompleteStyle } from '../../components/OverviewFilter';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { HrDivider } from '../../styled';
import { fetchApplicationStagesPie } from '../../redux/slices/dataAnalytics';
import XLSXButton from '../../components/XLSXButton';
import { getTranslatedList } from '../../constants/constants';
import * as ExcelJS from 'exceljs';
import { useSnackbar } from 'notistack';
import { getApplicationStagesPieForExcel } from 'requests/fixtures';
import { createExcel } from '../../utils/helpers';

ChartJS.register(ArcElement, Tooltip);

const JobApplicationStagesPie = () => {
  const [legendActive, setLegendActive] = useState(true);
  const { applicationStagesPie } = useSelector(
    (state: RootState) => state.dataAnalytics
  );
  const [allData, setAllData] = useState({
    existingJobPost: initialCurrentJobPost
  });
  const dispatch = useDispatch();
  const { allJobPosts } = useSelector((state: RootState) => state.job);
  const { STAGE_TYPES } = getTranslatedList();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useLocales();

  const setDataFromExistingPost = (data: any) => {
    dispatch(fetchApplicationStagesPie(data?.id));
    setAllData({
      existingJobPost: data
    });
  };

  const firstFields = {
    type: 'select',
    value: allData.existingJobPost,
    label: t('You can select one of the existing job posts'),
    onChange: (e: any, el: any) => {
      setDataFromExistingPost(el);
    },
    options: allJobPosts || [],
    helperText: t('You can select one of existing job-posts to auto fill form')
  };

  useEffect(() => {
    setDataFromExistingPost(null);
  }, []);

  const sum = applicationStagesPie?.data?.reduce(
    (sum: number, a: number) => sum + a,
    0
  );

  const onDownloadData = async () => {
    try {
      const res = await getApplicationStagesPieForExcel();
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Sheet 1');
      const cellA1 = worksheet.getCell('A1');
      cellA1.value = 'Job Post Name';
      worksheet.mergeCells('A1:A2');
      cellA1.alignment = { vertical: 'middle' };

      const obj: any = {};
      for (let i = 0; i < res.data.length - 1; i++) {
        if (
          !allData?.existingJobPost ||
          `${allData?.existingJobPost?.title}#${allData?.existingJobPost?.id}` ==
            `${res.data[i].vacancy__title}#${res.data[i].vacancy_id}`
        ) {
          if (!obj[`${res.data[i].vacancy__title}#${res.data[i].vacancy_id}`]) {
            obj[`${res.data[i].vacancy__title}#${res.data[i].vacancy_id}`] = [
              res.data[i]
            ];
          } else {
            obj[`${res.data[i].vacancy__title}#${res.data[i].vacancy_id}`].push(
              res.data[i]
            );
          }
        }
      }

      for (let i = 0; i < STAGE_TYPES.length; i++) {
        worksheet.mergeCells(1, i + 2, 2, i + 2);
        const cellTop = worksheet.getCell(1, i + 2);
        cellTop.value = STAGE_TYPES[i].label;
        const column = worksheet.getColumn(i + 2);
        column.width = Math.max(10, STAGE_TYPES[i].label.length + 4);
        column.alignment = { vertical: 'middle', horizontal: 'center' };
        for (let index = 0; index < Object.keys(obj).length; index++) {
          const jobPostName = Object.keys(obj)[index].split('#')[0];
          const cell = worksheet.getCell(index + 3, 1);
          cell.value = jobPostName;
          const element = obj[Object.keys(obj)[index]].find(
            (el: any) => el.stage == STAGE_TYPES[i].value
          );
          if (!element) {
            const cell = worksheet.getCell(index + 3, i + 2);
            cell.value = 0;
          } else {
            const cell = worksheet.getCell(index + 3, i + 2);
            cell.value = element.total;
          }
        }
      }

      const columnA = worksheet.getColumn('A');
      columnA.width = 40;
      await createExcel(workbook);
    } catch (err) {
      enqueueSnackbar((err as string), { variant: 'error' });
    }
  };

  return (
    <Grid>
      <XLSXButton
        available={applicationStagesPie?.data?.some((el: number) => el !== 0)}
        onClick={onDownloadData}
        style={{ right: '50px', bottom: '30px' }}
      />
      <Grid item xs={12}>
        <Title1>{t('Candidate stage breakdown per job post')}</Title1>
        <HrDivider />
      </Grid>
      <Grid xs={6}>
        <Typography
          style={{
            fontFamily: 'Rubik',
            color: '#666666',
            marginBottom: '6px'
          }}
        >
          {firstFields.label}
        </Typography>
        <AutocompleteStyle
          style={{
            background: 'white',
            borderRadius: '10px',
            fontFamily: 'Rubik'
          }}
          fullWidth
          options={firstFields.options || []}
          getOptionLabel={(option: any) =>
            option?.label || option?.name || option?.title
          }
          value={firstFields.options?.find(
            (el: any) => firstFields?.value?.id === el.id
          )}
          onChange={firstFields.onChange}
          renderInput={(params: any) => (
            <TextField {...params} variant="outlined" />
          )}
        />
      </Grid>
      <Grid
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          paddingTop: '20px',
          rowGap: '20px',
          columnGap: '20px'
        }}
      >
        {applicationStagesPie?.data?.map((item: any, index: number) => (
          <Grid key={index} style={{ width: '192px', position: 'relative' }}>
            {legendActive && (
              <Grid
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '0',
                  textAlign: 'center',
                  width: '100%',
                  zIndex: -1
                }}
              >
                <Description4>
                  {applicationStagesPie?.labels[index]}
                </Description4>
                <TitlePrimary>{item}</TitlePrimary>
              </Grid>
            )}
            <Doughnut
              options={{
                cutout: '80%',
                animation: {
                  animateRotate: true,
                  duration: 2000
                },
                responsive: true,
                plugins: {
                  datalabels: {
                    display: false
                  },
                  legend: {
                    display: false
                  },
                  title: {
                    display: true
                  },
                  tooltip: {
                    callbacks: {
                      title: (data: any) => {
                        const { formattedValue } = data[0];
                        return formattedValue;
                      },
                      label: () => '',
                      footer: (data: any) => {
                        const { label } = data[0];
                        return label;
                      }
                    },
                    titleMarginBottom: 0,
                    footerColor: 'rgba(123, 72, 178, 0.52)',
                    titleFont: {
                      size: 20
                    },
                    footerFont: {
                      size: 10
                    },
                    padding: {
                      left: 20,
                      top: 10,
                      bottom: 10,
                      right: 20
                    },
                    titleColor: '#7B48B2',
                    backgroundColor: '#fff',
                    borderWidth: 1,
                    borderColor: 'rgba(128,128,128,0.2)',
                    cornerRadius: 10,
                    caretSize: 0
                  }
                }
              }}
              data={{
                labels: legendActive
                  ? [applicationStagesPie?.labels[index] || 'nothing', 'total']
                  : ['Empty Data'] || [],
                datasets: [
                  {
                    label: 'Legend 1',
                    data: legendActive ? [item, sum || 1] : [1],
                    borderRadius: 10,
                    borderWidth: 0,
                    backgroundColor: legendActive
                      ? ['#9B5FDA', 'rgba(137, 82, 195, 0.09)']
                      : ['rgba(137, 82, 195, 0.09)']
                  }
                ]
              }}
            />
          </Grid>
        ))}
      </Grid>
      <Legend
        setLegendActive={setLegendActive}
        legendActive={legendActive}
        title="Legend 1"
      />
    </Grid>
  );
};

export default JobApplicationStagesPie;
