import {
  deleteDataByUrl,
  getDataByUrl,
  ID,
  postDataByUrl,
  updateDataByUrl
} from 'requests';
import { MatchProfileCandidatesFiltersType } from 'types/Profile';

export const getProfiles = (isCulture = false, params: any = {}) =>
  getDataByUrl(`/api/match/profiles/`, { culture: isCulture, ...params });

export const getProfile = (id: ID, isCulture = false, params: any = {}) =>
  getDataByUrl(`/api/match/profiles/${id}/`, { culture: isCulture, ...params });

export const postProfile = (data: any, isCulture = false) =>
  postDataByUrl(`/api/match/profiles/`, data, { culture: isCulture });

export const updateProfile = (id: ID, data: any, isCulture = false) =>
  updateDataByUrl(`/api/match/profiles/${id}/`, data, { culture: isCulture });

export const deleteProfile = (id: ID) =>
  deleteDataByUrl(`/api/match/profiles/${id}/`);

export const getProfileCandidates = async (
  id: ID,
  limit?: number,
  offset?: number,
  query?: string,
  filterData?: MatchProfileCandidatesFiltersType
) =>
  postDataByUrl(
    `/api/match/profiles/${id}/find_candidates/`,
    { name: query || null, ...filterData },
    { limit, offset }
  );

export const postCandidateToBlacklist = (profileId: any, data: any) =>
  postDataByUrl(`/api/match/profiles/${profileId}/remove_candidate/`, data);

export const getSelectedCandidatesData = async (params: any) =>
  getDataByUrl(`/api/match/selected_candidates/`, params);

export const deleteSelectedCandidate = async (id: number) =>
  deleteDataByUrl(`/api/match/selected_candidates/${id}/`);

export const createSelectedCandidateStage = async (data: any) =>
  postDataByUrl(`/api/match/selected_candidates/`, data);

export const updateSelectedCandidateStage = async (id: ID, stage: number) =>
  updateDataByUrl(`/api/match/selected_candidates/${id}/`, { stage });
