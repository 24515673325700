import { Button } from '@material-ui/core';
import { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';

interface IButton {
  title: string;
  click?: () => void;
  MyClass?: string;
  style?: any | null;
  StartIcon?: any;
}

const MButton: FC<IButton> = ({ title, MyClass, style, click, StartIcon }) => {
  const violetGradient =
    'linear-gradient(92.25deg, #9B5FDA -2.57%, #562C82 113.38%)';

  const useStyles = makeStyles({
    violetButton: {
      fontFamily: 'Rubik',
      background: violetGradient,
      color: '#FFFFFF',
      borderRadius: '30px',
      width: '155px',
      height: '36px',
      fontWeight: 700,
      ...style
    },

    redButton: {
      fontFamily: 'Rubik',
      background: 'red',
      color: '#FFFFFF',
      borderRadius: '30px',
      width: '120px',
      height: '36px',
      '&.css-at8kyu-MuiButtonBase-root-MuiButton-root:hover': {
        backgroundColor: 'red'
      },
      ...style
    },
    blueButton: {
      fontFamily: 'Rubik',

      background: 'transparent',
      color: '#1C9DD7',
      fontWeight: 600,
      textTransform: 'capitalize',
      ...style
    },
    violetOutlined: {
      background: 'transparent',
      borderRadius: '30px',
      color: violetGradient,
      border: '2px solid',
      borderColor: violetGradient,
      width: '155px',
      height: '36px'
    }
  });
  const classes = useStyles();

  const className = () => {
    let classVar = classes.blueButton;

    if (MyClass === 'violetButton') {
      classVar = classes.violetButton;
    } else if (MyClass === 'violetOutlined') {
      classVar = classes.violetOutlined;
    } else if (MyClass === 'redButton') {
      classVar = classes.redButton;
    }

    return classVar;
  };

  return (
    <Button
      type="submit"
      className={className()}
      onClick={click}
      startIcon={StartIcon}
    >
      {title}
    </Button>
  );
};
export default MButton;
