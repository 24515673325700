import { ReactNode } from 'react';
import { Box, Modal } from '@material-ui/core';

interface CandidateModalProps {
  open: boolean;
  onClose?: () => void;
  children: ReactNode;
}

const CandidateModalContainer = ({
  open,
  onClose,
  children
}: CandidateModalProps) => (
  <Modal open={open} onClose={onClose}>
    <Box className="candidate-profile-edit-modal">
      <Box className="candidate-profile-edit-modal-card">{children}</Box>
    </Box>
  </Modal>
);

export default CandidateModalContainer;
