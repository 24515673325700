import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { Bar } from 'react-chartjs-2';
import { Title1 } from 'components/text/title';
import { Chart } from 'chart.js';
import { Legend } from 'components/Legend';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { fetchAnalyticsJobPostDurations } from '../../redux/slices/dataAnalytics';
import { HrDivider } from '../../styled';
import XLSXButton from '../../components/XLSXButton';
import * as ExcelJS from 'exceljs';
import { createExcel } from '../../utils/helpers';
import useLocales from 'hooks/useLocales';

Chart.register(ChartDataLabels);

const JobPostDurations = () => {
  const [legendActive, setLegendActive] = useState(true);
  const dispatch = useDispatch();
  const { analyticsJobPostDurations } = useSelector(
    (state: RootState) => state.dataAnalytics
  );
  const { t } = useLocales();
  useEffect(() => {
    dispatch(fetchAnalyticsJobPostDurations());
  }, []);

  const minLengthOfLabel =
    analyticsJobPostDurations.labels.length &&
    Math.min(
      ...analyticsJobPostDurations.labels.map((label: any) => label.length)
    );

  const onDownloadData = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet 1');
    const cellA1 = worksheet.getCell('A1');
    cellA1.value = 'Job Posts Name';

    worksheet.mergeCells('A1:A2');
    cellA1.alignment = { vertical: 'middle', horizontal: 'left' };
    const cellB1 = worksheet.getCell('B1');
    cellB1.value = 'Durations In Days';
    worksheet.mergeCells('B1:B2');
    cellB1.alignment = { vertical: 'middle', horizontal: 'center' };
    for (let i = 0; i < analyticsJobPostDurations.labels.length; i++) {
      const label = analyticsJobPostDurations.labels[i];
      const cell = worksheet.getCell(i + 3, 1);
      cell.value = label;
    }
    for (let i = 0; i < analyticsJobPostDurations.data.length; i++) {
      const value = analyticsJobPostDurations.data[i];
      const cell = worksheet.getCell(i + 3, 2);
      cell.value = value;
      cell.alignment = { horizontal: 'center' };
    }
    const columnA = worksheet.getColumn('A');
    columnA.width = 40;
    const columnB = worksheet.getColumn('B');
    columnB.width = 20;
    await createExcel(workbook);
  };

  return (
    <Grid>
      <XLSXButton
        available={analyticsJobPostDurations?.data?.some(
          (el: number) => el !== 0
        )}
        onClick={onDownloadData}
        style={{ right: '50px', bottom: '30px' }}
      />
      <Grid item xs={12}>
        <Title1>{t('Number of days your jobpost has been active')}</Title1>
        <HrDivider />
      </Grid>
      <Grid>
        <Bar
          height={200}
          options={{
            responsive: true,
            plugins: {
              datalabels: {
                display: true,
                color: 'white',
                align: 'start',
                anchor: 'end',
                font: {
                  size: 8
                }
              },
              legend: {
                display: false
              },
              tooltip: {
                callbacks: {
                  title: (data: any) => {
                    const { formattedValue } = data[0];
                    return formattedValue;
                  },
                  label: () => '',
                  footer: (data: any) =>
                    analyticsJobPostDurations?.labels[data[0].dataIndex]
                },
                titleMarginBottom: 0,
                footerColor: 'rgba(123, 72, 178, 0.52)',
                titleFont: {
                  size: 20
                },
                footerFont: {
                  size: 10
                },
                padding: {
                  left: 20,
                  top: 10,
                  bottom: 10,
                  right: 20
                },
                titleColor: '#7B48B2',
                backgroundColor: '#fff',
                borderWidth: 1,
                borderColor: 'rgba(128,128,128,0.2)',
                cornerRadius: 10,
                caretSize: 0
              },
              title: {
                display: true,
                text: t('DURATION IN DAYS'),
                align: 'start',
                color: '#1C9DD7',
                padding: {
                  bottom: 32
                },
                font: {
                  size: 20
                }
              }
            },
            scales: {
              y: {
                grid: {
                  display: false
                }
              },
              x: {
                grid: {
                  display: false
                }
              }
            }
          }}
          data={{
            labels:
              analyticsJobPostDurations?.labels?.map((label: any) => {
                const min = minLengthOfLabel > 10 ? 10 : minLengthOfLabel;
                return label.length > min
                  ? `${label.slice(0, min - 1)}...`
                  : label;
              }) || [],
            datasets: [
              {
                data: analyticsJobPostDurations?.data,
                label: 'Legend 1',
                backgroundColor: (context: any) => {
                  const { chart } = context;
                  const { ctx } = chart;
                  const gradient1 = ctx.createLinearGradient(0, 0, 0, 1000);
                  gradient1.addColorStop(0, '#9B5FDA');
                  gradient1.addColorStop(1, '#562C82');
                  return legendActive ? gradient1 : '#ffffff00';
                },
                borderWidth: 0,
                barPercentage: legendActive ? 0.6 : 0
              }
            ]
          }}
        />
      </Grid>
      <Legend
        setLegendActive={setLegendActive}
        legendActive={legendActive}
        title="Legend 1"
      />
    </Grid>
  );
};

export default JobPostDurations;
