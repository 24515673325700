import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import { TextField } from '@material-ui/core';
import useIsMountedRef from 'hooks/useIsMountedRef';
import { emailError } from 'utils/helpError';
import { resetPassword } from 'requests/account';
import useLocales from 'hooks/useLocales';
import { useSnackbar } from 'notistack';
import styled from '@emotion/styled';
import { ButtonPrimary } from 'components/new-designer/button/ButtonPrimary';
import { Description2 } from 'components/text/title';
import { InitialValues, ResetPasswordFormProps } from './type';

const TextFieldStyle = styled(TextField)(() => ({
  '.MuiOutlinedInput-root': {
    background: '#FFFFFF',
    border: '1px solid #C9CAD9',
    borderRadius: '30px'
  }
}));

export default function ResetPasswordForm({
  onSent,
  onGetEmail
}: ResetPasswordFormProps) {
  const { t } = useLocales();
  const isMountedRef = useIsMountedRef();

  const { enqueueSnackbar } = useSnackbar();

  const ResetPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('Email must be a valid email address'))
      .required(t('Email is required'))
  });

  const formik = useFormik<InitialValues>({
    initialValues: {
      email: ''
    },
    validationSchema: ResetPasswordSchema,
    onSubmit: async ({ email }, { setSubmitting }) => {
      try {
        await resetPassword({ email });
        if (isMountedRef.current) {
          onSent();
          onGetEmail(email);
          setSubmitting(false);
        } else {
          enqueueSnackbar(
            t('Incorrect email, or this email does not exist in any account'),
            { variant: 'error' }
          );
        }
      } catch {
        if (isMountedRef.current) {
          setSubmitting(false);
        }
      }
    }
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Description2>{t('Email address')}</Description2>
        <TextFieldStyle
          fullWidth
          {...getFieldProps('email')}
          type="email"
          error={
            Boolean(touched.email && errors.email) ||
            emailError(errors.afterSubmit || '').error
          }
          helperText={
            (touched.email && errors.email) ||
            emailError(errors.afterSubmit || '').helperText
          }
          sx={{ mb: 3 }}
        />

        <ButtonPrimary
          fullWidth
          type="submit"
          pending={isSubmitting}
          style={{ minWidth: '130px' }}
        >
          {t('Reset Password')}
        </ButtonPrimary>
      </Form>
    </FormikProvider>
  );
}
