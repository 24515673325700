import { createSlice } from '@reduxjs/toolkit';
import { store } from '../store';
import {
  DEGREE_TYPES,
  STATUS_OPTIONS,
  YEAR_CHOICES,
  CONTRACT_TYPES,
  SALARY_CHOICES,
  SOCIAL_MEDIAS,
  ROLE_CHOICES,
  GENDER_CHOICES,
  CANDIDATE_AVAILABILITY,
  PROFICIENCIES_CHOICES,
  IT_SKILLS_PROFICIENCIES_CHOICES,
  STAGE_TYPES,
  APPROVAL_TYPE,
  CANDIDATE_WORK_HOURS,
  CURRENCY_CHOICES,
  LANGUAGES,
  FREELANCE_HOURLY_RATES
} from '../../constants/constants';
import { setAppLoading } from './loading';

const slice = createSlice({
  name: 'fixturesSlice',
  initialState: {
    statuses: STATUS_OPTIONS,
    degreesStages: DEGREE_TYPES,
    experiences: YEAR_CHOICES,
    contracts: CONTRACT_TYPES,
    salaries: SALARY_CHOICES,
    hourly_rates: FREELANCE_HOURLY_RATES,
    socialMedias: SOCIAL_MEDIAS,
    roles: ROLE_CHOICES,
    genders: GENDER_CHOICES,
    availabilities: CANDIDATE_AVAILABILITY,
    work_hours: CANDIDATE_WORK_HOURS,
    proficiencies: PROFICIENCIES_CHOICES,
    skillProficiencies: IT_SKILLS_PROFICIENCIES_CHOICES,
    stages: STAGE_TYPES,
    approval: APPROVAL_TYPE,
    currency: CURRENCY_CHOICES,
    default_lang: LANGUAGES
  },
  reducers: {
    optionsSuccess(state, action) {
      state.degreesStages = action.payload.degreesStages;
      state.contracts = action.payload.contracts;
      state.experiences = action.payload.experiences;
      state.roles = action.payload.roles;
      state.genders = action.payload.genders;
      state.availabilities = action.payload.availabilities;
      state.work_hours = action.payload.work_hours;
      state.proficiencies = action.payload.proficiencies;
      state.skillProficiencies = action.payload.skillProficiencies;
      state.stages = action.payload.stages;
      state.statuses = action.payload.statuses;
      state.approval = action.payload.approval;
      state.default_lang = action.payload.default_lang;
    }
  }
});
export default slice.reducer;

// TODO: delete from store, use in autocomplete component directly

export const setTranslatedOptions = (options: any) => () => {
  store.dispatch(slice.actions.optionsSuccess(options));
};

export const getOptionsData = (opt: any) => async () => {
  const { dispatch } = store;
  dispatch(setAppLoading(true));
  dispatch(
    setTranslatedOptions({
      statuses: opt.STATUS_OPTIONS,
      degreesStages: opt.DEGREE_TYPES,
      experiences: opt.YEAR_CHOICES,
      contracts: opt.CONTRACT_TYPES,
      roles: opt.ROLE_CHOICES,
      genders: opt.GENDER_CHOICES,
      availabilities: opt.CANDIDATE_AVAILABILITY,
      work_hours: opt.CANDIDATE_WORK_HOURS,
      proficiencies: opt.PROFICIENCIES_CHOICES,
      skillProficiencies: opt.IT_SKILLS_PROFICIENCIES_CHOICES,
      stages: opt.STAGE_TYPES,
      approval: opt.APPROVAL_TYPE,
      default_lang: opt.LANGUAGES
    })
  );
  dispatch(setAppLoading(false));
};
