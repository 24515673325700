import { lazy } from 'react';
import { Navigate } from 'react-router';
import HomeLayout from '../layouts/home';
import { PATH_HOME } from './paths';

// ----------------------------------------------------------------------

// Lazy load your components separately
const LazyLandingPage = lazy(() => import('views/home/LandingPage'));
const LazyJobsPage = lazy(() => import('views/home/JobsPage'));
const LazyJobPage = lazy(() => import('views/home/JobPage'));

const HomeRoutes = {
  path: '*',
  layout: HomeLayout,
  routes: [
    {
      path: PATH_HOME.home,
      element: (
        <HomeLayout>
          <LazyLandingPage />
        </HomeLayout>
      ) // Wrap the lazy-loaded component
    },
    {
      path: PATH_HOME.jobs,
      element: (
        <HomeLayout>
          <LazyJobsPage />
        </HomeLayout>
      ) // Wrap the lazy-loaded component
    },
    {
      path: PATH_HOME.job,
      element: (
        <HomeLayout>
          <LazyJobPage />
        </HomeLayout>
      ) // Wrap the lazy-loaded component
    },

    // ----------------------------------------------------------------------

    { path: '*', element: <Navigate to="/404" replace /> }
  ]
};



export default HomeRoutes;
