import styled from '@emotion/styled';
import { Button } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';

const ButtonStyle = styled(Button)(() => ({
  fontWeight: 700,
  borderRadius: '30px',
  padding: '10px 30px',
  color: '#ffffff',
  background: 'linear-gradient(92.25deg, #9B5FDA -2.57%, #562C82 113.38%)',
  height: 'max-content',
  fontFamily: 'Rubik',
  fontStyle: 'normal',
  fontSize: '14px'
}));

const ButtonStyleLoad = styled(LoadingButton)(() => ({
  fontWeight: 700,
  borderRadius: '30px',
  padding: '10px 30px',
  color: '#ffffff',
  background: 'linear-gradient(92.25deg, #9B5FDA -2.57%, #562C82 113.38%)',
  height: 'max-content',
  fontFamily: 'Rubik',
  fontStyle: 'normal',
  fontSize: '14px'
}));

export const ButtonPrimary = ({ children, pending, ...rest }: any) =>
  pending != undefined ? (
    <ButtonStyle variant="text" {...rest}>
      {children}
    </ButtonStyle>
  ) : (
    <ButtonStyleLoad variant="text" pending={pending} {...rest}>
      {children}
    </ButtonStyleLoad>
  );
