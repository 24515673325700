import { Grid } from '@material-ui/core';
import { useFormFieldsByType } from 'helpers/formFields';
import useLocales from 'hooks/useLocales';
import { useEffect, useState } from 'react';
import { DynamicInput } from './DynamicForm';

interface IVonqForm {
  params: any;
  setParams: any;
  handleSetParams: any;
  fullForm?: boolean;
  width?: string;
}

const VonqFrom = ({
  params,
  fullForm,
  handleSetParams,
  width = ''
}: IVonqForm) => {
  const { t } = useLocales();
  const [jobFunctionDisableFlag, setJobFunctionDisableFlag] = useState(false);

  const makeDaysArrForSelect = (count: number) => {
    const arr = [];
    for (let i = 0; i < count; i++) {
      const obj = { id: i, name: `${i} day${i > 1 ? 's' : ''}` };
      arr.push(obj);
    }
    return arr;
  };

  const options = {
    durationFrom: [...makeDaysArrForSelect(101)],
    durationTo: [...makeDaysArrForSelect(101)]
  };

  const disabled = {
    jobFunctionId: jobFunctionDisableFlag
  };

  useEffect(() => {
    if (params.jobTitleId && params.jobTitleId.job_function) {
      setJobFunctionDisableFlag(true);
    } else {
      setJobFunctionDisableFlag(false);
    }
  }, [params]);

  const helperText = {
    includeLocationId: t('Enter the include location where this job is based, or where you would like to look for candidates '),
    exactLocationId: t('Enter the exact location where this job is based, or where you would like to look for candidates '),
    jobFunctionId: t('Select a matching job function (used to select the best performing channels)'),
    jobTitleId: t('Enter and select the job title that best describes the role (used to select the best performing channels)')
  };

  const fields = [
    ...useFormFieldsByType(
      fullForm ? 'vonq-products-full' : 'vonq-products',
      params,
      handleSetParams,
      options,
      {},
      helperText,
      disabled
    )
  ];

  return (
    <>
      {fields.map(
        (props: any, i: number) =>
          (i <= fields.length - 3 || !fullForm) && (
            <Grid
              item
              sx={{
                minWidth: width || '30%'
              }}
              key={`${props.label}-item`}
            >
              <DynamicInput {...props} />
            </Grid>
          )
      )}
      <Grid
        style={{
          width: '30%',
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        {fields.map(
          (props: any, i: number) =>
            i > fields.length - 3 &&
            fullForm && (
              <Grid
                item
                sx={{
                  minWidth: width || '45%'
                }}
                key={`${props.label}-item`}
              >
                <DynamicInput {...props} />
              </Grid>
            )
        )}
      </Grid>
    </>
  );
};

export default VonqFrom;
