import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  Typography
} from '@material-ui/core';
import {
  AddCircleOutline,
  BorderColor,
  DeleteOutline
} from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { fDateDHM } from 'utils/formatTime';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { DeleteButton, EditButton } from './Buttons';
import { Description2, Title1, Title3 } from './text/title';

const ToDoCard = ({
  toDoLIstItem,
  ToDoid,
  editToDo,
  deliteFild,
  width,
  height,
  setModalData,
  modal,
  setEdit,
  addNew = false
}: any) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  const getStatusInfo = (item: any) => {
    let color: string;
    let text: string;
    if (item?.completed) {
      color = '#8AE98A';
      text = 'Completed';
    } else {
      color = '#1C9DD7';
      text = 'In Progress';
    }
    return { color, text };
  };

  return (
    <Grid
      spacing={2}
      sx={{ width: width || '49%', height }}
      onClick={() => {
        addNew && setEdit(true);
      }}
      style={{ pointerEvents: 'unset' }}
    >
      <Card
        style={{
          padding: '25px',
          height: '100%',
          minHeight: '240px',
          pointerEvents: 'unset'
        }}
      >
        {addNew ? (
          <Grid
            style={{
              display: 'flex',
              width: '100%',
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column'
            }}
          >
            <AddCircleOutline style={{ color: '#562C82', fontSize: '40px' }} />
            <Title1 style={{ color: '#562C82' }}>{t('Add new to do')}</Title1>
          </Grid>
        ) : (
          <>
            <Grid
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: '25px'
              }}
            >
              <Grid
                style={{
                  background: `${getStatusInfo(toDoLIstItem).color}20`,
                  width: 'max-content',
                  padding: '10px 20px',
                  borderRadius: '30px'
                }}
              >
                <Typography
                  style={{
                    color: getStatusInfo(toDoLIstItem).color,
                    fontWeight: 'bold'
                  }}
                >
                  {t(getStatusInfo(toDoLIstItem).text)}
                </Typography>
              </Grid>
              <Box display="flex" alignItems="center" position="relative">
                {modal ? (
                  <Typography
                    onClick={() => {
                      setEdit(false);
                      setModalData(null);
                      const newPath = pathname.replace(/\d+/g, '');
                      navigate(newPath + location.search, { replace: true });
                    }}
                  >
                    <CloseIcon
                      color="primary"
                      style={{
                        color: '#C9CAD9',
                        cursor: 'pointer',
                        position: 'absolute',
                        top: '-30px',
                        right: '-10px',
                        width: '48px',
                        height: '25px'
                      }}
                    />
                  </Typography>
                ) : (
                  <Box
                    style={{
                      display: 'flex',
                      columnGap: '15px'
                    }}
                  >
                    <Box
                      onClick={() => editToDo(ToDoid)}
                      style={{ cursor: 'pointer' }}
                    >
                      <img
                        src="/static/img/icons/newEdit.svg"
                        color="#FF6868"
                        style={{
                          width: '25px',
                          height: '25px'
                        }}
                      />
                    </Box>
                    <Box
                      onClick={() => deliteFild(ToDoid)}
                      style={{ cursor: 'pointer' }}
                    >
                      <img
                        src="/static/img/icons/trash.svg"
                        color="#FF6868"
                        style={{
                          width: '25px',
                          height: '25px',
                          cursor: 'pointer'
                        }}
                      />
                    </Box>
                  </Box>
                )}
              </Box>
            </Grid>
            <Title3>{t(toDoLIstItem.title)}</Title3>
            <Description2>{fDateDHM(toDoLIstItem.created_at)}</Description2>

            <Divider style={{ margin: '20px 0' }} />
            <Description2
              style={{
                flex: '1',
                overflow: 'hidden',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                wordWrap: 'break-word',
                WebkitLineClamp: 2,
                textOverflow: 'ellipsis'
              }}
            >
              {t(toDoLIstItem.description)}
            </Description2>

            {modal && (
              <Grid
                style={{
                  margin: '20px 0 0 0',
                  display: 'flex',
                  columnGap: '15px'
                }}
              >
                <Box
                  onClick={() => setEdit(true)}
                  style={{ cursor: 'pointer' }}
                >
                  <img
                    src="/static/img/icons/newEdit.svg"
                    color="#FF6868"
                    style={{
                      width: '20px',
                      height: '20px'
                    }}
                  />
                </Box>
                <Box
                  onClick={() => deliteFild(ToDoid)}
                  style={{ cursor: 'pointer' }}
                >
                  <img
                    src="/static/img/icons/trash.svg"
                    color="#FF6868"
                    style={{
                      width: '20px',
                      height: '20px'
                    }}
                  />
                </Box>
              </Grid>
            )}
          </>
        )}
      </Card>
    </Grid>
  );
};

export default ToDoCard;
