import { motion } from 'framer-motion';
import { Link as RouterLink } from 'react-router-dom';
// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { Avatar, Box, Button, Container, Typography } from '@material-ui/core';
// components
import { MotionContainer, varBounceIn } from '../components/animate';
import Page from '../components/containers/Page';
import useLocales from '../hooks/useLocales';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10)
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  left: 0,
  lineHeight: 0,
  width: '100%',
  position: 'absolute',
  padding: theme.spacing(3, 3, 0),
  [theme.breakpoints.up('sm')]: { padding: theme.spacing(5, 5, 0) }
}));

// ----------------------------------------------------------------------

export default function AcceptPrivacy() {
  const { t } = useLocales();
  const cms: any = useSelector((state: RootState) => state.cms.base);

  return (
    <RootStyle>
      <HeaderStyle>
        <RouterLink to="/">
          <Avatar
            src={cms.logo}
            sx={{ mr: 1, width: '100px', height: '100px' }}
            alt="logo"
          />
        </RouterLink>
      </HeaderStyle>

      <Container>
        <MotionContainer initial="initial" open>
          <Box
            sx={{
              maxWidth: 480,
              margin: 'auto',
              textAlign: 'center',
              fontFamily: 'Rubik'
            }}
          >
            <motion.div variants={varBounceIn}>
              <Typography
                variant="h3"
                gutterBottom
                style={{ color: '#131417', fontFamily: 'Rubik' }}
              >
                {t('We are glad that you accepted our privacy terms')}
              </Typography>
            </motion.div>
            <Typography sx={{ color: 'text.secondary', fontFamily: 'Rubik' }}>
              {t('We will check-in with you in 11 months to see if you changed your ideas or not!')}
            </Typography>

            <Button
              style={{
                marginTop: '30px',
                background:
                  'linear-gradient(92.25deg, #9B5FDA -2.57%, #562C82 113.38%)',
                borderRadius: '30px',
                fontFamily: 'Rubik'
              }}
              to="/"
              size="large"
              variant="contained"
              component={RouterLink}
            >
              {t('Go to Home')}
            </Button>
          </Box>
        </MotionContainer>
      </Container>
    </RootStyle>
  );
}
