import { enqueueSnackbar } from '../redux/slices/notifications';

export const showOnRequestMessage = (
  dispatch: any,
  response?: any,
  type?: string
) => {
  if (response)
    dispatch(
      enqueueSnackbar({
        messageType: type,
        options: { variant: 'success' }
      })
    );
  else
    dispatch(
      enqueueSnackbar({
        messageType: 'error',
        options: { variant: 'error' }
      })
    );
};
