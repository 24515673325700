import { useMemo, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { SignUpTooltip } from 'components/authentication/register/components';
import useLocales from 'hooks/useLocales';
import { updateAccountData } from '../../../requests/account';
import { formValidate } from '../../../helpers/formValidation';
import {
  prepareFormData,
  useFormFieldsByType
} from '../../../helpers/formFields';
import { DynamicForm } from '../../DynamicForm';
import { enqueueSnackbar } from '../../../redux/slices/notifications';
import { showOnRequestMessage } from '../../../helpers/showMessageOnRequest';
import { getInitialValuesByType } from '../../../helpers/formInitialValues';
import { patchCandidateMainInfoData } from 'redux/slices/candidate';
import { handleSetValues } from '../../../utils/helpersForms';
import { FormField } from './type';

export const AccountInfoForm = ({
  initialData,
  id,
  options,
  onNext,
  candidateId
}: any) => {
  const { t } = useLocales();
  const [errors, setErrors] = useState<any>({});
  const [itemValues, setItemValues] = useState(
    getInitialValuesByType('account-short-info', initialData)
  );
  const dispatch = useDispatch();

  const handleSetValue = (field: string, value: any) =>
    handleSetValues(
      setItemValues,
      itemValues,
      requiredFields,
      setErrors,
      errors,
      field,
      value
    );

  const formFields: FormField[] = useFormFieldsByType(
    'account-short-info',
    itemValues,
    handleSetValue,
    options,
    errors
  );

  const requiredFields = useMemo(
    () =>
      formFields
        .filter((field: any) => !field.unRequired)
        .map((field: any) => field.name),
    [formFields]
  );

  const errorHandler = (errors: any) => {
    setErrors(errors);
    if (Object.keys(errors).length) {
      dispatch(
        enqueueSnackbar({
          messageType: 'required',
          options: { variant: 'warning' }
        })
      );
    }
  };

  const handleSubmit = async () => {
    const defValue = itemValues?.default_lang;
    const sendValues = { ...itemValues };
    delete sendValues.default_lang;

    if (formValidate(itemValues, errorHandler, requiredFields)) {
      const data = prepareFormData(sendValues);
      const data2 = { default_lang: defValue };
      dispatch(
        patchCandidateMainInfoData(candidateId, {
          ...data2
        })
      );
      try {
        const res = await updateAccountData(id, data);
        showOnRequestMessage(dispatch, res, 'updated');
        onNext();
      } catch (error: any) {
        enqueueSnackbar({
          message: error,
          options: { variant: 'error' }
        });
      }
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sx={{ position: 'relative' }}>
        <SignUpTooltip title={t('personal_info_tooltip')} />
        <DynamicForm
          inputValues={formFields}
          onSubmit={handleSubmit}
          fullWidth
          type="account-short-info"
        />
      </Grid>
    </Grid>
  );
};
