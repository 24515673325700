import { useState } from 'react';
import { useSelector } from 'react-redux';
import { NotificationAddOutlined } from '@material-ui/icons';
import { Grid, Typography } from '@material-ui/core';
import ModalNotification from './ModalNotification';
import { RootState } from '../../redux/store';

const Notification = () => {
  const [openNotification, setOpenNotification] = useState(false);
  const { notifications, unReadCount }: any = useSelector(
    (state: RootState) => state.chat
  );

  const openModal = () => {
    setOpenNotification(!openNotification);
  };



  return (
    <Grid>
      <Grid style={{ display: 'flex' }}>
        <NotificationAddOutlined
          style={
            notifications?.length === 0 ? { color: 'black' } : { color: 'red' }
          }
          onClick={() => openModal()}
        />
        <Typography
          style={{ color: 'red', fontSize: '12px', fontWeight: 'bold' }}
        >
          {unReadCount?.unread_count === 0 ? '' : unReadCount?.unread_count}
        </Typography>
      </Grid>
      {openNotification ? (
        <ModalNotification
          close={openModal}
          notifications={notifications}
          count={unReadCount?.unread_count}
        />
      ) : null}
    </Grid>
  );
};
export default Notification;
