import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
  cms: false,
  app: false,
  dashboard: false
};

const slice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    setCmsLoading(state, action) {
      state.cms = action.payload;
    },
    setAppLoading(state, action) {
      state.app = action.payload;
    },
    setDashboardLoading(state, action) {
      state.dashboard = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setCmsLoading,
  setAppLoading,
  setDashboardLoading
} = slice.actions;
