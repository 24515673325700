import settings from '@iconify/icons-ant-design/setting';
import key from '@iconify/icons-akar-icons/key';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import companySettings from '@iconify/icons-akar-icons/settings-horizontal';
import Page from '../../../components/containers/Page';
import TabPanel from '../../../components/TabPanel';
import GeneralProfileSettings from '../companySettings/generalSettings';
import ChangePassword from '../companySettings/changePassword';
import useAuth from '../../../hooks/useAuth';
import PageContainer from '../../../components/containers/PageContainer';
import useLocales from '../../../hooks/useLocales';
import DashboardNavbar from '../../../layouts/dashboard/navbar/DashboardNavbar';
import CompanyProfileSettings from '../companySettings/companySettings';

export default function UserSettings() {
  const { company }: any = useSelector(
    (state: RootState) => state.generalRecruiter
  );
  const { t } = useLocales();
  const { user } = useAuth();
  const { role } = user;
  const isAdmin = role === 'admin';
  const companyStatus = company.status === 'active' || 'trialing';

  const tabs = [
    {
      label: t('Company Info'),
      Component: <CompanyProfileSettings type="company-info"/>,
      icon: companySettings,
      hide: !(isAdmin && companyStatus && company.active),
      path: 'company-info'
    },
    {
      label: t('General Settings'),
      Component: <GeneralProfileSettings accountData={user} />,
      icon: settings,
      path: 'general-settings'
    },
    {
      label: t('Change Password'),
      Component: <ChangePassword />,
      icon: key,
      path: 'change-password'
    },
  ];

  const title = t('Account Settings');

  return (
    <Page title={title}>
      <PageContainer>
        <DashboardNavbar title={title} />
        <TabPanel tabs={tabs} />
      </PageContainer>
    </Page>
  );
}
