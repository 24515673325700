import useLocales from '@hooks/useLocales';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import { CustomModal } from './CustomModal';

export const ScoresTooltip = ({ open, onClose }: any) => {
  const { t } = useLocales();
  const rows = [
    { score: 1, percentage: 4, natural: t('Very low') },
    { score: 2, percentage: 7, natural: t('Low') },
    { score: 3, percentage: 12, natural: t('Low') },
    { score: 4, percentage: 17, natural: t('Average') },
    { score: 5, percentage: 20, natural: t('Average') },
    { score: 6, percentage: 17, natural: t('Average') },
    { score: 7, percentage: 12, natural: t('High') },
    { score: 8, percentage: 7, natural: t('High') },
    { score: 9, percentage: 4, natural: t('Very high') }
  ];
  return (
    <CustomModal open={open} onClose={onClose}>
      <Typography variant="h3" gutterBottom>
        {t('Competency information')}
      </Typography>
      <Typography variant="body2" gutterBottom>
        {t('Please contact us if you have any questions')}
      </Typography>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>{t('Score')}</TableCell>
            <TableCell>{t('Percentage of the normgroup')}</TableCell>
            <TableCell align="right">{t('Natural ability')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row: any) => (
            <TableRow key={row.score}>
              <TableCell>{row.score}</TableCell>
              <TableCell>{row.percentage}%</TableCell>
              <TableCell align="right">{row.natural}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </CustomModal>
  );
};
