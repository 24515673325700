import { useTranslation } from 'react-i18next';

export function getTexts() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { t } = useTranslation();
  return {
    getViewed: (view_count: number | null) =>
      t('Viewed {count} times').replace('{count}', `${view_count || 0}`),
    TITLE: t('Title'),
    STATUS: t('Status'),
    DESCRIPTION: t('Description'),
    TIME_PERIOD: t('Time period'),
    PERIODICITY: t('Periodicity'),
    SCHOOL: t('School'),
    INSTITUTE: t('Institute'),
    STUDY: t('Study'),
    DEGREE: t('Degree'),
    PROFICIENCY: t('Proficiency'),
    SKILL: t('Skill'),
    COMPANY: t('Company'),
    JOB_TITLE: t('Job Title'),
    JOB_POSTS: t('Job Posts'),
    JOB_POST: t('Job Post'),
    JOBS: t('Jobs'),
    JOB_DESCRIPTION: t('Job Description'),
    INDUSTRY: t('Industry'),
    CONTRACT: t('Contract'),
    LOCATION: t('Location'),
    WEBSITE: t('Website'),
    YEAR: t('Year'),
    RECEIVED: t('Received'),
    CANDIDATE: t('Candidate'),
    CANDIDATES: t('Candidates'),
    MANAGEMENT: t('Management'),
    USER: t('User'),
    VACANCIES: t('Vacancies'),
    SHOWCASE: t('Showcase'),
    MY_PROFILE: t('My profile'),
    JOB_POST_NEW: t('Create New Job Post'),
    HOME: t('Home'),
    SALARY_RANGE: t('Salary range'),
    CAREER_LEVEL: t('Career Level'),
    CUSTOMERS: t('Customers')
  };
}

export const moduleDescriptions: any = {
  pitch: 'moduleDescriptions pitch',
  preference: 'moduleDescriptions preference',
  work_experience: 'moduleDescriptions work_experience',
  education: 'moduleDescriptions education',
  language: 'moduleDescriptions language',
  extra: 'moduleDescriptions extra',
  certification: 'moduleDescriptions certification',
  it_skill: 'moduleDescriptions it_skill',
  social_media: 'moduleDescriptions social_media',
  social_media_expertise: 'moduleDescriptions social_media_expertise'
};
