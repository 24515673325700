import { ReactNode } from 'react';
import { Box, Modal } from '@material-ui/core';

interface ProductsModalCardContainerProps {
  open: boolean;
  onClose?: () => void;
  children?: ReactNode;
}

const ProductsModalCardContainer = ({
  open,
  onClose,
  children
}: ProductsModalCardContainerProps) => (
  <Modal open={open} onClose={onClose}>
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%;',
        overflow: 'auto'
      }}
    >
      <Box
        sx={{
          minHeight: '400px',
          maxHeight: '100%',
          width: '80%'
        }}
      >
        <Box
          sx={{
            py: 5,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  </Modal>
);

export default ProductsModalCardContainer;
