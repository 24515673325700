import { ReactNode } from 'react';
import styled from '@emotion/styled';
import { Box } from '@material-ui/core';

interface PageContainerProps {
  children: ReactNode;
  style?: any;
}

const MainContainer = styled(Box)(() => ({
  padding: '0 2vw',
  maxWidth: '1500px'
}));

const PageContainer = ({ style = {}, children }: PageContainerProps) => (
  <MainContainer style={style}>{children}</MainContainer>
);

export default PageContainer;
