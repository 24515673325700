import useLocales from '../../hooks/useLocales';
import useAuth from '../../hooks/useAuth';
import Page from '../../components/containers/Page';
import TabPanel from '../../components/TabPanel';
import PageContainer from '../../components/containers/PageContainer';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getShowcaseData } from 'redux/slices/showcase';
import ApplicantsOverview from './ApplicantsOverview';
import FindCandidates from './FindCandidates';
import TalentPool from './TalentPool';
import Messenger from './Messenger';
import SelectedCandidates from './SelectedCandidates';

const candidateApplicantsOverview = '/static/img/icons/applicationOverview.svg';
const candidateApplicantsOverviewActive =
  '/static/img/icons/applicationOverviewActive.svg';
const candidateOverview = '/static/img/icons/candidateOverview.svg';
const candidateOverviewActive = '/static/img/icons/candidateOverviewActive.svg';
const findCandidate = '/static/img/icons/findCandidate.svg';
const findCandidateActive = '/static/img/icons/findCandidateActive.svg';
const talentPool = '/static/img/icons/talentPool.svg';
const talentPoolActive = '/static/img/icons/talentPoolActive.svg';
const messange = '/static/img/icons/messanger.svg';
const messangerActive = '/static/img/icons/messangerActive.svg';

export default function Candidates() {
  const { t } = useLocales();
  const { user } = useAuth();
  const { role } = user;
  const dispatch = useDispatch();

  const isAdmin = role === 'admin';

  useEffect(() => {
    dispatch(getShowcaseData());
  }, [dispatch]);

  const tabs = [
    {
      label: t('Applicants Overview'),
      Component: <ApplicantsOverview style={{ padding: 0 }} />,
      icon: candidateApplicantsOverview,
      activeIcon: candidateApplicantsOverviewActive,
      // hide: !isAdmin,
      path: 'applicants',
      subTitle: t('Your selection of candidates for your jobs, go ahead and make someone happy today!')
    },
    {
      label: t('Candidates Overview'),
      Component: <SelectedCandidates style={{ padding: 0 }} />,
      icon: candidateOverview,
      activeIcon: candidateOverviewActive,
      hide: !isAdmin,
      path: 'candidates-overview',
      subTitle: t('These are the candidates that you selected from your talent pool')
    },
    {
      label: t('Find Candidates'),
      Component: <FindCandidates style={{ padding: 0 }} />,
      icon: findCandidate,
      activeIcon: findCandidateActive,
      hide: !isAdmin,
      path: 'find-candidates',
      subTitle: t('Manage your match profiles and search for candidates in your talent pool')
    },
    {
      label: t('Talent Pool'),
      Component: <TalentPool style={{ padding: 0 }} />,
      icon: talentPool,
      activeIcon: talentPoolActive,
      hide: !isAdmin,
      path: 'talent-pool'
    }
    // {
    //   label: t('Messenger'),
    //   Component: <Messenger />,
    //   icon: messange,
    //   activeIcon: messangerActive,
    //   hide: !isAdmin,
    //   path: 'messenger'
    // }
  ];

  return (
    <Page>
      <PageContainer>
        <TabPanel tabs={tabs} dashboard={true} />
      </PageContainer>
    </Page>
  );
}
