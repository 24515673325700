import { getDataByUrl, ID, postDataByUrl } from 'requests';

export const getSubscription = () => getDataByUrl(`/api/subscription/`);
export const getPlans = () => getDataByUrl(`/api/subscription/plans/`);
export const getInvoices = () => getDataByUrl(`/api/subscription/invoices/`);

export const subscribeOnPlan = (planID: ID) =>
  postDataByUrl(`/api/subscription/create/`, {
    planID
  });

export const subscribeCheckout = (planID: ID) =>
  postDataByUrl(`/api/subscription/checkout/session/`, {
    planID
  });

export const getSubscriptionPortal = () =>
  postDataByUrl(`/api/subscription/customer/portal/`);
