import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  addAutoFillValues,
  addProduct,
  delProduct
} from 'redux/slices/productsReducer';
import { RootState } from 'redux/store';
import Page from 'components/containers/Page';
import PageContainer from 'components/containers/PageContainer';
import HeadingContainer from 'components/containers/HeadingContainer';
import ProductCard from 'components/ProductCard/ProductCard';
import VonqFrom from 'components/VonqForm';
import LoadingBox from 'components/LoadingBox';
import ProductsModalCardContainer from 'components/containers/ProductsModalContainer';
import useLocales from 'hooks/useLocales';
import PaginationContainer from 'components/containers/PaginationContainer';
import { getVonqProducts } from 'utils/vonq';
import ProductsBasket from 'components/ProductsBasket';
import { OverviewSearch } from 'components/OverviewFilter';
import DashboardNavbar from 'layouts/dashboard/navbar/DashboardNavbar';
import { IAutocomplete, IProduct, IResult } from './types';
import { ButtonContainer } from '../../styled';

const StartCampaign = () => {
  const { t } = useLocales();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [listPage, setListPage] = useState('first');
  const [products, setProducts] = useState<Array<IProduct>>([]);
  const [recommendedProducts, setRecommendedProducts] = useState<
    Array<IProduct>
  >([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState(1);
  const perPage = 12;
  const offset = page * perPage - perPage;
  const currency = 'EUR';
  const [initialFiltersFlag, setInitialFiltersFlag] = useState(true);
  const [params, setParams] = useState<IAutocomplete>({
    includeLocationId: '',
    jobFunctionId: '',
    jobTitleId: '',
    industryId: '',
    durationFrom: '',
    durationTo: '',
    name: '',
    currency: ''
  });

  const setResult = useCallback(
    (res: IResult) => {
      setProducts(res?.results || []);
      setTotalCount(res.count);
      if (!res.count) {
        enqueueSnackbar(
          t('No products found. Select other filters and try again'),
          {
            variant: 'warning'
          }
        );
      }
      setInitialFiltersFlag(false);
    },
    [enqueueSnackbar, t]
  );

  const setRecommendedResult = useCallback(
    (res: IResult) => {
      setRecommendedProducts(res?.results || []);
      setTotalCount(res.count);
      if (!res.count) {
        enqueueSnackbar(
          t('No products found. Select other filters and try again'),
          {
            variant: 'warning'
          }
        );
      }
      setInitialFiltersFlag(false);
    },
    [enqueueSnackbar, t]
  );

  const setPlatformsList = useCallback(async () => {
    const dataForSubmit = {
      ...params,
      includeLocationId: params.includeLocationId?.id?.toString(),
      jobFunctionId: params.jobFunctionId?.id?.toString(),
      jobTitleId: '',
      industryId: params.industryId?.id?.toString(),
      durationFrom: params.durationFrom?.toString(),
      durationTo: params.durationTo?.toString(),
      currency,
      limit: perPage,
      offset
    };
    setLoading(true);
    if (listPage === 'second') {
      const res = await getVonqProducts(dataForSubmit);
      setResult(res);
    } else {
      const recommended = await getVonqProducts({
        ...dataForSubmit,
        recommended: true,
        offset: 0
      });
      setRecommendedResult(recommended);
    }
    setLoading(false);
  }, [params, setResult, offset, setRecommendedResult, listPage]);

  const handleSetParams = (field: string, value: any) => {
    setParams({
      ...params,
      [field]: value
    });
  };

  const jobPostFor = useSelector(
    (state: RootState) => state.productsReducer?.jobPostFor
  );

  const addToStore = (product: IProduct) => {
    jobPostFor && addProduct({ ...product, jobPostFor });
  };

  const delFromStore = (product: IProduct) => {
    delProduct(product.product_id, jobPostFor?.id);
  };

  useEffect(() => {
    if (!initialFiltersFlag) {
      (() => setPlatformsList())();
    }
  }, [params, initialFiltersFlag, setPlatformsList]);

  useEffect(() => {
    if (
      params.jobTitleId &&
      params.jobTitleId.job_function &&
      params.jobFunctionId?.id !== params.jobTitleId?.job_function?.id
    ) {
      setParams({ ...params, jobFunctionId: params.jobTitleId.job_function });
    }
  }, [params, setParams]);

  useEffect(() => {
    setPage(1);
  }, [params]);

  useEffect(() => {
    jobPostFor?.id &&
      addAutoFillValues(jobPostFor.id, params.jobFunctionId, 'jobCategory');
  }, [jobPostFor, params.jobFunctionId]);

  useEffect(() => {
    jobPostFor?.id &&
      addAutoFillValues(jobPostFor.id, params.industryId, 'industries');
  }, [jobPostFor, params.industryId]);

  const recommendedProductsList = recommendedProducts?.map(
    (product: IProduct) => (
      <ProductCard
        key={product.product_id}
        product={product}
        addToStore={() => addToStore(product)}
        delFromStore={() => delFromStore(product)}
      />
    )
  );

  const productsList = products?.map((product: IProduct) => (
    <ProductCard
      product={product}
      key={product.product_id}
      addToStore={() => addToStore(product)}
      delFromStore={() => delFromStore(product)}
    />
  ));

  const getList = () => {
    let res;
    if (loading) {
      res = <LoadingBox />;
    } else if (listPage === 'first') {
      res = recommendedProductsList;
    } else {
      res = productsList;
    }
    return res;
  };

  const title = t(`New campaign for ${jobPostFor?.title} job post`);

  return (
    <Page title={t('New campaign')}>
      <ProductsModalCardContainer
        open={initialFiltersFlag}
        onClose={() => setInitialFiltersFlag(false)}
      >
        <Card sx={{ padding: 2, maxWidth: '960px' }}>
          <CardContent sx={{ height: '100%' }}>
            <Typography variant="h3" sx={{ mb: 3 }}>
              {t("Let's target the right talent")}
            </Typography>
            <Typography variant="h6" sx={{ mb: 3 }}>
              {t(`We will use this information to recommend you the best performing
            channels, so you can target the right candidates. A draft campaign
            will be saved for two months.`)}
            </Typography>
            <Grid
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-between'
              }}
            >
              <VonqFrom
                params={params}
                setParams={setParams}
                handleSetParams={handleSetParams}
                width="49%"
              />
            </Grid>
            {loading ? (
              <LoadingBox />
            ) : (
              <Button
                variant="contained"
                sx={{ width: '100%', my: 3 }}
                style={{
                  background:
                    'linear-gradient(92.25deg, #9B5FDA -2.57%, #562C82 113.38%)',
                  borderRadius: '30px',
                  padding: '10px'
                }}
                onClick={() => setInitialFiltersFlag(false)}
              >
                <Typography>{t('Search')}</Typography>
              </Button>
            )}
          </CardContent>
        </Card>
      </ProductsModalCardContainer>

      <PageContainer>
        <DashboardNavbar title={t('New campaign')} />
        <HeadingContainer
          subtitle={jobPostFor?.id ? title : t('Please select a jop post')}
        />
        <Grid
          container
          spacing={3}
          style={{
            flexDirection: 'column',
            paddingLeft: '10px'
          }}
        >
          <Grid
            item
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              rowGap: '20px',
              justifyContent: 'space-between',
              paddingLeft: 0
            }}
          >
            <OverviewSearch
              title={' '}
              placeholder={t('Search by Name')}
              setValue={(value: any) => handleSetParams('name', value)}
              value={params.name}
              style={{
                minWidth: '30%'
              }}
            />
            <VonqFrom
              params={params}
              setParams={setParams}
              handleSetParams={handleSetParams}
              fullForm
            />
          </Grid>
          <Grid
            style={{
              padding: '40px 0'
            }}
          >
            <Button
              onClick={() => {
                setListPage('first');
              }}
              variant="text"
              style={{
                borderRadius: '30px',
                padding: '10px 30px',
                color: listPage === 'first' ? '#ffffff' : '#131417',
                background:
                  listPage === 'first'
                    ? 'linear-gradient(92.25deg, #9B5FDA -2.57%, #562C82 113.38%)'
                    : 'none'
              }}
            >
              {t('Media mix Recommendation')}
            </Button>

            <Button
              onClick={() => {
                setListPage('second');
              }}
              variant="text"
              style={{
                color: listPage !== 'first' ? '#ffffff' : '#131417',
                background:
                  listPage !== 'first'
                    ? 'linear-gradient(92.25deg, #9B5FDA -2.57%, #562C82 113.38%)'
                    : 'none',
                borderRadius: '30px',
                padding: '10px 30px'
              }}
            >
              {t('Explore Other Relevant Channels')}
            </Button>
          </Grid>
          <Grid item>
            <Grid
              container
              spacing={2}
              style={{ justifyContent: 'space-between', rowGap: '20px' }}
            >
              <Grid
                container
                xs={12}
                style={{
                  paddingLeft: 0,
                  alignItems: 'end',
                  justifyContent: 'space-between'
                }}
              >
                <Grid item>
                  <Typography
                    variant="h4"
                    sx={{
                      pt: 3,
                      textTransform: 'uppercase',
                      color: '#666666',
                      fontWeight: 500,
                      marginBottom: '10px'
                    }}
                  >
                    {listPage === 'first'
                      ? t('Media mix Recommendation')
                      : t('Explore other relevant channels')}
                  </Typography>
                  <Typography sx={{ color: '#666666', fontWeight: 500 }}>
                    {t(`This channel mix shows to be the best for your target audience
                  based on 60 million records of job performance data and
                  similar jobs`)}
                  </Typography>
                </Grid>
                <ButtonContainer>
                  <ProductsBasket buttonTitle={t('Go to basket')} />
                </ButtonContainer>
              </Grid>
              {getList()}
              <Grid item xs={12}>
                <Box sx={{ pt: 3 }}>
                  <PaginationContainer
                    page={page}
                    count={totalCount - 1}
                    perPage={perPage}
                    setPage={(value) => setPage(value)}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </PageContainer>
    </Page>
  );
};

export default StartCampaign;
