import { Tooltip, Box, Typography, IconButton } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { useEffect, useState } from 'react';
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import { getButtons } from '../constants/text/buttons';
import useLocales from '../hooks/useLocales';
import { ButtonPrimary } from './new-designer/button/ButtonPrimary';
import { CustomModal } from './CustomModal';
import MButton from './new-designer/button/MButton';

const modalStyle = {
  width: '100%',
  height: '100%',
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center'
};

const previewStyle = {
  maxWidth: '100%',
  maxHeight: '300px',
  marginTop: '20px',
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
};

const deleteButtonStyle = {
  position: 'absolute',
  top: '-20px',
  right: '-20px',
  backgroundColor: 'rgba(255, 255, 255, 0.8)'
};

export const infoBoxStyle = {
  background:
    'linear-gradient(94.35deg, rgba(84, 46, 131, 0.07) -5.21%, rgba(255, 255, 255, 0) 114.12%);',
  border: '2px dashed #cccccc',
  borderRadius: '20px',
  padding: '20px',
  textAlign: 'center',
  cursor: 'pointer',
  marginTop: '20px',
  width: '510px',
  height: '267px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '20px'
};

const FileUploader = ({
  title,
  variant = 'contained',
  sx = {},
  style,
  onSubmit,
  children,
  openFromOut = false,
  close,
  fileSize = 5000000,
  accept = [
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/pdf'
  ]
}: any) => {
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const { t } = useLocales();
  const { UPLOAD } = getButtons();
  // title = title ?? t('Please, upload file in .pdf or .docx format');

  const transformAcceptArray = (acceptArray: string[]) => {
    const mimeToExtensionMap: Record<string, string[]> = {
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        ['.docx'],
      'application/pdf': ['.pdf']
      // Add more MIME types and their extensions here if needed
    };

    return acceptArray.reduce<Record<string, string[]>>((acc, mimeType) => {
      if (mimeToExtensionMap[mimeType]) {
        acc[mimeType] = mimeToExtensionMap[mimeType];
      }
      return acc;
    }, {});
  };

  const handleClose = () => {
    setOpen(false);
    setFile(null);
    document.body.style.overflow = '';
  };

  useEffect(() => {
    if (close) {
      handleClose();
    }
  }, [close]);

  const onDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      setFile(acceptedFiles[0]);
    }
  };

  const handleSubmit = async () => {
    if (file) {
      await onSubmit(file);
      handleClose();
    }
  };

  const handleDeleteFile = () => {
    setFile(null);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: transformAcceptArray(accept),
    maxSize: fileSize,
    multiple: false
  });

  return (
    <>
      {!close && (
        <Tooltip title={title}>
          <ButtonPrimary
            sx={sx}
            style={style}
            variant={variant}
            onClick={() => setOpen(true)}
          >
            {children || UPLOAD}
          </ButtonPrimary>
        </Tooltip>
      )}
      <CustomModal
        open={open || openFromOut}
        onClose={() => {
          if (close) {
            setOpen(false);
          } else {
            handleClose();
          }
        }}
      >
        <Box sx={modalStyle}>
          <Typography variant="h6" component="h2">
            {title}
          </Typography>
          <Box {...getRootProps()} sx={infoBoxStyle}>
            <img src="/static/img/illustrations/cloud.svg" alt="upload-avatar" />
            <input {...getInputProps()} />
            <Typography
              variant="h6"
              style={{ fontFamily: 'Rubik', fontWeight: 600, color: '#333333' }}
            >
              {t('Drag and drop a file here, or click to select one')}
            </Typography>
            <Box>
              <Typography
                variant="body2"
                style={{
                  fontFamily: 'Rubik',
                  fontWeight: 400,
                  color: '#666666'
                }}
              >
                {t('Allowed *.pdf or *.docx')}
              </Typography>
              <Typography
                variant="body2"
                style={{
                  fontFamily: 'Rubik',
                  fontWeight: 400,
                  color: '#666666'
                }}
              >
                {t(`Max size of ${fileSize / 1000000}MB`)}
              </Typography>
            </Box>
          </Box>
          {file && (
            <Box
              sx={{
                marginTop: '20px',
                textAlign: 'center',
                position: 'relative'
              }}
            >
              <Typography variant="body1" fontWeight={500} mb="16px">
                {file.name}
              </Typography>
              <Box sx={previewStyle}>
                <FileCopyIcon
                  color="primary"
                  sx={{
                    width: '100px',
                    height: '100px'
                  }}
                />
                <IconButton
                  onClick={handleDeleteFile}
                  aria-label="delete"
                  sx={{
                    ...deleteButtonStyle,
                    backgroundColor: 'red',
                    ':hover': { backgroundColor: 'white', color: 'red' },
                    color: 'white'
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
              <Box sx={{ display: 'flex', gap: '10px', marginTop: '20px' }}>
                <MButton
                  title={t('Cancel')}
                  MyClass="violetOutlined"
                  click={() => handleClose()}
                />
                <MButton
                  title={t('Submit')}
                  MyClass="violetButton"
                  click={() => handleSubmit()}
                />
              </Box>
            </Box>
          )}
        </Box>
      </CustomModal>
    </>
  );
};

export default FileUploader;
