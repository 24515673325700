import {
  Avatar,
  Box,
  Button,
  FormControl,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
  Tooltip,
  Zoom,
  IconButton
} from '@material-ui/core';
import { Autocomplete } from '@mui/material';

import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { fDateChat } from 'utils/formatTime';
import { NotInterested } from '@material-ui/icons';
import {
  AttachFile as AttachFileIcon,
  DescriptionOutlined as DescriptionIcon,
  Clear as ClearIcon,
  Send as SendIcon,
  SaveOutlined as SaveOutlinedIcon,
  AutoAwesomeMosaicOutlined as AutoAwesomeMosaicOutlinedIcon
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { getAsReadNotifications } from 'requests/Chat';
import ReactHtmlParser from 'react-html-parser';
import { useDebounce } from 'hooks/useDebounce';
import {
  AutoCompleteStyle,
  CustomPaper,
  ReactQuillBody,
  getInitials
} from 'views/dashboard/Messenger';
import {
  addTemplateCount,
  deleteRoomChange,
  getMessengerTemplate,
  privateRoom,
  roomMessage,
  saveTemplate,
  sendMessage
} from '../redux/slices/chat';
import { RootState } from '../redux/store';
import useAuth from '../hooks/useAuth';
import { ApproveDialog } from './Dialog';

interface Sender {
  id: number;
  name: string;
  email: string;
  avatar: string;
}

interface Message {
  id: number;
  sender: Sender;
  sender_name: string;
  content: string;
  created_at: string;
  is_sender_candidate: boolean;
  if_notified: boolean;
  upload: string;
  room: number;
}

interface Template {
  id: number;
  label: string;
  name: string;
  content: string;
  content_plain: string;
  click_count: number;
  created_at: string;
  owner: number;
}

const IconStyle = { width: 22 };

const IconsWithHover = {
  ...IconStyle,
  color: '#C9CAD9',
  ':hover': { color: '#562C82' }
};

/* Deprecated (Previously used as a mini pop-up messenger )
    export const Chat = ({ showChat }: { showChat: boolean }) => {
      const { candidate } = useSelector((state: RootState) => state.candidate);
      const { rooms }: any = useSelector((state: RootState) => state.chat);
      const { messagesRoom }: any = useSelector((state: RootState) => state.chat);
      const {
        user: { role }
      } = useAuth();
      const { user }: any = useSelector((state: RootState) => state.authJwt);
      const [allRoom, setAllRooms] = useState<any>();
      const [valueMessage, setValueMessage] = useState<any>('');
      const [changeRoom, setChangeRoom] = useState<any>();
      const [arrayMessage, setArrayMessage] = useState<any>([]);
      useEffect(() => {
        scrollToBottom();
      }, [arrayMessage]);
      useEffect(() => {
        setArrayMessage(messagesRoom.results);
      }, [messagesRoom]);
      useEffect(() => {
        setAllRooms(rooms?.results);
      }, [rooms]);
      useEffect(() => {
        if (role === 'candidate' || role === 'employee') {
          const room = candidate.mainInfo.messenger_room_id;
          roomMessage(room);
        } else {
          privateRoom(candidate.mainInfo.email);
        }
      }, []);
      useEffect(() => {
        if (role === 'candidate' || role === 'employee') {
          console.log('test');
        } else {
          setAllRooms(rooms.results);
        }
      }, [rooms]);

      const messages = (room: any) => {
        roomMessage(room);
        setChangeRoom(room);
        setTimeout(() => {
          scrollToBottom();
        }, 8000);
      };
      const deleteChat = (room: any) => {
        deleteRoomChange(room);
      };

      const SendMessage = () => {
        if (role === 'candidate' || role === 'employee') {
          const data = {
            room: candidate.mainInfo.messenger_room_id,
            content: valueMessage
          };
          sendMessage(data, changeRoom, role);
          setValueMessage('');
        } else {
          const data = {
            room: changeRoom,
            content: valueMessage
          };
          sendMessage(data, changeRoom, role);
          setValueMessage('');
        }
      };
      useEffect(() => {
        let interval: any = '';
        if (showChat) {
          interval = setInterval(() => {
            roomMessage(candidate.mainInfo.messenger_room_id);
          }, 10000);
          return () => {
            clearInterval(interval);
          };
        }
        return () => {};
      });
      const messagesEndRef = useRef<null | HTMLDivElement>(null);

      const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
      };
      const keyEnter = (event: any) => {
        if (event.key === 'Enter') {
          SendMessage();
        }
      };
      useEffect(() => {
        if (allRoom !== undefined) {
          const room = allRoom[0]?.id;

          messages(room);
        } else {
          console.log('chek');
        }
      }, [allRoom]);
      return (
        <div>
          <Grid container>
            <Grid item xs={12} style={{ border: 'none', padding: '15px' }}>
              <Typography
                variant="h4"
                className="header-message"
                style={{ marginLeft: '20px', marginTop: '10px' }}
              >
                Chat
              </Typography>
            </Grid>
          </Grid>
          <Grid container component={Paper}>
            <Grid item xs={3}>
              <List>
                <ListItem button key="RemySharp">
                  <ListItemIcon>
                    <Avatar alt="Remy Sharp" src={user.avatar} />
                  </ListItemIcon>
                  <ListItemText primary={user.name} />
                </ListItem>
              </List>
              <Divider />
              <Divider />
              <List
                sx={{ height: '350px', overflowY: 'scroll', paddingLeft: '10px' }}
              >
                {allRoom?.map((item: any) => (
                  <Grid style={{ display: 'flex' }} key={item.id}>
                    <Typography
                      style={{ color: 'red' }}
                      onClick={() => deleteChat(item.id)}
                    >
                      X
                    </Typography>

                    <ListItem
                      button
                      key="RemySharp"
                      onClick={() => messages(item.id)}
                    >
                      <ListItemIcon>
                        <Avatar alt="Remy Sharp" src={item.candidate.avatar} />
                      </ListItemIcon>
                      <ListItemText primary={item.candidate.name}>
                        {item.candidate.name}
                      </ListItemText>
                    </ListItem>
                  </Grid>
                ))}
              </List>
            </Grid>

            <Grid item xs={8}>
              <List
                style={{ height: '330px', overflowY: 'scroll' }}
                innerRef={messagesEndRef}
              >
                {arrayMessage
                  ?.slice(0)
                  .reverse()
                  .map((message: any) => {
                    console.log(message);

                    return (
                      <ListItem key={message.id}>
                        <Grid container>
                          <Grid item xs={12}>
                            <ListItemTextStyle
                              primary={
                                <Grid>
                                  <Typography>{message.content}</Typography>
                                  <Box>{fDateChat(message.created_at)}</Box>
                                </Grid>
                              }
                              secondary={
                                user.id === message?.sender?.id && (
                                  <Avatar
                                    alt={message?.sender?.name}
                                    src={message?.sender?.avatar}
                                  />
                                )
                              }
                              me={user.id === message?.sender?.id}
                              style={
                                user.id === message?.sender?.id
                                  ? {
                                      display: 'flex',
                                      justifyContent: 'end',
                                      columnGap: '20px',
                                      color: '#ffffff'
                                    }
                                  : {
                                      color: '#131417',
                                      display: 'flex',
                                      flexDirection: 'row-reverse',
                                      columnGap: '20px',
                                      justifyContent: 'start'
                                    }
                              }
                            />
                          </Grid>
                        </Grid>
                      </ListItem>
                    );
                  })}

                <div ref={messagesEndRef} />
              </List>
              <Divider />

              <Grid container style={{ padding: '20px' }}>
                <Grid item xs={11}>
                  <TextField
                    id="outlined-basic-email"
                    label="Type Something"
                    fullWidth
                    value={valueMessage}
                    onChange={(e) => setValueMessage(e.target.value)}
                    onKeyDown={keyEnter}
                  />
                </Grid>
                <Grid xs={1}>
                  <Fab
                    color="primary"
                    aria-label="add"
                    onClick={() => SendMessage()}
                    style={{ marginLeft: '5px' }}
                  >
                    Send
                  </Fab>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      );
    };
*/

export const ProfileChat = ({ candidate, showChat, setShowChat }: any) => {
  const candidateMessengerRoomId = candidate.mainInfo
    ? candidate.mainInfo?.messenger_room_id
    : candidate?.messenger_room_id;

  const candidateEmail = candidate.mainInfo
    ? candidate.mainInfo.email
    : candidate.email;

  const { t } = useTranslation();
  const [showModal, setShowModal] = useState<boolean>(false);
  const { rooms }: any = useSelector((state: RootState) => state.chat);
  const { messagesRoom, notifications, templates } = useSelector(
    (state: RootState) => state.chat
  );
  const {
    user: { role }
  } = useAuth();
  const { user }: any = useSelector((state: RootState) => state.authJwt);
  const [allRoom, setAllRooms] = useState<any>();
  const [rawInput, setRawInput] = useState<string>('');
  const [valueMessage, setValueMessage] = useState<any>('');
  const [changeRoom, setChangeRoom] = useState<any>();
  const [arrayMessage, setArrayMessage] = useState<Message[]>([]);
  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [templateSearchInput, setTemplateSearchInput] = useState('');
  const [showTemplates, setShowTemplates] = useState(false);

  const toggleButtonRef = useRef<HTMLButtonElement>(null);
  const formControlRef = useRef<HTMLDivElement | null>(null);

  const debouncedValue = useDebounce(rawInput, 500);
  const debouncedTemplateSearchInput = useDebounce(templateSearchInput, 500);

  const handleTemplateSelect = (event: any, newValue: Template | null) => {
    if (newValue) {
      addTemplateCount(newValue.id);
      setRawInput((prevRawInput) => prevRawInput + newValue.content);
    }
  };

  useEffect(() => {
    if (debouncedTemplateSearchInput) {
      getMessengerTemplate(debouncedTemplateSearchInput);
    }
  }, [debouncedTemplateSearchInput]);

  const handleSearch = (event: any, newInputValue: string) => {
    setTemplateSearchInput(newInputValue);
  };

  const handleClickOutside = (event: any) => {
    const { target } = event;
    if (
      formControlRef.current &&
      !formControlRef.current.contains(target) &&
      !target.closest('.MuiAutocomplete-popper') &&
      toggleButtonRef.current &&
      !toggleButtonRef.current.contains(target)
    ) {
      setShowTemplates(false);
      setTemplateSearchInput('');
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setValueMessage(debouncedValue);
  }, [debouncedValue]);

  useEffect(() => {
    getMessengerTemplate('');
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [arrayMessage]);
  useEffect(() => {
    setArrayMessage(messagesRoom.results);
  }, [messagesRoom]);

  useEffect(() => {
    if (role === 'candidate' || role === 'employee') {
      const room = candidateMessengerRoomId;
      roomMessage(room);
    } else {
      privateRoom(candidateEmail);
    }
  }, []);

  useEffect(() => {
    if (role === 'candidate' || role === 'employee') {
      console.log('test');
    } else {
      setAllRooms(rooms.results);
    }
  }, [rooms]);

  const messages = (room: any) => {
    if (room) {
      roomMessage(room);
      setChangeRoom(room);
    }
    setTimeout(() => {
      scrollToBottom();
    }, 8000);
  };
  const deleteChat = (room: any) => {
    deleteRoomChange(room);
  };

  const SendMessage = () => {
    if (
      valueMessage &&
      valueMessage.replace(/<[^>]*>?/gm, '').trim() !== '' &&
      valueMessage.replace(/<[^>]*>?/gm, '') !== ('<br>' || '<br/>')
    ) {
      if (role === 'candidate' || role === 'employee') {
        const data = {
          room: candidateMessengerRoomId,
          content: valueMessage
        };
        sendMessage(data, changeRoom, role);
        setValueMessage('');
      } else {
        const formData = new FormData();

        if (selectedFile) {
          formData.append('room', changeRoom.toString());
          formData.append('content', valueMessage || '');
          formData.append('upload', selectedFile);
        }
        const data = selectedFile
          ? formData
          : { room: changeRoom, content: valueMessage };

        sendMessage(data, changeRoom, role);

        setRawInput('');
        setValueMessage('');
        setSelectedFile(undefined);
      }
    }
  };

  useEffect(() => {
    let interval: any = '';
    if (showChat) {
      interval = setInterval(() => {
        if (candidateMessengerRoomId) {
          roomMessage(candidateMessengerRoomId);
        }
      }, 10000);
      return () => {
        clearInterval(interval);
      };
    }
    return () => {};
  });
  const messagesEndRef = useRef<null | HTMLDivElement>(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    if (allRoom !== undefined) {
      const room = allRoom.find(
        (item: any) =>
          item?.candidate?.id === (candidate?.mainInfo?.id || candidate?.id)
      );
      if (room?.id) messages(room?.id);
    } else {
      console.log('chek');
    }
  }, [allRoom, candidate]);

  useEffect(() => {
    if (notifications && notifications.length) {
      for (let i = 0; i < notifications.length; i++) {
        const element = notifications[i];
        if (element?.id) {
          getAsReadNotifications(element.id);
        }
      }
    }
  }, [notifications]);

  const saveToTemplates = (valueMessage: any) => {
    if (
      !templates?.some(
        (t) => t.content.toLowerCase() === valueMessage.toLowerCase()
      )
    ) {
      saveTemplate({
        content: valueMessage
      });
    }
  };

  const validateFile = (file: File) => {
    const maxSize = 5 * 1024 * 1024;
    if (file.size > maxSize) {
      alert(t('File is too large. Max size is 1MB.'));
      return false;
    }
    const validTypes = [
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    ];
    if (!validTypes.includes(file.type)) {
      alert(t('Invalid file type. Only PDF, DOC, and DOCX are allowed.'));
      return false;
    }
    return true;
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      if (validateFile(file)) {
        setSelectedFile(file);
      }
    }
    event.target.value = '';
  };

  const extractFileName = (url: string) => {
    const messengerIndex = url.lastIndexOf('messenger/');
    if (messengerIndex === -1) return '';

    const [fileNameWithExtension] = url
      .substring(messengerIndex + 'messenger/'.length)
      .split(/[?#]/);

    return fileNameWithExtension;
  };

  return (
    <div>
      <Grid
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          marginTop: '30px',
          marginRight: '30px',
          cursor: 'pointer'
        }}
      >
        {role !== 'candidate' && (
          <Box display="flex" my="15px">
            <ApproveDialog
              open={showModal}
              title={t('Are you sure you want to delete conversation?')}
              onClose={() => setShowModal(false)}
              onApprove={() => {
                deleteChat(changeRoom);
                setShowModal(false);
                setShowChat(false);
              }}
            />
            <NotInterested
              sx={{ fontSize: '1.3 rem', color: 'red', mr: '5.5px' }}
            />
            <Typography
              style={{
                color: 'red',
                fontSize: '16px',
                cursor: 'pointer'
              }}
              onClick={() => setShowModal(true)}
            >
              {t('Delete Conversation')}
            </Typography>
          </Box>
        )}
      </Grid>
      <List className="custom_disabled_scroll" innerRef={messagesEndRef}>
        <>
          {arrayMessage &&
            arrayMessage
              ?.slice(0)
              .reverse()
              .map((message: any) => (
                <ListItem key={message.id}>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      style={
                        user?.id === message?.sender?.id
                          ? {
                              display: 'flex',
                              justifyContent: 'flex-end'
                            }
                          : {
                              display: 'flex',
                              justifyContent: 'flex-start'
                            }
                      }
                    >
                      <List
                        style={
                          user.id === message?.sender?.id
                            ? {
                                display: 'flex',
                                gap: '25px',
                                flexDirection: 'row-reverse',
                                marginRight: '14px'
                              }
                            : {
                                display: 'flex',
                                gap: '25px',
                                flexDirection: 'row',
                                marginLeft: '14px'
                              }
                        }
                      >
                        <ListItemIcon
                          sx={{
                            margin: 0,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            rowGap: '10px'
                          }}
                        >
                          <Typography>
                            {message?.sender?.name || message?.sender_name}
                          </Typography>
                          <Avatar
                            src={message?.sender?.avatar}
                            sx={{ height: '50px', width: '50px' }}
                          >
                            {getInitials(
                              message?.sender?.name || message?.sender_name
                            )}
                          </Avatar>
                        </ListItemIcon>

                        <ListItemText
                          primary={ReactHtmlParser(message.content)}
                          primaryTypographyProps={{
                            sx: { mb: '12px', textAlign: 'start' }
                          }}
                          secondaryTypographyProps={
                            user.id === message?.sender?.id
                              ? {
                                  sx: {
                                    textAlign: 'end',
                                    color: 'rgba(255, 255, 255, 0.6)'
                                  }
                                }
                              : {
                                  sx: {
                                    textAlign: 'start',
                                    color: '#C9CAD9'
                                  }
                                }
                          }
                          secondary={
                            <Box
                              display="flex"
                              flexDirection="column"
                              alignItems="start"
                            >
                              {fDateChat(message.created_at)}
                              {message.upload && (
                                <Box
                                  display="flex"
                                  flexDirection="column"
                                  alignItems="start"
                                  textAlign="left"
                                >
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    height={24}
                                    my="12px"
                                  >
                                    <DescriptionIcon />
                                    <Button>
                                      <a
                                        href={message.upload}
                                        download
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{
                                          textDecoration: 'none',
                                          color: 'inherit'
                                        }}
                                      >
                                        <Typography color="white">
                                          {t('Download')}
                                        </Typography>
                                      </a>
                                    </Button>
                                  </Box>
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      wordBreak: 'break-word',
                                      width: 'auto'
                                    }}
                                  >
                                    {extractFileName(message.upload)}
                                  </Typography>
                                </Box>
                              )}
                            </Box>
                          }
                          style={
                            user.id === message?.sender?.id
                              ? {
                                  flex: 'unset',
                                  padding: '20px',
                                  color: '#fff',
                                  backgroundColor: '#562C82',
                                  borderRadius: '10px',
                                  maxWidth: '439px',
                                  width: 'max-content',
                                  justifyContent: 'flex-end'
                                }
                              : {
                                  flex: 'unset',
                                  padding: '20px',
                                  textAlign: 'start',
                                  color: '#131417',
                                  backgroundColor: '#EBEBF1',
                                  borderRadius: '10px',
                                  maxWidth: '439px',
                                  width: 'max-content'
                                }
                          }
                        />
                      </List>
                    </Grid>
                  </Grid>
                </ListItem>
              ))}
        </>

        <div ref={messagesEndRef} />
      </List>

      <Grid
        sx={{
          backgroundColor: 'white',
          borderRadius: '30px',
          padding: 0,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
          marginTop: '20px'
        }}
      >
        <Grid position="relative">
          <ReactQuillBody
            placeholder={t('Type a message...')}
            value={rawInput}
            onChange={setRawInput}
            modules={{
              toolbar: [
                [{ font: [] }],
                [{ size: ['small', false, 'large', 'huge'] }],
                ['bold', 'italic', 'underline'],
                [{ list: 'ordered' }, { list: 'bullet' }],
                [{ color: [] }, { background: [] }],
                ['clean']
              ]
            }}
          />

          <input
            ref={fileInputRef}
            type="file"
            accept=".pdf, .doc, .docx"
            onChange={handleFileUpload}
            style={{ display: 'none' }}
          />
        </Grid>
        <Grid
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            alignItems: 'center',
            marginRight: '10px',
            paddingBottom: {
              xs: '35px',
              sm: '20px',
              md: '35px',
              lg: '25px'
            }
          }}
        >
          <Box
            sx={{
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
              paddingBottom: { xs: '10px', md: 0 }
            }}
          >
            {showTemplates && (
              <FormControl
                ref={formControlRef}
                sx={{
                  position: 'absolute',
                  top: '-58px',
                  right: 10,
                  minWidth: '362px',
                  maxWidth: '362px'
                }}
              >
                <Autocomplete
                  options={templates}
                  getOptionLabel={(option) => option.label}
                  open
                  id="template-select"
                  onInputChange={handleSearch}
                  onChange={handleTemplateSelect}
                  PaperComponent={CustomPaper}
                  sx={AutoCompleteStyle}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Search Templates" />
                  )}
                  renderOption={(props, option) => (
                    <li {...props}>{option.label}</li>
                  )}
                />
              </FormControl>
            )}
            <Box
              sx={{
                ':hover': {
                  '& #file-icon': {
                    display: 'none'
                  },
                  '& #clear': {
                    display: 'flex',
                    justifyContent: 'start'
                  },
                  '& #clear-icon': {
                    display: 'flex'
                  }
                }
              }}
            >
              <Box
                display="flex"
                alignItems="center"
                position="relative"
                mx={role !== 'candidate' ? 0 : 2}
              >
                {!selectedFile ? (
                  <Tooltip
                    arrow
                    placement="top"
                    TransitionComponent={Zoom}
                    title={t('Attach File')}
                  >
                    <IconButton
                      onClick={() => {
                        if (fileInputRef.current) {
                          fileInputRef.current.click();
                        }
                      }}
                      sx={{ padding: 0 }}
                    >
                      <AttachFileIcon sx={IconsWithHover} />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip
                    arrow
                    placement="top"
                    TransitionComponent={Zoom}
                    title={selectedFile.name}
                  >
                    <Box width={22} height={22} mb="2px">
                      <DescriptionIcon
                        id="file-icon"
                        sx={{ ...IconStyle, color: '#C9CAD9' }}
                      />

                      <Button
                        id="clear"
                        onClick={() => setSelectedFile(undefined)}
                        sx={{
                          position: 'absolute',
                          display: 'none',
                          padding: 0,
                          ':hover': { backgroundColor: 'transparent' }
                        }}
                      >
                        <ClearIcon
                          id="clear-icon"
                          sx={{
                            ...IconStyle,
                            color: 'red',
                            display: 'none'
                          }}
                        />
                      </Button>
                    </Box>
                  </Tooltip>
                )}
              </Box>
            </Box>

            {role !== 'candidate' && (
              <>
                <Tooltip
                  arrow
                  placement="top"
                  TransitionComponent={Zoom}
                  title={t('Insert Template')}
                >
                  <IconButton
                    ref={toggleButtonRef}
                    onClick={() => {
                      setShowTemplates(!showTemplates);
                    }}
                    sx={{ padding: 0 }}
                  >
                    <AutoAwesomeMosaicOutlinedIcon sx={IconsWithHover} />
                  </IconButton>
                </Tooltip>

                <Tooltip
                  arrow
                  placement="top"
                  TransitionComponent={Zoom}
                  title={t('Save Template')}
                >
                  <IconButton
                    onClick={() => saveToTemplates(valueMessage)}
                    sx={{ padding: 0, marginRight: { xs: 0, md: '10px' } }}
                  >
                    <SaveOutlinedIcon sx={IconsWithHover} />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </Box>
          <IconButton
            onClick={() => SendMessage()}
            style={{
              width: '35px',
              height: '35px',
              borderRadius: 60,
              background:
                'linear-gradient(92.25deg, #9B5FDA -2.57%, #562C82 113.38%)'
            }}
          >
            <SendIcon
              sx={{ color: 'white', width: '18px', paddingLeft: '2px' }}
            />
          </IconButton>
        </Grid>
      </Grid>
    </div>
  );
};
