/* eslint-disable no-nested-ternary */
import { Box, Grid } from '@material-ui/core';
import { AccountType } from 'types/Account';
import { styled } from '@mui/material';
import EmptyContent from 'components/EmptyContent';
import LoadingBox from 'components/LoadingBox';

const MainContainer = styled(Box)(() => ({
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  marginBottom: '20px'
}));

interface ListContainerProps {
  loading?: boolean;
  list?: any[] | null;
  card: any;
  getCardProps?: (item: any) => any;
  noDataText?: string;
  gridProps?: any;
  user?: AccountType | null;
  limit?: number;
  invitation?: boolean;
  cardCandidate?: boolean;
  candidateCards?:boolean;
  match?: boolean
}

const ListContainer = ({
  loading,
  list,
  card: Component,
  getCardProps,
  user,
  noDataText = 'No data',
  gridProps = {},
  invitation,
  cardCandidate = true,
  candidateCards,
  match = false,
}: ListContainerProps) => (
  <MainContainer style={cardCandidate ? { display: 'flex' } : {}}>
    <Grid
      container={cardCandidate}
      spacing={2}
      style={invitation ? { justifyContent: 'space-between' } : candidateCards ? { display:'block'}: {}}
    >
      {loading ? (
        <LoadingBox />
      ) : list && list.length ? (
        list.map((item) => {
          const props = getCardProps ? getCardProps(item) : {};
          return (
            <Grid item {...gridProps} key={item.id} style={{maxWidth: "100%"}} sx={match ? {width: '100%'} : {}}>
              <Component item={item} {...props} user={user} />
            </Grid>
          );
        })
      ) : (
        <EmptyContent title={noDataText} />
      )}
    </Grid>
  </MainContainer>
);

export default ListContainer;
