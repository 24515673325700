import { Skeleton } from '@material-ui/core';
import CultureProfileCharts from 'components/CultureProfileCharts';
import { Title1 } from 'components/text/title';

export const CandidateAssessmentForPDF = ({
  pageCount,
  repeat,
  currentGroups,
  assessmentLoading
}: {
  pageCount: number;
  repeat?: boolean;
  assessmentLoading: boolean;
  currentGroups: any
}) => {
  if (assessmentLoading)
    return (
      <Skeleton variant="rectangular" height={300} sx={{ borderRadius: 1 }} />
    );


  return (
    <div style={{
      margin: '0 auto', display: 'flex',
      flexWrap: 'wrap',
      padding: '0 50px 20px 50px',
      justifyContent: 'space-between',
      rowGap: '40px'
    }}>
      {currentGroups && Object.keys(currentGroups)?.map((key: string, index: number) =>
        index < pageCount + 4 && index >= pageCount || repeat ? <div key={key}>
          <Title1 style={{
            textAlign: 'center',
            color: '#562C82',
            fontWeight: 700,
            fontSize: '18px',
            marginBottom: '20px'
          }}>{key}</Title1>
          <div style={{
            width: '468px',
            height: '468px',
            background: 'rgba(155, 95, 218, 0.07)',
            borderRadius: '16.7018px',
          }}>
            <CultureProfileCharts
              descriptions={currentGroups[key]?.descriptions}
              labels={currentGroups[key]?.lables}
              points={currentGroups[key]?.data}
              profile={true}
              fromPDF={true}
            />
          </div>
        </div> : <></>
      )}
    </div>
  );
};
