import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import {
  Box,
  Link,
  Hidden,
  Container,
  Typography,
  Grid
} from '@material-ui/core';
import { PATH_AUTH } from '../../routes/paths';
import Page from '../../components/containers/Page';
import { LoginForm } from '../../components/authentication/login';
import { RootState } from '../../redux/store';
import useLocales from '../../hooks/useLocales';
import HomeNavbar from 'layouts/home/HomeNavbar';
import { Title8 } from 'components/text/title';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  margin: 0,
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    justifyContent: 'center'
  }
}));

const SectionStyle = styled(Grid)(({ theme }) => ({
  width: '100%',
  maxWidth: 650,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 8)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 600,
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0),
  marginLeft: '100px'
}));

// ----------------------------------------------------------------------

export default function Login() {
  const { t } = useLocales();
  const { logo , linkedin_signup_url}: any = useSelector(
    (state: RootState) => state.generalRecruiter.company
  );
  const { base }: any = useSelector(
    (state: RootState) => state.cms
  );
  const { primary_color } = base

  return (
    <RootStyle title={t('Login')}>
      <HomeNavbar logo={logo} login={true} />
      <Hidden>
        <SectionStyle>
          <img src="/static/img/illustrations/illustration_login.png" alt="login" />
        </SectionStyle>
      </Hidden>

      <Container
        style={{
          maxWidth: '800px',
          margin: 0
        }}
      >
        <ContentStyle>
          <Box sx={{ mb: 5, display: 'flex', alignItems: 'center' }}>
            <Box sx={{ flexGrow: 1 }}>
              <Title8 gutterBottom style={{ color: primary_color }}>
                {t('Sign in to Recrout')}
              </Title8>
            </Box>
          </Box>

          <LoginForm
            color={primary_color}
            linkedin_signup_url={linkedin_signup_url}
          />

          <Hidden smUp>
            <Typography variant="body2" align="center" sx={{ mt: 3 }}>
              {t('Don’t have an account')}?&nbsp;
              <Link
                variant="subtitle2"
                component={RouterLink}
                to={PATH_AUTH.register}
              >
                {t('Get started')}
              </Link>
            </Typography>
          </Hidden>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
