import { Box } from '@material-ui/core';
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton
} from 'react-share';

interface SharedButtonsType {
  url: string;
  title: string;
  description?: string;
  networks?: string[];
  size?: number;
  round?: boolean;
  fullWidth?: boolean;
  borderBottom?: boolean;
  borderTop?: boolean;
}

export const SharedButtons = ({
  url,
  title,
  description = '',
  networks = ['linkedin', 'facebook', 'twitter', 'email'],
  size = 32,
  round = true,
  fullWidth = false,
  borderBottom = false,
  borderTop = false
}: SharedButtonsType) => (
  <Box
    sx={
      fullWidth
        ? {
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2,
            borderTop: borderTop ? '1px solid #e0e0e0' : '',
            borderBottom: borderBottom ? '1px solid #e0e0e0' : ''
          }
        : {}
    }
  >
    {networks.includes('linkedin') && (
      <LinkedinShareButton
        style={{ marginRight: '5px' }}
        url={url}
        title={title}
        summary={description}
      >
        <LinkedinIcon round={round} size={size} />
      </LinkedinShareButton>
    )}
    {networks.includes('email') && (
      <EmailShareButton style={{ marginRight: '5px' }} url={url} title={title}>
        <EmailIcon round={round} size={size} />
      </EmailShareButton>
    )}
    {networks.includes('facebook') && (
      <FacebookShareButton
        style={{ marginRight: '5px' }}
        url={url}
        quote={title}
      >
        <FacebookIcon round={round} size={size} />
      </FacebookShareButton>
    )}
    {networks.includes('twitter') && (
      <TwitterShareButton
        style={{ marginRight: '5px' }}
        url={url}
        title={title}
      >
        <TwitterIcon round={round} size={size} />
      </TwitterShareButton>
    )}
  </Box>
);
