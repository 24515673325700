export const getItemTitleByType = (type: string, item?: any) => {
  switch (type) {
    case 'educations':
      return item?.school?.name;
    case 'it-skills':
      return item?.skill?.name;
    case 'activities':
      return item?.title;
    case 'certifications':
      return item?.title;
    case 'languages':
      return item?.language?.name;
    case 'social-media':
      return item?.url;
    case 'social-media-expertises':
      return item?.network;
    case 'work-experiences':
      return item?.company
        ? `${item?.company} ${item?.title ? `: ${item.title}` : ''}`
        : '';
    default:
      return null;
  }
};
