import styled from '@emotion/styled';
import {
  Box,
  Card,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography
} from '@material-ui/core';
import CompetenciesSelect from '../../components/CompetenciesSelect';
import useLocales from '../../hooks/useLocales';

const LabelTypography = styled(Typography)(() => ({
  fontFamily: 'Rubik',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '17px',
  color: '#666666'
}));

const InputTextField = styled(TextField)`
  .MuiOutlinedInput-root {
    background: #ffffff;
    border: 1px solid #c9cad9;
    border-radius: 30px;
    margin-top: 8px;
  }
`;

const CultureProfileFirstStep = ({
  competencies,
  handleSetSelected,
  selectedOptionsLabels,
  title,
  setCurrentProfileTitle,
  anonymous,
  setAnonymous
}: any) => {
  const { t } = useLocales();
  const text = [
    t('We advise you to select 5 main competencies'),
    t("Go for the once that really matter to achieve success in the candidate's work")
  ].join(' ');
  return (
    <Box>
      <Typography>{text}</Typography>
      <Box sx={{ mt: 2 }}>
        <Card sx={{ p: 6 }}>
          <LabelTypography>{t('Matching profile title')}</LabelTypography>
          <InputTextField
            fullWidth
            value={title}
            onChange={(e) => setCurrentProfileTitle(e.target.value)}
          />
          <FormControlLabel
            sx={{ p: 1, mt: 2 }}
            control={
              <Checkbox
                checked={anonymous}
                onChange={() => setAnonymous(!anonymous)}
                color="primary"
              />
            }
            label={t('Anonymous')}
          />
        </Card>
      </Box>
      <CompetenciesSelect
        competencies={competencies}
        selectedList={selectedOptionsLabels}
        onSelect={handleSetSelected}
      />
    </Box>
  );
};

export default CultureProfileFirstStep;
