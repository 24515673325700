import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { RootState } from '../../redux/store';
import { getCompanyModuleInsights } from '../../redux/slices/dashboard';
import Page from '../../components/containers/Page';
import ModuleSettings from './companySettings/moduleSettings';
import LoadingScreen from '../../components/LoadingScreen';
import PageContainer from '../../components/containers/PageContainer';
import useLocales from '../../hooks/useLocales';
import DashboardNavbar from '../../layouts/dashboard/navbar/DashboardNavbar';

interface CompanyModuleInsights {
  id: number | null;
  pitch: boolean;
  preference: boolean;
  work_experience: boolean;
  education: boolean;
  language: boolean;
  extra: boolean;
  certification: boolean;
  it_skill: boolean;
  social_media: boolean;
  social_media_expertise: boolean;
}

const ModuleSettingsPage = () => {
  const modules: CompanyModuleInsights = useSelector(
    (state: RootState) => state.dashboard.companyModuleInsights
  );
  const [isRefresh, setIsRefresh] = useState(false);
  const refreshModules = () => {
    setIsRefresh(!isRefresh);
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCompanyModuleInsights());
  }, [dispatch, modules.id, isRefresh]);

  const { t } = useLocales();

  const title = t('Module Settings');

  return (
    <Page title={title}>
      <PageContainer style={{ padding: 0 }}>
        {!modules.id ? (
          <LoadingScreen />
        ) : (
          <ModuleSettings
            refreshModules={refreshModules}
            companyModules={modules}
          />
        )}
      </PageContainer>
    </Page>
  );
};

export default ModuleSettingsPage;
