import { Box } from '@material-ui/core';
import { getPlaceholders } from 'constants/text/placeholders';
import styled from '@emotion/styled';

const PublicStatusWrapper = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  width: '100%',
  marginBottom: '-40px',
  marginRight: '40px'
}));

const PublicStatus = ({ anonymous }: { anonymous: boolean }) => {
  const { ANONYMOUS, PUBLIC } = getPlaceholders();
  return (
    <PublicStatusWrapper>
      <Box
        sx={{
          padding: 1,
          borderRadius: 15,
          backgroundColor: 'rgba(255,255,255,0.5)'
        }}
      >
        {anonymous ? ANONYMOUS : PUBLIC}
      </Box>
    </PublicStatusWrapper>
  );
};

export default PublicStatus;
