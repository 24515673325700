import { Card, Modal, Box } from '@material-ui/core';

export const CustomModal = ({ open, onClose, children, style = {} }: any) => (
  <Modal open={open} onClose={onClose}>
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%'
      }}
    >
      <Card
        sx={{
          minWidth: 500,
          width: 'auto',
          maxWidth: 1500,
          textAlign: 'center',
          p: 4,
          ...style
        }}
      >
        {children}
      </Card>
    </Box>
  </Modal>
);
