import { Grid } from '@material-ui/core';
import { ReactNode } from 'react';

const ColumnedList = ({ columns = [] }: { columns: any[] }) => (
  <Grid container spacing={1} style={{ padding: '30px 20px 0 20px' }}>
    {columns.map((col: any, i: number) => (
      <Grid
        item
        xs={12}
        md={6}
        key={i}
        style={{ paddingRight: '8px', paddingLeft: '16px' }}
      >
        {col.map((Card: ReactNode, i: any) => (
          <div key={i} style={{ marginBottom: '20px' }}>
            {Card}
          </div>
        ))}
      </Grid>
    ))}
  </Grid>
);

export default ColumnedList;
