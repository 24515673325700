import { useSelector } from 'react-redux';
import {
  Box,
  Grid,
  Dialog,
  Avatar,
  DialogTitle,
  Card,
  CardContent
} from '@material-ui/core';

import { getComparedData } from 'helpers/listValues';
import { getCardValuesByType } from 'helpers/cardValues';
import { RootState } from 'redux/store';
import useLocales from 'hooks/useLocales';
import { getPlaceholders } from 'constants/text/placeholders';
import { Close, LegendToggle } from '@material-ui/icons';
import { PATH_DASHBOARD } from 'routes/paths';
import { Link as RouterLink, Link } from 'react-router-dom';
import { getButtons } from 'constants/text/buttons';
import { DEFAULT_MESSAGE_TEXTS, SALARY_CHOICES_INR } from 'constants/constants';
import { useMemo } from 'react';
import ScoreField from './ScoreField';
import CultureProfileCharts, {
  ChartDescription,
  sortedPoint
} from './CultureProfileCharts';
import SvgIconStyle from './SvgIconStyle';
import { Description2, Title1, TitlePrimary } from './text/title';
import { ButtonPrimary } from './new-designer/button/ButtonPrimary';
import Markdown from './Markdown';
import { getCandidateRemind } from 'requests/candidate';
import { useSnackbar } from 'notistack';

const CandidateModal = ({
  open,
  handleClose,
  candidate,
  profile,
  cultureProfile,
  item
}: any) => {
  const { t } = useLocales();
  const { ANONYMOUS } = getPlaceholders();
  // const options = useSelector((state: RootState) => state.fixturesReducer);
  const opt: any = useSelector((state: RootState) => state.fixturesReducer);
  const options = { ...opt };
  const { company }: any = useSelector(
    (state: RootState) => state.generalRecruiter
  );
  if (company.currency == 'INR') {
    options.salaries = SALARY_CHOICES_INR;
  }
  const { ShOW_BEST_MATCHES } = getButtons();
  const { score, culture_score } = candidate;
  candidate = candidate?.candidate ? candidate.candidate : candidate;
  const candidateAvatar = candidate.anonymous ? null : candidate.avatar;
  const candidateName = candidate.anonymous ? ANONYMOUS : candidate.name;
  const user = useSelector((state: RootState) => state.authJwt.user);
  const { enqueueSnackbar } = useSnackbar();

  const fields = getCardValuesByType(
    'candidate-modal-info',
    candidate,
    options,
    company.currency
  );

  const isRecruiterOrAdmin = useMemo(
    () => user.role === 'recruiter' || user.role === 'admin',
    [user?.role]
  );

  const matchCompared = getComparedData(
    candidate.competencies,
    profile.competencies
  );
  const cultureCompared = getComparedData(
    candidate.competencies,
    cultureProfile.competencies
  );

  const labels = cultureProfile.competency_labels;
  const points = cultureProfile.competency_min_scores;
  let sortedPoints: Array<sortedPoint>;
  if (!labels?.[0]?.id) {
    sortedPoints =
      labels
        ?.map((el: string, i: number) => ({
          point: points[i],
          label: el,
          descriptions: '',
          comparePoint: cultureCompared[i].score
        }))
        ?.sort((a: any, b: any) => b.point - a.point) || [];
  } else {
    const sortedLabels = labels.sort((a: any, b: any) => b.id - a.id).reverse();
    const sortedInPoints = points
      .sort((a: any, b: any) => b.id - a.id)
      .reverse();
    sortedPoints =
      sortedLabels
        ?.map((el: any, i: number) => ({
          point: sortedInPoints[i].min_score,
          label: el.name,
          descriptions: '',
          comparePoint: cultureCompared[i]?.score
        }))
        ?.sort((a: any, b: any) => b.point - a.point) || [];
  }

  const labelsProfile = profile.competency_labels;
  const pointsProfile = profile.competency_min_scores;
  let sortedPointsProfile: Array<sortedPoint>;
  if (!labelsProfile?.[0]?.id) {
    sortedPointsProfile =
      labelsProfile
        ?.map((el: string, i: number) => ({
          point: pointsProfile[i],
          label: el,
          descriptions: '',
          comparePoint: matchCompared[i].score
        }))
        ?.sort((a: any, b: any) => b.point - a.point) || [];
  } else {
    const sortedLabels = labelsProfile
      .sort((a: any, b: any) => b.id - a.id)
      .reverse();
    const sortedInPoints = pointsProfile
      .sort((a: any, b: any) => b.id - a.id)
      .reverse();
    sortedPointsProfile =
      sortedLabels
        ?.map((el: any, i: number) => ({
          point: sortedInPoints[i].min_score,
          label: el.name,
          descriptions: '',
          comparePoint: matchCompared[i]?.score
        }))
        ?.sort((a: any, b: any) => b.point - a.point) || [];
  }
  const handleRemind = async () => {
    const res = await getCandidateRemind(item?.is);
    try {
      if (res) {
        enqueueSnackbar('Reminded successfully', { variant: 'success' });
      }
    } catch {
      enqueueSnackbar(DEFAULT_MESSAGE_TEXTS.error, { variant: 'error' });
    }
  };

  return (
    <Dialog onClose={handleClose} open={open} maxWidth="md" fullWidth>
      <Grid
        container
        style={{
          justifyContent: 'space-between',
          padding: '20px'
        }}
      >
        <DialogTitle
          sx={{ maxWidth: 400, borderRadius: '21px' }}
          style={{
            background:
              'linear-gradient(94.35deg, rgba(255, 255, 255, 0.8) -5.21%, rgba(255, 255, 255, 0.3) 114.12%)',
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.08)',
            borderRadius: '20px'
          }}
        >
          <Grid
            style={{
              background:
                'linear-gradient(92.25deg, #9B5FDA -2.57%, #562C82 113.38%)',
              boxShadow: '0px 0px 5.71474px rgba(0, 0, 0, 0.08)',
              height: '120px',
              borderRadius: '21px'
            }}
          />
          <Avatar
            sx={{ height: '90px', width: '90px' }}
            alt={candidateName}
            src={candidateAvatar || ''}
            style={{
              margin: '-90px auto 0 auto',
              width: '142px',
              height: '142px',
              border: '6px solid #ffffff'
            }}
          />
          <CardContent style={{ textAlign: 'center' }}>
            <Title1>{candidateName}</Title1>
            {/* <Typography variant="h4" color="GrayText" padding={0}>
            {profile.name}
          </Typography> */}
            <Description2>
              <Markdown source={candidate.pitch} />
            </Description2>
            {(score || item?.score )|| (culture_score || item?.culture_score) ? (
              <>
                <Grid style={{ display: 'flex', justifyContent: 'center' }}>
                  <Box
                    style={{
                      alignItems: 'center',
                      display: 'flex',
                      color: '#4cbbea',
                      columnGap: '10px',
                      width: '200px'
                    }}
                  >
                    <LegendToggle />
                    <ScoreField score={score || item?.score} type="talent" />
                  </Box>
                </Grid>
                <Grid style={{ display: 'flex', justifyContent: 'center' }}>
                  <Box
                    style={{
                      alignItems: 'center',
                      display: 'flex',
                      color: '#14a741',
                      columnGap: '10px',
                      width: '200px'
                    }}
                  >
                    <LegendToggle />
                    <ScoreField
                      score={culture_score || item?.culture_score}
                      type="culture"
                    />
                  </Box>
                </Grid>
              </>
            ) : (
              <ButtonPrimary
                style={{
                  width: '100%',
                  borderRadius: '30px',
                  cursor: 'pointer',
                  padding: '5px 15px',
                  marginTop:"20px"
                }}
                onClick={handleRemind}
              >
                {t('Remind the candidate to take personality assessment')}
              </ButtonPrimary>
            )}
            <Link
              to={PATH_DASHBOARD.candidates.getCandidateProfileUrl(
                candidate.id
              )}
              style={{ textDecoration: 'none' }}
            >
              <ButtonPrimary
                sx={{ width: '100%', my: 3 }}
                style={{
                  padding: '10px 35px',
                  width: 'max-content'
                }}
              >
                {t('View full profile')}
              </ButtonPrimary>
            </Link>
          </CardContent>
        </DialogTitle>
        <Grid
          style={{
            width: '100%',
            maxWidth: '480px'
          }}
        >
          <Close
            onClick={handleClose}
            style={{
              marginLeft: 'auto',
              display: 'block',
              cursor: 'pointer',
              color: '#C9CAD9',
              marginBottom: '10px',
              fontSize: '30px'
            }}
          />
          <Grid
            style={{
              background:
                'linear-gradient(94.35deg, rgba(255, 255, 255, 0.8) -5.21%, rgba(255, 255, 255, 0.3) 114.12%)',
              boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.08)',
              borderRadius: '20px',
              maxWidth: '550px',
              width: '100%',
              padding: '20px 28px',
              height: 'calc(100% - 40px)'
            }}
          >
            <Title1
              style={{
                fontWeight: 600,
                fontSize: '20px',
                color: '#131417',
                marginBottom: '20px'
              }}
            >
              Information
            </Title1>
            <Grid
              style={{
                rowGap: '48px',
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              {fields.map((el: any, i: number) => (
                <Grid
                  item
                  xs={6}
                  key={i}
                  style={{
                    display: 'flex',
                    alignItems: 'start',
                    maxWidth: '100%'
                  }}
                >
                  <SvgIconStyle
                    src={el.image}
                    style={{ marginRight: '10px' }}
                  />
                  <TitlePrimary
                    style={{
                      width: '150px',
                      marginLeft: '10 px'
                    }}
                  >
                    {el.label}
                  </TitlePrimary>
                  <Description2
                    variant="body1"
                    style={{
                      width: '150px',
                      marginLeft: 'auto'
                    }}
                  >
                    {el.value}
                  </Description2>
                </Grid>
              ))}
              {!item && isRecruiterOrAdmin && (
                <ButtonPrimary
                  variant="contained"
                  color="primary"
                  component={RouterLink}
                  to={PATH_DASHBOARD.jobPosts.getBestMatchesJobUrl(
                    candidate?.id
                  )}
                >
                  {ShOW_BEST_MATCHES}
                </ButtonPrimary>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ padding: '20px' }}>
        <Grid item xs={12} sm={6}>
          <Card className="open-job-posts-card">
            <Box
              style={{
                alignItems: 'center',
                display: 'flex',
                color: '#4cbbea',
                columnGap: '10px',
                padding: '10px',
                justifyContent: 'center'
              }}
            >
              <LegendToggle />
              <ScoreField score={score || item?.score} type="talent" />
            </Box>
          </Card>
          <Card className="open-job-posts-card">
            <CultureProfileCharts
              title={t('Match competencies')}
              labels={profile?.competency_labels}
              points={profile?.competency_min_scores}
              compareTitle={t('Candidate competencies')}
              comparePoints={matchCompared}
              descriptions={profile?.competencies?.map((el: any) => ({
                description: el.competency.description,
                name: el.competency.name
              }))}
            />
          </Card>
          <ChartDescription labels={sortedPointsProfile} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card className="open-job-posts-card">
            <Box
              style={{
                alignItems: 'center',
                display: 'flex',
                color: '#14a741',
                columnGap: '10px',
                justifyContent: 'center',
                padding: '10px'
              }}
            >
              <LegendToggle />
              <ScoreField
                score={culture_score || item?.culture_score}
                type="culture"
              />
            </Box>
          </Card>
          <Card className="open-job-posts-card">
            <CultureProfileCharts
              title={t('Culture competencies')}
              labels={cultureProfile?.competency_labels}
              points={cultureProfile?.competency_min_scores}
              compareTitle={t('Candidate competencies')}
              comparePoints={cultureCompared}
              descriptions={cultureProfile?.competencies?.map((el: any) => ({
                description: el.competency.description,
                name: el.competency.name
              }))}
            />
          </Card>
          <ChartDescription labels={sortedPoints} />
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default CandidateModal;
