import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Card, Grid } from '@material-ui/core';
import { RootState } from 'redux/store';
import { getCardValuesByType } from 'helpers/cardValues';
import { getPlaceholders } from 'constants/text/placeholders';
import { CandidateFormsContainer } from 'components/forms/account/CandidateForm';
import CandidateCardContainer from 'components/containers/CandidateCardContainer';
import CandidateModalContainer from 'components/containers/CandidateModalContainer';
import EmptyContent from 'components/EmptyContent';
import CardField from 'components/CardField';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import { Download } from '@material-ui/icons';
import ListItemText from '@mui/material/ListItemText';
import useLocales from 'hooks/useLocales';
import ImageUploader from 'components/ImageUploader';
import { CustomModal } from 'components/CustomModal';
import { useFormFieldsByType } from 'helpers/formFields';
import { DynamicForm } from 'components/DynamicForm';
import {
  deleteCandidateData,
  deleteCustomerData,
  postCandidateData,
  postCustomerData
} from 'requests/candidate';
import { useParams } from 'react-router';
import { DeleteButton } from 'components/Buttons';
import { useSnackbar } from 'notistack';
import { getCustomerFiles } from '../../../redux/slices/customrs';
import { handleSetValues } from '../../../utils/helpersForms';
import { FormField } from '../../../components/forms/account/type';

const DataCard = ({
  item,
  type,
  options,
  withoutBox
}: {
  item: any;
  type: string;
  options: any;
  withoutBox?: boolean;
}) => {
  const fields: any[] = getCardValuesByType(type, item, options);
  return (
    <Card
      key={item.id}
      className="candidate-info-inner-cards"
      style={{
        boxShadow: 'none',
        ...(withoutBox ? { borderRadius: 0 } : {})
      }}
    >
      {fields.map(
        ({ label, value, proficiency, component, image }: any, i: number) => (
          <CardField
            key={i}
            label={label}
            value={value}
            proficiency={proficiency}
            component={component}
            withoutBox={withoutBox}
            iconSrc={image}
          />
        )
      )}
    </Card>
  );
};

const UploadFileCard = ({
  type,
  item,
  options,
  candidateId,
  onClose,
  onChanging,
  isForCostumerProfile
}: {
  item: any;
  type: string;
  options: any;
  candidateId: any;
  onClose: any;
  onChanging: any;
  isForCostumerProfile: boolean;
}) => {
  const [editFiles, setEditFiles] = useState(false);
  const { id } = useParams<any>();
  const dispatch = useDispatch();

  const handleApply = async () => {
    onChanging(true);
    if (isForCostumerProfile && id) {
      await deleteCustomerData(id, item?.id, 'files');
      dispatch(getCustomerFiles(id));
    } else await deleteCandidateData(candidateId, item?.id, 'files');
    onClose();
  };

  const downloadCv = () =>
    item.uploaded_file ? window.open(item.uploaded_file, '_blank') : null;

  return (
    <>
      <UploadFileModal
        onClose={() => {
          onClose();
          setEditFiles(false);
        }}
        open={editFiles}
        onChanging={onChanging}
        options={options}
        type={type}
        candidateId={candidateId}
        item={item}
        edit={true}
      />
      <ListItem
        secondaryAction={
          <>
            {((item.is_uploaded_by_candidate && id === 'me') ||
              (!item.is_uploaded_by_candidate && id !== 'me')) && (
              <>
                <DeleteButton onClick={handleApply} />
              </>
            )}
          </>
        }
      >
        <ListItemIcon>
          <Download sx={{ color: 'rgb(28, 157, 215)' }} onClick={downloadCv} />
        </ListItemIcon>
        <ListItemText primary={`${item.title} - by ${item.uploaded_by}`} />
      </ListItem>
    </>
  );
};

const UploadFileModal = ({
  onClose,
  open,
  onChanging,
  type,
  item = { title: '' },
  options,
  candidateId,
  edit = false,
  isForCostumerProfile,
  customerId
}: any) => {
  const [itemValues, setItemValues] = useState(item);
  const [errors, setErrors] = useState<any>({});
  const [img, setImg] = useState<any>(edit ? item?.uploaded_file : '');
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams<any>();
  const dispatch = useDispatch();
  const { t } = useLocales();

    const onSubmit = async () => {
    onChanging(true);
    const formData = new FormData();
    const values = { ...itemValues, is_uploaded_by_candidate: id === 'me' };
    for (const key in values) {
      if (key !== 'id' && key !== 'img' && key !== 'uploaded_file') {
        formData.append(key, (values as any)[key]);
      }
    }

    if (!itemValues.title.trim()) {
      enqueueSnackbar('Please write a title', { variant: 'warning' });
      return;
    }

    if (img instanceof File) {
      formData.append('uploaded_file', img);
    } else if (typeof img === 'string' && !img.startsWith('blob:')) {
      const response = await fetch(img);
      const blob = await response.blob();
      formData.append('uploaded_file', blob, 'filename.ext');
    }

    if (edit) {
      if (isForCostumerProfile) {
        await deleteCustomerData(customerId, item?.id, 'files');
        dispatch(getCustomerFiles(customerId));
      } else {
        await deleteCandidateData(candidateId, item?.id, 'files');
      }
    }

    if (isForCostumerProfile) {
      await postCustomerData(customerId, formData, 'files');
      dispatch(getCustomerFiles(customerId));
    } else {
      await postCandidateData(candidateId, formData, 'files');
    }
    onClose();
  };

  const handleSetValue = (field: string, value: any) =>
    handleSetValues(
      setItemValues,
      itemValues,
      requiredFields,
      setErrors,
      errors,
      field,
      value
    );

  const formFields: FormField[] = useFormFieldsByType(
    type,
    itemValues,
    handleSetValue,
    options,
    errors
  );

  const requiredFields = formFields
    .filter((field) => !field.unRequired)
    .map((field) => field.name);

  return (
    <>
      <CustomModal open={open} onClose={onClose}>
        <ImageUploader
          index={Math.round(Math.random() * 1000)}
          prevValue={img || item?.uploaded_file}
          newValue={img}
          setValue={setImg}
          fileSize={2000000}
          imgTypesText="Allowed *.doc, *.pdf, *.jpeg, *.jpg, *.png"
          fileTypes={[
            'image/jpeg',
            'image/png',
            'application/pdf',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/pdf'
          ]}
        />
        <Grid>
          <DynamicForm
            type={type}
            inputValues={formFields}
            onSubmit={onSubmit}
            fullWidth
          />
        </Grid>
      </CustomModal>
    </>
  );
};

export const CandidateModalCard = ({
  title,
  type,
  candidateId,
  dataList,
  editable,
  onInit,
  iconSrc,
  withoutBox,
  isForCostumerProfile,
  customerId
}: any) => {
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [isChanging, setIsChanging] = useState(true);
  const [len, setLen] = useState(0);
  const { id } = useParams<any>();
  const { enqueueSnackbar } = useSnackbar();
  const options = useSelector((state: RootState) => state.fixturesReducer);
  const { NO_DATA } = getPlaceholders();

  const handleClose = () => {
    if (isChanging) {
      onInit();
    }
    setEditModalOpen(false);
  };

  useEffect(() => {
    if (Array.isArray(dataList) && type === 'upload_files' && dataList.length) {
      setLen(
        (prev) =>
          dataList.map((item: any) => item.is_uploaded_by_candidate) && ++prev
      );
    }
  }, [dataList]);

  return (
    <CandidateCardContainer
      title={title}
      withoutBox={withoutBox}
      iconSrc={iconSrc}
      onEdit={
        editable || type === 'upload_files'
          ? () => {
              if (
                type === 'upload_files' &&
                dataList &&
                dataList.length - len === 5 &&
                id !== 'me'
              ) {
                enqueueSnackbar('You can not add more then 5', {
                  variant: 'warning'
                });
              } else if (type === 'upload_files' && len === 3 && id === 'me') {
                enqueueSnackbar('You can not add more then 3', {
                  variant: 'warning'
                });
              } else if (editable || type === 'upload_files') {
                setEditModalOpen(true);
              }
            }
          : null
      }
      type={type}
    >
      <CandidateModalContainer open={editModalOpen} onClose={handleClose}>
        {type !== 'upload_files' ? (
          <CandidateFormsContainer
            initialDataList={dataList}
            candidateId={candidateId}
            groupTitle={title}
            type={type}
            options={options}
            onClose={handleClose}
            onChanging={setIsChanging}
            updateData={onInit}
          />
        ) : (
          <UploadFileModal
            onClose={handleClose}
            open={editModalOpen}
            onChanging={setIsChanging}
            options={options}
            type={type}
            candidateId={candidateId}
            isForCostumerProfile={isForCostumerProfile}
            customerId={customerId}
          />
        )}
      </CandidateModalContainer>

      <Box
        sx={{
          width: '100%',
          ...(type === 'work-experiences' && withoutBox
            ? { display: 'flex', columnGap: '40px' }
            : {})
        }}
      >
        {Array.isArray(dataList) && dataList.length ? (
          dataList.map((item: any) =>
            type === 'upload_files' ? (
              <UploadFileCard
                key={item.id}
                type={type}
                item={item}
                options={options}
                onClose={handleClose}
                onChanging={setIsChanging}
                candidateId={candidateId}
                isForCostumerProfile={isForCostumerProfile}
              />
            ) : (
              <DataCard
                key={item.id}
                type={type}
                item={item}
                options={options}
                withoutBox={withoutBox}
              />
            )
          )
        ) : (
          <EmptyContent title={NO_DATA} />
        )}
      </Box>
    </CandidateCardContainer>
  );
};
