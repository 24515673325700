import { useSelector } from 'react-redux';

// ----------------------------------------------------------------------

function useColors() {
  const { primary_color, secondary_color }: any = useSelector(
    (state: any) => state.generalRecruiter.company || {}
  );
  return {
    primary_color,
    secondary_color
  };
}

export default useColors;
