import { Box, BoxProps } from '@material-ui/core';

// ----------------------------------------------------------------------
interface LogoProps extends BoxProps {
  url?: string | null;
}
export default function Logo(props: LogoProps) {
  const { url } = props;
  return <Box component="img" className='logoImg' alt="logo" src={url} {...props} />;
}
