import { useState } from 'react';
import styled from '@emotion/styled';
import { withStyles, Slider } from '@material-ui/core';
import { Title1 } from './text/title';

const PrettoSlider = withStyles({
  root: {
    height: 13
  },
  thumb: {
    display: 'none'
  },
  valueLabel: {
    left: 'calc(-50% + 4px)'
  },
  track: {
    height: 13,
    borderRadius: 4
  },
  rail: {
    height: 13,
    borderRadius: 4
  }
})(Slider);

const StylePrettoSlider = styled(PrettoSlider)(() => ({
  '& .MuiSlider-mark': {
    top: '20px',
    height: '18px',
    width: '6px',
    color: '#ffffff',
    '&.MuiSlider-markActive': {
      opacity: 1
    }
  }
}));

export default function SliderComponent({
  title,
  minVal = 1,
  maxVal = 9,
  values,
  handleSetValues
}: any) {
  const [value, setValue] = useState<any>(values || minVal);

  const handleChangeValue = (event: any, newValue: number | number[]) => {
    setValue(newValue);
  };

  const handleSetValue = (event: any, newValue: number | number[]) => {
    handleSetValues(newValue);
  };

  const getMarks = (min: number, max: number) => {
    const marks = [];
    for (let i = min; i <= max; i++) {
      marks.push({ value: i, label: `${i}` });
    }
    return marks;
  };

  return (
    <div>
      <Title1 id="range-slider" gutterBottom>
        {title}
      </Title1>
      <StylePrettoSlider
        value={value}
        onChange={handleChangeValue}
        onChangeCommitted={handleSetValue}
        valueLabelDisplay="on"
        defaultValue={minVal}
        step={minVal}
        min={minVal}
        max={maxVal}
        marks={[...getMarks(minVal, maxVal)]}
        disableSwap
      />
    </div>
  );
}
