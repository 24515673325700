import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, Link as RouterLink, useNavigate } from 'react-router-dom';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  Hidden,
  Link,
  Modal,
  Typography
} from '@material-ui/core';
import ReactHtmlParser from 'react-html-parser';
import { CustomTextObject } from 'components/CompanyStyleSettings';
import Page from '../../components/containers/Page';
import RegisterForm from '../../components/authentication/register/RegisterForm';
import ClarificationForm from '../../components/authentication/register/ClarificationForm';
import FinishNotificationModal from './RegisterFinishModal';
import { PATH_AUTH, PATH_HOME } from '../../routes/paths';
import axios from 'utils/axios';

import { RootState } from '../../redux/store';
import { setTempSession } from '../../redux/slices/authJwt';
import {
  getCompanyModuleInsights,
  getManagementInsights
} from '../../redux/slices/dashboard';
import useLocales from '../../hooks/useLocales';
import HomeNavbar from 'layouts/home/HomeNavbar';
import { Title8 } from 'components/text/title';
import { RegistrationProps } from '../../redux/slices/types';

const RootStyle = styled(Page)(({ theme }) => ({
  margin: 0,
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    justifyContent: 'center'
  }
}));

const SectionStyle = styled(Grid)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  maxWidth: 650,
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 8)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0),
  marginLeft: '100px'
}));

const AdminNotificationModal = ({
  open,
  handleClose
}: {
  open: boolean;
  handleClose: () => any;
}) => (
  <Modal open={open} onClose={handleClose}>
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%'
      }}
    >
      <Card sx={{ width: 500, textAlign: 'center', p: 4 }}>
        <Typography variant="h3" color="primary">
          Congratulations!
        </Typography>
        <Typography variant="body1">
          Your request has been sent and will be reviewed by admin soon. We will
          contact you via email as soon as possible.
        </Typography>
      </Card>
    </Box>
  </Modal>
);

const FormHeader = ({ title, color }: any) => (
  <Box sx={{ mb: 5, display: 'flex', alignItems: 'center' }}>
    <Box sx={{ flexGrow: 1 }}>
      <Title8 gutterBottom style={{ color }}>
        {title}
      </Title8>
    </Box>
  </Box>
);

export default function Register() {
  const { t } = useLocales();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isUserCreated, setIsUserCreated] = useState(false);
  const [asAdmin, setAsAdmin] = useState(false);
  const [finishModalOpen, setFinishModalOpen] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const { base }: any = useSelector((state: RootState) => state.cms);
  const { company }: any = useSelector(
    (state: RootState) => state.generalRecruiter
  );
  const { primary_color } = base;

  const candidateSignUpMessage = company.custom_texts?.find(
    (CT: CustomTextObject) => CT.name === 'candidate_signup_message'
  ).text;

  const email = new URLSearchParams(useLocation().search).get('email');
  const role = new URLSearchParams(useLocation().search).get('role');
  const isRecruiterSignUp = !!email && !role;

  const formHeaderText = useMemo(() => {
    const getTitle = () => {
      if (isRecruiterSignUp) {
        return 'Welcome to your new dashboard';
      }
      if (!isUserCreated) {
        return t('Sign Up');
      }
      return t('Tell us about yourself');
    };

    const getSubtitle = () => {
      if (isRecruiterSignUp) {
        return 'Please activate your recruiter account';
      }
      if (!isUserCreated) {
        return 'Free forever. No credit card needed';
      }
      return 'This information will help us quickly find a job for you';
    };

    return {
      title: getTitle(),
      subtitle: getSubtitle()
    };
  }, [isRecruiterSignUp, isUserCreated]);

  const registrationProps: RegistrationProps | null = useSelector(
    (state: RootState) => state.authJwt.onRegistered
  );
  const { logo, linkedin_signup_url }: any = useSelector(
    (state: RootState) => state.generalRecruiter.company
  );

  useEffect(() => {
    if (!registrationProps) return;

    const { token, registeredAs, isRegistered } = registrationProps;

    if (token) {
      localStorage.setItem('accessToken', token);
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    }

    if (registeredAs === 'recruiter' || registeredAs === 'admin') {
      navigate(PATH_HOME.dashboardRecruiter);
      setAsAdmin(registeredAs === 'admin');
    } else {
      setIsUserCreated(isRegistered);
      setIsOpenModal(true);
    }
  }, [registrationProps, navigate]);

  const handleAdminModalClose = () => {
    navigate('/');
  };

  useEffect(() => {
    setTempSession(registrationProps?.token || null);
    if (registrationProps?.token) {
      dispatch(getCompanyModuleInsights());
    }
    return function cleanup() {
      setTempSession(null);
    };
  }, [dispatch, registrationProps]);

  useEffect(() => {
    dispatch(getManagementInsights());
  }, []);

  const onCandidateFormsSubmit = () => {
    setFinishModalOpen(true);
  };

  return (
    <RootStyle title={t('Register')}>
      <HomeNavbar logo={logo} />

      <Hidden>
        <SectionStyle>
          <img
            alt="register"
            src="/static/img/illustrations/illustration_register.png"
            style={{ opacity: 0.1 }}
          />
          <Typography sx={{ position: 'absolute', maxWidth: 650 }}>
            {ReactHtmlParser(candidateSignUpMessage)}
          </Typography>
        </SectionStyle>
      </Hidden>

      <AdminNotificationModal
        open={asAdmin}
        handleClose={handleAdminModalClose}
      />

      <FinishNotificationModal
        open={finishModalOpen}
        userId={registrationProps?.userId}
      />

      <Container
        style={{
          maxWidth: '900px',
          margin: 0
        }}
      >
        <ContentStyle sx={{ maxWidth: 900 }}>
          <FormHeader title={formHeaderText.title} color={primary_color} />
          {!isUserCreated ? (
            <RegisterForm
              color={primary_color}
              linkedin_signup_url={linkedin_signup_url}
            />
          ) : (
            <>
              <Modal open={isOpenModal} onClose={() => setIsOpenModal(false)}>
                <Box
                  sx={{
                    position: 'absolute',
                    width: '30%',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '20px',
                    alignItems: 'center'
                  }}
                >
                  <Typography variant="h5" textAlign="center">
                    {t(
                      'You can now continue your application. A password has been sent to your email. You can use this password when you want to login in the next time.'
                    )}
                  </Typography>
                  <Button
                    sx={{ width: '50%' }}
                    variant="contained"
                    onClick={() => setIsOpenModal(false)}
                  >
                    Ok
                  </Button>
                </Box>
              </Modal>
              <ClarificationForm
                userId={registrationProps?.userId}
                candidateId={registrationProps?.candidateId || 0}
                onFinish={onCandidateFormsSubmit}
              />
            </>
          )}
          <Hidden smUp>
            <Typography variant="subtitle2" sx={{ mt: 3, textAlign: 'center' }}>
              {t('Already have an account')}? &nbsp;
              <Link to={PATH_AUTH.login} component={RouterLink}>
                {t('Login')}
              </Link>
            </Typography>
          </Hidden>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
