import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Grid, Typography } from '@material-ui/core';
import { enqueueSnackbar } from 'redux/slices/notifications';
import { RootState } from 'redux/store';
import { updateCompanyInfo } from '../../../redux/slices/generalRecruiter';
import useLocales from '../../../hooks/useLocales';
import { prepareFormData } from '../../../helpers/formFields';
import { getInitialValuesByType } from '../../../helpers/formInitialValues';
import { DynamicFormWrapper } from '../../../components/DynamicForm';
import { styleCompanyCard } from '../../../styled';

const CompanyProfileSettings = ({
  registerStep,
  setRegisterStep,
  type
}: {
  registerStep?: number;
  setRegisterStep?: (e: number) => void;
  type: string;
}) => {
  const { company }: any = useSelector(
    (state: RootState) => state.generalRecruiter
  );
  const [values, setValues] = useState(
    getInitialValuesByType('company-info', company)
  );
  const { id } = company;
  const { t } = useLocales();
  const dispatch = useDispatch();

  const setNewValues = (field: string, value: any) => {
    setValues({
      ...values,
      [field]: value
    });
  };

  const handleSubmit = () => {
    const data = prepareFormData({
      ...values,
      industry: values.industry.id
    });

    if (id) {
      dispatch(updateCompanyInfo(id, data));
      if (setRegisterStep && registerStep) setRegisterStep(registerStep + 1);
    } else {
      dispatch(
        enqueueSnackbar({
          messageType: 'error',
          options: { variant: 'error' }
        })
      );
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card
          sx={{ p: 2, mb: 2 }}
          style={{
            ...styleCompanyCard,
            boxShadow: registerStep
              ? 'none'
              : '0px 0px 10px rgba(0, 0, 0, 0.08)',
            padding: registerStep ? '0 40px' : '40px'
          }}
        >
          {!registerStep && (
            <Typography
              sx={{ mb: 1 }}
              variant="h5"
              style={{ fontFamily: 'Rubik', color: '#131417', fontWeight: 600 }}
            >
              {t('Company Info')}
            </Typography>
          )}
          <DynamicFormWrapper
            type={type}
            itemValues={values}
            setValues={setNewValues}
            onSubmit={handleSubmit}
            cols
            registerStep={registerStep}
            setRegisterStep={setRegisterStep}
          />
        </Card>
      </Grid>
    </Grid>
  );
};

export default CompanyProfileSettings;
