import React, { useEffect, useState } from 'react';
import useLocales from 'hooks/useLocales';
import { Grid, TextField, Typography, useTheme } from '@material-ui/core';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  LinearScale,
  Title,
  Tooltip
} from 'chart.js';

import { Line } from 'react-chartjs-2';
import { fDateForChart } from 'utils/formatTime';
import { Title1 } from 'components/text/title';
import { Legend } from 'components/Legend';
import { initialCurrentJobPost } from './JobPost';

import {
  AutocompleteStyle,
  OverviewFilter
} from '../../components/OverviewFilter';
import { getTranslatedList } from '../../constants/constants';
import {
  appendLabels,
  generateLabels
} from '../../utils/changeLabelsForDataAnalytics';
import { getTexts } from '../../constants/text/texts';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { HrDivider } from '../../styled';
import { fetchApplicationStages } from '../../redux/slices/dataAnalytics';
import XLSXButton from '../../components/XLSXButton';
import * as ExcelJS from 'exceljs';
import { createExcel } from '../../utils/helpers';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip);

const JobApplicationStages = () => {
  const [legendActive, setLegendActive] = useState<any>({});
  const { PERIOD_TYPES_ANALYTICS } = getTranslatedList();
  const [allData, setAllData] = useState({
    existingJobPost: initialCurrentJobPost,
    period: PERIOD_TYPES_ANALYTICS[1].value
  });
  const dispatch = useDispatch();
  const { allJobPosts } = useSelector((state: RootState) => state.job);
  const { applicationStages } = useSelector(
    (state: RootState) => state.dataAnalytics
  );
  const { PERIODICITY } = getTexts();
  const { t } = useLocales();
  const theme = useTheme();

  const getData = (data: any, value: string) => {
    dispatch(fetchApplicationStages({ value, id: data?.id }));
    setAllData({
      existingJobPost: data?.id ? data : initialCurrentJobPost,
      period: value
    });
  };

  const setDataFromExistingPost = (data: any, value: string) => {
    getData(data, value || allData.period);
  };

  const firstFields = {
    type: 'select',
    value: allData.existingJobPost,
    label: t('You can select one of the existing job posts'),
    onChange: (e: any, el: any) => setDataFromExistingPost(el, ''),
    options: allJobPosts || [],
    helperText: t('You can select one of existing job-posts to auto fill form')
  };

  const colors = ['rgba(155,95,218, 0.6)', 'rgba(28, 157, 215, 0.6)'];
  const colorsPoint = ['#9B5FDA', '#209DD8'];
  const colors2 = ['#9B5FDA30', '#209DD830'];
  const legendLabels = ['Legend 1', 'Legend 2'];

  useEffect(() => {
    setDataFromExistingPost(null, allData.period);
  }, []);

  const newData = JSON.parse(JSON.stringify(applicationStages?.datasets || []));

  if (newData.length) {
    newData?.forEach((el: any) => {
      if (!el.data.length) {
        el.data.length = 7;
        el.data.fill(0, 0, 7);
      } else if (el.data.length < 7) {
        for (let i = el.data.length; i < 7; i++) {
          el.data.push(0);
        }
      }
    });
  }

  const onDownloadData = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet 1');
    const { period } = allData;
    const dataLine: any = applicationStages;
    const cellA1 = worksheet.getCell('A1');
    cellA1.value = `Job Status / ${period}`;
    worksheet.mergeCells('A1:A2');
    const cellA3 = worksheet.getCell('A3');
    cellA3.value = dataLine.datasets[0].label;
    const cellA4 = worksheet.getCell('A4');
    cellA4.value = dataLine.datasets[1].label;
    cellA1.alignment = { vertical: 'middle' };

    for (let i = 0; i < dataLine.labels.length; i++) {
      const label = dataLine.labels[i];
      const columnIndex = i + 2;

      for (let row = 1; row <= 2; row++) {
        const cell = worksheet.getCell(row, columnIndex);
        const date = new Date(label);
        cell.value =
          period === 'monthly'
            ? date.toLocaleString('en-US', { month: 'short' })
            : i + 1;
      }

      i === 0
        ? worksheet.mergeCells(1, 2, 2, 2)
        : worksheet.mergeCells(1, columnIndex, 2, columnIndex);

      const column = worksheet.getColumn(columnIndex);
      const labelLength = label.length;
      column.width = Math.max(10, labelLength + 2);
      column.alignment = { vertical: 'middle', horizontal: 'center' };
    }

    const { datasets } = dataLine;

    for (let i = 0; i < datasets.length; i++) {
      const dataset = datasets[i];
      const { data } = dataset;

      for (let j = 0; j < data.length; j++) {
        const value = data[j];
        const cell = worksheet.getCell(i + 3, j + 2);
        cell.value = value;
      }
    }

    const columnA = worksheet.getColumn('A');
    columnA.width = 30;

    await createExcel(workbook);
  };

  return (
    <Grid container flexWrap="wrap" justifyContent="start">
      <XLSXButton
        available={
          applicationStages?.datasets[0]?.data?.some(
            (el: number) => el !== 0
          ) &&
          applicationStages?.datasets[1]?.data?.some((el: number) => el !== 0)
        }
        onClick={onDownloadData}
        style={{ right: '50px', bottom: '30px' }}
      />
      <Grid item xs={12}>
        <Title1 marginBottom="15px">
          {t('Number of hires and offerings per jobpost')}
        </Title1>
        <HrDivider />
      </Grid>
      <Grid item xs={6} paddingRight={1}>
        <Typography
          style={{
            fontFamily: 'Rubik',
            color: '#666666',
            marginBottom: '6px'
          }}
        >
          {firstFields.label}
        </Typography>
        <AutocompleteStyle
          style={{
            background: 'white',
            borderRadius: '10px',
            fontFamily: 'Rubik'
          }}
          fullWidth
          options={firstFields.options || []}
          getOptionLabel={(option: any) =>
            option?.label || option?.name || option?.title
          }
          value={firstFields.options?.find(
            (el: any) => firstFields.value?.id === el?.id
          )}
          onChange={firstFields.onChange}
          renderInput={(params: any) => (
            <TextField {...params} variant="outlined" />
          )}
        />
      </Grid>
      <Grid item xs={6} paddingLeft={1}>
        <OverviewFilter
          title={PERIODICITY}
          activeOption={allData.period}
          options={PERIOD_TYPES_ANALYTICS}
          setOption={(value) =>
            setDataFromExistingPost(allData.existingJobPost, value)
          }
        />
      </Grid>
      <Grid item xs={12}>
        <Line
          height={200}
          options={{
            responsive: true,
            scales: {
              x: {
                grid: {
                  display: false
                }
              },
              y: {
                grid: {
                  display: false
                }
              }
            },
            plugins: {
              datalabels: {
                display: false
              },
              legend: {
                display: false
              },
              title: {
                display: true,
                text: allData?.existingJobPost?.title,
                color: theme.palette.primary.main,
                font: {
                  size: 40
                }
              },
              tooltip: {
                callbacks: {
                  title: (data: any) => data[0].formattedValue,
                  label: () => '',
                  footer: (data: any) =>
                    applicationStages?.labels[data[0].dataIndex] &&
                    fDateForChart(applicationStages?.labels[data[0].dataIndex])
                },
                titleMarginBottom: 0,
                footerColor: 'rgba(123, 72, 178, 0.52)',
                titleFont: {
                  size: 20
                },
                footerFont: {
                  size: 10
                },
                padding: {
                  left: 20,
                  top: 10,
                  bottom: 10,
                  right: 20
                },
                titleColor: (data: any) =>
                  colorsPoint[data.tooltip.dataPoints[0].datasetIndex],
                backgroundColor: '#fff',
                borderWidth: 1,
                borderColor: 'rgba(128,128,128,0.2)',
                cornerRadius: 10,
                caretSize: 0
              }
            }
          }}
          data={{
            labels: (
              (!applicationStages?.labels?.length &&
                generateLabels(allData?.period)[0]) ||
              (applicationStages?.labels?.length < 7 &&
                appendLabels(applicationStages?.labels, allData?.period)) ||
              applicationStages?.labels
            )?.map((el: any) => fDateForChart(el)),
            datasets: newData?.map((el: any, index: number) => ({
              ...el,
              borderColor:
                legendActive[`${index}`] === undefined || !legendActive[index]
                  ? colors[index]
                  : '#ffffff00',
              fill: true,
              borderWidth: 1,
              label: legendLabels[index],
              backgroundColor: (context: any) => {
                const { chart } = context;
                const { ctx } = chart;
                const gradient = ctx.createLinearGradient(0, 100, 0, 150);
                gradient.addColorStop(0, colors[index]);
                gradient.addColorStop(1, colors2[index]);
                return legendActive[`${index}`] === undefined ||
                  !legendActive[index]
                  ? gradient
                  : '#ffffff00';
              },
              pointBackgroundColor:
                legendActive[`${index}`] === undefined || !legendActive[index]
                  ? colorsPoint[index]
                  : '#ffffff00',
              pointBorderColor:
                legendActive[`${index}`] === undefined || !legendActive[index]
                  ? '#ffffff'
                  : '#ffffff00',
              pointBorderWidth: legendActive ? 2 : 0,
              pointRadius: legendActive ? 5 : 0
            }))
          }}
        />
      </Grid>
      <Grid
        style={{
          display: 'flex',
          columnGap: '20px',
          margin: '0 auto'
        }}
      >
        {newData?.map((item: any, index: number) => (
          <Legend
            key={index}
            setLegendActive={() => {
              const obj = {
                ...legendActive,
                [`${index}`]: !legendActive[`${index}`]
              };
              setLegendActive(obj);
            }}
            legendActive={legendActive}
            title="Legend 1"
            index={index}
            color={colorsPoint[index]}
          />
        ))}
      </Grid>
    </Grid>
  );
};

export default JobApplicationStages;
