import { useRef, useState } from 'react';
import {
  Box,
  Button,
  ListItemIcon,
  ListItemText,
  MenuItem
} from '@material-ui/core';
import useLocales from '../../../hooks/useLocales';
import MenuPopover from '../../../components/MenuPopover';

export default function LanguagePopover(props: any) {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { allLang, currentLang, onChangeLang } = useLocales();

  return (
    <Box
      {...props}
      sx={{
        '&:hover': {
          background: 'unset !important',
          transform: 'none'
        },
        '& button:hover': {
          background: 'unset !important',
          transform: 'none'
        },
        '& .MuiButton-root.MuiButton-text.MuiButton-textPrimary ': {
          background: 'unset !important',
          transform: 'none'
        }
      }}
    >
      <Button
        ref={anchorRef}
        onClick={() => setOpen(true)}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && { bgcolor: 'action.selected' }),
          '& .MuiTouchRipple-root': {
            display: 'none'
          }
        }}
      >
        <img src="/static/img/icons/headerGlobe.svg" alt={currentLang.label} />
      </Button>

      <MenuPopover
        open={open}
        onClose={() => setOpen(false)}
        anchorEl={anchorRef.current}
      >
        <Box sx={{ py: 1 }}>
          {allLang.map((option) => (
            <MenuItem
              key={option.value}
              selected={option.value === currentLang.value}
              onClick={() => {
                onChangeLang(option.value);
                setOpen(false);
              }}
              sx={{ py: 1, px: 2.5 }}
            >
              <ListItemIcon>
                <Box component="img" alt={option.label} src={option.icon1} />
              </ListItemIcon>

              <ListItemText primaryTypographyProps={{ variant: 'body2' }}>
                {option.label}
              </ListItemText>
            </MenuItem>
          ))}
        </Box>
      </MenuPopover>
    </Box>
  );
}
