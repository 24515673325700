export const getInitialValuesByType = (
  type: string,
  item?: any,
  withOutLang?: boolean
) => {
  switch (type) {
    case 'candidate-pitch':
      return {
        pitch: item?.pitch || ''
      };
    case 'candidate-preferences-info':
      return {
        availability: item?.availability || 0,
        work_hours: item?.work_hours || 0,
        relocation: item?.relocation || 0,
        work_exp: item?.work_exp || 0,
        management_exp: item?.management_exp || 0,
        salary: item?.salary || 0,
        locations: item?.locations || [],
        industries: item?.industries || [],
        contract: item?.contract || [],
        ...(withOutLang
          ? {}
          : {
            default_lang: item?.default_lang || null
          })
      };
    case 'address-info':
      return {
        country: item?.country || '',
        city: item?.city || '',
        address: item?.address || '',
        state: item?.state || '',
        zip: item?.zip || ''
      };

    case 'account-short-info':
      return {
        phone: item?.phone || '',
        default_lang: item?.default_lang || 0
      };
    case 'account-info':
      return {
        first_name: item?.first_name || '',
        last_name: item?.last_name || '',
        phone: item?.phone || '',
        email: item?.email || '',
        birthday: item?.birthday || '',
        gender: item?.gender || 0
      };
    case 'notification-info':
      return {
        contact_email: item?.contact_email || ''
      };
    case 'company-info':
      return {
        name: item?.name || '',
        website: item?.website || '',
        anonymous: item?.anonymous || false,
        assessment_optional: item?.assessment_optional || false,
        contact: item?.contact || '',
        general_terms: item?.general_terms || '',
        privacy_statement: item?.privacy_statement || '',
        industry: item?.industry || 0,
        currency: item?.currency || ''
      };
    case 'company-info-register':
      return {
        name: item?.name || '',
        website: item?.website || '',
        industry: item?.industry || 0,
      };
    case 'job-post':
      return {
        id: item?.id || null,
        user: item?.user || null,
        user_name: item?.user_name || '',
        culture_profile: item?.culture_profile || {},
        title: item?.title || '',
        description: item?.description || '',
        status: item?.status || '',
        studies: item?.studies || 0,
        contract: item?.contract || 0,
        degree: item?.degree || 0,
        work_exp: item?.work_exp || 0,
        salary: item?.salary || 0,
        hourly_rate: item?.hourly_rate || 0,
        locations: item?.locations || [],
        industries: item?.industries || [],
        skills: item?.skills || [],
        musts: item?.musts || [],
        perks: item?.perks || [],
        available: item?.available || new Date().toISOString().split('T')[0],
        drivers_license: item?.drivers_license || false,
        competencies: item?.competencies || [],
        competency_labels: item?.competency_labels || [],
        competency_min_scores: item?.competency_min_scores || [],
        created_at: item?.created_at || new Date().toISOString(),
        view_count: item?.view_count || 0,
        slug: item?.slug ?? ''
      };
    case 'educations':
      return {
        school: item?.school || 0,
        study: item?.study || 0,
        degree: item?.degree || 0,
        start: item?.start || '',
        end: item?.end || '',
        grade: item?.grade || null,
        graduate: item?.graduate || false
      };
    case 'it-skills':
      return {
        skill: item?.skill || 0,
        description: item?.description || '',
        proficiency: item?.proficiency || 0
      };
    case 'activities':
      return {
        title: item?.title || '',
        description: item?.description || '',
        website: item?.website || '',
        start: item?.start || '',
        end: item?.end || ''
      };
    case 'certifications':
      return {
        title: item?.title || '',
        institute: item?.institute || '',
        recieved: item?.recieved || false,
        year: item?.year || ''
      };
    case 'languages':
      return {
        language: item?.language || 0,
        proficiency: item?.proficiency || 0
      };
    case 'social-media':
      return {
        network: item?.network || '',
        url: item?.url || ''
      };
    case 'social-media-expertises':
      return {
        network: item?.network || 0,
        proficiency: item?.proficiency || 0
      };
    case 'work-experiences':
      return {
        company: item?.company || '',
        title: item?.title || '',
        description: item?.description || '',
        website: item?.website || '',
        start: item?.start || '',
        end: item?.end || '',
        industry: item?.industry || 0,
        location: item?.location || null,
        contract: item?.contract || 0
      };

    default:
      return {};
  }
};
