import { useState } from 'react';
import {
  Box,
  Card,
  Button,
  Typography,
  Popover,
  Divider
} from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Title1 } from './text/title';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popover: {
      pointerEvents: 'none'
    },
    paper: {
      padding: theme.spacing(2),
      maxWidth: '400px'
    }
  })
);

const MouseOverPopover = ({ children, popUpText }: any) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Box
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        sx={{
          display: 'inline-block',
          marginRight: '10px',
          marginBottom: '20px'
        }}
      >
        {children}
      </Box>
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{ paper: classes.paper }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography color="GrayText" variant="caption">
          {popUpText}
        </Typography>
      </Popover>
    </>
  );
};

const CompetenciesSelect = ({ competencies, selectedList, onSelect }: any) => (
  <Box sx={{ mt: 2 }}>
    {Array.isArray(competencies) &&
      competencies.length &&
      competencies.map((group: any) => (
        <Box key={group.id} sx={{ mt: 2 }}>
          <Card
            sx={{
              p: '40px',
              pb: '20px',
              background:
                'linear-gradient(94.35deg, rgba(255, 255, 255, 0.8) -5.21%, rgba(255, 255, 255, 0.3) 114.12%)'
            }}
          >
            <Title1
              sx={{
                m: 1,
                ml: '0',
                mr: '0'
              }}
            >
              {group.name}
            </Title1>
            <Divider sx={{ mb: '16px' }} />
            {group.competencies.map((competency: any) => {
              const inArray = selectedList.includes(competency.name);
              return (
                <MouseOverPopover
                  key={competency.id}
                  popUpText={competency.description}
                >
                  <Button
                    sx={
                      inArray
                        ? {
                            width: '100%',
                            color: '#fff',
                            height: '36px',
                            fontWeight: '700',
                            borderRadius: '30px',
                            background: '#9B5FDA',
                            fontSize: '14px',
                            fontFamily: 'Rubik',
                            padding: '8px 24px'
                          }
                        : {
                            width: '100%',
                            color: '#9B5FDA',
                            height: '36px',
                            fontWeight: '700',
                            borderRadius: '30px',
                            fontSize: '14px',
                            fontFamily: 'Rubik',
                            border: '1px solid #9B5FDA',
                            padding: '8px 24px'
                          }
                    }
                    onClick={() =>
                      onSelect(
                        competency.name,
                        competency.id,
                        competency.description
                      )
                    }
                  >
                    {competency.name}
                  </Button>
                </MouseOverPopover>
              );
            })}
          </Card>
        </Box>
      ))}
  </Box>
);

export default CompetenciesSelect;
