import { format, formatDistanceToNow } from 'date-fns';

// ----------------------------------------------------------------------
export function fDate(date: string | number | Date) {
  return format(new Date(date), 'dd MMMM yyyy');
}

export function fDateJobPOst(date: string | number | Date) {
  return format(new Date(date), 'MMM dd, yyyy');
}

export function fDateForChart(date: string | number | Date) {
  return format(new Date(date), 'dd MMM');
}

export function fDateChat(date: string | number | Date) {
  return format(new Date(date), 'p / dd.mm.yy');
}

export function fDateTime(date: string | number | Date) {
  return new Date(date).toLocaleString();
}

export function fDateDHM(date: string | number | Date) {
  return !date ? '' : format(new Date(date), 'dd/MM/yyyy');
}

export function fDateTimeSuffix(date: string | number | Date) {
  return format(new Date(date), 'dd/MM/yyyy p');
}

export function fDateTimeBy(date: string | number | Date) {
  return format(new Date(date), 'yyyy-MM-dd');
}

export function fToNow(date: string | number | Date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true
  });
}

export function FDateD(date: string | number | Date) {
  return format(new Date(date), 'dd MMM yyyy');
}

export function FDateCurrentTime(
  date: string | number | Date,
  formatType: string
) {
  if (formatType === 'duration') {
    const hours = new Date(date).getHours();
    const minutes = new Date(date).getMinutes();
    const hour = Number(hours) > 0 ? `${hours} h` : '';
    return `${hour} ${minutes} m`;
  }
  return format(new Date(date), formatType);
}
