import jwtDecode from 'jwt-decode';
import Cookies from 'js-cookie';
import { createSlice } from '@reduxjs/toolkit';
import axios from 'utils/axios';
import {
  deleteAccount,
  getAccountData,
  logOutUser,
  searchRecruters,
  signIn,
  signUp,
  updateAccountData
} from 'requests/account';
import { AppDispatch, history } from 'redux/store';
import { unAuthUser } from 'constants/states';
import { enqueueSnackbar } from './notifications';
import { AuthJWTState } from './types';
import { getAssessmentTestLink } from '../../requests/candidate';
import { openAssesmentLimitDialog } from './dialogs';
import { LIMIT_ASSESSMENT_ERROR_MESSAGE } from '../../constants/constants';
import { ID } from 'requests';
import { resetCandidate } from './candidate';


const initialState: AuthJWTState = {
  isLoading: false,
  isAuthenticated: false,
  user: unAuthUser,
  onRegistered: null,
  users: {
    results: []
  }
};

const slice = createSlice({
  name: 'authJwt',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    setSearchedUsers(state, action) {
      state.users = action.payload.user;
    },

    getInitialize(state, action) {
      state.isLoading = false;
      state.isAuthenticated = action.payload.isAuthenticated;
      state.user = action.payload.user;
    },

    loginSuccess(state, action) {
      state.isAuthenticated = true;
      state.user = action.payload.user;
    },

    accountSuccess(state, action) {
      state.user = action.payload.user;
    },

    registerSuccess(state, action) {
      state.onRegistered = action.payload;
    },

    logoutSuccess(state) {
      state.isAuthenticated = false;
      state.user = unAuthUser;
    }
  }
});

export default slice.reducer;

const isValidToken = (accessToken: string) => {
  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode<{ exp: number }>(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

export const setSession = (accessToken: string | null) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

export const setTempSession = (accessToken: string | null) => {
  if (accessToken) {
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    delete axios.defaults.headers.common.Authorization;
  }
};

export const getAccount = () => async (dispatch: AppDispatch) => {
  getAccountData().then((res) =>
    dispatch(slice.actions.accountSuccess({ user: res }))
  );
};

export const patchAccountData =
  (id: string | number, formData: any) => async (dispatch: AppDispatch) => {
    updateAccountData(id, formData).then((res) =>
      dispatch(slice.actions.accountSuccess({ user: res }))
    );
  };

export function login({
  email,
  password
}: {
  email: string;
  password: string;
}) {
  return async (dispatch: AppDispatch) => {
    const res = await signIn({ email, password });
    setSession(res.access);

    const accounts = await getAccountData();
    dispatch(slice.actions.loginSuccess({ user: accounts }));
    dispatch(
      enqueueSnackbar({
        message: 'Login Successful',
        options: { variant: 'success' }
      })
    );
  };
}

export const register =
  (
    {
      email,
      firstName,
      lastName,
      asRecruiter,
      role,
      password
    }: {
      email: string;
      firstName: string;
      lastName: string;
      asRecruiter: boolean;
      role?: string | null;
      password?: string;
    },
    invitation?: string | null
  ) =>
  async (dispatch: AppDispatch) => {
    let roleValue;
    if (role && !asRecruiter) {
      roleValue = role;
    } else {
      roleValue = !role && asRecruiter ? 'recruiter' : 'candidate';
    }
    const res = await signUp(
      {
        email,
        first_name: firstName,
        last_name: lastName,
        role: roleValue,
        ...(password ? { password } : {})
      },
      invitation
    );
    setSession(res.token);
    dispatch(
      slice.actions.registerSuccess({
        isRegistered: true,
        registeredAs: res.role,
        userId: res.id,
        candidateId: res.candidate_id,
        token: res?.token
      })
    );
  };

export const logout = (id?: ID) => async (dispatch: AppDispatch) => {
  try {
    if (id) await deleteAccount(id);
    await logOutUser();
    setSession(null);
    dispatch(resetCandidate());
    Cookies.remove('accessToken');
    dispatch(slice.actions.logoutSuccess());
  } catch {
    dispatch(
      enqueueSnackbar({
        message: 'Logout rejected',
        options: { variant: 'error' }
      })
    );
  } finally {
    history.push('/');
  }
};

export const getInitialize =
  (userId?: number) => async (dispatch: AppDispatch) => {
    dispatch(slice.actions.startLoading());
    if (userId) {
      getAssessmentTestLink(userId)
        .then((res) => window.open(res.test_logon_url, '_self'))
        .catch(() =>
          dispatch(openAssesmentLimitDialog(LIMIT_ASSESSMENT_ERROR_MESSAGE))
        );
    }
    const accessToken =
      window.localStorage.getItem('accessToken') || Cookies.get('accessToken');
    if (accessToken && isValidToken(accessToken)) {
      setSession(accessToken);
      getAccountData()
        ?.then((res) =>
          dispatch(
            slice.actions.getInitialize({
              isAuthenticated: true,
              user: res
            })
          )
        )
        .catch(() => {
          dispatch(
            enqueueSnackbar({
              message: 'Logout rejected',
              options: { variant: 'error' }
            })
          );
        });
    } else {
      dispatch(
        slice.actions.getInitialize({
          isAuthenticated: false,
          user: unAuthUser
        })
      );
    }
  }; 

export const getSearchedRecruters =
  (text: string | any, limit: number) => async (dispatch: AppDispatch) => {
    searchRecruters(text, limit).then((res: any) =>
      dispatch(slice.actions.setSearchedUsers({ user: res }))
    );
  };
