import NProgress from 'nprogress';
import { Fragment, lazy, ReactNode, Suspense, useEffect, useMemo } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
// material
import { makeStyles } from '@material-ui/core/styles';
// guards
import AssessmentLimitModal from 'views/dashboard/candidateProfile/AssessmentLimitModal';
import Page500 from 'views/Page500';
import DenyPrivacy from 'views/DenyPrivacy';
import AcceptPrivacy from 'views/AcceptPrivacy';
import ComingSoon from 'views/ComingSoon';
import Maintenance from 'views/Maintenance';
import VerifyPassword from 'views/authentication/VerifyPassword';
import GuestGuard from '../guards/GuestGuard';
// components
import LoadingScreen from '../components/LoadingScreen';
//
import { PATH_AUTH, PATH_DASHBOARD, PATH_PAGE } from './paths';
import DashboardRoutes from './dashboard.routes';
import HomeRoutes from './home.routes';
import Login from '../views/authentication/Login';
import Register from '../views/authentication/Register';
import ResetPassword from '../views/authentication/ResetPassword';
import Page404 from '../views/Page404';

// ----------------------------------------------------------------------

const nprogressStyle = makeStyles((theme) => ({
  '@global': {
    '#nprogress': {
      pointerEvents: 'none',
      '& .bar': {
        top: 0,
        left: 0,
        height: 2,
        width: '100%',
        position: 'fixed',
        zIndex: theme.zIndex.snackbar,
        backgroundColor: theme.palette.primary.main,
        boxShadow: `0 0 2px ${theme.palette.primary.main}`
      },
      '& .peg': {
        right: 0,
        opacity: 1,
        width: 100,
        height: '100%',
        display: 'block',
        position: 'absolute',
        transform: 'rotate(3deg) translate(0px, -4px)',
        boxShadow: `0 0 10px ${theme.palette.primary.main}, 0 0 5px ${theme.palette.primary.main}`
      }
    }
  }
}));

// NProgress Logic moved to a wrapper outside routes
function RouteProgress({ element }: { element: ReactNode }) {
  nprogressStyle();

  useMemo(() => {
    NProgress.start();
  }, []);

  useEffect(() => {
    NProgress.done();
  }, []);

  return element;
}

export function renderRoutes(routes: RouteItem[] = []) {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Routes>
        {routes.map((route, idx) => {
          // const Element = route.element;
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;

          // If path is an array, render multiple <Route /> components for each path
          if (Array.isArray(route.children)) {
            return route.children.map((child, subIdx) => (
              <Route
                key={`routes-${idx}-${subIdx}`}
                path={child.path}
                element={
                  <Guard>
                    <Layout>{child.element}</Layout>
                  </Guard>
                }
              />
            ));
          }

          // For a single route
          return (
            <Route
              key={`route-${idx}`}
              path={route.path}
              element={
                <Guard>
                  <Layout>{route.element}</Layout>
                </Guard>
              }
            />
          );
        })}
      </Routes>
    </Suspense>
  );
}

type RouteItem = {
  guard?: ({ children }: { children: ReactNode }) => JSX.Element;
  path?: string;
  element?: any;
  layout?: ({ children }: { children: ReactNode }) => JSX.Element;
  children?: RouteItem[];
};

export const routes: RouteItem[] = [
  // Auth Routes
  {
    guard: GuestGuard,
    path: PATH_AUTH.login,
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <Login />
      </Suspense>
    )
  },
  {
    path: PATH_AUTH.loginUnprotected,
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <Login />
      </Suspense>
    )
  },
  {
    guard: GuestGuard,
    path: PATH_AUTH.register,
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <Register />
      </Suspense>
    )
  },
  {
    path: PATH_AUTH.registerUnprotected,
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <Register />
      </Suspense>
    )
  },
  {
    path: PATH_AUTH.resetPassword,
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <ResetPassword />
      </Suspense>
    )
  },
  {
    path: PATH_AUTH.verify,
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <VerifyPassword />
      </Suspense>
    )
  },
  {
    path: PATH_PAGE.page404,
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <Page404 />
      </Suspense>
    )
  },
  {
    path: PATH_PAGE.page500,
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <Page500 />
      </Suspense>
    )
  },
  {
    path: PATH_PAGE.denyPrivacy,
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <DenyPrivacy />
      </Suspense>
    )
  },
  {
    path: PATH_PAGE.acceptPrivacy,
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <AcceptPrivacy />
      </Suspense>
    )
  },
  {
    path: PATH_PAGE.comingSoon,
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <ComingSoon />
      </Suspense>
    )
  },
  {
    path: PATH_PAGE.maintenance,
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <Maintenance />
      </Suspense>
    )
  },
  {
    path: PATH_AUTH.root,
    element: <Navigate to={PATH_AUTH.login} replace />
  },
  // Not found candidate //
  {
    path: PATH_DASHBOARD.candidates.candidateProfileNotFound,
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <Page404 />
      </Suspense>
    )
  },

  ...DashboardRoutes,
  ...HomeRoutes.routes // Spread the routes array from HomeRoutes
];

export default function AppRoutes() {
  return renderRoutes(routes);
}
