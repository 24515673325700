import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Card,
  Grid,
  TextField,
  Typography,
  makeStyles,
  Link
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { RootState } from 'redux/store';
import { DynamicForm } from 'components/DynamicForm';
import { CustomModal } from 'components/CustomModal';
import { ImageContainer } from 'components/ImageContainer';
import {
  deleteCmsGallery,
  deleteCmsMediaInfo,
  deleteCmsOffer,
  getCmsBaseInfo,
  getCmsData,
  getCmsMediaInfo,
  postCmsGallery,
  postCmsOffer,
  saveCmsMediaInfo,
  updateCmsBaseInfo,
  updateCmsGallery,
  updateCmsMediaInfo,
  updateCmsOffer
} from 'redux/slices/cms';
import { DeleteButton, EditButton } from 'components/Buttons';
import { updateCompanyInfo } from 'redux/slices/generalRecruiter';
import { prepareFormData, useFormFieldsByType } from 'helpers/formFields';
import { getButtons } from 'constants/text/buttons';
import useLocales from 'hooks/useLocales';
import TabPanel from 'components/TabPanel';
import Page from 'components/containers/Page';
import PageContainer from 'components/containers/PageContainer';
import { Icon } from '@iconify/react';
import editIcon from '@iconify/icons-ant-design/edit';
import plusCircle from '@iconify/icons-ant-design/plus-circle';
import settings from '@iconify/icons-ant-design/setting';
import notifications from '@iconify/icons-ant-design/bell';
import layout from '@iconify/icons-ant-design/layout-outline';
import { ButtonPrimary } from 'components/new-designer/button/ButtonPrimary';
import { useSnackbar } from 'notistack';
import { OverviewSelect } from 'components/OverviewFilter';
import { Button } from '@mui/material';
import ImageUploader from 'components/ImageUploader';
import { PATH_DASHBOARD } from 'routes/paths';
import NotificationsSettings from './notificationsSettings';
import MButton from '../../../components/new-designer/button/MButton';
import DashboardNavbar from '../../../layouts/dashboard/navbar/DashboardNavbar';
import { styleAddNew, useCmsCareer, useCmsStyles } from '../../../styled';
import { NETWORKS } from '../../../constants/constants';
import CompanyStyleSettings from 'components/CompanyStyleSettings';
import ModuleSettingsPage from '../ModuleSettingsPage';

const useStyles = makeStyles({
  scrollBar: {
    '&::-webkit-scrollbar': {
      borderRadius: '2px',
      width: '2px',
      background: '#C9CAD9'
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#1C9DD7',
      borderRadius: '10px'
    }
  },
  trashSection: {
    marginTop: '16px',
    position: 'absolute',
    right: '37px',
    background: 'rgba(255, 255, 255, 0.50)',
    width: '40px',
    height: '40px',
    padding: '10px',
    borderRadius: '50%',
    cursor: 'pointer',

    '&:hover': {
      background: 'rgba(255, 255, 255, 0.19)'
    }
  },
  edit: {
    marginTop: '16px',
    background: 'rgba(255, 255, 255, 0.50)',
    width: '40px',
    height: '40px',
    padding: '10px',
    borderRadius: '50%',
    cursor: 'pointer',
    position: 'absolute',
    right: '36px',
    top: '44px',
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.19)'
    }
  }
});

const Network = ({ network, onChange, onDelete, socialSettings }: any) => {
  const { t } = useLocales();
  const [itemValues, setItemValues] = useState({
    ...network
  });

  const classes = useCmsStyles();

  useEffect(() => {
    setItemValues(network);
  }, [network]);

  const handleSetValue = (field: string, value: any) => {
    const obj = {
      ...itemValues,
      [field]: value
    };
    setItemValues(obj);
    onChange(network.network, obj);
  };

  const items = useFormFieldsByType(
    'social-media-urls',
    itemValues,
    handleSetValue
  );

  return (
    <Box style={{ display: 'flex', alignItems: 'center' }}>
      {items.map((item: any, index: number) => (
        <Grid key={index} display="flex" flexDirection="row" mt={2}>
          <Grid display="flex" flexDirection="column">
            <Typography
              style={{
                fontFamily: 'Rubik',
                color: '#666666',
                marginBottom: '6px'
              }}
            >
              {item?.label}
            </Typography>
            <TextField
              fullWidth
              InputProps={{ classes, disableUnderline: true }}
              key={index}
              error={item.error}
              type={item.type}
              value={item.value}
              onChange={(el: any) => item.onChange(network?.id, el)}
              multiline={item.multiline}
              rows={item.rows}
              className={item.className || null}
              style={
                socialSettings && {
                  width: '447px',
                  height: '50px'
                }
              }
            />
          </Grid>
        </Grid>
      ))}
      <Grid
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: '10px',
          marginLeft: '35px'
        }}
      >
        <Button
          onClick={() => onDelete(network.network)}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            columnGap: '11px',
            width: '218px',
            height: '45px',
            backgroundColor: '#FF6868',
            color: '#FFF',
            borderRadius: '30px',
            marginTop: '30px'
          }}
        >
          <img src="/static/img/icons/trashWhite.svg" alt="" />
          {t('Delete')}
        </Button>
      </Grid>
    </Box>
  );
};

const SocialMedia = () => {
  const { base }: any = useSelector((state: RootState) => state.cms);
  const { t } = useLocales();
  const { media }: any = useSelector((state: RootState) => state.cms);
  const [values, setValues] = useState<any>(media?.results || []);
  const [networkOptions, setNetworkOptions] = useState([...NETWORKS]);
  const [option, setOption] = useState<any>(networkOptions[0]?.value);
  const { messages }: any = useSelector((state: RootState) => state.errors);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [itemValues, setItemValues] = useState(base);
  const [isNewNetwork, setIsNewNetwork] = useState<boolean>(false);
  const [newNetworkUrl, setNewNetworkUrl] = useState<string>('');

  const classes = useCmsStyles();
  const classCms = useCmsCareer();
  const { SAVE } = getButtons();

  useEffect(() => {
    if (values.length) {
      const filteredArray = [
        ...NETWORKS.filter((e) => !values.some((i: any) => i.network === e.key))
      ];
      setNetworkOptions(filteredArray);

      setOption(filteredArray[0]?.value);
    }
  }, [values]);

  const onChange = (network: any, value: any) => {
    const items = [...values];
    const index = items.findIndex((el: any) => el.network === network);
    if (index !== -1) {
      items[index] = value;
      setValues(items);
    }
  };

  useEffect(() => {
    dispatch(getCmsMediaInfo());
  }, []);

  const onUpdateOrSave = async (network: any, value: any, name = '') => {
    await Promise.all([
      dispatch(getCmsMediaInfo()),
      dispatch(getCmsBaseInfo())
    ]);
  };

  const saveDataAsync = async (itemValues: any, formData: any) => {
    try {
      const res: any = await Promise.all([
        itemValues?.id
          ? dispatch(updateCmsMediaInfo(itemValues.id, formData))
          : dispatch(saveCmsMediaInfo(formData))
      ]);
      if (res?.payload?.updatedMedia?.id) {
        onUpdateOrSave(itemValues.network, res);
        enqueueSnackbar('Updated successfully', { variant: 'success' });
      }
    } catch (err: any) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  const updateOrSave = async () => {
    for (let i = 0; i < values.length; i++) {
      const itemValues = values[i];

      const formData = new FormData();
      for (const key in itemValues) {
        if (key !== 'id') {
          formData.append(key, (itemValues as any)[key]);
        }
      }
      if (itemValues?.url) {
        saveDataAsync(itemValues, formData);
        enqueueSnackbar('Updated successfully', { variant: 'success' });
      }
    }
  };

  const addNewNetwork = async (): Promise<void> => {
    const itemValue: any = {
      network: networkOptions.find((e) => e?.value == option)?.key,
      url: newNetworkUrl
    };
    const formData = new FormData();
    for (const key in itemValue) {
      if (Object.prototype.hasOwnProperty.call(itemValue, key)) {
        formData.append(key, itemValue[key]);
      }
    }
    if (itemValue?.url) {
      await saveDataAsync(itemValue, formData);
    }
    setNewNetworkUrl('');
    dispatch(getCmsMediaInfo());
  };

  useEffect(() => {
    const lastMessage = messages[messages.length - 1];

    if (lastMessage?.response?.request?.response) {
      let errorMessages = null;

      try {
        errorMessages = JSON.parse(lastMessage.response.request.response);
      } catch (error) {
        console.log('Error parsing JSON:', error);
      }

      if (errorMessages) {
        if (errorMessages.network) {
          enqueueSnackbar(errorMessages.network[0], { variant: 'error' });
        }
        if (errorMessages.url) {
          enqueueSnackbar(errorMessages.url[0], { variant: 'error' });
        }
      }
    }
  }, [messages]);

  const onDelete = async (network: string) => {
    const deletedItem = values?.find((el: any) => el.network == network);
    await dispatch(deleteCmsMediaInfo(deletedItem?.id));
    onUpdateOrSave(network, null, 'delete');
  };

  useEffect(() => {
    if (media?.results) {
      setValues(media.results);
    }
  }, [media]);

  const handleSetValue = (field: string, value: any) => {
    setItemValues({
      ...itemValues,
      [field]: value
    });
  };

  const handleSubmit = () => {
    const excludedKeys = [
      'id',
      'welcome_section_img',
      'hero_img',
      'vacancy_section_banner'
    ];
    const formData = new FormData();

    for (const [key, value] of Object.entries(itemValues)) {
      if (!excludedKeys.includes(key)) {
        formData.append(key, value as any);
      }
    }

    dispatch(updateCmsBaseInfo(base.id, formData));
    enqueueSnackbar('Updated successfully', { variant: 'success' });
  };

  const firstColFields = useFormFieldsByType(
    'cms-settings-first',
    itemValues,
    handleSetValue
  );

  const secondColFields = useFormFieldsByType(
    'cms-settings-second',
    itemValues,
    handleSetValue
  );

  const setOptionHandle = (value: any) => {
    if (value) {
      setOption(value);
    }
  };

  return (
    <Grid>
      <Card
        style={{
          maxWidth: '1173px',
          padding: '25px 55px 30px 25px',
          background:
            'linear-gradient(94deg, rgba(255, 255, 255, 0.80) -5.21%, rgba(255, 255, 255, 0.30) 114.12%)'
        }}
      >
        <Typography
          style={{
            marginBottom: '25px',
            fontFamily: 'Rubik',
            fontSize: '20px',
            color: '#131417',
            fontWeight: 700
          }}
          fontWeight="600"
        >
          {t('Career Site Settings')}
        </Typography>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '36px'
          }}
        >
          <Grid display="flex" flexDirection="row">
            <Grid xs={8}>
              <Grid display="flex" flexDirection="row">
                {firstColFields.map((item: any, index: number) =>
                  index < 2 ? (
                    <Grid
                      key={item.id}
                      display="flex"
                      flexDirection="column"
                      xs={12}
                      style={{ marginRight: '40px' }}
                    >
                      <Typography
                        style={{
                          fontFamily: 'Rubik',
                          color: '#666666',
                          marginBottom: '6px'
                        }}
                      >
                        {item.label}
                      </Typography>
                      <TextField
                        fullWidth
                        InputProps={{ classes, disableUnderline: true }}
                        key={index}
                        error={item.error}
                        type={item.type}
                        value={item.value}
                        onChange={item.onChange}
                        multiline={item.multiline}
                        rows={item.rows}
                        className={item.className || null}
                      />
                    </Grid>
                  ) : null
                )}
              </Grid>
              <Grid display="flex" flexDirection="row">
                {firstColFields.map((item: any, index: number) =>
                  index >= 2 && index < 4 ? (
                    <Grid
                      key={item.id}
                      display="flex"
                      flexDirection="column"
                      xs={12}
                      style={{
                        marginRight: '40px',
                        marginTop: '30px'
                      }}
                    >
                      <Typography
                        style={{
                          fontFamily: 'Rubik',
                          color: '#666666',
                          marginBottom: '6px'
                        }}
                      >
                        {item.label}
                      </Typography>
                      <TextField
                        InputProps={{
                          classes,
                          disableUnderline: true
                        }}
                        key={index}
                        error={item.error}
                        type={item.type}
                        value={item.value}
                        onChange={item.onChange}
                      />
                    </Grid>
                  ) : null
                )}
              </Grid>
              <Grid display="flex" flexDirection="row">
                {firstColFields.map((item: any, index: number) =>
                  index === 4 ? (
                    <Grid
                      key={item.id}
                      display="flex"
                      flexDirection="column"
                      xs={12}
                      style={{ marginRight: '40px', marginTop: '35px' }}
                    >
                      <Typography
                        style={{
                          fontFamily: 'Rubik',
                          color: '#666666',
                          marginBottom: '6px'
                        }}
                      >
                        {item.label}
                      </Typography>
                      <TextField
                        InputProps={{ classes, disableUnderline: true }}
                        key={index}
                        error={item.error}
                        type={item.type}
                        value={item.value}
                        onChange={item.onChange}
                      />
                    </Grid>
                  ) : null
                )}
                {secondColFields.map((item: any, index: number) =>
                  index === 0 ? (
                    <Grid
                      key={item.id}
                      display="flex"
                      flexDirection="column"
                      xs={12}
                      style={{ marginRight: '40px', marginTop: '35px' }}
                    >
                      <Typography
                        style={{
                          fontFamily: 'Rubik',
                          color: '#666666',
                          marginBottom: '6px'
                        }}
                      >
                        {item.label}
                      </Typography>
                      <TextField
                        InputProps={{ classes, disableUnderline: true }}
                        key={index}
                        error={item.error}
                        type={item.type}
                        value={item.value}
                        onChange={item.onChange}
                      />
                    </Grid>
                  ) : null
                )}
              </Grid>
              {firstColFields.map((item: any, index: number) =>
                index > 4 ? (
                  <Grid key={item.id} display="flex" flexDirection="row">
                    <Grid
                      display="flex"
                      flexDirection="column"
                      xs={12}
                      style={{
                        marginRight: '40px',
                        marginTop: '30px'
                      }}
                    >
                      <Typography
                        style={{
                          fontFamily: 'Rubik',
                          color: '#666666',
                          marginBottom: '6px'
                        }}
                      >
                        {item.label}
                      </Typography>
                      <TextField
                        InputProps={{
                          classes,
                          disableUnderline: true
                        }}
                        key={index}
                        error={item.error}
                        type={item.type}
                        value={item.value}
                        onChange={item.onChange}
                      />
                    </Grid>
                  </Grid>
                ) : null
              )}
            </Grid>
            <Grid xs={4}>
              {secondColFields.map((item: any, index: number) =>
                index === 1 ? (
                  <Grid key={item.id} display="flex" flexDirection="column">
                    <Typography
                      style={{
                        fontFamily: 'Rubik',
                        color: '#666666',
                        marginBottom: '6px'
                      }}
                    >
                      {item.label}
                    </Typography>
                    <TextField
                      InputProps={{ classes: classCms }}
                      key={index}
                      error={item.error}
                      type={item.type}
                      value={item.value}
                      onChange={item.onChange}
                      multiline={item.multiline}
                    />
                  </Grid>
                ) : null
              )}
            </Grid>
          </Grid>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '30px'
            }}
          >
            <MButton
              title={SAVE}
              MyClass="violetButton"
              click={handleSubmit}
              style={{ width: '337px' }}
            />
          </Box>
        </Box>
        <Card
          style={{
            boxShadow: 'none',
            backgroundColor: 'transparent'
          }}
        >
          <Typography
            style={{
              fontFamily: 'Rubik',
              fontSize: '20px',
              color: '#131417',
              fontWeight: 700
            }}
            fontWeight="600"
          >
            {t('Social Networks Settings')}
          </Typography>
          {values.map((e: any) => (
            <Box key={e.id}>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'flex-end'
                }}
              >
                <Box>
                  <Typography
                    style={{
                      fontFamily: 'Rubik',
                      color: '#666666',
                      marginBottom: '6px',
                      fontSize: 15
                    }}
                  >
                    {t('Network')}
                  </Typography>
                  <TextField
                    fullWidth
                    InputProps={{ classes, disableUnderline: true }}
                    type="text"
                    disabled
                    value={e.network}
                    style={{
                      width: '350px',
                      height: '50px'
                    }}
                  />
                </Box>
                <Grid mt={2} pl={2}>
                  <Network
                    network={e}
                    onChange={onChange}
                    onDelete={onDelete}
                    socialSettings
                  />
                </Grid>
              </Box>
            </Box>
          ))}
          <Link
            onClick={() => setIsNewNetwork(true)}
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              columnGap: '13px',
              marginBottom: '35px',
              width: 'max-content',
              cursor: 'pointer',
              marginTop: 20
            }}
          >
            <Grid
              style={{
                backgroundImage: `url("/static/img/icons/plus.svg") `,
                width: '29px',
                height: '29px'
              }}
            />
            <Typography
              sx={{
                color: '#562C82',
                fontSize: '16px',
                fontWeight: 700,
                fontFamily: 'Rubik'
              }}
            >
              {t('Add Social Network')}
            </Typography>
          </Link>
          {isNewNetwork && (
            <Box
              style={{
                marginBottom: 10
              }}
            >
              <Box
                style={{
                  display: 'flex',
                  gap: 10,
                  alignItems: 'center'
                }}
              >
                <Box style={{ display: 'flex' }}>
                  <OverviewSelect
                    title="Social Networks"
                    activeOption={option}
                    options={networkOptions}
                    filterSx={{
                      marginRight: '25px',
                      marginBottom: '3px'
                    }}
                    width="350px"
                    setOption={(value: any) => setOptionHandle(value)}
                  />
                  <Box style={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography
                      style={{
                        fontFamily: 'Rubik',
                        color: '#666666',
                        marginBottom: '6px',
                        fontSize: 15
                      }}
                    >
                      {t('URL')}
                    </Typography>
                    <TextField
                      fullWidth
                      InputProps={{ classes, disableUnderline: true }}
                      type="text"
                      value={newNetworkUrl}
                      onChange={(el: any) => setNewNetworkUrl(el.target.value)}
                      style={{
                        width: '450px',
                        height: '50px'
                      }}
                    />
                  </Box>
                </Box>
                <Button onClick={addNewNetwork}>{t('Add')}</Button>
                <Button
                  style={{ color: '#666666' }}
                  onClick={() => setIsNewNetwork(false)}
                >
                  {t('Cancel')}
                </Button>
              </Box>
            </Box>
          )}
          <Grid
            mt={3}
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <MButton
              style={{ width: '337px' }}
              title={t('Save All')}
              MyClass="violetButton"
              click={() => updateOrSave()}
            />
          </Grid>
        </Card>
      </Card>
    </Grid>
  );
};

const WelcomeItemModal = ({ item, open, onClose }: any) => {
  const [itemValues, setItemValues] = useState(
    item || {
      welcome_section_title: '',
      welcome_section_text: '',
      welcome_section_img: null
    }
  );
  const [img, setImg] = useState('');
  const { t } = useLocales();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setItemValues(item);
  }, [item]);

  const handleSetValue = (field: string, value: any) => {
    setItemValues({
      ...itemValues,
      [field]: value
    });
  };

  const colFields = useFormFieldsByType(
    'welcome-form',
    itemValues || {
      welcome_section_title: '',
      welcome_section_text: '',
      welcome_section_img: null
    },
    handleSetValue
  );

  useEffect(() => {
    dispatch(getCmsData());
  }, [dispatch]);

  const handleSubmit = () => {
    const excludedKeys = [
      'id',
      'welcome_section_img',
      'hero_img',
      'vacancy_section_banner'
    ];
    const formData = new FormData();

    for (const [key, value] of Object.entries(itemValues)) {
      if (!excludedKeys.includes(key)) {
        formData.append(key, value as any);
      }
    }

    if (img) {
      formData.append('welcome_section_img', img);
    }

    dispatch(updateCmsBaseInfo(itemValues.id, formData));
    enqueueSnackbar('Updated successfully', { variant: 'success' });
    onClose();
  };

  return (
    <CustomModal open={open} onClose={onClose}>
      <ImageUploader
        index={Math.round(Math.random() * 1000)}
        label={t('Welcome Image')}
        prevValue={item?.welcome_section_img || ''}
        newValue={img}
        setValue={setImg}
      />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <DynamicForm
            inputValues={colFields}
            onSubmit={handleSubmit}
            fullWidth
            type="welcome-form"
          />
        </Grid>
      </Grid>
    </CustomModal>
  );
};

const WelcomeForm = () => {
  const [open, setOpen] = useState(false);
  const { t } = useLocales();
  const { base }: any = useSelector((state: RootState) => state.cms);

  return (
    <Grid>
      <WelcomeItemModal
        item={base}
        open={open}
        onClose={() => setOpen(false)}
      />
      <Card
        style={{
          width: '437px',
          height: '525px',
          background:
            'linear-gradient(94.35deg, rgba(255, 255, 255, 0.8) -5.21%, rgba(255, 255, 255, 0.3) 114.12%)',
          boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.08)',
          borderRadius: '20px',
          padding: '25px 25px 28px 25px'
        }}
      >
        <Grid>
          <Grid>
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <Box>
                <Typography
                  sx={{ mb: 1, fontFamily: 'Rubik', fontWeight: 700 }}
                  fontSize="20px"
                  gutterBottom
                >
                  {t('Welcome Settings')}
                </Typography>
              </Box>
              <Box style={{ display: 'flex', flexDirection: 'row' }}>
                <EditButton onClick={() => setOpen(true)} />
                <DeleteButton onClick={() => {}} />
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={7}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              maxWidth: '100%',
              margin: '35px 0 28px 0'
            }}
          >
            <ImageContainer
              style={{
                backround: 'transparent',
                borderRadius: '20px',
                maxHeight: '208px',
                width: '387px',
                objectFit: 'contain'
              }}
              title={base?.welcome_section_title}
              src={base?.welcome_section_img}
            />
            <Typography
              style={{
                fontFamily: 'Rubik',
                fontSize: '24px',
                width: '100%',
                fontWeight: '700',
                lineHeight: 'normal',
                marginTop: '20px',
                marginBottom: '15px'
              }}
            >
              {base?.welcome_section_title}
            </Typography>
            <Typography style={{ fontFamily: 'Rubik', color: '#666666' }}>
              {base?.welcome_section_text}
            </Typography>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
};

const BaseInfoForm = () => {
  const { base }: any = useSelector((state: RootState) => state.cms);
  const [hero, setHero] = useState('');
  const [jobsBanner, setJobsBanner] = useState('');
  const dispatch = useDispatch();
  const { t } = useLocales();
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = (): void => {
    const formData = new FormData();

    if (hero) {
      formData.append(`hero_img`, hero);
    }
    if (jobsBanner) {
      formData.append(`vacancy_section_banner`, jobsBanner);
    }

    dispatch(updateCmsBaseInfo(base.id, formData));
    setHero('');
    setJobsBanner('');
    enqueueSnackbar('Updated successfully', { variant: 'success' });
  };

  useEffect(() => {
    if (hero || jobsBanner) {
      handleSubmit();
    }
  }, [jobsBanner, hero]);

  useEffect(() => {
    dispatch(getCmsData());
  }, [dispatch]);

  return (
    <Grid spacing={3} style={{ marginTop: '25px' }}>
      <Grid item>
        <Card
          sx={{ p: 2, mb: 2 }}
          style={{
            width: '437px',
            height: '560px',
            background:
              'linear-gradient(94.35deg, rgba(255, 255, 255, 0.8) -5.21%, rgba(255, 255, 255, 0.3) 114.12%)',
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.08)',
            borderRadius: '20px',
            padding: '25px 25px 28px 25px'
          }}
        >
          <Typography
            style={{
              fontFamily: 'Rubik',
              fontSize: '20px',
              color: '#131417',
              fontWeight: 700
            }}
            fontWeight="600"
          >
            {t('Career Site Settings')}
          </Typography>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              position: 'relative',
              rowGap: '35px'
            }}
          >
            <Box
              style={{
                position: 'absolute',
                right: '0'
              }}
            >
              <img src="/static/img/icons/download.svg" width="25px" height="25px" />
            </Box>
            <ImageUploader
              siteSettingsUpload={true}
              index={0}
              label={t('Homepage Hero Image')}
              prevValue={base?.hero_img}
              newValue={hero}
              setValue={setHero}
              imageStyle={{
                background: 'transparent',
                boxShadow: 'none'
              }}
              imageStyleBox={{
                width: '387px',
                height: '191px',
                objectFit: 'initial'
              }}
              dropZoneDialog={{
                color: '#131417',
                marginTop: '5px',
                fontSize: '14px',
                height: '191px',
                width: '387px'
              }}
              labelStyle={{ marginBottom: '10px' }}
            />
            <Box
              style={{
                position: 'absolute',
                right: '0',
                bottom: '41%'
              }}
            >
              <img src="/static/img/icons/download.svg" width="25px" height="25px" />
            </Box>
            <ImageUploader
              siteSettingsUpload={true}
              index={1}
              label={t('Jobs Banner Image')}
              prevValue={base?.vacancy_section_banner}
              newValue={jobsBanner}
              setValue={setJobsBanner}
              imageStyle={{
                background: 'transparent',
                boxShadow: 'none',
                marginBottom: 0
              }}
              imageStyleBox={{
                width: '387px',
                height: '191px',
                objectFit: 'initial'
              }}
              dropZoneDialog={{
                color: '#131417',
                fontFamily: 'Rubik',
                lineHeight: '16px',
                marginTop: '5px'
              }}
            />
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
};

const GalleryItemModal = ({
  item,
  open,
  onClose,
  defaultImage,
  setSelectedFiles
}: any) => {
  const [itemValues, setItemValues] = useState(item || {});
  const [image, setImage] = useState('');
  const { t } = useLocales();
  const dispatch = useDispatch();

  useEffect(() => {
    setItemValues(item);
  }, [item]);

  const handleSubmit = () => {
    const formData = new FormData();
    const item = itemValues || {
      image: '',
      title: t('New'),
      description: '',
      home: 1
    };

    // if (item) {
    for (const key in item) {
      if (key !== 'id' && key !== 'image') {
        formData.append(key, (item as any)[key]);
      }
    }

    if (image) {
      formData.append(`image`, image);
    }

    if (item && item?.id) {
      dispatch(updateCmsGallery(item?.id, formData));
    } else {
      dispatch(postCmsGallery(formData));
    }
    setImage('');
    setSelectedFiles([]);
    onClose(item, 'submit');
  };

  return (
    <CustomModal
      open={open}
      onClose={onClose}
      style={{
        maxWidth: '500px',
        display: 'flex',
        flexDirection: 'column',
        rowGap: '20px'
      }}
    >
      <ImageUploader
        index={Math.round(Math.random() * 1000)}
        label={t('Gallery Image')}
        prevValue={item?.image || ''}
        newValue={image}
        setValue={setImage}
        setSelectedFiles={setSelectedFiles}
      />
      <ButtonPrimary
        disabled={defaultImage}
        type="submit"
        onClick={handleSubmit}
      >
        {t('Save')}
      </ButtonPrimary>
    </CustomModal>
  );
};

const GalleryForm = () => {
  const { gallery }: any = useSelector((state: RootState) => state.cms);
  const [galleryItems, setGalleryItems] = useState<any>([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [currentItemIndex, setCurrentItemIndex] = useState(0);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isItemsGalleryDisabled, setIsItemsGalleryDisabled] =
    useState<boolean>(false);
  const { t } = useLocales();
  const classes = useStyles();
  const dispatch = useDispatch();
  const gal = [...galleryItems?.filter((e: any) => !e?.id)];

  useEffect(() => {
    if (gal) {
      setIsItemsGalleryDisabled(true);
      if (selectedFiles.length) {
        setIsItemsGalleryDisabled(false);
      }
    } else {
      setIsItemsGalleryDisabled(false);
    }
  }, [gal, selectedFiles]);

  useEffect(() => {
    if (gallery?.results?.length) {
      setGalleryItems([...gallery?.results]);
    }
  }, [gallery?.results]);

  const handleAddNew = () => {
    setIsModalOpen(true);
  };

  const handleDelete = (index: number) => {
    setGalleryItems([
      ...galleryItems.filter((el: any, i: number) => i !== index)
    ]);

    if (galleryItems[index]?.id) {
      dispatch(deleteCmsGallery(galleryItems[index]?.id));
    }
  };

  const onCloseHandle = (item: any, type: string) => {
    setCurrentItemIndex(0);
    setIsModalOpen(false);
    if (item && !item?.id && type === 'submit') {
      setGalleryItems([...galleryItems?.slice(1, galleryItems.length)]);
    }
  };

  return (
    <Grid
      style={{
        width: '711px',
        height: '525px',
        background:
          'linear-gradient(94.35deg, rgba(255, 255, 255, 0.8) -5.21%, rgba(255, 255, 255, 0.3) 114.12%)',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.08)',
        borderRadius: '20px',
        padding: '25px 25px 28px 25px'
      }}
    >
      <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography
          sx={{ mb: 1, fontFamily: 'Rubik', fontWeight: 700 }}
          fontSize="20px"
          gutterBottom
        >
          {t('Welcome Settings')}
        </Typography>
      </Box>
      <Box>
        <Card
          style={{
            backgroundColor: 'transparent',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            boxShadow: 'none',
            flexWrap: 'wrap',
            rowGap: '20px'
          }}
        >
          <GalleryItemModal
            item={galleryItems[currentItemIndex - 1]}
            open={isModalOpen}
            onClose={(item: any, type: string) => onCloseHandle(item, type)}
            galleryItems={galleryItems}
            defaultImage={isItemsGalleryDisabled}
            setSelectedFiles={setSelectedFiles}
          />
          <Box
            className={classes.scrollBar}
            style={{
              display: 'grid',
              overflowY: 'auto',
              height: '420px',
              gridTemplateColumns: '227px auto',
              columnGap: '22px',
              rowGap: '24px'
            }}
          >
            <Grid style={styleAddNew}>
              <Grid
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'start'
                }}
                onClick={handleAddNew}
              >
                <Icon
                  icon={plusCircle}
                  color="#8321FF"
                  style={{ width: '27px', height: '27px', cursor: 'pointer' }}
                />
                <Grid>
                  <Typography
                    style={{
                      color: '#8321FF',
                      fontFamily: 'Rubik',
                      cursor: 'pointer'
                    }}
                    onClick={handleAddNew}
                  >
                    {t('Add New')}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {galleryItems.map((el: any, i: any) => (
              <Grid container key={i} style={{ position: 'relative' }}>
                <Grid style={{ borderRadius: '20px', marginRight: '15px' }}>
                  <ImageContainer
                    id={i}
                    style={{
                      borderRadius: '20px',
                      width: '256px',
                      height: '191px'
                    }}
                    title={el.title}
                    src={el.image}
                  />
                </Grid>
                <Grid
                  onClick={() => handleDelete(i)}
                  xs={12}
                  flexBasis="0"
                  className={classes.trashSection}
                >
                  <Grid>
                    <img
                      src="/static/img/icons/trash.svg"
                      color="#FF6868"
                      style={{
                        width: '20px',
                        height: '20px',
                        cursor: 'pointer'
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid className={classes.edit}>
                  <Grid
                    onClick={() => {
                      setCurrentItemIndex(i + 1);
                      setIsModalOpen(true);
                    }}
                  >
                    <Icon
                      icon={editIcon}
                      color="#1C9DD7"
                      style={{ width: '25px', height: '25px' }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Box>
        </Card>
      </Box>
    </Grid>
  );
};

const OfferItemModal = ({ item, open, onClose }: any) => {
  const [itemValues, setItemValues] = useState(item || {});
  const [img, setImg] = useState('');
  const { t } = useLocales();
  const dispatch = useDispatch();

  useEffect(() => {
    setItemValues(item);
  }, [item]);

  const handleSetValue = (field: string, value: any) => {
    setItemValues({
      ...itemValues,
      [field]: value
    });
  };

  const colFields = useFormFieldsByType(
    'cms-offer',
    itemValues,
    handleSetValue
  );

  const handleSubmit = () => {
    const formData = new FormData();
    if (itemValues) {
      for (const key in itemValues) {
        if (key !== 'id' && key !== 'img') {
          formData.append(key, (itemValues as any)[key]);
        }
      }
    }

    if (!itemValues?.home) {
      formData.append(`home`, '1');
    }

    if (img) {
      formData.append(`img`, img);
    }
    if (itemValues?.id) {
      dispatch(updateCmsOffer(itemValues.id, formData));
    } else {
      dispatch(postCmsOffer(formData));
    }
    onClose();
  };

  return (
    <CustomModal open={open} onClose={onClose}>
      <ImageUploader
        index={Math.round(Math.random() * 1000)}
        label={t('Offer Image')}
        prevValue={item?.img || ''}
        newValue={img}
        setValue={setImg}
      />

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <DynamicForm
            inputValues={colFields}
            onSubmit={handleSubmit}
            fullWidth
            type="cms-offer"
          />
        </Grid>
      </Grid>
    </CustomModal>
  );
};

const OffersForm = () => {
  const { offers }: any = useSelector((state: RootState) => state.cms);
  const [offerItems, setOfferItems] = useState(offers?.results || []);
  const [currentItemIndex, setCurrentItemIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { t } = useLocales();
  const dispatch = useDispatch();

  useEffect(() => {
    setOfferItems(offers?.results);
  }, [offers?.results]);

  const classes = useStyles();

  const handleAddNew = () => {
    setIsModalOpen(true);
  };

  const handleDelete = (index: number) => {
    if (offerItems[index]?.id) {
      dispatch(deleteCmsOffer(offerItems[index]?.id));
    } else {
      setOfferItems([
        ...offerItems.filter((el: any, i: number) => i !== index)
      ]);
    }
  };

  return (
    <Grid
      style={{
        width: '711px',
        height: '560px',
        background:
          'linear-gradient(94.35deg, rgba(255, 255, 255, 0.8) -5.21%, rgba(255, 255, 255, 0.3) 114.12%)',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.08)',
        borderRadius: '20px',
        marginTop: '25px'
      }}
    >
      <Box
        style={{
          padding: '25px 25px 28px 25px'
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <Typography
            sx={{
              mb: 1,
              fontFamily: 'Rubik',
              fontSize: '20px',
              fontWeight: 700
            }}
            gutterBottom
          >
            {t('Welcome Settings')}
          </Typography>
          <MButton
            title={t('Add New')}
            MyClass="violetButton"
            click={handleAddNew}
            style={{ width: '140px', borderRadius: '30px' }}
          />
        </Box>

        <OfferItemModal
          item={offerItems[currentItemIndex - 1]}
          open={isModalOpen}
          onClose={() => {
            setCurrentItemIndex(0);
            setIsModalOpen(false);
          }}
        />
        <Box
          className={classes.scrollBar}
          style={{ height: '444px', overflowY: 'auto', overflowX: 'hidden' }}
        >
          {offerItems &&
            offerItems?.map((el: any, i: any) => (
              <Card
                key={i}
                sx={{ p: 3, mb: 2 }}
                style={{
                  background:
                    'linear-gradient(94.35deg, rgba(255, 255, 255, 0.8) -5.21%, rgba(255, 255, 255, 0.3) 114.12%)',
                  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.08)',
                  borderRadius: '20px',
                  width: '650px'
                }}
              >
                <Grid
                  spacing={3}
                  sx={{
                    display: 'flex',
                    height: '100%',
                    alignItems: 'flex-start',
                    columnGap: '25px'
                  }}
                >
                  <Grid>
                    <ImageContainer
                      id={i}
                      style={{
                        borderRadius: '20px',
                        maxHeight: '133px',
                        width: '218px',
                        objectFit: 'cover'
                      }}
                      title={el.title}
                      src={el.img}
                    />
                  </Grid>
                  <Box
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      width: '100%'
                    }}
                  >
                    <Grid
                      item
                      xs={7}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column'
                      }}
                    >
                      <Typography
                        style={{
                          fontFamily: 'Rubik',
                          fontSize: '18px',
                          width: '100%',
                          color: '#131417',
                          fontWeight: 700
                        }}
                        fontWeight="bold"
                      >
                        {el.title}
                      </Typography>
                      <Typography
                        fontSize="14px"
                        style={{ fontFamily: 'Rubik', color: '#666666' }}
                      >
                        {el.description}
                      </Typography>
                    </Grid>
                    <Box style={{ display: 'flex', flexDirection: 'row' }}>
                      <EditButton
                        onClick={() => {
                          setCurrentItemIndex(i + 1);
                          setIsModalOpen(true);
                        }}
                      />
                      <DeleteButton onClick={() => handleDelete(i)} />
                    </Box>
                  </Box>
                </Grid>
              </Card>
            ))}
        </Box>
      </Box>
    </Grid>
  );
};

export const CmsSettings = () => {
  const { hash } = useLocation();

  const { company }: any = useSelector(
    (state: RootState) => state.generalRecruiter
  );
  const { t } = useLocales();

  const tabs: {
    label: string;
    Component: any;
    icon: any;
    path: string;
    activeIcon?: string;
  }[] = [
    {
      label: t('Settings'),
      icon: settings,
      Component: <CompanyStyleSettings />,
      path: t('site-settings')
    },
    {
      label: t('Notifications'),
      icon: notifications,
      Component: <NotificationsSettings isShowSubscribe />,
      path: 'notifications'
    },
    {
      label: t('Career site'),
      icon: layout,
      Component: (
        <Box>
          <Box>
            <Box
              style={{ display: 'flex', columnGap: '25px', marginTop: '28px' }}
            >
              <WelcomeForm />
              <GalleryForm />
            </Box>
            <Box
              style={{ display: 'flex', columnGap: '25px', marginTop: '28px' }}
            >
              <OffersForm />
              <BaseInfoForm />
            </Box>
            <Box>
              <SocialMedia />
            </Box>
          </Box>
        </Box>
      ),
      path: 'career-site'
    },
    {
      label: t('Module Settings'),
      icon: layout,
      Component: (
        <Box>
          <Box>
            <ModuleSettingsPage />
          </Box>
        </Box>
      ),
      path: 'module-settings'
    }
  ];

  return (
    <Page title={t('settings')}>
      <PageContainer>
        <DashboardNavbar
          title={
            hash === '#module-settings' ? t('Module Settings') : t('Settings')
          }
        />
        <TabPanel tabs={tabs} dashboard old={true} />
      </PageContainer>
    </Page>
  );
};

export default CmsSettings;
