import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { Card, Grid, Typography } from '@material-ui/core';
import Tooltip from '@mui/material/Tooltip';

import { DynamicFormWrapper } from '../../../components/DynamicForm';
import ImageUploader from '../../../components/ImageUploader';
import { prepareFormData } from '../../../helpers/formFields';
import { getInitialValuesByType } from '../../../helpers/formInitialValues';
import useLocales from '../../../hooks/useLocales';
import { updateCompanyInfo } from '../../../redux/slices/generalRecruiter';
import { enqueueSnackbar } from 'redux/slices/notifications';

const NotificationsSettings = ({ styles, isShowSubscribe = false }: { styles?: any, isShowSubscribe: boolean }) => {
  const { company }: any = useSelector(
    (state: RootState) => state.generalRecruiter
  );
  const { id, email_header } = company;
  const [values, setValues] = useState<any>(
    getInitialValuesByType('notification-info', company)
  );
  const [images, setImages] = useState({
    email_header: ''
  });

  useEffect(() => {
    if (images?.email_header) {
      handleSubmit();
    }
  }, [images?.email_header]);

  const setNewValues = (field: string, value: any) => {
    setValues({
      ...values,
      [field]: value
    });
  };

  const setNewImage = (field: string, file: any) => {
    setImages({
      ...images,
      [field]: file
    });
  };

  const { t } = useLocales();
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    const data = prepareFormData(values, images);
    dispatch(updateCompanyInfo(id, data));
    dispatch(
      enqueueSnackbar({
        messageType: 'updated',
        options: { variant: 'success' }
      })
    )
  };

  const uploaderData = [
    {
      label: t('Email Header'),
      prevValue: email_header,
      newValue: images.email_header,
      setValue: (file: any) => setNewImage('email_header', file)
    }
  ];

  const cardStyle = {
    width: '378px',
    height: '427px',
    background: !styles
      ? 'linear-gradient(94.35deg, rgba(255, 255, 255, 0.8) -5.21%, rgba(255, 255, 255, 0.3) 114.12%)'
      : 'linear-gradient(94deg, rgba(84, 46, 131, 0.07) -5.21%, rgba(255, 255, 255, 0.00) 114.12%)',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.08)',
    borderRadius: '20px',
    padding: styles ? '13px 13px 13px 20px' : '30px 30px 0 30px'
  };
  return (
    <Grid
      container
      spacing={3}
      direction="row"
      justifyContent={styles?.justifyContent ?? 'flex-start'}
      style={{ marginTop: styles ? '0' : '40px', marginLeft: 0, ...styles }}
      sx={{
        '& p': {
          textAlign: styles ? 'start' : 'center'
        }
      }}
    >
      <Grid style={{ marginRight: styles ? 0 : '30px' }}>
        {
        isShowSubscribe &&  uploaderData.map((el: any, i: number) => (
          <ImageUploader
            key={i}
            index={i}
            label={el.label}
            prevValue={el.prevValue}
            newValue={el.newValue}
            setValue={el.setValue}
            imageStyle={{ height: '427px', width: '321px' }}
            labelStyle={{
              fontFamily: 'Rubik',
              marginTop: '12px',
              fontWeight: 700
            }}
            styles={!!styles}
          />
        ))}
      </Grid>
      <Grid>
        <Card style={cardStyle}>
          {styles && (
            <Grid
              style={{
                justifyContent: 'end',
                display: 'flex'
              }}
            >
              <Tooltip
                title="Lorem ipsum"
                placement="top-start"
                componentsProps={{
                  tooltip: {
                    sx: {
                      background: '#fff',
                      color: '#000',
                      display: 'inline-flex',
                      padding: '10px 34px 13px 10px',
                      alignItems: 'center',
                      borderRadius: '13.417px 13.417px 0px 13.417px',
                      boxShadow:
                        '0px 5.85953px 11.71907px -1.46488px rgba(145, 158, 171, 0.24), 0px 0px 0.73244px 0px rgba(145, 158, 171, 0.24)',
                      marginLeft: '-115px',
                      maxWidth: '115px',
                      minHeight: '55px',
                      position: 'absolute',
                      bottom: '-20px',
                      left: '-5px'
                    }
                  }
                }}
              >
                <Grid
                  style={{
                    color: '#fff',
                    borderRadius: '50%',
                    background: '#6A3B9C',

                    width: '18px',
                    height: '18px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    opacity: '0.4',
                    paddingTop: '4px'
                  }}
                >
                  i
                </Grid>
              </Tooltip>
            </Grid>
          )}
          <Typography
            variant="h5"
            style={{
              fontFamily: 'Rubik',
              textAlign: styles ? 'start' : 'center',
              fontWeight: 700
            }}
          >
            {t('Email Settings')}
          </Typography>

          <DynamicFormWrapper
            type="notification-info"
            itemValues={values}
            setValues={setNewValues}
            onSubmit={handleSubmit}
            registerNotice={!!styles}
          />
        </Card>
      </Grid>
    </Grid>
  );
};

export default NotificationsSettings;
