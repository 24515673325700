import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { Card, Grid } from '@material-ui/core';
import { Color, ColorPicker, createColor } from 'material-ui-color';

import { GridPickerStyle, styleCard } from 'styled';
import styled from '@emotion/styled';

const CustomCard = styled(Card)(() => ({
  background:
    'linear-gradient(94deg, rgba(84, 46, 131, 0.07) -5.21%, rgba(255, 255, 255, 0.00) 114.12%)',
  boxShadow: '0px 4px 14px 0px rgba(0, 0, 0, 0.08)',
  borderRadius: '20px',
  marginRight: '30px',
  marginTop: '0px',
  padding: '36px 30px 30px'
}));

const SiteColours = ({
  setColors,
  colors
}: {
  setColors: Dispatch<
    SetStateAction<{
      primary_color: string;
      secondary_color: string;
    }>
  >;
  colors: any;
}) => {
  const { company }: any = useSelector(
    (state: RootState) => state.generalRecruiter
  );
  const [values, setValues] = useState(colors);

  useEffect(() => {
    setValues({
      primary_color: company.primary_color || '#000000',
      secondary_color: company.secondary_color || '#808080'
    });
  }, [company]);

  const handleSetValues = (field: string, value: any) => {
    const newValue = value?.css?.backgroundColor || value;
    const data = {
      ...values,
      [field]: newValue
    };
    setValues(data);
    setColors(data);
  };

  return (
    <CustomCard
      sx={{ p: 3, marginRight: '0 !important' }}
      style={{
        ...styleCard,
        background:
          'linear-gradient(94deg, rgba(84, 46, 131, 0.07) -5.21%, rgba(255, 255, 255, 0.00) 114.12%)',
        padding: '40px 45px !important',
        display: 'flex',
        alignItems: 'center',
        marginRight: '0 !important'
      }}
    >
      <Grid
        style={{ rowGap: '20px', display: 'flex', flexDirection: 'column' }}
      >
        <GridPickerStyle>
          <ColorPicker
            value={createColor(values.primary_color)}
            onChange={(color: Color) => handleSetValues('primary_color', color)}
          />
        </GridPickerStyle>
        <GridPickerStyle>
          <ColorPicker
            value={createColor(values.secondary_color)}
            onChange={(color: Color) =>
              handleSetValues('secondary_color', color)
            }
          />
        </GridPickerStyle>
      </Grid>
    </CustomCard>
  );
};

export default SiteColours;
