import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IProduct } from '../../views/dashboard/types';
import { getVonqProducts } from '../../utils/vonq';
import { useSnackbar } from 'notistack';

const initialState: {
  loading: boolean;
  totalCount: number;
  products: IProduct[] | null;
  recommendedProducts: IProduct[] | null;
} = {
  loading: false,
  products: null,
  totalCount: 0,
  recommendedProducts: null
};
export const fetchProducts = createAsyncThunk(
  'job/fetchProducts',
  async (params: { data: any }) => getVonqProducts(params.data)
);

export const fetchRecommendedProducts = createAsyncThunk(
  'job/fetchRecommendedProducts',
  async (params: { data: any }) => getVonqProducts(params.data)
);

const vonqProducts = createSlice({
  name: 'vonqProducts',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProducts.pending, (state) => {
        state.loading = false;
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.products = action.payload.results;
        state.totalCount = action.payload.count;
        state.loading = false;
      })

      .addCase(fetchProducts.rejected, () => {
        const { enqueueSnackbar } = useSnackbar();
        enqueueSnackbar(
          'No products found. Select other filters and try again',
          {
            variant: 'warning'
          }
        );
      })
      .addCase(fetchRecommendedProducts.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchRecommendedProducts.fulfilled, (state, action) => {
        state.recommendedProducts = action.payload.results;
        state.totalCount = action.payload.count;
        state.loading = false;
      })
      .addCase(fetchRecommendedProducts.rejected, () => {
        const { enqueueSnackbar } = useSnackbar();
        enqueueSnackbar(
          'No products found. Select other filters and try again',
          {
            variant: 'warning'
          }
        );
      });
  }
});

export default vonqProducts.reducer;
