import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import homeFill from '@iconify/icons-eva/home-fill';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
import { NavLink, useLocation } from 'react-router-dom';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import {
  AppBar,
  Box,
  Container,
  Hidden,
  List,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Toolbar
} from '@material-ui/core';
import ProductsBasket from 'components/ProductsBasket';
import { ButtonPrimary } from 'components/new-designer/button/ButtonPrimary';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { PATH_AUTH, PATH_HOME } from '../../routes/paths';
import useOffSetTop from '../../hooks/useOffSetTop';
import { MIconButton } from '../../components/@material-extend';
import Logo from '../../components/Logo';
import MenuPopover from '../../components/MenuPopover';
import useAuth from '../../hooks/useAuth';
import LanguagePopover from '../dashboard/navbar/LanguagePopover';
import useLocales from '../../hooks/useLocales';

const MENU_LINKS = [
  { title: 'Home', icon: homeFill, href: PATH_HOME.home },
  { title: 'Jobs', icon: homeFill, href: PATH_HOME.jobs }
];

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 96;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  background: 'transparent'
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: APP_BAR_MOBILE,
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  [theme.breakpoints.up('md')]: { height: APP_BAR_DESKTOP }
}));

const ToolbarShadowStyle = styled('div')(() => ({
  left: 0,
  right: 0,
  bottom: 0,
  height: 24,
  zIndex: -1,
  margin: 'auto',
  borderRadius: '50%',
  position: 'absolute',
  width: `calc(100% - 48px)`
}));

export default function HomeNavbar({
  logo,
  login = false
}: {
  logo: string;
  login?: boolean;
}) {
  const anchorRef = useRef(null);
  const { pathname } = useLocation();
  const offset = useOffSetTop(100);
  const [openMenu, setOpenMenu] = useState(false);
  const isHome = pathname === '/';
  const auth = useAuth();
  const { t } = useLocales();
  const { base }: any = useSelector((state: RootState) => state.cms);
  const { primary_color } = base;

  const getButtonPath = () => {
    if (!auth.isAuthenticated) {
      return login ? PATH_AUTH.register : PATH_AUTH.login;
    }
    if (auth.user.role === 'candidate' || auth.user.role === 'employee') {
      return PATH_HOME.dashboardCandidate;
    }
    return PATH_HOME.dashboardRecruiter;
  };

  const renderMenuDesktop = (
    <>
      {MENU_LINKS.map((link) => (
        <NavLink
          to={link.href}
          key={link.title}
          style={({ isActive }) => ({
            fontFamily: 'Rubik',
            fontWeight: 400,
            fontSize: '18px',
            textTransform: 'uppercase',
            color: isActive ? primary_color : 'inherit',
            display: 'relative',
            marginRight: 5,
            transition: 'opacity 0.3s',
            opacity: isActive ? 1 : 0.48,
            '&:hover': {
              opacity: 0.7,
              color: primary_color
            }
          })}
        >
          {t(link.title)}
        </NavLink>
      ))}
    </>
  );

  const renderMenuMobile = (
    <MenuPopover
      disablePortal
      open={openMenu}
      anchorEl={anchorRef.current}
      onClose={() => setOpenMenu(false)}
    >
      <List>
        {MENU_LINKS.map((link) => (
          <MenuItem
            to={link.href}
            key={link.title}
            component={NavLink} // Use NavLink for mobile menu
            onClick={() => setOpenMenu(false)}
            sx={{ color: 'text.secondary', py: 1 }}
          >
            <ListItemIcon>
              <Icon icon={link.icon} width={20} height={20} />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ typography: 'body2' }}>
              {t(link.title)}
            </ListItemText>
          </MenuItem>
        ))}
      </List>
    </MenuPopover>
  );

  const getButtonName = () => {
    if (auth.isAuthenticated) {
      return t('Your dashboard');
    }
    return login ? t('Register') : t('Login');
  };

  return (
    <RootStyle color="inherit">
      <ToolbarStyle
        disableGutters
        sx={{
          ...(offset && {
            height: { md: APP_BAR_DESKTOP - 20 }
          })
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <>
            <NavLink to="/">
              {logo ? <Logo url={logo} height="90px" /> : null}
            </NavLink>
            <Box sx={{ flexGrow: 1 }} />

            {(base.active || auth.isAuthenticated) && (
              <Hidden mdDown>{renderMenuDesktop}</Hidden>
            )}
            <LanguagePopover sx={{ mr: 3 }} />
            {(auth.isAuthenticated && auth.user.role !== 'candidate') ||
              (auth.isAuthenticated && auth.user.role !== 'employee' && (
                <Box sx={{ mr: 3 }}>
                  <ProductsBasket />
                </Box>
              ))}
            <ButtonPrimary
              component={NavLink}
              to={getButtonPath()}
              style={{
                minWidth: '170px',
                background: `linear-gradient(92.25deg, ${primary_color}90 -2.57%, ${primary_color} 113.38%)`
              }}
            >
              {getButtonName()}
            </ButtonPrimary>

            <Hidden mdUp>
              <MIconButton
                ref={anchorRef}
                onClick={() => setOpenMenu(true)}
                sx={{
                  ml: 1,
                  ...(isHome && { color: 'common.white' }),
                  ...(offset && { color: 'text.primary' })
                }}
              >
                <Icon icon={menu2Fill} />
              </MIconButton>
              {(base.active || auth.isAuthenticated) && renderMenuMobile}
            </Hidden>
          </>
        </Container>
      </ToolbarStyle>

      {offset && <ToolbarShadowStyle />}
    </RootStyle>
  );
}
