import { useSelector } from 'react-redux';
import Page from '../../components/containers/Page';
import TabPanel from '../../components/TabPanel';
import useAuth from '../../hooks/useAuth';
import PageContainer from '../../components/containers/PageContainer';
import useLocales from '../../hooks/useLocales';
import { RootState } from '../../redux/store';
import DashboardNavbar from '../../layouts/dashboard/navbar/DashboardNavbar';
import SubscriptionPlanList from './companySettings/subscriptionPlanList';
import Invitations from './companySettings/invitations';

const billing = '/static/img/icons/billings.svg';
const billingNew = '/static/img/icons/biilingsNew.svg';
const invitations = '/static/img/icons/invitations.svg';
const invitationsNew = '/static/img/icons/invitationsNew.svg';

export default function Settings() {
  const { company }: any = useSelector(
    (state: RootState) => state.generalRecruiter
  );
  const { t } = useLocales();
  const { user } = useAuth();
  const { role } = user;
  const isAdmin = role === 'admin';
  const isRecruiter = role === 'recruiter';
  const companyStatus = company.status === 'active' || 'trialing'

  const tabs = [
    {
      label: t('Billings'),
      Component: <SubscriptionPlanList companyData={company} />,
      icon: billing,
      activeIcon: billingNew,
      hide: !isAdmin,
      path: 'billings',
    },
    {
      label: t('Invitations'),
      Component: <Invitations />,
      icon: invitations,
      activeIcon: invitationsNew,
      hide: !(isAdmin || isRecruiter),
      path: 'invitations',
    }
  ];

  const title = t('Account Settings');

  return (
    <Page title={title}>
      <PageContainer>
        <DashboardNavbar title={title} />
        <TabPanel tabs={tabs} dashboard={true}/>
      </PageContainer>
    </Page>
  );
}
