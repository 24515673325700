import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  LinearScale,
  Title,
  Tooltip
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Title1 } from 'components/text/title';
import useLocales from 'hooks/useLocales';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Legend } from 'components/Legend';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { fetchCostsAnalytics } from '../../redux/slices/dataAnalytics';
import * as ExcelJS from 'exceljs';
import XLSXButton from '../../components/XLSXButton';
import { HrDivider } from '../../styled';
import { createExcel } from '../../utils/helpers';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  ChartDataLabels
);

const JobCostsAnalytics = () => {
  const [legendActive, setLegendActive] = useState(true);
  const { costsAnalytics } = useSelector(
    (state: RootState) => state.dataAnalytics
  );
  const dispatch = useDispatch();
  const { t } = useLocales();

  const minLengthOfLabel = costsAnalytics?.labels
    ? Math.min(...costsAnalytics?.labels?.map((label: any) => label.length))
    : 0;

  useEffect(() => {
    dispatch(fetchCostsAnalytics());
  }, []);

  const onDownloadData = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet 1');
    const cellA1 = worksheet.getCell('A1');
    cellA1.value = 'Job Posts Name';
    const cellA3 = worksheet.getCell('A3');
    cellA3.value = 'Total cost per job marketing campagne';
    worksheet.mergeCells('A1:A2');
    cellA1.alignment = { vertical: 'middle', horizontal: 'left' };
    for (let i = 0; i < costsAnalytics.labels.length; i++) {
      const label: any = costsAnalytics.labels[i];
      const columnIndex = i + 2;
      for (let row = 1; row <= 2; row++) {
        const cell = worksheet.getCell(row, columnIndex);
        cell.value = label;
      }

      if (i === 0) {
        worksheet.mergeCells(1, 2, 2, 2);
      } else {
        worksheet.mergeCells(1, columnIndex, 2, columnIndex);
      }

      const column = worksheet.getColumn(columnIndex);
      column.width = Math.max(10, label.length + 2);
      column.alignment = { vertical: 'middle', horizontal: 'center' };
    }

    for (let i = 0; i < costsAnalytics.data.length; i++) {
      const value = costsAnalytics.data[i];
      const cell = worksheet.getCell(3, i + 2);
      cell.value = value;
    }
    const columnA = worksheet.getColumn('A');
    columnA.width = 40;
    await createExcel(workbook);
  };

  return (
    <Grid>
      <XLSXButton
        available={costsAnalytics?.data?.some((el: number) => el !== 0)}
        onClick={onDownloadData}
        style={{ right: '50px', bottom: '30px' }}
      />
      <Grid item xs={12}>
        <Title1>{t('Total cost per job marketing campagne')}</Title1>
        <HrDivider />
      </Grid>
      <Grid>
        <Bar
          height={200}
          options={{
            responsive: true,
            plugins: {
              datalabels: {
                display: true,
                color: 'white',
                align: 'start',
                anchor: 'end',
                font: {
                  size: 8
                }
              },
              legend: {
                display: false
              },
              title: {
                display: true,
                text: `${t('CURRENCY')} ${costsAnalytics.currency}`,
                align: 'start',
                color: '#1C9DD7',
                padding: {
                  bottom: 32
                },
                font: {
                  size: 20
                }
              },
              tooltip: {
                callbacks: {
                  title: (data: any) => {
                    const { formattedValue } = data[0];
                    return formattedValue;
                  },
                  label: () => '',
                  footer: (data: any) =>
                    costsAnalytics?.labels[data[0].dataIndex]
                },
                titleMarginBottom: 0,
                footerColor: 'rgba(123, 72, 178, 0.52)',
                titleFont: {
                  size: 20
                },
                footerFont: {
                  size: 10
                },
                padding: {
                  left: 20,
                  top: 10,
                  bottom: 10,
                  right: 20
                },
                titleColor: '#7B48B2',
                backgroundColor: '#fff',
                borderWidth: 1,
                borderColor: 'rgba(128,128,128,0.2)',
                cornerRadius: 10,
                caretSize: 0
              }
            },
            scales: {
              y: {
                grid: {
                  display: false
                }
              },
              x: {
                grid: {
                  display: false
                }
              }
            }
          }}
          data={{
            labels:
              costsAnalytics?.labels?.map((label: any) => {
                const min = minLengthOfLabel > 10 ? 10 : minLengthOfLabel;
                return label.length > min
                  ? `${label.slice(0, min - 1)}...`
                  : label;
              }) || [],
            datasets: [
              {
                data: costsAnalytics?.data,
                label: 'Legend 1',
                backgroundColor: (context: any) => {
                  const { chart } = context;
                  const { ctx } = chart;
                  const gradient1 = ctx.createLinearGradient(0, 0, 0, 1000);
                  gradient1.addColorStop(0, '#9B5FDA');
                  gradient1.addColorStop(1, '#562C82');
                  return legendActive ? gradient1 : '#ffffff00';
                },
                borderWidth: 0,
                barPercentage: legendActive ? 0.6 : 0
              }
            ]
          }}
        />
      </Grid>
      <Legend
        setLegendActive={setLegendActive}
        legendActive={legendActive}
        title="Legend 1"
      />
    </Grid>
  );
};

export default JobCostsAnalytics;
