import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { Button, Grid, Typography } from '@mui/material';
import IntegrationsTable from './integration/IntegrationsTable';
import KomboModal from './integration/KomboModal';
import { getDataByUrl } from '../../../requests';
import DashboardNavbar from '../../../layouts/dashboard/navbar/DashboardNavbar';

const Integrations = () => {
  const [open, setOpen] = useState(false);
  const [komboData, setKomboData] = useState<any>(null);
  const [count, setCount] = useState<any>(null);
  const navigate = useNavigate();

  const company: any = useSelector(
    (state: RootState) => state.generalRecruiter.company
  );

  useEffect(() => {
    if (company.subscription_id === 'free') {
      navigate(-1);
    }
  }, [company]);

  const getKomboData = async () => {
    const data = await getDataByUrl('/api/kombo/integrations/');
    setCount(data.count);
    setKomboData(data.results);
  };

  useEffect(() => {
    getKomboData();
  }, [open]);

  return (
    <Grid>
      <DashboardNavbar title="Integrations" />
      <Grid container rowGap={2} pl={4} mt={5}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="end"
          marginBottom={4}
        >
          <Typography maxWidth="70%">
            Here you can manage the integrations that you or your customers have
            set up or create new ones
          </Typography>
          <Button
            variant="contained"
            sx={{
              p: 1.5,
              background: '#18B590',
              ':hover': { background: '#0d9d7b' }
            }}
            onClick={() => setOpen(true)}
          >
            + New integration
          </Button>
        </Grid>
        <KomboModal open={open} setOpen={setOpen} />
        <Grid item xs={12}>
          <IntegrationsTable
            komboData={komboData}
            setKomboData={setKomboData}
            getKomboData={getKomboData}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Integrations;
