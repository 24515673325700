export enum CalendarEnum {
  EDIT = 'edit',
  ADD = 'add',
  DELETE = 'delete',
  INTERVIWERS = 'interviewers',
  YEARLY = 'yearly',
  UPDATE = 'update',
  CANDIDATE = 'candidate',
  VACANCY = 'vacancy',
  START = 'start',
  END = 'end',
  DATE = 'DATE',
  ADMIN = 'admin'
}

export const eventTypes = [
  {
    id: 0,
    label: 'On-site interview'
  },
  {
    id: 1,
    label: 'Phone interview'
  },
  {
    id: 2,
    label: 'Video interview'
  }
];
