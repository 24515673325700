import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState
} from 'react';
import useLocales from 'hooks/useLocales';
import Page from 'components/containers/Page';
import PageContainer from 'components/containers/PageContainer';
import { ResetButton } from 'components/Buttons';
import DashboardNavbar from 'layouts/dashboard/navbar/DashboardNavbar';
import {
  Box,
  Grid,
  Typography,
  Dialog,
  IconButton,
  InputLabel
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { OverviewSearch } from 'components/OverviewFilter';
import { useDispatch, useSelector } from 'react-redux';
import PaginationContainer from 'components/containers/PaginationContainer';
import { resetTemplatesFilters, setTemplates } from 'redux/slices/filters';
import { RootState } from 'redux/store';
import {
  createTemplate,
  getTemplatesList,
  updateTemplate
} from 'redux/slices/template';
import { useSnackbar } from 'notistack';
import { deleteTemplate } from 'requests/templates';
import { getTranslatedList } from 'constants/constants';
import ListContainer from 'components/containers/ListContainer';
import TemplateCard from 'components/TemplateCard';
import { useDebounce } from 'hooks/useDebounce';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import ReactQuill from 'react-quill';
import { ButtonPrimary } from 'components/new-designer/button/ButtonPrimary';
import LoadingBox from 'components/LoadingBox';
import EmptyContent from 'components/EmptyContent';

interface EditModalProps {
  dispatch: Dispatch<any>;
  isOpen: boolean;
  handleClose: () => void;
  results: any[] | null;
  templateId: any;
  templateName: string | undefined;
  templateOwner: number;
  setTemplateName: Dispatch<SetStateAction<string | undefined>>;
  rawInput: string | undefined;
  content: string | undefined;
  setRawInput: Dispatch<SetStateAction<string>>;
}

const ReactQuillBody = styled(ReactQuill)(() => ({
  '.ql-container': { height: '100%' },
  '.ql-snow': {
    background: '#FFFFFF',
    border: '1px solid',
    borderColor: 'transparent'
  },
  '.ql-snow:first-of-type': {
    borderRadius: '5px 5px 0 0'
  },
  '.ql-snow:last-of-type': {
    borderRadius: '0 0 5px 5px',
    borderTop: 0,
    minHeight: 'auto',
    height: '220px'
  }
}));

const EditModal = ({
  dispatch,
  isOpen,
  handleClose,
  results,
  templateId,
  templateName,
  templateOwner,
  setTemplateName,
  rawInput,
  content,
  setRawInput
}: EditModalProps) => {
  const { t } = useLocales();

  const saveToTemplates = () => {
    if (content) {
      if (
        !results?.some((t) => t.content.toLowerCase() === content.toLowerCase())
      ) {
        const data = { content, name: templateName || null };
        dispatch(createTemplate(data));
        handleClose();
      }
    }
  };

  const update = () => {
    const id = templateId;
    const data = { name: templateName || null, content, owner: templateOwner };

    dispatch(updateTemplate(id, data));
    handleClose();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      sx={{
        '& .MuiDialog-paper': {
          minWidth: '631px'
        },
        '& .MuiPaper-root': {
          borderRadius: '20px'
        }
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        px="25px"
        pt="25px"
      >
        <Typography variant="h6" fontSize="20px" fontWeight={600}>
          {t(`Edit Template`)}
        </Typography>
        <IconButton
          edge="start"
          onClick={handleClose}
          aria-label="close"
          sx={{ color: '#C9CAD9' }}
        >
          <CloseIcon />
        </IconButton>
      </Box>

      <Box
        sx={{
          padding: '25px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            height: '100%',
            marginBottom: '25px'
          }}
        >
          <InputLabel htmlFor="template name" sx={{ color: '#666666' }}>
            {t('Title of template')}
          </InputLabel>
          <input
            className="task_field"
            value={templateName?.replace(/<[^>]*>?/gm, '')}
            placeholder={t(`Title of template (Optional)`)}
            color="#C9CAD9"
            onChange={(el: any) => {
              setTemplateName(el.target.value);
            }}
            style={{
              width: '100%',
              padding: '15px',
              borderRadius: '30px',
              border: '1px solid #C9CAD9',
              cursor: 'pointer',
              fontSize: '14px',
              textAlign: 'initial'
            }}
          />
          <ReactQuillBody
            placeholder={t('Start typing...')}
            value={rawInput}
            onChange={setRawInput}
            modules={{
              toolbar: [
                [{ font: [] }],
                [{ size: ['small', false, 'large', 'huge'] }],
                ['bold', 'italic', 'underline'],
                [{ list: 'ordered' }, { list: 'bullet' }],
                [{ align: [] }],
                [{ color: [] }, { background: [] }],
                ['clean']
              ]
            }}
          />
        </Box>

        <ButtonPrimary
          variant="contained"
          color="primary"
          onClick={() => {
            if (templateOwner === 0) {
              saveToTemplates();
            } else {
              update();
            }
          }}
        >
          {templateOwner === 0
            ? `${t(`Save as template`)}`
            : `${t(`Update template`)}`}
        </ButtonPrimary>
      </Box>
    </Dialog>
  );
};

const Templates = () => {
  const [content, setContent] = useState<string>('');
  const [rawInput, setRawInput] = useState<string>('');
  const [templateName, setTemplateName] = useState<string | undefined>('');
  const [templateId, setTemplateId] = useState(null);
  const [templateOwner, setTemplateOwner] = useState<number>(0);
  const [isEditing, setIsEditing] = useState(false);

  const { t } = useLocales();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const debouncedValue = useDebounce(rawInput, 500);

  const { DEFAULT_MESSAGE_TEXTS } = getTranslatedList();

  useEffect(() => {
    setContent(debouncedValue);
  }, [debouncedValue]);

  const {
    templates: { results, count },
    isTemplatesLoading
  } = useSelector((state: RootState) => state.template);
  const { page, limit, offset, query } = useSelector(
    (state: RootState) => state.filtersReducer.templates
  );

  useEffect(() => {
    dispatch(setTemplates('offset', page * limit - limit));
  }, [dispatch, page, limit]);

  useEffect(() => {
    dispatch(getTemplatesList(limit, offset, query));
  }, [dispatch, limit, offset, query]);

  const onDelete = async (id: string | number) => {
    try {
      await deleteTemplate(id);
      enqueueSnackbar(DEFAULT_MESSAGE_TEXTS.deleted, { variant: 'success' });
    } catch {
      enqueueSnackbar(DEFAULT_MESSAGE_TEXTS.error, { variant: 'error' });
    }
    dispatch(getTemplatesList(limit, offset, query));
  };

  const onEdit = (id: any, content: any, label: string, owner: number) => {
    setIsEditing(true);

    setTemplateId(id);
    setRawInput(content);
    setTemplateName(label);
    setTemplateOwner(owner);
    // console.log('received name:', label);
  };

  const handleCloseModal = () => {
    setIsEditing(false);
    setTemplateId(null);
    setTemplateName(undefined);
    setTemplateOwner(0);
    setRawInput('');
    setContent('');
  };

  return (
    <Page title={t('Templates')}>
      <PageContainer>
        <DashboardNavbar title={t('Templates')} />
        <Grid container direction="column" rowGap={4} maxWidth="1400px">
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignItems: 'baseline',
              width: '100%',
              justifyContent: 'space-between'
            }}
          >
            <ButtonPrimary
              sx={{ width: { xs: '100%', md: '30%' } }}
              variant="contained"
              color="primary"
              onClick={() => setIsEditing(true)}
            >
              {t(`Add new template`)}
            </ButtonPrimary>
            <Box
              sx={{
                width: { xs: '100%', md: '50%' },
                maxWidth: { xs: 'auto', md: '400px' },
                display: 'flex',
                justifyContent: 'end',
                alignItems: 'baseline',
                gap: 2,
                paddingLeft: '15px',
                paddingRight: '15px',
                marginTop: { xs: 4, md: 0 }
              }}
            >
              <OverviewSearch
                style={{ minWidth: '270px', width: '100%' }}
                title={' '}
                placeholder={t('Search by Name')}
                setValue={useCallback(
                  (value: any) => dispatch(setTemplates('query', value)),
                  [dispatch]
                )}
                value={query}
              />
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  pb: 2,
                  cursor: 'pointer'
                }}
                onClick={() => dispatch(resetTemplatesFilters())}
              >
                <Typography
                  style={{
                    fontFamily: 'Rubik',
                    fontWeight: 700,
                    fontSize: '18px',
                    color: '#562C82'
                  }}
                >
                  {t('Reset')}
                </Typography>
                <ResetButton />
              </Box>
            </Box>
          </Box>
          <EditModal
            isOpen={isEditing}
            handleClose={handleCloseModal}
            {...{
              dispatch,
              results,
              templateId,
              templateName,
              templateOwner,
              setTemplateName,
              rawInput,
              content,
              setRawInput
            }}
          />
          <Box>
            <Grid container spacing={2}>
              {isTemplatesLoading ? (
                <LoadingBox />
              ) : (
                <>
                  {results && results.length ? (
                    results.map((item) => (
                      <Grid
                        item
                        xs={12}
                        md={6}
                        key={item.id}
                        style={{ width: '100%' }}
                      >
                        <TemplateCard
                          item={item}
                          onDelete={() => onDelete(item.id)}
                          onEdit={() =>
                            onEdit(
                              item.id,
                              item.content,
                              item.label,
                              item.owner
                            )
                          }
                        />
                      </Grid>
                    ))
                  ) : (
                    <EmptyContent title="No data" />
                  )}
                </>
              )}
            </Grid>
          </Box>

          <PaginationContainer
            page={page}
            count={count}
            perPage={limit}
            setPage={(value) => dispatch(setTemplates('page', value))}
          />
        </Grid>
      </PageContainer>
    </Page>
  );
};

export default Templates;
