/* eslint-disable no-nested-ternary */
import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';
import { getPlaceholders } from 'constants/text/placeholders';

const TypographyStyle = styled(Typography)(() => ({
  fontFamily: 'Rubik',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '14px'
}));

const ScoreField = ({
  score,
  type = 'talent',
  ...props
}: {
  score?: number;
  type: 'talent' | 'culture';
} & any) => {
  const { TALENT_MATCH, CULTURAL_FIT } = getPlaceholders();
  const text = type === 'talent' ? TALENT_MATCH : CULTURAL_FIT;
  const color = type === 'talent' ? '#1C9DD7' : '#14a741';
  return (
    <TypographyStyle variant="caption" color={color} {...props}>
      {score && score > 0 ? `${score}% ${text}` : ''}
      {/* {score && score > 0
        ? `${score}% ${text}`
        : 'Please select a culture profile to see the culture fit'} */}
    </TypographyStyle>
  );
};

export default ScoreField;
