import { useState } from 'react';
import { Typography, Box, IconButton } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { useSnackbar } from 'notistack';
import DeleteIcon from '@mui/icons-material/Delete';
import MButton from './new-designer/button/MButton';
import useLocales from '../hooks/useLocales';
import { IMG_PLACEHOLDER } from 'constants/constants';
import { CustomModal } from './CustomModal';

const modalContentStyle = {
  width: '100%',
  height: '100%',
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center'
};

const CustomDropzoneDialog = ({
  open,
  onClose,
  onSave,
  prevValue
}: {
  open: boolean;
  onClose: any;
  onSave: any;
  prevValue: any;
}) => {
  const [file, setFile] = useState<File | null>(null);
  const [image, setImage] = useState<string>(prevValue || IMG_PLACEHOLDER);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useLocales();

  const onDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      const selectedFile = acceptedFiles[0];
      setFile(selectedFile);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result as string);
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/png': ['.png'],
      'image/gif': ['.gif'],
      'image/jpeg': ['.jpeg'],
      'image/bmp': ['.bmp']
    },
    maxSize: 1000000,
    multiple: false,
    onDropRejected: () => {
      enqueueSnackbar(t('Please add a file less than 1MB'), {
        variant: 'warning'
      });
    }
  });

  const handleSubmit = () => {
    if (file) {
      onSave(file);
      setFile(null);
      onClose();
    }
  };

  const handleCancel = () => {
    setFile(null);
    onClose();
  };

  const handleDelete = () => {
    setFile(null);
  };

  return (
    <CustomModal open={open} onClose={onClose}>
      <Box sx={modalContentStyle}>
        <Box
          {...getRootProps({
            style: {
              border: '2px dashed #cccccc',
              padding: '20px',
              textAlign: 'center',
              cursor: 'pointer'
            }
          })}
        >
          <input {...getInputProps()} />
          <Typography
            variant="h6"
            style={{ fontFamily: 'Rubik', fontWeight: 600, color: '#333333' }}
          >
            {t('Drag and drop a file here, or click to select one')}
          </Typography>
          <Typography
            variant="body2"
            style={{ fontFamily: 'Rubik', fontWeight: 400, color: '#666666' }}
          >
            {t('Allowed *.jpeg, *.jpg, *.png, *.gif')}
          </Typography>
        </Box>
        {file && (
          <Box sx={{ marginTop: '20px', textAlign: 'center' }}>
            <Typography
              variant="subtitle1"
              style={{ fontFamily: 'Rubik', fontWeight: 400 }}
            >
              {file.name}
            </Typography>
            <Box sx={{ position: 'relative', display: 'inline-block' }}>
              <img
                src={image}
                alt="Preview"
                style={{ maxWidth: '100%', maxHeight: '300px' }}
              />
              <IconButton
                sx={{
                  position: 'absolute',
                  top: '-10px',
                  right: '-10px',
                  backgroundColor: 'red',
                  ':hover': { backgroundColor: 'white', color: 'red' },
                  color: 'white'
                }}
                onClick={handleDelete}
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          </Box>
        )}
        <Box sx={{ display: 'flex', gap: '10px', marginTop: '20px' }}>
          <MButton
            title={t('Submit')}
            MyClass="violetButton"
            click={handleSubmit}
          />
          <MButton
            title={t('Cancel')}
            MyClass="violetOutlined"
            click={handleCancel}
          />
        </Box>
      </Box>
    </CustomModal>
  );
};

export default CustomDropzoneDialog;
