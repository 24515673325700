/* eslint-disable no-nested-ternary */
import { Link } from '@material-ui/core';
import { getPlaceholders } from 'constants/text/placeholders';
import { ProficiencyDisplay } from './ProficiencyDisplay';
import SvgIconStyle from './SvgIconStyle';
import { Description3, TitlePrimary1 } from './text/title';

const isLink = (val: string) =>
  val.includes('http://') || val.includes('https://');

const CardField = ({ label, value, proficiency, component, iconSrc, recruiterOrAdmin, withoutBox }: any) => {
  const { NOT_INDICATED } = getPlaceholders();
  return (
    <Description3
      style={{
        margin: '5px 0',
        fontFamily: 'Rubik',
        color: '#131417',
        display: 'flex',
        fontSize: '18px'
      }}
      color="GrayText"
    >
      {iconSrc && (
        <SvgIconStyle src={iconSrc} style={{ ...(withoutBox ? { margin: '10px 10px 0 0' } : { marginRight: '10px' }) }} onClick={() => { if (recruiterOrAdmin) window.open(`mailto:${value}`) }} />
      )}
      <TitlePrimary1 style={{ ...(withoutBox ? { whiteSpace: 'nowrap' } : {}) }}>{label ? `${label}:` : ''}</TitlePrimary1>
      &nbsp;
      {proficiency ? (
        <ProficiencyDisplay proficiency={value} withoutBox={withoutBox} />
      ) : value?.length ? (
        isLink(value) ? (
          <Link href={value} target="blank">
            {value}
          </Link>
        ) : (
          value
        )
      ) : (
        NOT_INDICATED
      )}
      {component || null}
    </Description3>
  );
};

export default CardField;
