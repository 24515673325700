import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
  Autocomplete,
  Box,
  Card,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography
} from '@material-ui/core';

import LoadingScreen from '../../components/LoadingScreen';
import StepperComponent from '../../components/Stepper';
import SliderComponent from '../../components/Slider';
import Page from '../../components/containers/Page';
import CultureProfileCharts from '../../components/CultureProfileCharts';
import { updateProfile } from '../../requests/profile';
import {
  getTranslatedList,
  MAX_COMPETENCY_SELECTED_LENGTH,
  MAX_COMPETENCY_SLIDER_RANGE,
  MIN_COMPETENCY_SELECTED_LENGTH,
  MIN_COMPETENCY_SLIDER_RANGE
} from '../../constants/constants';
import { PATH_DASHBOARD } from '../../routes/paths';
import CompetenciesSelect from '../../components/CompetenciesSelect';
import PageContainer from '../../components/containers/PageContainer';
import HeadingContainer from '../../components/containers/HeadingContainer';
import useLocales from '../../hooks/useLocales';
import { makeStyles } from '@material-ui/core/styles';
import { useDebounce } from '../../hooks/useDebounce';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import {
  fetchCultureProfiles,
  getCompetencies,
  getProfileCandidate,
  postNewProfile
} from '../../redux/slices/cultureProfileReducer';

interface Competency {
  name: string;
  id: number;
  description: string;
}

const FirstStep = ({
  competencies,
  handleSetSelected,
  selectedOptionsLabels,
  setSelectedOptionsLabels,
  title,
  setCurrentProfileTitle,
  cultureProfiles,
  setCultureProfileId,
  anonymous,
  setAnonymous,
  loading,
  defaultCultureProfile,
  setQuery
}: any) => {
  const { dataFromJobFile }: any = useSelector((state: RootState) => state.job);
  const { t } = useLocales();
  const useStyles = makeStyles(() => ({
    root: {
      width: '100%',
      overflow: 'hidden',
      height: '48px',
      borderRadius: 30,
      backgroundColor: '#FFFFFF',
      fontFamily: 'Rubik'
    },
    input: {
      color: '#000'
    }
  }));

  const filterCompetenciesById = (targetIds: number[]) => {
    const allCompetencies = competencies?.flatMap(
      (group: any) => group.competencies
    );

    const filteredCompetencies = targetIds.reduce((acc, id) => {
      const matchedNames = allCompetencies
        .filter((competency: Competency) => competency.id === id)
        .map((competency: Competency) => competency.name);

      return acc.concat(matchedNames);
    }, []);

    return filteredCompetencies;
  };

  useEffect(() => {
    if (dataFromJobFile) {
      setCurrentProfileTitle(dataFromJobFile.title);

      if (dataFromJobFile.competencies) {
        const list: Competency[] = filterCompetenciesById(
          dataFromJobFile.competencies
        );
        setSelectedOptionsLabels(list);
      }
    }
  }, [dataFromJobFile]);

  const classes = useStyles();

  return (
    <Box>
      <Typography sx={{ fontWeight: 500 }}>
        {t("We advise you to select no more than 5 competencies Go for the once that really matter to achieve success in the candidate's work The average is 7 competencies per matching profile But depending on your results you can always add or delete competencies This way you can fine-tune your ideal profile.")}
      </Typography>
      <Box sx={{ mt: 2 }}>
        <Card
          sx={{
            padding: '40px',
            background:
              'linear-gradient(94.35deg, rgba(255, 255, 255, 0.8) -5.21%, rgba(255, 255, 255, 0.3) 114.12%)'
          }}
        >
          <Typography sx={{ mb: '6px' }}>Matching profile title</Typography>
          <TextField
            fullWidth
            label={t('Matching profile title')}
            value={title}
            onChange={(e) => setCurrentProfileTitle(e.target.value)}
            InputProps={{ classes }}
            sx={{ mb: '20px' }}
          />
          <Typography sx={{ mb: '6px' }}>Culture Profile</Typography>

          <Autocomplete
            freeSolo
            loading={loading}
            fullWidth
            options={cultureProfiles || []}
            defaultValue={defaultCultureProfile}
            getOptionLabel={(option: any) => option.name}
            onChange={(e: any, el: any) => setCultureProfileId(el?.id)}
            onInputChange={(event: any, value: string) => setQuery(value)}
            renderInput={(params) => (
              <TextField
                {...params}
                name="profile"
                label={t('Culture Profile')}
                variant="outlined"
                sx={{ mb: '6px', fontWeight: '400', fontSize: '14px' }}
              />
            )}
          />
          <FormControlLabel
            sx={{ p: 1 }}
            control={
              <Checkbox
                checked={anonymous}
                onChange={() => setAnonymous(!anonymous)}
                color="primary"
              />
            }
            label={t('Anonymous')}
          />
        </Card>
      </Box>
      <CompetenciesSelect
        competencies={competencies}
        selectedList={selectedOptionsLabels}
        onSelect={handleSetSelected}
      />
    </Box>
  );
};

const SecondStep = ({
  selectedOptionsLabels,
  minRanges,
  setRanges,
  descriptions
}: any) => (
  <Box
    sx={{
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%'
    }}
  >
    <Card sx={{ p: 2, m: 1, width: '48%' }}>
      {selectedOptionsLabels.map((el: string, i: number) => (
        <SliderComponent
          key={i}
          title={el}
          minVal={MIN_COMPETENCY_SLIDER_RANGE}
          maxVal={MAX_COMPETENCY_SLIDER_RANGE}
          values={minRanges[i]}
          handleSetValues={(values: number[]) => setRanges(i, values)}
        />
      ))}
    </Card>
    <Card sx={{ p: 2, m: 1, width: '48%' }}>
      <CultureProfileCharts
        labels={selectedOptionsLabels}
        points={minRanges}
        descriptions={descriptions}
        withInfo
      />
    </Card>
  </Box>
);

const MatchProfile = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [descriptions, setDescriptions] = useState<any[] | null>(null);
  const [selectedLabels, setSelectedLabels] = useState<string[]>([]);
  const [currentProfileTitle, setCurrentProfileTitle] = useState('');
  const [currentProfileAnonymous, setCurrentProfileAnonymous] = useState(false);
  const [cultureProfileId, setCultureProfileId] = useState(0);
  const [minRanges, setMinRanges] = useState<number[]>([]);
  const [defaultCultureProfile, setDefaultCultureProfile] = useState(null);
  const [query, setQuery] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { id }: any = useParams();
  const { t } = useLocales();
  const { DEFAULT_MESSAGE_TEXTS } = getTranslatedList();
  const dispatch = useDispatch();
  const {
    profile,
    cultureProfilesSearch,
    isLoading,
    competency,
    newProfile,
    searchLoading
  } = useSelector((state: RootState) => state.cultureProfileReducer);
  const debouncedQuery = useDebounce(query, 500);
  const isNew = id === 'new';

  const getInitialData = () => {
    if (id && !isNew && competency) {
      dispatch(getProfileCandidate(id));
    }
  };

  useEffect(() => {
    getInitialData();
  }, [id, isNew, competency]);

  useEffect(() => {
    if (debouncedQuery) {
      fetchData(debouncedQuery);
    }
  }, [debouncedQuery]);

  const fetchData = (query?: string) => {
    dispatch(fetchCultureProfiles(query));
  };

  useEffect(() => {
    if (profile) {
      if (profile?.competencies) {
        const desc: any = [];
        profile.competencies.map((item: any) => {
          desc.push({
            name: item?.competency.name,
            description: item.competency.description
          });
        });
        setDescriptions(desc);
      }
      if (profile.name) setCurrentProfileTitle(profile.name);
      if (profile.anonymous) setCurrentProfileAnonymous(profile.anonymous);
      if (profile.competency_labels)
        setSelectedLabels(profile.competency_labels);
      if (profile.competency_min_scores)
        setMinRanges(profile.competency_min_scores);
      if (profile.culture_profile?.id) {
        setCultureProfileId(profile.culture_profile.id);
        setDefaultCultureProfile(profile.culture_profile);
      }
    }
  }, [profile]);

  const handleSetSelected = (label: string) => {
    const newSelected: string[] = [...selectedLabels];
    const newMinRanges: number[] = [...minRanges];

    const index = selectedLabels.indexOf(label);
    if (index !== -1) {
      newSelected.splice(index, 1);
      newMinRanges.splice(index, 1);
    } else if (newSelected.length < MAX_COMPETENCY_SELECTED_LENGTH) {
      newSelected.push(label);
      newMinRanges.push(MIN_COMPETENCY_SLIDER_RANGE);
    }
    setSelectedLabels(newSelected);
    setMinRanges(newMinRanges);
  };

  const handleSetRanges = (i: number, values: number[] | number) => {
    const newMinRanges: number[] = [...minRanges];
    const value: number = typeof values === 'number' ? values : values[0];

    newMinRanges.splice(i, 1, value);
    setMinRanges(newMinRanges);
  };

  const handleSubmit = async () => {
    const data = {
      name: currentProfileTitle,
      anonymous: currentProfileAnonymous,
      competencies: Array.isArray(competency)
        ? competency
            .map((group: any) => group.competencies)
            .reduce((acc: any[], val: any) => acc.concat(val), [])
            .filter((el: any) => selectedLabels.includes(el.name))
            .map((el: any) => ({
              competency: el.id,
              min_score: minRanges[selectedLabels.indexOf(el.name)]
            }))
        : [],
      culture_profile: cultureProfileId
    };

    if (isNew) {
      dispatch(postNewProfile(data));
      enqueueSnackbar(DEFAULT_MESSAGE_TEXTS.created, { variant: 'success' });
    } else {
      const { id } = profile;
      await updateProfile(id, data);
      enqueueSnackbar(DEFAULT_MESSAGE_TEXTS.updated, { variant: 'success' });
      navigate(`${PATH_DASHBOARD.candidates.getMatchProfileCandidatesUrl(id)}`);
    }
  };

  useEffect(() => {
    if (newProfile)
      navigate(
        `${PATH_DASHBOARD.candidates.getMatchProfileCandidatesUrl(
          newProfile.id
        )}`
      );
  }, [newProfile]);

  useEffect(() => {
    if (!competency) return;

    const desc = selectedLabels.reduce((acc: any[], label) => {
      const elem: any = competency.find((item: any) =>
        item.competencies.some((el: any) => el.name === label)
      );

      if (elem) {
        acc.push({
          name: elem.competencies.find((el: any) => el.name === label)?.name,
          description: elem.competencies.find((el: any) => el.name === label)
            ?.description
        });
      }

      return acc;
    }, []);

    setDescriptions(desc);
  }, [competency, selectedLabels]);

  useEffect(() => {
    dispatch(getCompetencies());
  }, []);

  const getActiveStepComponent = () => {
    switch (activeStep) {
      case 0:
        return (
          <FirstStep
            competencies={competency}
            selectedOptionsLabels={selectedLabels}
            setSelectedOptionsLabels={setSelectedLabels}
            handleSetSelected={handleSetSelected}
            title={currentProfileTitle}
            setCurrentProfileTitle={setCurrentProfileTitle}
            cultureProfiles={cultureProfilesSearch}
            setCultureProfileId={setCultureProfileId}
            anonymous={currentProfileAnonymous}
            setAnonymous={setCurrentProfileAnonymous}
            loading={searchLoading}
            defaultCultureProfile={defaultCultureProfile}
            setQuery={setQuery}
          />
        );
      case 1:
        return (
          <SecondStep
            selectedOptionsLabels={selectedLabels}
            minRanges={minRanges}
            setRanges={handleSetRanges}
            descriptions={descriptions}
          />
        );
      default:
        return 'No such step';
    }
  };

  const steps = [
    {
      label: t('Select the most important competencies'),
      nextEnable:
        selectedLabels?.length >= MIN_COMPETENCY_SELECTED_LENGTH &&
        selectedLabels?.length <= MAX_COMPETENCY_SELECTED_LENGTH &&
        currentProfileTitle?.length !== 0,
      subtitle: 'STEP 1',
      startSrc: '',
      activeSrc: '/static/img/icons/cursor-add.svg',
      endSrc: '/static/img/icons/cursor-add-active.svg'
    },
    {
      label: t('Select competencies score'),
      nextEnable: true,
      subtitle: 'STEP 2',
      startSrc: '/static/img/icons/Ellipse.svg',
      activeSrc: '/static/img/icons/Ellipse-active.svg',
      endSrc: ''
    }
  ];

  return isLoading ? (
    <LoadingScreen />
  ) : (
    <Page title={`${isNew ? t('Add') : t('Edit')} ${t('Profile')}`}>
      <PageContainer>
        <HeadingContainer
          subtitle={t(
            'Please select the most important competencies for your job opening'
          )}
        />
        <StepperComponent
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          onFinish={handleSubmit}
          onBack={() => {}}
          steps={steps}
          sx={{ fontWeight: '600' }}
        >
          {getActiveStepComponent()}
        </StepperComponent>
      </PageContainer>
    </Page>
  );
};

export default MatchProfile;
