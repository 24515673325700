import {
  getDataByUrl,
  postDataByUrl,
  updateDataByUrl,
  deleteDataByUrl,
  ID
} from 'requests';

export const getCmsBaseData = () => getDataByUrl('/api/cms/');
export const getCmsGalleryData = () => getDataByUrl('/api/cms/gallery/');
export const getCmsOffersData = () => getDataByUrl(`/api/cms/offer/`);

export const createCmsGalleryData = (data: any) =>
  postDataByUrl(`/api/cms/gallery/`, data);
export const createCmsOffersData = (data: any) =>
  postDataByUrl(`/api/cms/offer/`, data);

export const updateCmsBaseData = (id: ID, data: any) =>
  updateDataByUrl(`/api/cms/${id}/`, data);
export const updateCmsGalleryData = (id: ID, data: any) =>
  updateDataByUrl(`/api/cms/gallery/${id}/`, data);
export const updateCmsOffersData = (id: ID, data: any) =>
  updateDataByUrl(`/api/cms/offer/${id}/`, data);

export const deleteCmsGalleryData = (id: ID) =>
  deleteDataByUrl(`/api/cms/gallery/${id}/`);
export const deleteCmsOffersData = (id: ID) =>
  deleteDataByUrl(`/api/cms/offer/${id}/`);

export const getCmsMediaData = () => getDataByUrl('/api/cms/social-media/');
export const updateCmsMediaData = (id: ID, data: any) =>
  updateDataByUrl(`/api/cms/social-media/${id}/`, data);
export const saveCmsMediaData = (data: any) =>
  postDataByUrl(`/api/cms/social-media/`, data);
export const deleteCmsMediaData = (id: any) =>
  deleteDataByUrl(`/api/cms/social-media/${id}/`);
