export const firebaseConfig = {
  apiKey: import.meta.env.VITE_APP_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: import.meta.env.VITE_APP_FIREBASE_DATABASE_URL,
  projectId: import.meta.env.VITE_APP_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_APP_FIREBASE_APPID,
  measurementId: import.meta.env.VITE_APP_FIREBASE_MEASUREMENT_ID
};

export const cloudinaryConfig = {
  cloudinaryKey: import.meta.env.VITE_APP_CLOUDINARY_KEY,
  cloudinaryPreset: import.meta.env.VITE_APP_CLOUDINARY_PRESET,
  cloudinaryUrl: import.meta.env.VITE_APP_CLOUDINARY_URL
};

export const stripeConfig = {
  liveMode: import.meta.env.VITE_APP_STRIPE_LIVE_MODE === '1',
  testKey: import.meta.env.VITE_APP_STRIPE_TEST_PUBLIC_KEY,
  liveKey: import.meta.env.VITE_APP_STRIPE_LIVE_PUBLIC_KEY
};

export const mapConfig = import.meta.env.VITE_APP_MAP_MAPBOX;
export const googleAnalyticsConfig = import.meta.env.VITE_APP_GA_MEASUREMENT_ID;
