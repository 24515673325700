import { ReactNode } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { styled } from '@mui/material';

const MainContainer = styled(Box)(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  marginBottom: '20px',
  marginTop: '20px'
}));

const GridBlock = styled(Grid)(() => ({
  flexDirection: 'column',
  flexWrap: 'unset'
}));

const GridButton = styled(Grid)(() => ({
  width: 'max-content'
}));

const ButtonContainer = styled(Box)`
  display: flex;
  width: 100%;
  min-width: max-content;
  justify-content: flex-start;
  border-radius: 30px;
  overflow: hidden;
  margin: 0;

  button {
    background: linear-gradient(92.25deg, #9b5fda -2.57%, #562c82 113.38%);
    padding: 10px 45px;

    .MuiButton-label {
      background: none;
      line-height: 17px;
    }
  }
`;

interface HeadingContainerProps {
  title?: string;
  subtitle?: string;
  actionButton?: ReactNode;
  actionButtons?: ReactNode[];
  invitations?: boolean;
}

const HeadingContainer = ({
  title,
  subtitle,
  actionButton,
  actionButtons,
  invitations = false
}: HeadingContainerProps) => (
  <MainContainer>
    <GridBlock container spacing={3}>
      <Grid paddingLeft="25px" sm={9}>
        {title ? (
          <Typography
            sx={invitations ? { fontWeight: 'bold' } : {}}
            style={{ fontFamily: 'Rubik', fontSize: '40px', fontWeight: 700 }}
          >
            {title}
          </Typography>
        ) : null}
        {subtitle ? (
          <Typography
            variant="h4"
            style={{ fontFamily: 'Rubik', fontWeight: 400 }}
          >
            {subtitle}
          </Typography>
        ) : null}
      </Grid>
      <GridButton item xs={12} sm={3}>
        {actionButton ? (
          <ButtonContainer sx={{ mt: 2 }}>{actionButton}</ButtonContainer>
        ) : null}
        {actionButtons && (
          <Grid
            sx={{
              display: 'flex',
              gap: '8px',
              width: '100%',
              height: '40px',
              mt: 2
            }}
          >
            {actionButtons.map((actionButton, idx) => (
              <ButtonContainer key={idx}>{actionButton}</ButtonContainer>
            ))}
          </Grid>
        )}
      </GridButton>
    </GridBlock>
  </MainContainer>
);

export default HeadingContainer;
