import { getDataByUrl, postDataByUrl, deleteDataByUrl, ID } from 'requests';

export const getInvitations = (params?: any) =>
  getDataByUrl(`/api/invitations/`, params);

export const getAcceptRecruiterInvitation = (key: ID) =>
  getDataByUrl(`/api/invitations/accept-invite/${key}/`);

export const createInvitation = (data: any) =>
  postDataByUrl(`/api/invitations/`, data);

export const resendInvitation = (id: ID) =>
  postDataByUrl(`/api/invitations/${id}/resend_invitation_email/`, {});

export const deleteInvitation = (id: ID) =>
  deleteDataByUrl(`/api/invitations/${id}/`);
