import { useCallback, useEffect, useState } from 'react';
import useLocales from 'hooks/useLocales';
import Page from 'components/containers/Page';
import PageContainer from 'components/containers/PageContainer';
import ListContainer from 'components/containers/ListContainer';
import PaginationContainer from 'components/containers/PaginationContainer';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { getCampaignsThunk } from 'redux/slices/campaignsReducer';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography
} from '@material-ui/core';
import { fDateDHM } from 'utils/formatTime';
import { useNavigate, useLocation } from 'react-router-dom';
import { DeleteOutline, DoubleArrow } from '@material-ui/icons';
import { PATH_DASHBOARD } from 'routes/paths';
import { ApproveDialog } from 'components/Dialog';
import { OverviewFilter, OverviewSearch } from 'components/OverviewFilter';
import { getTexts } from 'constants/text/texts';
import { getTranslatedList } from 'constants/constants';
import {
  resetCampaignsFilters,
  setCampaignsFilter
} from 'redux/slices/filters';
import DashboardNavbar from '../../layouts/dashboard/navbar/DashboardNavbar';
import SvgIconStyle from 'components/SvgIconStyle';
import InProgress, {
  getStatusString
} from 'components/new-designer/InProgress/InProgress';
import { ResetButton } from 'components/Buttons';
import useURLFilters from '@hooks/useURLFilters';
import { sumAmount } from '../../utils/helpers';
import { FilterGroupButton, TypographySubTit } from '../../styled';

const CampaignsCard = ({ item, onCancel, onOpenMoreInfo, logo }: any) => {
  const [open, setOpen] = useState(false);
  const { t } = useLocales();

  const handleCancel = () => {
    onCancel();
    setOpen(false);
  };

  const jobTitle = item.vacancy?.title || t('No name');

  return (
    <Card
      sx={{
        height: '100%',
        display: 'grid',
        gridTemplateColumns: '0.5fr 2fr',
        padding: '30px',
        minWidth: '572px',
        background:
          'linear-gradient(94.35deg, rgba(255, 255, 255, 0.8) -5.21%, rgba(255, 255, 255, 0.3) 114.12%)',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.08)'
      }}
    >
      <Box
        style={{
          background: '#FFFFFF',
          border: '1px solid #C9CAD9',
          borderRadius: '5px',
          minWidth: '100px',
          height: '100px',
          display: 'flex',
          padding: '10px',
          alignItems: 'center'
        }}
      >
        <SvgIconStyle src={logo || ''} />
      </Box>
      <CardContent sx={{ height: '100%', padding: '0 0 0 20px !important' }}>
        <Box
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} style={{ padding: '0 0 0 20px' }}>
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                <Typography
                  variant="h4"
                  sx={{
                    py: 1,
                    fontWeight: 700,
                    fontSize: '20px',
                    color: '#131417',
                    whiteSpace: 'pre-line'
                  }}
                >
                  {t('Campaign For')} {jobTitle}
                </Typography>
                <InProgress
                  status={item.details?.status}
                  style={{ marginTop: '16px' }}
                />
              </Box>
              <Typography
                sx={{
                  py: 1,
                  fontSize: '0.8rem',
                  paddingTop: '0',
                  color: '#666666'
                }}
              >
                {fDateDHM(item.created_at)}
              </Typography>
              <Box style={{ display: 'flex', alignItems: 'center' }}>
                <TypographySubTit>{t('Number of clicks ')}:</TypographySubTit>
                &nbsp;
                <Typography sx={{ py: 1, fontSize: '0.9rem' }}>
                  {` ${
                    item.details?.posting?.length > 0
                      ? sumAmount('clicks', item.details?.posting)
                      : 0
                  }`}
                </Typography>
              </Box>
              <Box style={{ display: 'flex', alignItems: 'center' }}>
                <TypographySubTit>
                  {t('Total amount spend on this campaign')}:
                </TypographySubTit>
                &nbsp;
                <Typography sx={{ py: 1, fontSize: '0.9rem' }}>
                  {` €${
                    item.ordered_products?.length > 0
                      ? Math.round(sumAmount('price', item.ordered_products))
                      : 0
                  }`}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Box>
            <Box sx={{ display: 'flex' }} style={{ columnGap: '20px' }}>
              <ApproveDialog
                open={open}
                title={`${t('Are you sure you want cancel ')} "${
                  item.vacancy?.title || 'this campaign'
                }"?`}
                onClose={() => setOpen(false)}
                onApprove={handleCancel}
              />
              <Button
                onClick={() => setOpen(true)}
                variant="text"
                color="error"
                disabled={getStatusString(item.details?.status) === 'offline'}
              >
                {t('Cancel')}
                <DeleteOutline
                  style={{
                    fontSize: '18px',
                    margin: '0 0 5px 5px'
                  }}
                />
              </Button>
              <Button
                onClick={onOpenMoreInfo}
                variant="text"
                style={{
                  color: '#1C9DD7'
                }}
              >
                {t('More info')}
                <DoubleArrow
                  style={{
                    fontSize: '18px',
                    margin: '0 0 5px 5px'
                  }}
                />
              </Button>
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

const CampaignsOverview = () => {
  const { t } = useLocales();
  const navigate = useNavigate();
  const { PERIODICITY, STATUS } = getTexts();
  const { PERIOD_TYPES, CAMPAIGN_STATUS_OPTIONS } = getTranslatedList();
  const for_vacancy = new URLSearchParams(useLocation().search).get(
    'for_vacancy'
  );
  const dispatch = useDispatch();
  const user_id = useSelector((state: RootState) => state.authJwt.user.id);
  const { campaigns, count, isLoading } = useSelector(
    (state: RootState) => state.campaignsReducer
  );
  const filters = useSelector(
    (state: RootState) => state.filtersReducer.campaigns
  );
  const { company }: any = useSelector(
    (state: RootState) => state.generalRecruiter
  );

  const onCancel = (id: number | string) => {
    searchCampaigns(id);
  };

  const onOpenMoreInfo = (id: number | string) => {
    navigate(PATH_DASHBOARD.campaigns.getCampaignMoreInfoUrl(id));
  };

  const { page, limit, offset, status, query, periodicity } = filters;

  const searchCampaigns = (id?: string | number) => {
    dispatch(
      getCampaignsThunk(
        user_id,
        offset,
        limit,
        Number(for_vacancy) || undefined,
        periodicity,
        status,
        query,
        id
      )
    );
  };

  useEffect(() => {
    searchCampaigns();
  }, [status, periodicity, limit, offset, query, user_id]);

  useEffect(() => {
    dispatch(setCampaignsFilter('offset', page * limit - limit));
  }, [dispatch, page, limit]);

  const filterNames = ['limit', 'offset', 'status', 'periodicity', 'q'];

  const currentFilters = useSelector(
    (state: RootState) => state.filtersReducer.campaigns
  );

  useURLFilters({
    fragmentIdentifier: '#campaigns/',
    filterNames,
    setterFunction: setCampaignsFilter,
    currentFilters
  });

  return (
    <Page title={t('Overview Job Marketing Campaigns')}>
      <DashboardNavbar title={t('Overview Job Marketing Campaigns')} />
      <PageContainer
        style={{
          padding: 0
        }}
      >
        <FilterGroupButton
          style={{
            alignItems: 'end',
            justifyContent: 'space-between'
          }}
        >
          <OverviewFilter
            title={PERIODICITY}
            activeOption={periodicity}
            options={PERIOD_TYPES}
            setOption={(value) =>
              dispatch(setCampaignsFilter('periodicity', value))
            }
          />
          <OverviewFilter
            title={STATUS}
            activeOption={status}
            options={CAMPAIGN_STATUS_OPTIONS}
            setOption={(value) => dispatch(setCampaignsFilter('status', value))}
          />
          <OverviewSearch
            placeholder={t('Search by Name')}
            title={' '}
            setValue={useCallback(
              (value: any) => dispatch(setCampaignsFilter('query', value)),
              [dispatch]
            )}
            value={query}
            style={{
              minWidth: '40%'
            }}
          />
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              pb: 2,
              cursor: 'pointer'
            }}
            onClick={() => dispatch(resetCampaignsFilters())}
          >
            <Typography
              style={{
                fontFamily: 'Rubik',
                fontWeight: 700,
                fontSize: '18px',
                color: '#562C82'
              }}
            >
              {t('Reset All')}
            </Typography>
            <ResetButton />
          </Box>
        </FilterGroupButton>
        <ListContainer
          loading={isLoading}
          list={campaigns}
          card={CampaignsCard}
          gridProps={{ sm: 6, lg: 4 }}
          getCardProps={(item: any) => ({
            onCancel: () => onCancel(item.id),
            onOpenMoreInfo: () => onOpenMoreInfo(item.id),
            logo: company?.logo
          })}
        />
        <PaginationContainer
          page={page}
          count={count}
          perPage={limit}
          setPage={(value) => dispatch(setCampaignsFilter('page', value))}
        />
      </PageContainer>
    </Page>
  );
};

export default CampaignsOverview;
