import { useEffect, useState } from 'react';
import { Box, makeStyles } from '@material-ui/core';

const daysOfWeek: string[] = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];
const months: string[] = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

const useStyles = makeStyles({
  scrollBar: {
    borderRadius: '4px',
    marginTop: '4px',
    alignItems: 'center',
    background: '#ffffff',
    width: '150px',
    height: '293px',
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '20px',
    padding: '20px 30px',
    overflowY: 'scroll',
    overflowX: 'hidden',
    cursor: 'pointer',
    '&::-webkit-scrollbar': {
      borderRadius: '2px',
      background: '#ffffff',
      width: '7px',
      height: '39px'
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#562C82',
      borderRadius: '10px'
    }
  },
  year: {
    borderRadius: '4px',
    overflowX: 'hidden',
    marginTop: '4px',
    background: '#ffffff',
    width: '150px',
    height: '293px',
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '20px',
    padding: '20px 30px',
    overflowY: 'scroll',
    alignItems: 'center',
    cursor: 'pointer',
    '&::-webkit-scrollbar': {
      borderRadius: '2px',
      background: '#ffffff',
      width: '7px',
      height: '39px'
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#562C82',
      borderRadius: '10px'
    }
  },
  monthNavigation: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '40px',
    columnGap: '10px',
    '& button': {
      background: '#fff',
      padding: '13px',
      boxShadow: '0px 0px 0.72368px 0px rgba(0, 0, 0, 0.08)',
      border: 'none',
      borderRadius: '50%'
    },
    '& span': {
      borderRadius: '7px',
      background: '#fff',
      boxShadow: '0px 0px 0.72368px 0px rgba(0, 0, 0, 0.08)',
      display: 'flex',
      columnGap: '3px',
      color: '#131417',
      fontFamily: 'Rubik',
      fontSize: '18px',
      fontWeight: '600',
      padding: '13px 13px 10px',
      justifyContent: 'center',
      '& img': {
        marginBottom: '3px'
      }
    }
  },
  prev: {
    width: '44px',
    height: '44px',
    marginRight: '10px',
    '& button': {
      cursor: 'pointer'
    }
  },
  next: {
    width: '44px',
    height: '44px',
    marginLeft: '10px',
    '& button': {
      cursor: 'pointer'
    }
  },
  monthButton: {
    width: '150px',
    minWidth: '80px'
  },
  blank: {
    color: 'rgba(0, 23, 84, 0.15) !important',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    fontFamily: 'Rubik',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '100%',
    background: 'none !important'
  },
  calendar: {
    display: 'grid',
    gridTemplateColumns: 'repeat(7, 1fr)',
    gap: '4px',
    maxWidth: '380px',
    margin: '0 auto'
  },
  timePicker: {
    display: 'flex',
    columnGap: '3px',
    background: 'white',
    border: 'none',
    borderRadius: '7px',
    cursor: 'pointer',
    color: '#131417',
    fontFamily: 'Rubik',
    fontSize: '18px',
    fontWeight: '600',
    padding: '13px 13px 10px',
    minWidth: '87px',
    '& img': {
      marginBottom: '3px'
    }
  },
  dayOfWeeks: {
    color: '#1f1f1f',
    textAlign: 'center',
    fontFamily: 'Rubik',
    fontSize: '18px !important',
    fontWeight: '500',
    lineHeight: '100%'
  },
  day: {
    background: 'white',
    cursor: 'pointer',
    transition: 'background-color 0.3s, color 0.3s'
  },
  dayOfWeek: {
    fontSize: '16px',
    fontWeight: 'bold',
    marginBottom:"13px"
  },
  selected: {
    cursor: 'pointer',
    display: 'flex',
    width: '50px',
    height: '44px',
    padding: '13px 20px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    background: '#935ad0 !important',
    color: 'white !important',
    borderRadius: '6px'
  },
  normal: {
    display: 'flex',
    width: '50px',
    height: '44px',
    padding: '13px 20px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    background: '#ffffff',
    borderRadius: '6px',
    cursor: 'pointer'
  },
  monthHov: {
    '&:hover': {
      color: '#562c82',
      fontFamily: 'Rubik',
      cursor: 'pointer'
    }
  },
  disabled: {
    color: 'rgba(0, 23, 84, 0.15)',
    cursor: 'normal'
  }
});

const calendarDown = '/static/img/icons/calendarDown.svg';

const DateTimePicker = ({
  onChange,
  formDataDate
}: {
  onChange: (e: Date) => void;
  formDataDate: Date;
}) => {
  const [date, setDate] = useState<Date>(
    formDataDate ? new Date(formDataDate) : new Date()
  );
  const [openOptionsMonth, setOpenOptionsMonth] = useState<boolean>(false);
  const [openOptionsYear, setOpenOptionsYear] = useState<boolean>(false);
  const getDaysInMonth = (year: number, month: number) =>
    new Date(year, month + 1, 0).getDate();
  const classes = useStyles();
  const daysInMonth = getDaysInMonth(date.getFullYear(), date.getMonth());
  const dayOfWeek = new Date(date.getFullYear(), date.getMonth(), 1).getDay();

  useEffect(() => {
    if (date) {
      const newDate = new Date(date);
      const currentDate: Date = new Date();

      if (newDate.getDate() === currentDate.getDate()) {
        newDate.setHours(new Date().getHours());
        newDate.setMinutes(new Date().getMinutes() + 1);
        onChange(newDate);
        setDate(newDate);
      }
    }
  }, []);

  const generateYears = (startYear: number, endYear: number): number[] => {
    const years = [];
    for (let year = startYear; year <= endYear; year++) {
      years.push(year);
    }
    return years;
  };

  const years = generateYears(
    new Date().getFullYear(),
    new Date().getFullYear() + 40
  );

  const sendOptionYear = (year: number): void => {
    const newDate = new Date(date);
    newDate.setFullYear(year);

    setDate(newDate);
    onChange(newDate);
    setOpenOptionsYear(false);
  };

  const previousMonth = (): void => {
    const isSameYear: boolean = new Date().getFullYear() === date.getFullYear();
    const isSameMonth: boolean = new Date().getMonth() === date.getMonth();
    const isSameMonthAndYear: boolean = isSameYear && isSameMonth;

    const month = date.getMonth() - 1 == -1 ? 11 : date.getMonth() - 1;
    const year =
      date.getMonth() - 1 == -1 ? date.getFullYear() - 1 : date.getFullYear();

    if (!isSameMonthAndYear) {
      setDate(new Date(year, month, 1));
    }
  };

  const nextMonth = (): void => {
    const month = date.getMonth() + 1 == 12 ? 0 : date.getMonth() + 1;
    const year =
      date.getMonth() + 1 == 12 ? date.getFullYear() + 1 : date.getFullYear();

    setDate(new Date(year, month, 1));
  };

  const renderCalendarDays = (): JSX.Element[] => {
    const calendarSquares: JSX.Element[] = [];

    const prevMonth = new Date(date.getFullYear(), date.getMonth(), 0);
    const formDate = formDataDate ? new Date(formDataDate) : date;
    const lastDayOfPrevMonth = prevMonth.getDate();
    for (let i = dayOfWeek - 1; i >= 0; i--) {
      const day = lastDayOfPrevMonth - i;
      let selected = false;
      if (
        formDataDate &&
        prevMonth.getMonth() == formDate.getMonth() &&
        prevMonth.getFullYear() == formDate.getFullYear() &&
        new Date(formDataDate).getDate() === day
      ) {
        selected = true;
      }

      calendarSquares.push(
        <Box
          key={`blank-${i}`}
          className={`${classes.dayOfWeeks} ${
            selected ? classes.selected : classes.normal
          } ${classes.blank}`}
        >
          {day}
        </Box>
      );
    }

    for (let day = 1; day <= daysInMonth; day++) {
      let selected = false;
      if (
        formDataDate &&
        date.getMonth() == formDate.getMonth() &&
        date.getFullYear() == formDate.getFullYear() &&
        new Date(formDataDate).getDate() === day
      ) {
        selected = true;
      }
      calendarSquares.push(
        <Box
          key={day}
          className={`${classes.dayOfWeeks} ${
            !handleCheckCalendar(day) ? classes.disabled : ''
          } ${selected ? classes.selected : classes.normal}`}
          onClick={() => handleDayClick(day)}
        >
          {day}
        </Box>
      );
    }

    const remainingDays = 7 - (calendarSquares.length % 7);
    for (let day = 1; day <= remainingDays; day++) {
      calendarSquares.push(
        <Box
          key={`next-month-${day}`}
          className={`${classes.dayOfWeeks} ${classes.blank}`}
          onClick={() => handleDayClick(day)}
        >
          {day}
        </Box>
      );
    }

    return calendarSquares;
  };

  const handleCheckCalendar = (day: number): boolean => {
    const currentDate: Date = new Date();
    const isSameYear: boolean =
      currentDate.getFullYear() === date.getFullYear();
    const isSameMonth: boolean = currentDate.getMonth() === date.getMonth();
    const isSameMonthAndYear: boolean = isSameYear && isSameMonth;

    return (
      !!(isSameMonthAndYear && day >= new Date().getDate()) ||
      !isSameMonthAndYear
    );
  };

  const handleDayClick = (day: number): void => {
    const currentDate: Date = new Date();
    const isClickable = handleCheckCalendar(day);

    if (isClickable) {
      const newDate = new Date(date);
      newDate.setDate(day);
      if (newDate.getDate() === currentDate.getDate()) {
        newDate.setHours(new Date().getHours());
        newDate.setMinutes(new Date().getMinutes() + 1);
        onChange(newDate);
        setDate(newDate);
      } else {
        setDate(newDate);
        onChange(newDate);
      }
    }
  };

  const handleCheckMonth = (month: string): boolean => {
    const currentMonth = new Date().getMonth();
    const currentYear = new Date().getFullYear();

    return !!(
      currentMonth <= months.indexOf(month) ||
      currentYear < new Date(date).getFullYear()
    );
  };

  const sendOptionMonth = (month: string): void => {
    const isClickable = handleCheckMonth(month);
    if (isClickable) {
      const selectedMonthIndex: number = months.indexOf(month);
      const newDate: Date = new Date(date.getFullYear(), selectedMonthIndex, 1);

      setDate(newDate);
      onChange(newDate);
      setOpenOptionsMonth(false);
    }
  };

  return (
    <Box className="date-time-picker">
      <Box className={classes.monthNavigation}>
        <Box className={classes.prev}>
          <button onClick={previousMonth}>
            <img
              src="/static/img/icons/leftCalendarArrow.svg"
              width="18px"
              height="18px"
              alt="leftCalendarArrow"
            />
          </button>
        </Box>
        <Box>
          <Box
            style={{ position: 'relative', cursor: 'pointer' }}
            onClick={() => setOpenOptionsMonth(!openOptionsMonth)}
          >
            <span className={classes.monthButton}>
              {months[date.getMonth()]}
              <img src={calendarDown} alt={calendarDown} />
            </span>
          </Box>
          {openOptionsMonth && (
            <Box className={classes.scrollBar}>
              {months.map((month: string, i: number) => (
                <Box
                  key={i}
                  className={
                    !handleCheckMonth(month)
                      ? classes.disabled
                      : classes.monthHov
                  }
                  onClick={() => sendOptionMonth(month)}
                >
                  {month}
                </Box>
              ))}
            </Box>
          )}
        </Box>
        <Box>
          <Box
            style={{ position: 'relative', cursor: 'pointer' }}
            onClick={() => setOpenOptionsYear(!openOptionsYear)}
          >
            <span className={classes.monthButton}>
              {date.getFullYear()}
              <img src={calendarDown} alt={calendarDown} />
            </span>
          </Box>
          {openOptionsYear && (
            <Box className={classes.year}>
              {years?.map((year) => (
                <Box
                  key={year}
                  className={classes.monthHov}
                  onClick={() => sendOptionYear(year)}
                >
                  <Box>{year}</Box>
                </Box>
              ))}
            </Box>
          )}
        </Box>
        <Box className={classes.next}>
          <button onClick={nextMonth}>
            <img
              src="/static/img/icons/rightCalendarArrow.svg"
              width="18px"
              height="18px"
              alt="rightCalendarArrow"
            />
          </button>
        </Box>
      </Box>

      <Box className={classes.calendar}>
        {daysOfWeek.map((dow) => (
          <Box
            key={dow}
            className={`${classes.dayOfWeeks} ${classes.dayOfWeek}`}
          >
            {dow}
          </Box>
        ))}
        {renderCalendarDays()}
      </Box>
    </Box>
  );
};

export default DateTimePicker;
