import { createSlice } from '@reduxjs/toolkit';
import { AppDispatch } from '../store';
import { initialPaginated } from '../../constants/states';
import { enqueueSnackbar } from './notifications';
import { ID } from '../../requests';
import { MatchProfileCandidatesFiltersType } from '../../types/Profile';
import {
  getProfile,
  getProfileCandidates,
  postProfile
} from '../../requests/profile';
import { getCompetency, getCultureProfiles } from '../../requests/fixtures';

const initialState: {
  cultureProfilesSearch: any;
  isLoading: boolean;
  searchLoading: boolean;
  profiles: any;
  profile: any;
  cultureProfile: any;
  competency: any[];
  newProfile: any;
} = {
  isLoading: false,
  searchLoading: false,
  profiles: initialPaginated,
  cultureProfilesSearch: null,
  profile: {
    competencies: [],
    id: null
  },
  cultureProfile: {
    id: '',
    competencies: [],
    name: '',
    anonymous: false,
    competency_labels: [],
    competency_min_scores: []
  },
  competency: [],
  newProfile: null
};

const slice = createSlice({
  name: 'toDoSlice',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
    setSearchLoading(state, action) {
      state.searchLoading = action.payload;
    },
    profilesSuccess(state, action) {
      state.profiles.results = action.payload.results;
      state.profiles.count = action.payload.count;
    },
    profileSuccess(state, action) {
      state.profile = action.payload;
    },
    cultureProfileSuccess(state, action) {
      state.cultureProfile = action.payload;
    },
    setCompetency(state, action) {
      state.competency = action.payload;
    },
    cultureProfilesSearch(state, action) {
      state.cultureProfilesSearch = action.payload;
    },
    postProfile(state, action) {
      state.newProfile = action.payload;
    }
  }
});

export const getProfilesCandidates =
  (
    id: ID,
    limit?: number,
    offset?: number,
    query?: string,
    data?: MatchProfileCandidatesFiltersType
  ) =>
  async (dispatch: AppDispatch) =>
    getProfileCandidates(id, limit, offset, query, data)
      .then((res) => {
        dispatch(slice.actions.profilesSuccess(res));
      })
      .catch(() =>
        dispatch(
          enqueueSnackbar({
            messageType: 'error',
            options: { variant: 'error' }
          })
        )
      )
      .finally(() => dispatch(slice.actions.setLoading(false)));

export const getProfileCandidate = (id: ID) => async (dispatch: AppDispatch) =>
  getProfile(id)
    .then((res) => {
      dispatch(slice.actions.profileSuccess(res));
      if (res.culture_profile) {
        getProfile(res.culture_profile.id, true)
          .then((res) => dispatch(slice.actions.cultureProfileSuccess(res)))
          .catch(() =>
            dispatch(
              enqueueSnackbar({
                messageType: 'error',
                options: { variant: 'error' }
              })
            )
          );
      }
    })
    .catch(() =>
      dispatch(
        enqueueSnackbar({
          messageType: 'error',
          options: { variant: 'error' }
        })
      )
    )
    .finally(() => dispatch(slice.actions.setLoading(false)));

export const getCultureProfile =
  (id: ID, isCulture = false) =>
  async (dispatch: AppDispatch) => {
    dispatch(slice.actions.setLoading(true));
    getProfile(id, isCulture)
      .then((res) => dispatch(slice.actions.cultureProfileSuccess(res)))
      .catch(() =>
        dispatch(
          enqueueSnackbar({
            messageType: 'error',
            options: { variant: 'error' }
          })
        )
      )
      .finally(() => dispatch(slice.actions.setLoading(false)));
  };

export const getCompetencies = () => async (dispatch: AppDispatch) => {
  dispatch(slice.actions.setLoading(true));
  getCompetency()
    .then((res) => {
      dispatch(slice.actions.setCompetency(res));
    })
    .catch(() =>
      dispatch(
        enqueueSnackbar({
          messageType: 'error',
          options: { variant: 'error' }
        })
      )
    )
    .finally(() => dispatch(slice.actions.setLoading(false)));
};

export const fetchCultureProfiles =
  (query?: string) => async (dispatch: AppDispatch) => {
    dispatch(slice.actions.setSearchLoading(true));
    getCultureProfiles(query)
      .then((res) => {
        dispatch(slice.actions.cultureProfilesSearch(res));
      })
      .catch(() =>
        dispatch(
          enqueueSnackbar({
            messageType: 'error',
            options: { variant: 'error' }
          })
        )
      )
      .finally(() => dispatch(slice.actions.setSearchLoading(false)));
  };

export const postNewProfile = (data: any) => async (dispatch: AppDispatch) => {
  postProfile(data)
    .then((res) => {
      dispatch(slice.actions.postProfile(res));
    })
    .catch(() =>
      dispatch(
        enqueueSnackbar({
          messageType: 'error',
          options: { variant: 'error' }
        })
      )
    );
};

export default slice.reducer;
