import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Divider,
  Grid,
  Link,
  makeStyles,
  Typography
} from '@material-ui/core';
import { DoubleArrow } from '@material-ui/icons';
import ProductsModalCardContainer from 'components/containers/ProductsModalContainer';
import SvgIconStyle from 'components/SvgIconStyle';
import useLocales from 'hooks/useLocales';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { getIcon } from 'utils/getIcon';
import { IProduct } from '../../views/dashboard/types';

interface PropsType {
  product: IProduct;
  addToStore: () => void;
  delFromStore: () => void;
}

interface PropsListType {
  title: string;
  list?: Array<{
    id: number;
    name?: string | undefined;
    canonical_name?: string | undefined;
  }>;
}

const useStyles = makeStyles({
  description: {
    py: 0.5,
    fontWeight: 700,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical'
  }
});

const List = ({ list, title }: PropsListType) => {
  const { t } = useLocales();
  return list?.length ? (
    <Box sx={{ mt: 3 }}>
      <Typography sx={{ fontWeight: 'bold', pb: 1 }}>{t(title)}</Typography>
      {list?.map((item) => (
        <Box key={item.id} sx={{ display: 'inline' }}>
          <Box
            sx={{
              display: 'inline',
              backgroundColor: 'rgba(0,0,0,.08)',
              borderRadius: '3px',
              px: 1
            }}
          >
            {(item?.canonical_name && t(item.canonical_name)) ||
              (item?.name && t(item.name))}
          </Box>{' '}
        </Box>
      ))}
    </Box>
  ) : (
    <></>
  );
};

const ProductCard = ({ product, addToStore, delFromStore }: PropsType) => {
  const { t } = useLocales();
  const classes = useStyles();
  const [productMoreInfo, setProductMoreInfo] = useState<IProduct | null>(null);
  const productsFromStore = useSelector(
    (state: RootState) => state.productsReducer?.products
  );
  const jobPostForId = useSelector(
    (state: RootState) => state.productsReducer?.jobPostFor?.id
  );

  const isHasInStore = (): boolean =>
    !!productsFromStore?.find(
      (item: any) =>
        item.product_id === product.product_id &&
        item.jobPostFor?.id === jobPostForId
    );

  return (
    <>
      <ProductsModalCardContainer
        open={!!productMoreInfo}
        onClose={() => setProductMoreInfo(null)}
      >
        <Card sx={{ maxWidth: '70%' }}>
          <CardContent>
            <Box
              sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}
            >
              <Typography>
                {`${product.duration.period} ${product.duration.range}`}
              </Typography>
              <Typography>
                {`${product.vonq_price[0].currency} ${product.vonq_price[0].amount}`}
              </Typography>
            </Box>

            <Divider sx={{ mt: 2 }} />

            <Box sx={{ width: '40%', mt: 3 }}>
              <CardMedia
                component="img"
                height="100%"
                width="100%"
                image={
                  product.logo_rectangle_url
                    ? product.logo_rectangle_url[0]?.url
                    : ''
                }
                alt="product logo"
              />
            </Box>

            <Box sx={{ mt: 3 }}>
              <Typography variant="h5" sx={{ textTransform: 'capitalize' }}>
                {product.type}
              </Typography>
              <Typography variant="h3">{product.title}</Typography>
            </Box>

            <Box sx={{ mt: 3 }}>
              <Link href={product.homepage} target="_blank">
                {product.homepage}
              </Link>
            </Box>

            <Box sx={{ mt: 3 }}>
              <Typography>{product.description}</Typography>
            </Box>

            <Box sx={{ mt: 3 }}>
              <Typography sx={{ fontWeight: 'bold' }}>Delivery time</Typography>
              <Typography sx={{ mt: 0.7 }}>
                {`${product.time_to_process?.period} ${product.time_to_process?.range}`}
              </Typography>
              <Typography sx={{ mt: 0.7 }}>
                {t(' * Your first order may take up to 6 working days.')}
                <Link href="#" target="_blank">
                  {t('Learn why')}
                </Link>
              </Typography>
            </Box>

            {product.cross_postings?.length ? (
              <Box sx={{ mt: 3 }}>
                <Typography sx={{ fontWeight: 'bold' }}>
                  {t('Cross-posting on')}
                </Typography>
                {product.cross_postings?.map((link, i) => (
                  <Link key={`${link}-${i}`} href={link} target="_blank">
                    {link}{' '}
                  </Link>
                ))}
              </Box>
            ) : (
              ''
            )}

            <List title="Job functions" list={product.job_functions} />

            <List title="Locations" list={product.locations} />

            <List title="Industries" list={product.industries} />

            <Divider sx={{ my: 2 }} />

            <Box sx={{ pb: 4 }}>
              <Button
                variant="outlined"
                onClick={() => setProductMoreInfo(null)}
                sx={{ float: 'left', px: 0 }}
              >
                {getIcon('controls/close')}
              </Button>
              <Button
                variant="contained"
                color={isHasInStore() ? 'warning' : 'info'}
                sx={{ float: 'right' }}
                onClick={isHasInStore() ? delFromStore : addToStore}
              >
                <Typography sx={{ textTransform: 'capitalize' }}>
                  {isHasInStore()
                    ? t('DELETE FROM CAMPAIGN')
                    : t('ADD TO CAMPAIGN')}
                </Typography>
              </Button>
            </Box>
          </CardContent>
        </Card>
      </ProductsModalCardContainer>
      {product?.logo_rectangle_url && (
        <Card
          sx={{
            height: '100%',
            display: 'flex',
            padding: '20px',
            width: '49%',
            minWidth: '550px',
            minHeight: '254px'
          }}
        >
          <Box
            style={{
              background: '#FFFFFF',
              border: '1px solid #C9CAD9',
              borderRadius: '5px',
              width: '150px',
              height: '65px',
              padding: '10px'
            }}
          >
            <SvgIconStyle
              src={
                product.logo_rectangle_url
                  ? product.logo_rectangle_url[0]?.url
                  : ''
              }
            />
          </Box>
          <CardContent
            sx={{ height: '100%', padding: '0 0 0 30px !important' }}
          >
            <Box
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between'
              }}
            >
              <Grid container spacing={2} style={{ margin: 0 }}>
                <Grid
                  item
                  xs={12}
                  style={{
                    padding: '0',
                    display: 'flex',
                    rowGap: '10px',
                    flexDirection: 'column'
                  }}
                >
                  <Typography className={classes.description}>
                    {product.title}
                  </Typography>
                  <Typography display="flex">
                    <Typography fontWeight="bold">
                      {product.duration.range}
                    </Typography>
                    &nbsp; &nbsp;
                    {product.duration.period}
                  </Typography>
                  <Typography display="flex">
                    <Typography fontWeight="bold">
                      {product.vonq_price[0]?.currency}
                    </Typography>
                    &nbsp; &nbsp;
                    {product.vonq_price[0]?.amount}
                  </Typography>
                  <Link href={product.homepage} target="_blank">
                    <Typography
                      sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        color: '#9B5FDA'
                      }}
                    >
                      {product.homepage}
                    </Typography>
                  </Link>
                </Grid>
              </Grid>
              <Box>
                <Box
                  sx={{ display: 'flex' }}
                  style={{ columnGap: '20px', marginTop: '20px' }}
                >
                  <Button
                    onClick={isHasInStore() ? delFromStore : addToStore}
                    variant="contained"
                    style={{
                      background:
                        'linear-gradient(92.25deg, #9B5FDA -2.57%, #562C82 113.38%)',
                      borderRadius: '30px',
                      padding: '10px 30px'
                    }}
                  >
                    {isHasInStore()
                      ? t('delete From Basket')
                      : t('Add To Basket')}
                  </Button>
                  <Button
                    onClick={() => setProductMoreInfo(product)}
                    variant="text"
                    style={{
                      color: '#1C9DD7',
                      padding: '10px 30px'
                    }}
                  >
                    {t('More info')}
                    <DoubleArrow
                      style={{
                        fontSize: '18px',
                        margin: '0 0 5px 5px'
                      }}
                    />
                  </Button>
                </Box>
              </Box>
            </Box>
          </CardContent>
        </Card>
      )}
    </>
  );
};

export default ProductCard;
