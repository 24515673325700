import { useEffect, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

interface UseURLFiltersProps {
  fragmentIdentifier: string;
  filterNames: string[];
  setterFunction: (key: string, value: any) => void;
  currentFilters: Record<string, any>;
}

const useURLFilters = ({
  fragmentIdentifier,
  filterNames,
  setterFunction,
  currentFilters
}: UseURLFiltersProps) => {
  const [initialized, setInitialized] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();

  const setFilter = useCallback(
    (key: string, value: any) => {
      dispatch(setterFunction(key, value));
    },
    [dispatch]
  );

  // Stable function to parse the URL parameters
  const parseParams = useCallback(
    (url: string) => {
      const params = new URLSearchParams(url);
      const filters: Record<string, string | null> = {};

      filterNames.forEach((filter) => {
        filters[filter] = params.get(filter);
      });

      return filters;
    },
    [filterNames]
  );

  useEffect(() => {
    if (!initialized) {
      // Parse filters from the URL on the initial render
      const url =
        location.search ||
        (location.hash && location.hash.replace(fragmentIdentifier, ''));

      const filters = parseParams(url);

      // Set the filters if they exist in the URL and haven't been applied yet
      Object.entries(filters).forEach(([key, value]) => {
        if (value !== null && currentFilters[key] !== value) {
          setFilter(key, value);
        }
      });

      // Mark the filters as initialized so this effect doesn't run again
      setInitialized(true);
    }
  }, [
    location.search,
    location.hash,
    parseParams,
    currentFilters,
    setFilter,
    initialized
  ]);
};

export default useURLFilters;
