import { useEffect, useState } from 'react';
import { getToDos } from 'requests/todos';
import { Avatar, Card, CardContent, Typography } from '@material-ui/core';
import { PATH_DASHBOARD } from 'routes/paths';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ViewtoDoList = () => {
  const { t } = useTranslation();
  const [lIst, setlIst] = useState<any>([]);

  const getDataToDos = () => {
    getToDos().then((data) => {
      setlIst(data.results);
    });
  };
  useEffect(() => {
    getDataToDos();
  }, []);

  return (
    <div className="view_contayner">
      {lIst &&
        lIst.map((toDoLIstItem: any) => {
          const ToDoid = toDoLIstItem.id;

          return (
            <Card key={toDoLIstItem.id} sx={{ width: '100%', my: 1 }}>
              <CardContent className="mine_todo">
                <Avatar src={' '} sx={{ mr: 2 }} alt="logo" />
                <RouterLink
                  className="toDo_text"
                  to={PATH_DASHBOARD.jobPosts.currentToDoURL(ToDoid)}
                >
                  {t(toDoLIstItem.title)}
                </RouterLink>
                <Typography className="complited_text">
                  {toDoLIstItem?.completed ? t('Competed task') : ' '}
                </Typography>
              </CardContent>
            </Card>
          );
        })}
    </div>
  );
};
export default ViewtoDoList;
