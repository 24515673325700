import { SetStateAction } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { uploadJobAI } from 'redux/slices/jobSlice';

interface uploadJobFileToAIProps {
  file: any;
  path: string;
  setShowUploader: (value: SetStateAction<boolean>) => void;
  setOpenAIPopUp: (value: SetStateAction<boolean>) => void;
  enqueueSnackbar: any;
  navigate: NavigateFunction;
  dispatch: any;
  translate: any;
}

export const UploadJobFileToAI = async ({
  file,
  path,
  setShowUploader,
  setOpenAIPopUp,
  enqueueSnackbar,
  navigate,
  dispatch,
  translate
}: uploadJobFileToAIProps) => {
  const formData = new FormData();

  const fileErrorMessage = 'The file could not be processed';

  if (file) {
    formData.append('file', file);

    try {
      setShowUploader(false);
      setOpenAIPopUp(true);
      const res = await dispatch(uploadJobAI(formData));
      if (!res.error) {
        navigate(path);
      } else {
        enqueueSnackbar(translate(fileErrorMessage), {
          variant: 'error'
        });
        setShowUploader(true);
      }
      setOpenAIPopUp(false);
    } catch (error) {
      enqueueSnackbar(translate(fileErrorMessage), {
        variant: 'error'
      });
      setShowUploader(true);
      setOpenAIPopUp(false);
    }
  }
};

export const colorNameToHex = (colorName: string): string => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  if (context) {
    context.fillStyle = colorName;
    return context.fillStyle;
  }

  return colorName;
};
