import useLocales from 'hooks/useLocales';
import Page from 'components/containers/Page';
import PageContainer from 'components/containers/PageContainer';
import DashboardNavbar from 'layouts/dashboard/navbar/DashboardNavbar';
import TabPanel from '../../components/TabPanel';
import Messenger from './Messenger';

const messengerIcon = '/static/img/icons/messanger.svg';
const messengerActiveIcon = '/static/img/icons/messangerActive.svg';
const colleaguesIcon = '/static/img/icons/candidateOverview.svg';
const colleaguesActiveIcon = '/static/img/icons/candidateOverviewActive.svg';

const MessengerPage = () => {
  const { t } = useLocales();

  const tabs = [
    {
      label: t('Candidates'),
      Component: <Messenger messengerVariant="candidates" />,
      icon: messengerIcon,
      activeIcon: messengerActiveIcon,
      path: 'candidates'
    },
    {
      label: t('Colleagues'),
      Component: <Messenger messengerVariant="colleagues" />,
      icon: colleaguesIcon,
      activeIcon: colleaguesActiveIcon,
      path: 'colleagues'
    }
  ];
  return (
    <Page title={t('Messenger')}>
      <PageContainer>
        <DashboardNavbar title={t('Messenger')} />
        <TabPanel tabs={tabs} dashboard={true} />
      </PageContainer>
    </Page>
  );
};

export default MessengerPage;
