import { pxToRem } from '../utils/formatFontSize';

// ----------------------------------------------------------------------

function responsiveFontSizes({
  sm,
  md,
  lg
}: {
  sm: number;
  md: number;
  lg: number;
}) {
  return {
    '@media (min-width:600px)': {
      fontSize: pxToRem(sm)
    },
    '@media (min-width:960px)': {
      fontSize: pxToRem(md)
    },
    '@media (min-width:1280px)': {
      fontSize: pxToRem(lg),
    }
  };
}

const FONT_PRIMARY = 'Rubik, sans-serif'; // Google Font

const typography = {
  fontFamily: FONT_PRIMARY,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 700,
  h1: {
    fontWeight: 700,
    lineHeight: 80 / 64,
    fontSize: pxToRem(60),
    ...responsiveFontSizes({ sm: 52, md: 58, lg: 60 })
  },
  h2: {
    fontWeight: 700,
    lineHeight: 64 / 48,
    fontSize: pxToRem(34),
    ...responsiveFontSizes({ sm: 30, md: 32, lg: 34 })
  },
  h3: {
    lineHeight: 1.5,
    fontWeight: 700,
    fontSize: pxToRem(26),
    ...responsiveFontSizes({ sm: 20, md: 24, lg: 26 })
  },
  h4: {
    lineHeight: 1.5,
    fontWeight: 500,
    fontSize: pxToRem(20),
    ...responsiveFontSizes({ sm: 20, md: 20, lg: 20 })
  },
  h5: {
    lineHeight: 1.5,
    fontWeight: 500,
    fontSize: pxToRem(18),
    ...responsiveFontSizes({ sm: 19, md: 20, lg: 20 })
  },
  h6: {
    fontWeight: 500,
    lineHeight: 28 / 18,
    fontSize: pxToRem(17),
    ...responsiveFontSizes({ sm: 18, md: 18, lg: 18 })
  },
  subtitle1: {
    fontSize: pxToRem(16),
    lineHeight: 1.5,
    fontWeight: 500
  },
  subtitle2: {
    fontSize: pxToRem(14),
    lineHeight: 22 / 14,
    fontWeight: 500
  },
  body1: {
    fontSize: pxToRem(16),
    lineHeight: 1.5
  },
  body2: {
    fontSize: pxToRem(14),
    lineHeight: 22 / 14
  },
  caption: {
    fontSize: pxToRem(12),
    lineHeight: 1.5
  },
  overline: {
    fontSize: pxToRem(12),
    lineHeight: 1.5,
    fontWeight: 700,
    letterSpacing: 1.2,
    textTransform: 'uppercase'
  },
  button: {
    fontWeight: 700,
    lineHeight: 24 / 14,
    fontSize: pxToRem(14),
    textTransform: 'capitalize'
  }
} as const;

export default typography;
