import { Box, Grid, makeStyles } from '@material-ui/core';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FDateCurrentTime } from 'utils/formatTime';

const generateFormattedArray = (limit: number): string[] =>
  Array.from({ length: limit }, (_, index) =>
    index.toString().padStart(2, '0')
  );

const hours: string[] = generateFormattedArray(24);
const minutes: string[] = generateFormattedArray(60);

const useStyles = makeStyles({
  timeHours: {
    zIndex: 999,
    borderRadius: '4px',
    marginTop: '4px',
    background: '#ffffff',
    width: '25px',
    height: '293px',
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: '20px',
    padding: '20px 30px',
    overflowY: 'scroll',
    overflowX: 'hidden',
    boxShadow: '0px 0px 69px #DCDCDD',
    '&::-webkit-scrollbar': {
      borderRadius: '2px',
      background: '#ffffff',
      width: '7px',
      height: '39px'
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#562C82',
      borderRadius: '10px'
    }
  },
  timeMinutes: {
    zIndex: 999,
    boxShadow: '0px 0px 69px #DCDCDD',
    borderRadius: '4px',
    marginTop: '4px',
    background: '#ffffff',
    width: '25px',
    height: '293px',
    right: '0px',
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: '20px',
    padding: '20px 30px',
    overflowY: 'scroll',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      borderRadius: '2px',
      background: '#ffffff',
      width: '7px',
      height: '39px'
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#562C82',
      borderRadius: '10px'
    }
  },
  monthHov: {
    '&:hover': {
      color: '#562c82',
      fontFamily: 'Rubik',
      cursor: 'pointer'
    }
  },
  disabled: {
    color: 'rgba(0, 23, 84, 0.15)',
    cursor: 'normal'
  },
  duration: {
    zIndex: 999,
    boxShadow: '0px 0px 69px #DCDCDD',
    borderRadius: '4px',
    marginTop: '4px',
    background: '#ffffff',
    width: '20px',
    height: '293px',
    right: '10px',
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: '20px',
    padding: '20px 30px',
    overflowY: 'scroll',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      borderRadius: '2px',
      background: '#ffffff',
      width: '7px',
      height: '39px'
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#562C82',
      borderRadius: '10px'
    }
  }
});

export default function TimePicker({
  formData,
  setFormData,
  field,
  fromDuration = false,
  isCandidate=false
}: any) {
  const [openTimePopUp, setOpenTimePopUp] = useState<boolean>(false);
  const [closedPopUpMinutes, setClosedPopUpMinutes] = useState<boolean>(false);
  const [closedPopUpHours, setClosedPopUpHours] = useState<boolean>(false);
  const [time, setTime] = useState<any>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const classes = useStyles();
  const containerRef = useRef<HTMLDivElement>(null)
  const { t } = useTranslation();

  const openTime = () => {
    if(!isCandidate){
      setOpenTimePopUp(!openTimePopUp);
      setIsOpen(!isOpen);
      setClosedPopUpMinutes(true);
      setClosedPopUpHours(true);
    }
  };

  useEffect(() => {
    if (formData?.start) {
      const newDate = new Date(formData.start);
      const currentDate: Date = new Date();

      if (newDate.getDate() === currentDate.getDate()) {
        newDate.setHours(new Date().getHours());
        newDate.setMinutes(new Date().getMinutes() + 1);
      }
      setTime(newDate);
    }
  }, [formData]);

  const handleCheckTime = (time: number, type: string): boolean => {
    const newDate = new Date(formData?.start);
    let setDateValue = null;
    if (type === 'minute') {
      setDateValue = newDate.setMinutes(time);
    } else if (type === 'hour') {
      setDateValue = newDate.setHours(time);
    }
    return !!(
      setDateValue && new Date().getTime() < new Date(setDateValue).getTime()
    );
  };

  const handleTimeSelection = (time: number, type: string): void => {
    const newDate = new Date(formData[field]);
    const setDateValue = fromDuration || handleCheckTime(time + 1, type);

    if (setDateValue) {
      if (type === 'minute') {
        newDate.setMinutes(time);
        setClosedPopUpMinutes(false);
      } else if (type === 'hour') {
        newDate.setHours(time);
        setClosedPopUpHours(false);
      }
      setFormData({
        ...formData,
        [field]: newDate
      });
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
      setOpenTimePopUp(false);
      setIsOpen(false);
      setClosedPopUpMinutes(false);
      setClosedPopUpHours(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Grid
      sx={{
        width: '140px',
        position: 'relative'
      }}
      ref={containerRef}
    >
      <h5
        style={{
          color: '#666666',
          fontSize: '0.95rem',
          fontWeight: '400',
          textAlign: 'start',
          paddingBottom: '4px'
        }}
      >
        {!fromDuration ? t('Time') : t('Duration')}
      </h5>
      <Box
        style={{
          position: 'relative',
          display: 'flex'
        }}
        onClick={openTime}
      >
        <div
          style={{
            width: '150px',
            padding: '20px 10px 15px 20px',
            borderRadius: '5px',
            border: '1px solid #C9CAD9',
            cursor: 'pointer',
            fontSize: '14px',
            textAlign: 'initial',
            height: '56px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          {time
            ? FDateCurrentTime(
              formData[field],
              fromDuration ? 'duration' : 'HH:mm'
            )
            : time}

          {!openTimePopUp ? <KeyboardArrowDownIcon  style={{color:"#562C82"}}/> : <KeyboardArrowUpIcon  style={{color:"#562C82"}}/>}
        </div>
      </Box>
      <Box>
        {openTimePopUp && (
          <Box
            style={{
              background: 'red'
            }}
          >
            <Box
              style={{
                display: 'flex',
                background: 'red',
                zIndex: '9999'
              }}
            >
              {isOpen && (
                <Box display="flex" columnGap="5px">
                  {closedPopUpMinutes && (
                    <Box className={classes.timeMinutes}>
                      {minutes.map((minute) => (
                        <Box
                          key={minute}
                          className={
                            !handleCheckTime(Number(minute) + 1, 'minute') &&
                              !fromDuration
                              ? classes.disabled
                              : classes.monthHov
                          }
                          onClick={() =>
                            handleTimeSelection(Number(minute), 'minute')
                          }
                        >
                          {minute}
                        </Box>
                      ))}
                    </Box>
                  )}
                  {closedPopUpHours && (
                    <Box className={classes.timeHours}>
                      {hours.map((hour) => (
                        <Box
                          key={hour}
                          className={
                            !handleCheckTime(Number(hour) + 1, 'hour') &&
                              !fromDuration
                              ? classes.disabled
                              : classes.monthHov
                          }
                          onClick={() =>
                            handleTimeSelection(Number(hour), 'hour')
                          }
                        >
                          {hour}
                        </Box>
                      ))}
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          </Box>
        )}
      </Box>
    </Grid>
  );
}
